import React from "react";
import style from "./style.module.css";

const SearchItem = ({ item, setItem }) => {
  const onClickHandler = () => setItem({ ...item });

  return (
    <div className={style.pushItem} onClick={onClickHandler}>
      <div className={style.pushTitle}>{item.title}</div>
      <div className={style.pushBody}>{item.body}</div>
    </div>
  );
};

export default SearchItem;
