/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type {
  ActionType,
  ApiState,
  NotificationType,
  AddNotificationActionPayload,
  ModifyNotificationActionPayload,
  AdminPanelStore,
} from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'api';

// Constants (up)

// Reducer (down)


const initialState: ApiState = {
  notifications: [],
};

export default function reducer(state: ApiState = initialState, action: ActionType<AddNotificationActionPayload & ModifyNotificationActionPayload>): ApiState {
  const { type, payload } = action;

  if (!payload) {
    return { ...state };
  }

  switch (type) {
    case actionTypes.API_ADD_NOTIFICATION: {
      const { notification } = (payload: AddNotificationActionPayload);

      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...notification,
            opened: true,
          }],
      };
    }

    case actionTypes.API_CLOSE_NOTIFICATION: {
      const { notificationId } = (payload: ModifyNotificationActionPayload);

      return {
        ...state,
        notifications: state.notifications.map((notification: NotificationType) => (
          notification.id === notificationId ? {
            ...notification,
            opened: false,
          } : notification
        )),
      };
    }

    case actionTypes.API_REMOVE_NOTIFICATION: {
      const { notificationId } = (payload: ModifyNotificationActionPayload);

      return {
        ...state,
        notifications: state.notifications.filter(({ id }: NotificationType) => (
          id !== notificationId
        )),
      }
    }

    case actionTypes.API_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

// Reducer (up)

// Selects (down)

const stateSelector: InputSelector<AdminPanelStore, any, ApiState> = (state: AdminPanelStore, _props: any): ApiState => state[moduleName];
const apiNotificationsCombiner: (s: ApiState) => NotificationType[] = (s: ApiState): NotificationType[] => s.notifications;
const preparedNotificationsCombiner: (notifications: NotificationType[]) => NotificationType[] = (notifications: NotificationType[]): NotificationType[] => ([
  ...notifications.filter(({ withReload }) => !!withReload).slice(0, 1),
  ...notifications.filter(({ withReload }) => !withReload)
]);

export const selectApiNotifications: OutputSelector<AdminPanelStore, any, NotificationType[]> = createSelector(stateSelector, apiNotificationsCombiner);
export const selectPreparedNotifications: OutputSelector<AdminPanelStore, any, NotificationType[]> = createSelector(selectApiNotifications, preparedNotificationsCombiner);

// Selects (up)
