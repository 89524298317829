/** @flow */

import { v4 } from 'uuid';

import type { ActionType, ConfigData } from '../../types';
import { actionTypes } from './index';
import {
  CONFIG_NAME,
  CONFIG_LOADING_ERROR_MESSAGE,
  configIncorrectFormatErrorFactory
} from '../../constants'
import { apiAddNotification, NOTIFICATION_TYPE } from './api';

function setConfigLoadingError(): ActionType {
  return {
    type: actionTypes.SET_CONFIG_ERROR
  }
}

export function setConfigData(payload: ConfigData): ActionType<ConfigData> {
  return {
    type: actionTypes.SET_CONFIG_DATA,
    payload,
  };
}

export const getConfig = () => async dispatch => {
  fetch(CONFIG_NAME)
    .then(async res => {
      try {
        if (!res.ok) {
          throw new Error(CONFIG_LOADING_ERROR_MESSAGE);
        }
        let data;
        try {
          data = await res.json();
        } catch (e) {
          dispatch(setConfigLoadingError());
          dispatch(apiAddNotification({
            id: v4(),
            message: configIncorrectFormatErrorFactory(e.message),
            type: NOTIFICATION_TYPE.ERROR,
          }));
          return;
        }
        if (!data.hidePartnerByDefault) {
          throw new Error(configIncorrectFormatErrorFactory('hidePartnerByDefault'));
        }
        dispatch(setConfigData({ hiddenPartnerName: data.hidePartnerByDefault }));
      } catch (e) {
        dispatch(setConfigLoadingError());
        dispatch(apiAddNotification({
          id: v4(),
          message: e.message,
          type: NOTIFICATION_TYPE.ERROR,
        }));
      }
    });
}