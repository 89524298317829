import React, { useState } from 'react';
import { Card, DatePicker, Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import style from './style.module.css';
import { apiUrls } from '../../store/api';
import { fetchFileBlob } from '../../store/api/fetch-wrapper';


const { RangePicker } = DatePicker;

const Finances = ({ userData }) => {
  const [range, setRange] = useState([]);
  const csvDownloadAnchor = React.createRef();

  const onRangeDateChange = date => {
    setRange(date);
  };

  const onCSVExportClick = async () => {
    const { userAuth } = userData;
    const [dateFrom, dateTo] = range;
    const startDate = dateFrom?.startOf('day');
    const endDate = dateTo?.endOf('day');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userAuth}`,
    };

    try {
      const url = new URL(apiUrls.GET_FINANCES_CSV);
      if (startDate) url.searchParams.append('startDate', `${startDate.format('YYYY-MM-DDTHH:mm:ss')  }.000Z`);
      if (endDate) url.searchParams.append('endDate', `${endDate.format('YYYY-MM-DDTHH:mm:ss')  }.000Z`);
      const [csvFileBlob, csvFileName] = await fetchFileBlob(url, {
        method: 'GET',
        headers,
      });
      const objectURL = URL.createObjectURL(csvFileBlob);
      const downloadAnchor = csvDownloadAnchor.current;
      downloadAnchor.href = objectURL;
      downloadAnchor.download = csvFileName;
      downloadAnchor.click();
    } catch (error) {
      throw new Error('Failed to download CSV')
    }
  };

  return (
    <Card cardClass="route-content" className={style.card}>
      <h2>Finances</h2>
      <Card title="Statistics by partners" type="inner">
        <div>
          <RangePicker value={range} onChange={onRangeDateChange} />
          <Button key="1" type="primary" onClick={onCSVExportClick} className={style.button}>Export CSV</Button>
        </div>
      </Card>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/control-has-associated-label */}
      <a style={{ display: 'none' }} href='' ref={csvDownloadAnchor} />
    </Card>
  );
};

Finances.propTypes = {
  userData: PropTypes.shape({
    userAuth: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  userData: state.loggedInUser,
});

export default connect(mapStateToProps)(Finances);
