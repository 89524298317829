/** @flow */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Save as SaveIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import type { SectorType } from '../../../store/reducers/sectors';
import type { PartnerType } from '../../../store/reducers/partners';
import Select from '../../select';
import Input from '../../input';
import Textarea from '../../textarea';
import { SECTOR_TYPE, SERVICE_TYPE } from '../sector-information/sector-information';

import style from './sector-form.module.css';

type SectorRemovingDialogProps = {
  open: boolean,
  editSector: SectorType,
  sectors: SectorType,
  partners: PartnerType,
  onCancel: () => void,
  onSubmit: () => void,
};

const useStyles = makeStyles({
  footer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#068f06',
    '&:hover': {
      backgroundColor: '#19a919'
    }
  }
});

const SELECT_ITEM = {
  name: 'Select',
  value: '',
};
const TITLE_SECTOR = 'Sector';
const TITLE_CATEGORY = 'Category';
const TITLE_SERVICE = 'Service';
const SECTOR = 'sector';
const CATEGORY = 'category';
const NAME = 'category_services_name';
const TAG = 'category_services_tag';
const MAX_COST = 'maxCost';
const IMG_URL = 'imgUrl';
const SORT_ORDER = 'sortOrder';
const DESCRIPTION = 'description';

function SectorForm(props: SectorRemovingDialogProps) {
  const { open, editSector, sectors, partners, onCancel, onSubmit } = props;
  const classes = useStyles();
  const title = editSector ? 'Edit' : 'Add New';
  const [titleType, setTitleType] = React.useState<string>(TITLE_SECTOR);
  const [selectedSector, setSelectedSector] = React.useState<string>('');
  const [selectedCategory, setSelectedCategory] = React.useState<string>('');
  const [categoryServicesInfo, setCategoryServicesInfo] = React.useState({});
  const [categoryServicesError, setCategoryServicesError] = React.useState({});
  const [selectedPartners, setSelectedPartners] = React.useState([]);

  const getSectors = () => {
    const sectorsData = [];
    sectors.forEach(sector => {
      if (!editSector || sector.tag !== editSector.tag) {
        sectorsData.push({
          name: sector.name,
          value: sector.tag,
        });
      }
    });
    return [SELECT_ITEM, ...sectorsData];
  };

  const getCategories = () => {
    const sectorCategories = [SELECT_ITEM];

    if (selectedSector) {
      sectors.forEach(sector => {
        if (selectedSector === sector.tag && sector.childs && sector.childs.length) {
          sector.childs.forEach(category => {
            if (!editSector || category.tag !== editSector.tag) {
              sectorCategories.push({
                name: category.name,
                value: category.tag,
              })
            }
          });

        }
      });
    }

    return sectorCategories;
  };

  const sectorsData = getSectors();
  const categoriesData = getCategories();

  const getTitleType = () => {
    let titleType = TITLE_SECTOR;
    if (selectedSector && selectedCategory) {
      titleType = TITLE_SERVICE;
    } else if (selectedSector) {
      titleType = TITLE_CATEGORY;
    }

    return titleType;
  }

  React.useEffect(() => {
    const relatedPartners = editSector && editSector.relatedPartners ? editSector.relatedPartners : [];
    setSelectedPartners(relatedPartners);
  }, [editSector]);

  React.useEffect(() => {
    setTitleType(getTitleType());
  }, [selectedSector, selectedCategory]);

  React.useEffect(() => {
    if (editSector) {
      const { name, tag, description, imgUrl, maxCost, sortOrder, parents } = editSector;
      const [editSelectedSector, editSelectedCategory] = parents || [];
      setCategoryServicesInfo({
        category_services_name: name || '',
        category_services_tag: tag || '',
        description: description || '',
        imgUrl: imgUrl || '',
        maxCost: maxCost || '',
        sortOrder: sortOrder || '',
      });

      editSelectedSector && setSelectedSector(editSelectedSector);
      editSelectedCategory && setSelectedCategory(editSelectedCategory);
    }
  }, [editSector]);

  const handleOnSelectSector = e => {
    const { value } = e.target;

    setSelectedSector(value);
    setSelectedCategory('');
    setCategoryServicesError({});
  };

  const handleOnSelectCategory = e => {
    const { value } = e.target;

    setSelectedCategory(value);
    setCategoryServicesError({});
  };

  const handleOnChange = e => {
    const { name, value } = e.target;

    setCategoryServicesInfo({ ...categoryServicesInfo, [name]: value });
    setCategoryServicesError({});
  };

  const handleOnChangeTag = e => {
    const { name, value } = e.target;
    const tag = value.replace(/\s/g, '');

    setCategoryServicesInfo({ ...categoryServicesInfo, [name]: tag.toLowerCase() });
    setCategoryServicesError({});
  }

  const handleOnChangeMaxCost = e => {
    const { name, value } = e.target;
    const maxCost = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');

    setCategoryServicesInfo({ ...categoryServicesInfo, [name]: maxCost });
    setCategoryServicesError({});
  }

  const handleOnChangeSortOrder = e => {
    const { name, value } = e.target;
    const sortOrder = value.replace(/[^\d]/g, '');

    setCategoryServicesInfo({ ...categoryServicesInfo, [name]: sortOrder });
    setCategoryServicesError({});
  }

  const handleOnChangePartners = e => {
    const { name, checked } = e.target
    let partners;

    if (checked) {
      partners = [...selectedPartners, name]
    } else {
      partners = selectedPartners.filter(selectedPartner => selectedPartner !== name);
    }

    setSelectedPartners(partners);
  }

  const checkTagExist = (tag, items = sectors) => items.some(item => {
    if (editSector && editSector.tag === tag) {
      return false;
    }
    const isExist = item.tag === tag;
    if (!isExist && item.childs && item.childs.length) {
      return checkTagExist(tag, item.childs);
    }
    return isExist;
  });

  const validation = () => {
    const validation = {}

    if (!categoryServicesInfo[NAME]) {
      validation[NAME] = 'The name field is required.'
    }
    if (!categoryServicesInfo[TAG]) {
      validation[TAG] = 'The tag field is required.'
    } else if(checkTagExist(categoryServicesInfo[TAG])) {
      validation[TAG] = 'This tag already exists.'
    }

    return validation;
  }

  const clearState = () => {
    setSelectedSector('');
    setSelectedCategory('');
    setCategoryServicesInfo({});
    setCategoryServicesError({});
  }

  const handleClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onCancel();
    clearState();
  };

  const handleSave = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const formErrors = validation();

    if (Object.keys(formErrors).length) {
      setCategoryServicesError(formErrors);
    } else {
      let method = 'POST';
      const data = {
        name: categoryServicesInfo[NAME],
        tag: categoryServicesInfo[TAG],
        imgUrl: categoryServicesInfo[IMG_URL] || '',
        sortOrder: parseInt(categoryServicesInfo[SORT_ORDER], 10) || 0,
        maxCost: parseFloat(categoryServicesInfo[MAX_COST]) || 0,
        description: categoryServicesInfo[DESCRIPTION] || '',
        relatedPartners: selectedPartners || [],
      }
      if (selectedSector || selectedCategory) {
        data.parentTag = selectedCategory || selectedSector;
      }

      if (editSector && editSector.id) {
        data.id = editSector.id;
        method = 'PUT';
      }

      onSubmit(method, data);
      clearState();
    }
  };

  const renderPartners = partners.map(partner => {
    const checked = selectedPartners.some(selectedPartner => selectedPartner === partner.tag);
    return (
      <div className={style.partner}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={checked}
              onChange={handleOnChangePartners}
              name={partner.tag}
              color="primary"
            />
          )}
          label={partner.name}
        />
      </div>
    );
  });

  return (
    <Dialog
      fullWidth
      open={open}
    >
      <DialogTitle>
        {`${title} ${titleType}`}
      </DialogTitle>
      <DialogContent>
        {(!editSector || (editSector && editSector.editItemType !== SECTOR_TYPE)) && (
          <div className={style.fieldGroup}>
            <div className={style.fieldLabel}>Service sector:</div>
            <Select
              name={SECTOR}
              value={selectedSector}
              data={sectorsData}
              onChange={handleOnSelectSector}
            />
          </div>
        )}
        {(((editSector && editSector.editItemType === SERVICE_TYPE) || !editSector) && (selectedSector && categoriesData.length > 1)) && (
          <div className={style.fieldGroup}>
            <div className={style.fieldLabel}>Service category:</div>
            <Select
              name={CATEGORY}
              value={selectedCategory}
              data={categoriesData}
              onChange={handleOnSelectCategory}
            />
          </div>
        )}
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Name:</div>
          <Input
            name={NAME}
            value={categoryServicesInfo[NAME] || ''}
            onChange={handleOnChange}
          />
          {categoryServicesError[NAME] && <div className={style.fieldError}>{categoryServicesError[NAME]}</div>}
        </div>
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Tag:</div>
          <Input
            name={TAG}
            value={categoryServicesInfo[TAG] || ''}
            onChange={handleOnChangeTag}
          />
          {categoryServicesError[TAG] && <div className={style.fieldError}>{categoryServicesError[TAG]}</div>}
        </div>
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Max cost:</div>
          <Input
            name={MAX_COST}
            value={categoryServicesInfo[MAX_COST] || ''}
            onChange={handleOnChangeMaxCost}
          />
        </div>
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Image URL:</div>
          <Input
            name={IMG_URL}
            value={categoryServicesInfo[IMG_URL] || ''}
            onChange={handleOnChange}
          />
        </div>
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Sort order:</div>
          <Input
            name={SORT_ORDER}
            value={categoryServicesInfo[SORT_ORDER] || ''}
            onChange={handleOnChangeSortOrder}
          />
        </div>
        {!!partners.length && (
          <div className={style.fieldGroup}>
            <div className={style.fieldLabel}>Partners:</div>
            <div className={style.partnersWrap}>
              {renderPartners}
            </div>
          </div>
        )}
        <div className={style.fieldGroup}>
          <div className={style.fieldLabel}>Description:</div>
          <Textarea
            name={DESCRIPTION}
            value={categoryServicesInfo[DESCRIPTION] || ''}
            onChange={handleOnChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={handleClose} color="primary" size="medium">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SectorForm;
