/** @flow */

import { AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SectorInformation from '../sector-information';
import type { SectorType } from '../../../store/reducers/sectors';

const useStyles = makeStyles({
  childrenList: {
    flexDirection: 'column',
    padding: '8px 16px 16px 30px',
  },
  sectorInformationPaper: {
    padding: '16px',
    border: '1px solid #000',
    marginBottom: '16px',
    boxShadow: 'none',
  },
  accordionWrapper: {
    border: '1px solid #000',
    marginBottom: '16px',
    boxShadow: 'none',
  }
});

type SectorPanelItemProps = {
  sector: SectorType,
  parents: string[],
  handleEditSector: () => void
};

function SectorPanelItem(props: SectorPanelItemProps) {
  const { sector, parents = [], handleEditSector } = props;
  const { childs } = sector;
  const classes = useStyles();

  const sectorInformation = React.useMemo(() => (
    <SectorInformation sector={sector} parents={parents} handleEditSector={handleEditSector} />
  ), [sector, parents]);

  if (!childs.length) {
    return (
      <Paper className={classes.sectorInformationPaper}>
        {sectorInformation}
      </Paper>
    );
  }

  return (
    <Accordion className={classes.accordionWrapper}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {sectorInformation}
      </AccordionSummary>
      <AccordionDetails className={classes.childrenList}>
        {childs.map((item: SectorType) => (
          <SectorPanelItem key={item.id} sector={item} parents={[...parents, sector.tag]} handleEditSector={handleEditSector} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default SectorPanelItem;
