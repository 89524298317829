/** @flow */

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import type { SectorInformationType } from '../sector-information/sector-information';

type SectorRemovingDialogProps = {
  open: boolean,
  data: SectorInformationType,
  onCancel: () => void,
  onRemove: () => void,
};

function SectorRemovingDialog(props: SectorRemovingDialogProps) {
  const { open, data: { name, tag }, onCancel, onRemove } = props;

  const handleRemove = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onRemove();
  };

  const handleClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onCancel();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Are you sure you want to remove <b>{name} ({tag})</b>
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRemove} color="primary" autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SectorRemovingDialog;
