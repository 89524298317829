/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';

import Card from '../../components/Layout/card';
import { getPendings, clearPendings } from '../../store/actions/pending';
import { PAGE_SIZE, ROUTES } from '../../constants';
import { pendingTableColumns as columns } from '../../helpers/table-data';

class Requests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: { order: 'descend', field: 'updateProData.id', columnKey: 'updateProData.id' },
      pagination: { pageSize: PAGE_SIZE, current: 1 }
    };
  }

  componentDidMount() {
    this.userAuth = this.props.userData.userAuth;
    this.userAuth && this.props.getPendings(this.userAuth);
  }

  componentWillUnmount() {
    this.props.clearPendings();
  }

  onRow = record => ({
    onClick: async () => {
      const { history } = this.props;
      const userId = record ? record.id : '';

      history.push({
        pathname: `${ROUTES.PRO_REQUEST}/${userId}`
      });
    }
  });

  onChange = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      sortedInfo: sorter,
      pagination: prevState.pagination.current !== pagination.current
        ? pagination
        : { ...pagination, current: 1 }
    }));
  };

  render() {
    const {
      message = '',
      error: isError,
      load: isLoaded,
      pendings = []
    } = this.props.pending;
    const { sortedInfo, pagination } = this.state;

    return (
      <Card cardClass="route-content" headerText="Pro applications">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoaded ? (
          isError ? (
            <div className="errorContainer">
              Error!
              <div className="errorMsg">{message}</div>
            </div>
          ) : (
            <Table
              columns={columns(sortedInfo)}
              rowKey={record => record.id}
              onChange={this.onChange}
              onRow={this.onRow}
              pagination={pagination}
              size="small"
              dataSource={pendings.length ? pendings : []}
              locale={{ emptyText: 'No Pro Details for approve' }}
              scroll={{ x: true }}
            />
          )
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  pending: state.pending
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPendings,
  clearPendings,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Requests));
