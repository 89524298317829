import { apiUrls } from './index';
import { http } from './fetch-wrapper';

export const getCategories = authData => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  return http(apiUrls.GET_CATEGORIES, { method: 'GET', headers });
};

export const getTotalStatistics = authData => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  return fetch(apiUrls.GET_TOTAL_STATISTICS, { method: 'GET', headers }).then(
    response =>
      response
        .json()
        .then(json => json)
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          return { error: true };
        })
  );
};

export const getStatistics = (authData, queryArr = []) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  let query = '';
  queryArr.forEach(({ name, value }, i) => {
    if (i === 0) {
      query = `?${name}=${value}`;
    } else {
      query += `&${name}=${value}`;
    }
  });

  return fetch(apiUrls.GET_STATISTICS + query, { method: 'GET', headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: 'You don’t have enough rights'
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
}
