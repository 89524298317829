import * as api from "../api/featuredService";
import { actionTypes } from "./index";

const clearAction = type => ({ type });

export const addFeaturedService = (data, authData) => async dispatch => {
  dispatch({ type: actionTypes.ADD_FEATURED_SERVICE_START });
  const response = await api.addFeaturedService(data, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.ADD_FEATURED_SERVICE_FAILURE,
      message: response.message
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
      dispatch(getAllFeaturedService(authData));
    }
  } else {
    dispatch({ type: actionTypes.ADD_FEATURED_SERVICE_SUCCESS, featuredServices: response });
  }
};

export const removeFeaturedService = (data, authData) => async dispatch => {
  dispatch({ type: actionTypes.REMOVE_FEATURED_SERVICE_START });
  const response = await api.removeFeaturedService(data, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.REMOVE_FEATURED_SERVICE_FAILURE,
      message: response.message,
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
      dispatch(getAllFeaturedService(authData));
    }
  } else {
    dispatch({ type: actionTypes.REMOVE_FEATURED_SERVICE_SUCCESS });
    dispatch(getAllFeaturedService(authData));
  }
};

export const editFeaturedService = (data, authData) => async dispatch => {
  dispatch({ type: actionTypes.EDIT_FEATURED_SERVICE_START });
  const response = await api.editFeaturedService(data, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.EDIT_FEATURED_SERVICE_FAILURE,
      message: response.message,
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
      dispatch(getAllFeaturedService(authData));
    }
  } else {
    dispatch({ type: actionTypes.EDIT_FEATURED_SERVICE_SUCCESS });
    dispatch(getAllFeaturedService(authData));
  }
};

export const getAllFeaturedService = (authData) => async dispatch => {
  dispatch({ type: actionTypes.GET_ALL_FEATURED_SERVICES_START });
  const response = await api.getAllFeaturedService(authData);
  if (response.error) {
    dispatch({
      type: actionTypes.GET_ALL_FEATURED_SERVICES_FAILURE,
      message: response.message,
    });
  } else {
    dispatch({ type: actionTypes.GET_ALL_FEATURED_SERVICES_SUCCESS, featuredServices: response });
  }
};

export const clearFeaturedServices = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_FEATURED_SERVICES));
};
