/** @flow */

import type { ActionType } from '../../types';
import { actionTypes } from './index';

export function callsSetDays(days: string): ActionType<{ days: string }> {
  return {
    type: actionTypes.CALLS_SET_DAYS,
    payload: { days },
  };
}

export function callsSetRange(
  range: any[]
): ActionType<{ range: any[] }> {
  return {
    type: actionTypes.CALLS_SET_RANGE,
    payload: { range },
  };
}
