/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';

import Card from '../../components/Layout/card';

import { getInvites, clearInvites } from '../../store/actions/user';

import { invitesColumns as columns } from '../../helpers/table-data';
import { PAGE_SIZE } from '../../constants';

class Invites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: {},

      isFiltered: false,
      filteredData: [],
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.userAuth = this.props.userData.userAuth;
    if (this.userAuth) {
      this.props.getInvites(this.userAuth);
    }
  }

  componentWillUnmount() {
    this.props.clearInvites();
  }

  onChange = (pagination, _filters, sorter) => {
    this.setState(prevState => ({
      sortedInfo: sorter,
      currentPage: prevState.currentPage !== pagination.current ? pagination.current : 1
    }));
  };

  onFilter = (statusFilter, generalLength) => {
    const { invites = [] } = this.props.uArrays;
    if (statusFilter.length === generalLength) {
      this.setState({ filteredData: [], isFiltered: false });
    } else {
      const newData = invites.filter(
        el => statusFilter.indexOf(el.status) !== -1
      );
      this.setState({ filteredData: newData, isFiltered: true });
    }
  };

  render() {
    const {
      load: isLoaded = false,
      error: isError = false,
      message: errorMsg = '',
      invites = []
    } = this.props.uArrays;

    const { currentPage } = this.state;

    const dataSource = this.state.isFiltered
      ? this.state.filteredData
      : invites;

    return (
      <Card cardClass="route-content">
        <p>Total number of invites: {invites.length}</p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoaded ? (
          !isError ? (
            <Table
              columns={columns(this.state.sortedInfo)}
              rowKey={record => record.id}
              onChange={this.onChange}
              pagination={{ pageSize: PAGE_SIZE, current: currentPage }}
              dataSource={dataSource}
              scroll={{ x: true }}
            />
          ) : (
            <div className="errorContainer">
              Error!
              <div className="errorMsg">{errorMsg}</div>
            </div>
          )
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  uArrays: state.usersArrays
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInvites,
      clearInvites
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invites);
