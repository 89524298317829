import React from "react";
import { useParams } from "react-router-dom";

import "antd/dist/antd.css";

import Card from "../../components/Layout/card";
import UserInfo from "../../components/user-info";

const UserArchived = () => {
  const { id } = useParams();

  return (
    <Card cardClass="route-content" headerText="Archived user">
      <UserInfo
        userId={id}
        isPending={false}
        isForDelete
      />
    </Card>
  );
};

export default UserArchived;
