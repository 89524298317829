import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logOff } from "../../../store/actions/user";

import style from "./style.module.css";
import { ROUTES } from "../../../constants/routes-constants";

const Header = ({ loggedInUser, logOff }) => (
  <header className={style.header}>
    <Link to={ROUTES.HOME}>
      <span className={style.headerTitle}>Telmie Admin App</span>
    </Link>
    {loggedInUser.userAuth && (
      <button type="button" className={style.logOutButton} onClick={logOff}>
        Log out
      </button>
    )}
  </header>
);

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logOff
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
