import { actionTypes } from "../actions";

export const featuredServices = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_FEATURED_SERVICE_START:
      return {
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.ADD_FEATURED_SERVICE_SUCCESS:
      return {
        error: false,
        loading: false,
        success: true,
        message: 'success adding featured service',
        featuredServices: action.featuredServices,
      };
    case actionTypes.ADD_FEATURED_SERVICE_FAILURE:
      return {
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };

    case actionTypes.REMOVE_FEATURED_SERVICE_START:
      return {
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.REMOVE_FEATURED_SERVICE_SUCCESS:
      return {
        error: false,
        loading: false,
        success: true,
        message: 'success remove featured service',
      };
    case actionTypes.REMOVE_FEATURED_SERVICE_FAILURE:
      return {
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };
    
    case actionTypes.EDIT_FEATURED_SERVICE_START:
      return {
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.EDIT_FEATURED_SERVICE_SUCCESS:
      return {
        error: false,
        loading: false,
        success: true,
        message: 'success editing featured service',
      };
    case actionTypes.EDIT_FEATURED_SERVICE_FAILURE:
      return {
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };
    case actionTypes.GET_ALL_FEATURED_SERVICES_START:
      return {
        error: false,
        loading: true,
        success: false,
        featuredServices: null,
      };
    case actionTypes.GET_ALL_FEATURED_SERVICES_SUCCESS:
      return {
        error: false,
        loading: false,
        success: true,
        featuredServices: action.featuredServices,
      };
    case actionTypes.GET_ALL_FEATURED_SERVICES_FAILURE:
      return {
        error: true,
        loading: false,
        success: false,
        featuredServices: [],
      };
    case actionTypes.CLEAR_FEATURED_SERVICES:
      return {
        error: false,
        loading: false,
        success: true,
        featuredServices: [],
      }  

    default:
      return {
        error: false,
        loading: false,
        success: true,
        message: '',
        featuredServices: [],
      }
  }
};