/** @flow */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import type { Node, Context, AbstractComponent } from 'react';

type OwnProps = $ReadOnly<{|
  children: Node;
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  currency: string,
|}>;

const FALLBACK_VALUE = '-';

const MONETARY_AMOUNT_SANITIZER = /[£$,]+/g;

export interface IInternationalizationContext {
  currency: string;
  getCurrentTimezone: () => string;
  formatCurrency: (x: number | string) => string;
  formatTimeAsHoursMinutes: (isoDateString: ?string) => string;
  formatTimeAsHoursMinutesWithTimeZone: (isoDateString: ?string) => string;
  formatNumericDateTimeWithTimeZone: (isoDateString: ?string) => string;
  formatAmPmTime: (hours: number, minutes: number) => string;
  format24HoursTime: (hours: number, minutes: number) => string;
}

const EMPTY_CONTEXT: IInternationalizationContext = {
  currency: '',
  getCurrentTimezone: () => FALLBACK_VALUE,
  formatCurrency: (x: number | string) => x.toString(),
  formatTimeAsHoursMinutes: (_isoDateString: ?string) => FALLBACK_VALUE,
  formatTimeAsHoursMinutesWithTimeZone: (_isoDateString: ?string) => FALLBACK_VALUE,
  formatNumericDateTimeWithTimeZone: (_isoDateString: ?string) => FALLBACK_VALUE,
  formatAmPmTime: (_hours: number, _minutes: number) => '',
  format24HoursTime: (_hours: number, _minutes: number) => '', 
 };


const TIME_FORMATTING_OPTIONS_HH_MM = {
  hour: 'numeric',
  minute: 'numeric',
};

const TIME_FORMATTING_OPTIONS_HH_MM_TIMEZONE = {
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short'
}
 
const FULL_DATE_TIME_FORMATTING_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

export const InternationalizationContext: Context<IInternationalizationContext> = React.createContext<
IInternationalizationContext
>(EMPTY_CONTEXT);

const formatAmPmTime = (hours: number, minutes: number) => {
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return strTime;
}

const format24HoursTime = (hours: number, minutes: number) => `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

const formatIsoDateStringWithFormatter: (
  isoDateString: ?string,
  formatter: Intl.DateTimeFormat
) => string = (isoDateString, formatter) => {
  if (!isoDateString) {
    return FALLBACK_VALUE;
  }

  const parsedDateAsMilliseconds = Date.parse(isoDateString);
  if (Number.isNaN(parsedDateAsMilliseconds)) {
    return FALLBACK_VALUE;
  }

  return formatter.format(parsedDateAsMilliseconds);
}

const Internationalization = ({ currency, children }: Props) => {
  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat(undefined, { style: 'currency', currency }),
    [currency],
  );

  const timeAsHHMMFormatter = useMemo(() => new Intl.DateTimeFormat(undefined, TIME_FORMATTING_OPTIONS_HH_MM));
  const timeAsHHMMwithTimeZoneFormatter = useMemo(() => new Intl.DateTimeFormat(undefined, TIME_FORMATTING_OPTIONS_HH_MM_TIMEZONE));
  const numericDateTimeWithTimeZoneFormatter = useMemo(() => new Intl.DateTimeFormat(undefined, FULL_DATE_TIME_FORMATTING_OPTIONS));
  
  const formatCurrency = (value: number | string): string => {
    const number = typeof value === 'string'
      ? Number.parseFloat(value.replace(MONETARY_AMOUNT_SANITIZER, ''))
      : value
    return (!Number.isNaN(number) && Number.isFinite(number))
      ? currencyFormatter.format(number)
      : FALLBACK_VALUE;
  }

  const formatTimeAsHoursMinutes = (isoDateString: ?string): string => 
    formatIsoDateStringWithFormatter(isoDateString, timeAsHHMMFormatter);
  
  const formatTimeAsHoursMinutesWithTimeZone = (isoDateString: ?string): string =>
    formatIsoDateStringWithFormatter(isoDateString, timeAsHHMMwithTimeZoneFormatter);

  const formatNumericDateTimeWithTimeZone = (isoDateString: ?string): string =>
    `${formatIsoDateStringWithFormatter(isoDateString, numericDateTimeWithTimeZoneFormatter)} ${formatIsoDateStringWithFormatter(isoDateString, timeAsHHMMwithTimeZoneFormatter)}`;

  const getCurrentTimezone = () => {
    const formatter = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });
    const timeZonePart = formatter.formatToParts().find(item => item.type === 'timeZoneName');
    return timeZonePart.value;
  }

  return (
    <InternationalizationContext.Provider
      value={{
        currency,
        getCurrentTimezone,
        formatCurrency,
        formatTimeAsHoursMinutes,
        formatTimeAsHoursMinutesWithTimeZone,
        formatNumericDateTimeWithTimeZone,
        formatAmPmTime,
        format24HoursTime
      }}
    >
      {children}
    </InternationalizationContext.Provider>
  );
};

const mapStateToProps = state => ({
  currency: (state.loggedInUser && state.loggedInUser.settings && state.loggedInUser.settings.currency) || 'GBP',
})

export default (connect(mapStateToProps)(Internationalization): AbstractComponent<OwnProps>)