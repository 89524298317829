import { actionTypes } from "../actions";

const initialState = {
  sectors: {},
  categories: {},
  services: {},
  users: {},
  chats: {},
  calls: {},
  transactions: {}
};

export const serverData = (state = initialState, action) => {
  const { type, statistics } = action;

  switch (type) {
    case actionTypes.SET_CATEGORIES: {
      const { categories: sectorsFullObject } = action;
      const sectors = {};
      const categories = {};
      const services = {};

      sectorsFullObject.forEach(sector => {
        sectors[sector.tag] = sector.name;
        sector.childs && sector.childs.length && sector.childs.forEach(category => {
          categories[category.tag] = {
            name: category.name,
            sector: sector.tag
          };
          category.childs && category.childs.length && category.childs.forEach(service => {
            services[service.tag] = {
              name: service.name,
              sector: sector.tag
            };
          });
        });
      });

      return {
        ...state,
        sectors,
        services,
        categories,
        sectorsFullObject,
      };
    }
    case actionTypes.GET_TOTAL_STATISTICS:
      return {
        ...state,
        ...statistics
      };
    case actionTypes.CLEAR_STATISTICS:
      return {};
    default:
      return state;
  }
};
