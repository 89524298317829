/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type { ActionType, CountryType, CountriesState, AdminPanelStore } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'countries';

// Constants (up)

// Reducer (down)

const initialState: CountriesState = {
  fetching: false,
  error: '',
  entities: [],
  failedEmails: null,
};

export default function reducer(state: CountriesState = initialState, action: ActionType<>): CountriesState {
  const { error, type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        fetching: true,
        error: initialState.error,
        entities: initialState.entities,
      };

    case actionTypes.FETCH_COUNTRIES_SUCCESS: {
      if (!payload) {
        return { ...state };
      }

      const { countries }: { countries: CountryType[] } = payload;

      return {
        ...state,
        fetching: false,
        entities: countries,
      };
    }

    case actionTypes.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: error || 'Cannot fetch countries list! Try again later',
        entities: initialState.entities,
      };

    case actionTypes.DELETE_COUNTRIES_FAILURE:
      return {
        ...state,
        error: error || 'Cannot delete country(s)! Try again later',
      };

    case actionTypes.SET_FAILED_AUTOLINKING_EMAILS:
      return {
        ...state,
        failedEmails: payload.failedEmails,
        error
      };

    case actionTypes.CLEANUP_COUNTRIES_FAILURE:
      return {
        ...state,
        error: '',
      };

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, CountriesState> = (s: AdminPanelStore, _props: any): CountriesState => s[moduleName];

const isFetchingCombiner: (s: CountriesState) => boolean = (s: CountriesState): boolean => s?.fetching;
const entitiesCombiner: (s: CountriesState) => CountryType[] = (s: CountriesState): CountryType[] => s?.entities;
const errorCombiner: (s: CountriesState) => string = (s: CountriesState): string => s?.error;

export const selectCountriesFetching: OutputSelector<AdminPanelStore, any, boolean>  = createSelector(stateSelector, isFetchingCombiner);
export const selectCountriesEntities: OutputSelector<AdminPanelStore, any, CountryType[]> = createSelector(stateSelector, entitiesCombiner);
export const selectCountriesError: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, errorCombiner);

// Selectors (up)
