import React, {Component} from 'react';
import { Spin, Switch } from "antd";
import style from './style.module.css';

class FeaturedService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enable: this.props.data.enable,
      agreement: this.props.data.agreement,
    };
  }

  onChange = (checked, event) => {
    const { setFeaturedServiceByUser, user: {id}, authData, data: {featuredInfo} } = this.props;
    if(event.target.textContent === "agree" || event.target.textContent === "disagree") {
      this.setState({
        agreement: checked,
      });
    } else if (event.target.textContent === "enable" || event.target.textContent === "disable") {
      this.setState({
        enable: checked,
      });
    }
    setTimeout(() => {
      const data = {
        userId: id,
        agreement: this.state.agreement,
        enable: this.state.enable,
        fcid: featuredInfo.id, 
      };
      setFeaturedServiceByUser(data, authData);
    }, 100 );
  }

  render() {
    const { data, isPending } = this.props;
    const { agreement, enable} = this.state;
    return (
      <div className={style.featuredServiceWrapper}>
        <span>{data.featuredInfo.title}</span>
        <div className={style.switchersWrapper}>
          <div className={style.switcher}>
            <Switch
              name="agreement"
              checkedChildren="agree"
              unCheckedChildren="disagree"
              defaultChecked={agreement}
              onChange={this.onChange}
            />
          </div>
          <div className={style.switcher}>
            <Switch
              name="enable"
              checkedChildren="enable"
              unCheckedChildren="disable"
              defaultChecked={enable}
              onChange={this.onChange}
              disabled={isPending}
            />
          </div>
        </div>
        <div className={style.hr} />
      </div>
    );
  }
}

export default FeaturedService;
