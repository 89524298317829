import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from "antd";
import { ROUTES, PRIVATE, PUBLIC, PRIVATE_AND_PUBLIC } from "../../constants/routes-constants";

import style from "./style.module.css";

const CustomRoute = ({ loggedInUser, type, component: Component, ...rest }) => {
  const customRoute = props => {
    let route = null;
    if (loggedInUser.loading) {
      route = <Spin size="large" className={style.loader} />;
    } else {
      if (loggedInUser.userAuth) {
        route = (
          type === PRIVATE || type === PRIVATE_AND_PUBLIC ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTES.HOME, state: { from: props.location } }} />
          )
        );
      } else {
        route = (
          type === PUBLIC || type === PRIVATE_AND_PUBLIC ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
          )
        );
      }
    }
    return route;
  };
  return (
    <Route
      {...rest}
      render={customRoute}
    />
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

export default connect(mapStateToProps)(CustomRoute);
