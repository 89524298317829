import * as api from '../api/data';
import { actionTypes } from './index';

const setCategories = response => ({
  type: actionTypes.SET_CATEGORIES,
  categories: response
});

export const getCategories = authData => async dispatch => {
  const response = await api.getCategories(authData);

  if (!response.error) {
    dispatch(setCategories(response));
  }
};

const setTotalStatistics = response => ({
  type: actionTypes.GET_TOTAL_STATISTICS,
  statistics: response
});

const clearStatistics = () => ({
  type: actionTypes.CLEAR_STATISTICS,
});

export const getTotalStatistics = authData => async dispatch => {
  const response = await api.getTotalStatistics(authData);

  if (!response.error) {
    dispatch(setTotalStatistics(response));
  }
};

export const getStatistics =(
  authData,
  start = '',
  end = '',
  days = ''
) => async dispatch => {
  const queryArr = [];

  if (start) {
    queryArr.push({
      name: 'startDate',
      value: start.toISOString(),
    });
  }
  if (end) {
    queryArr.push({
      name: 'endDate',
      value: end.toISOString(),
    });
  }
  if (days) {
    queryArr.push({
      name: 'days',
      value: days
    });
  }
  dispatch(clearStatistics());
  const response = await api.getStatistics(authData, queryArr);

  if (response.message === 'You don’t have enough rights') {
    /* eslint-disable-next-line no-alert */
    alert(response.message);
  }

  if (!response.error) {
    dispatch(setTotalStatistics(response));
  }
};
