/** @flow */

import { Dispatch } from 'redux';
import type { ActionType } from '../../types';
import type { PartnerType } from '../reducers/partners';
import { actionTypes } from './index';
import * as api from '../api/partners';

const fetchPartnersSuccess = (partners: PartnerType[]): ActionType<{ partners: PartnerType[] }> => ({
  type: actionTypes.FETCH_PARTNERS_SUCCESS,
  payload: { partners },
});

const fetchPartnersFailure = (error: string) => ({
  error,
  type: actionTypes.FETCH_PARTNERS_FAILURE,
});

const partnersDeleteFailure = (error: string) => ({
  type: actionTypes.DELETE_PARTNERS_FAILURE,
  error: error || '',
});

const partnersErrorCleanup = () => ({
  type: actionTypes.CLEANUP_PARTNERS_FAILURE,
})

const saveEmailsFailure = (failedEmails: string[] | null, error: string) => ({
  type: actionTypes.SET_FAILED_AUTOLINKING_EMAILS,
  payload: { failedEmails },
  error
});

export const fetchPartners = (userAuth: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: actionTypes.FETCH_PARTNERS_REQUEST,
    });

    const partners: PartnerType[] = await api.getPartners(userAuth);

    dispatch(fetchPartnersSuccess(partners));
  } catch (error) {
    dispatch(fetchPartnersFailure(error));
  }
};

export const addPartner = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();

    const newPartner = { ...data };
    if(newPartner.autoLinkingEmails) {
      delete newPartner.autoLinkingEmails;
    }    
    const partners: PartnerType[] = await api.addPartner(userAuth || '', newPartner);

    let failedEmails: string[] | null = null;

    if(data.autoLinkingEmails !== undefined) {
      const emailsListData = {
        tag: data.tag,
        emails: data.autoLinkingEmails,
      };
      const resp = await api.updatePartnersAutoLinkingEmails(userAuth || '', emailsListData);
      failedEmails = resp.rejected.length ? resp.rejected : null;
    }
    
    if(failedEmails) {
      const errorMessage = `The following emails did not pass validation and were not saved: ${failedEmails.join(', ')}`
      dispatch(saveEmailsFailure(failedEmails, errorMessage));
    } 

    dispatch(fetchPartnersSuccess(partners));
  } catch (error) {
    dispatch(fetchPartnersFailure(error));
  }
};

export const editPartner = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();
    
    const modifiedPartner = { ...data };
    if(modifiedPartner.autoLinkingEmails) {
      delete modifiedPartner.autoLinkingEmails;
    }      
    const partners: PartnerType[] = await api.editPartner(userAuth || '', modifiedPartner);

    let failedEmails: string[] | null = null;

    if(data.autoLinkingEmails !== undefined) {
      const emailsListData = {
        tag: data.tag,
        emails: data.autoLinkingEmails,
      };
      const resp = await api.updatePartnersAutoLinkingEmails(userAuth || '', emailsListData);
      failedEmails = resp.rejected.length ? resp.rejected : null;
    }
    
    if(failedEmails) {
      const errorMessage = `The following emails did not pass validation and were not saved: ${failedEmails.join(', ')}`
      dispatch(saveEmailsFailure(failedEmails, errorMessage));
      return;
    } 

    dispatch(fetchPartnersSuccess(partners));
    return true;
  } catch (error) {
    dispatch(fetchPartnersFailure(error));
  }
};

export const deletePartner = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();
    const partners: PartnerType[] = await api.deletePartner(userAuth || '', data);

    if (partners.message) {
      dispatch(partnersDeleteFailure(partners.message));
    } else {
      dispatch(fetchPartnersSuccess(partners));
    }
  } catch (error) {
    dispatch(fetchPartnersFailure(error));
  }
};

export const clearPartnersError = () => (dispatch: Dispatch) => dispatch(partnersErrorCleanup());
