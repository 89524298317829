/** @flow */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import {
  activateUser, clearActivateUserStatus, declineUser, deleteUserProData,
} from '../../../store/actions/pending';
import style from './style.module.css';

type ApproveAreaProps = {
  activateUser: {
    error: boolean,
    isComplete: boolean,
    isSuccess: boolean,
    message: string,
  },
  activateUserFunc: (userId: string, userAuth: string) => void,
  declineUser: (userId: string, userAuth: string) => void,
  deleteUserProData: (userId: string, userAuth: string) => void,
  loggedInUser?: { userAuth?: string },
  resetProApplicationStatus: () => void,
  selectedUser?: {
    selectedUser: {
      id: string,
    },
  },
};

const ApproveArea = (props: ApproveAreaProps) => {
  const {
    resetProApplicationStatus,
    loggedInUser,
    activateUser,
    selectedUser,
    activateUserFunc,
    declineUser,
    deleteUserProData,
  } = props;

  const { userAuth } = loggedInUser || {};
  const { selectedUser: user } = selectedUser || {};
  const { isComplete, error, message, isSuccess } = activateUser

  React.useEffect(() => resetProApplicationStatus, [resetProApplicationStatus]);

  const handleApprove = () => {
    if (userAuth) {
      activateUserFunc(user.id, userAuth);
    }
  };

  const handleDecline = () => {
    if (userAuth) {
      declineUser(user.id, userAuth)
    }
  };
  const handleDeleteUserProData = () => {
    if (userAuth) {
      deleteUserProData(user.id, userAuth);
    }
  };

  return (
    <div className={style.approveArea}>
      {isComplete ? (
        [
          error ? (
            <div className={`errorContainer ${style.messageContainer}`}>
              Error! {message}
            </div>
          ) : (
            <div className={style.messageContainer}>{message}</div>
          ),
          <button
            type="button"
            className={style.approveBtn}
            onClick={handleApprove}
            disabled={isSuccess && isComplete}
          >
            Approve
          </button>,
          <button
            type="button"
            className={style.declineBtn}
            onClick={handleDecline}
            disabled={isSuccess && isComplete}
          >
            Decline
          </button>,
          <button
            type="button"
            className={style.deleteBtn}
            onClick={handleDeleteUserProData}
            disabled={isSuccess && isComplete}
          >
            Delete
          </button>
        ]
      ) : (
        <div className="spinContainer">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

ApproveArea.defaultProps = {
  loggedInUser: {},
  selectedUser: {},
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  activateUser: state.activateUser,
  selectedUser: state.selectedUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  activateUserFunc: activateUser,
  declineUser,
  resetProApplicationStatus: clearActivateUserStatus,
  deleteUserProData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApproveArea);
