/** @flow */

import type { ActionType, SetActiveUsersFiltersPayload } from '../../types';
import { actionTypes } from './index';

export function activeUsersSetPage(page: number): ActionType<{ page: number }> {
  return {
    type: actionTypes.ACTIVE_USERS_SET_PAGE,
    payload: { page },
  };
}

export function activeUsersSetSearch(search: string): ActionType<{ search: string }> {
  return {
    type: actionTypes.ACTIVE_USERS_SET_SEARCH,
    payload: { search },
  };
}

export function activeUsersSetSorted(sorted: string): ActionType<{ sorted: string }> {
  return {
    type: actionTypes.ACTIVE_USERS_SET_SORTED,
    payload: { sorted },
  };
}

export function activeUsersSetStatusFilters(
  statusFilters: string[],
): ActionType<{ statusFilters: string[] }> {
  return {
    type: actionTypes.ACTIVE_USERS_SET_STATUS_FILTERS,
    payload: { statusFilters },
  };
}

export function activeUsersSetFilters(filters: { [key: string]: string[] }): ActionType<SetActiveUsersFiltersPayload> {
  return {
    type: actionTypes.ACTIVE_USERS_SET_FILTERS,
    payload: { filters },
  };
}
