/** @flow */

import { v4 } from 'uuid';

import React, { Component, type AbstractComponent } from 'react';
import Moment from 'moment'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router'
import { Table, Spin, DatePicker, Input, type PaginationConfig, PageHeader, Button, Modal } from 'antd';

import style from './style.module.css';

import { fetchAppointments, setAppointmentsDisplayRange, deleteAppointmentAction } from '../../store/actions/appointments';
import {
  selectDisplayRangeStart,
  selectDisplayRangeEnd,
  selectRetrievedAppointments,
  selectRetrievedAppointmentsCount,
  selectAppointmentsAreFetching,
  selectAppointmentsFetchingFailed,
  selectAppointmentsFetchingErrorMessage,
} from '../../store/reducers/appointments';
import { apiAddNotification, NOTIFICATION_TYPE } from '../../store/actions/api';
import type { Appointment } from '../../types';
import { appointmentsColumns as columns } from '../../helpers/table-data';
import getSearchResults, { type TableSearchType, type RowWithClientProFieldsType } from '../../helpers/table-search';
import { getPage, generateRowKey } from '../../helpers/table-rows-paging';
import { PAGE_SIZE, ROUTES } from '../../constants';
import { type SorterType } from '../../types';
import Card from '../../components/Layout/card';
import { InternationalizationContext } from '../../i18n/internationalization';
import type { IInternationalizationContext } from '../../i18n/internationalization';

import { fetchFileBlob } from '../../store/api/fetch-wrapper';
import { apiUrls } from '../../store/api';

const { RangePicker } = DatePicker;
const { Search } = Input;

type AppointmentsOwnProps = {| 
  ...ContextRouter
  |}

type AppointmentsProps = {|
  ...AppointmentsOwnProps,
  displayRangeStart: ?Moment,
  displayRangeEnd: ?Moment,
  appointments: Array<Appointment>,
  appointmentsFetching: boolean,
  appointmentsFetchingError: boolean,
  appointmentsFetchingErrorMessage: string,
  userData: {
  userAuth?: string,
  },
  fetchAppointments: (startDate: ?Moment, endDate: ?Moment) => void,
  setAppointmentsDisplayRange: (startDate: Moment, endDate: Moment) => void,
  deleteAppointment: (appointmentId: string, isPro: boolean) => void
|}

type AppointmentsState = {
  proSearch: string,
  clientSearch: string,
  sortedInfo: SorterType,
  isCancelAppointmentAsProConfirmationVisible: boolean,
  isCancelAppointmentAsClientConfirmationVisible: boolean,
  selectedClientId: number | null,
};

const extractProClientFields = (appointment: Appointment): RowWithClientProFieldsType => ({
  proEmail: appointment.pro.email,
  proFullName: appointment.pro.nickname,
  proId: appointment.pro.id.toString(10),
  clientEmail: appointment.client.email,
  clientFullName: appointment.client.nickname,
  clientId: appointment.client.id.toString(10),
});

class Appointments extends Component<AppointmentsProps, AppointmentsState> {
  static contextType = InternationalizationContext;

  context: IInternationalizationContext;

  userAuth: string | null;

  csvDownloadAnchor: { current: null | HTMLAnchorElement }

  constructor(props: AppointmentsProps) {
    super(props);

    this.state = {
      proSearch: '',
      clientSearch: '',
      sortedInfo: {
        columnKey: '',
        field: '',
        order: 'ascend',
      },
      isCancelAppointmentAsProConfirmationVisible: false,
      isCancelAppointmentAsClientConfirmationVisible: false,
      selectedClientId: null,
    };

    this.csvDownloadAnchor = React.createRef < HTMLAnchorElement > ();
  }

  componentDidMount() {
    const {
      displayRangeStart,
      displayRangeEnd,
      userData: { userAuth },
      fetchAppointments,
    } = this.props

    this.userAuth = userAuth || null;

    if (this.userAuth) {
      fetchAppointments(displayRangeStart, displayRangeEnd);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: AppointmentsProps) {
    const { appointments } = nextProps;
    const { appointments: prevAppointments } = this.props;

    if (appointments !== prevAppointments) {
      this.pagerChange(1);
    }
  }

  onUserSelect: (id: string) => () => void = (id: string) => () => {
    const { history } = this.props;

    history.push({
      pathname: `${ROUTES.USER}/${id}`
    });
  };

  pagerChange: (current: number) => void = current => {
    const { history, match } = this.props;
    if (current) {
      history.push({
        pathname: match.url,
        search: `?page=${current}`
      });
    }
  };

  onChange: (pagination: PaginationConfig, _filters: any, sorter: SorterType) => void = (pagination, _filters, sorter) => {
    this.setState({ sortedInfo: sorter });

    const currentPage = getPage(this.props.location.search);
    if (currentPage === pagination.current) {
      // This was a sort or filter change - reset the current page to 1
      this.pagerChange(1);
    }
  };

  onSearch: (e: SyntheticInputEvent<HTMLInputElement>, type: TableSearchType) => void = (e, type) => {
    const {
      target: { value }
    } = e;
    const searchKey = `${type}Search`;
    this.setState(
      {
        [searchKey]: value,
      }
    );
    this.pagerChange(1);
  }

  onRangeDateChange: (dates: Array<Moment>, _dateStrings: Array<string>) => void = (dates, _dateStrings) => {
    const [dateFrom, dateTo] = dates;

    this.props.setAppointmentsDisplayRange(dateFrom, dateTo)

    const startDate = dateFrom?.startOf('day');
    const endDate = dateTo?.endOf('day');

    if (this.userAuth) {
      this.props.fetchAppointments(startDate, endDate);
    }
  };

  onCSVExportClick: () => void = async () => {
    if (!this.csvDownloadAnchor) {
      return;
    }

    const { displayRangeStart, displayRangeEnd } = this.props;
    const downloadAnchor = this.csvDownloadAnchor.current;

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${this.props.userData.userAuth}`);
    headers.append('Content-Type', 'application/json ');

    const url = new URL(apiUrls.GET_APPOINTMENTS_CSV);

    if (displayRangeStart) url.searchParams.append('startDate', displayRangeStart.toISOString());
    if (displayRangeEnd) url.searchParams.append('endDate', displayRangeEnd.toISOString());

    const [csvFileBlob, csvFileName] = await fetchFileBlob(url, {
      method: 'GET',
      headers,
    });

    const objectURL = URL.createObjectURL(csvFileBlob);

    downloadAnchor.href = objectURL;
    downloadAnchor.download = csvFileName;
    downloadAnchor.click();
  }

  showCancellationError = () => {
    const {      
      apiAddNotification
    } = this.props;

    apiAddNotification({
      id: v4(),
      message: 'The appointment cannot be canceled as it has already started.',
      type: NOTIFICATION_TYPE.ERROR,
    });
  }

  fetchAppointmentsWithRange = () => {
    const {
      displayRangeStart,
      displayRangeEnd,
      fetchAppointments,
    } = this.props;

    fetchAppointments(displayRangeStart, displayRangeEnd);
  }

  checkIfAppointmentStarted = (startTime: string) => {    
    const a = Moment();
    const b = Moment(startTime);
    return b.diff(a) < 0;
  }

  closeConfirmAppointmentCancellationModal = () => {
    this.setState({
      selectedClientId: null,
      isCancelAppointmentAsProConfirmationVisible: false,
      isCancelAppointmentAsClientConfirmationVisible: false
    });
  }

  renderButtons = recordData => [
    <button
      className={style.declineBtn}
      key='cancel-pro'
      onClick={() => {
        if(this.checkIfAppointmentStarted(recordData.startTime)) {          
          this.showCancellationError();
          this.fetchAppointmentsWithRange();
          return;
        }
        
        this.setState({
          selectedClientId: recordData.id,
          isCancelAppointmentAsProConfirmationVisible: true
        })
      }}
      type="button"
    >
      Cancel as Pro
    </button>,
    <button
      className={style.declineBtn}
      key='cancel-client'
      onClick={() => {
        if(this.checkIfAppointmentStarted(recordData.startTime)) {
          this.showCancellationError();
          this.fetchAppointmentsWithRange();
          return;
        }
        
        this.setState({
          selectedClientId: recordData.id,
          isCancelAppointmentAsClientConfirmationVisible: true
        })
      }}
      type="button"
    >
      Cancel as Client
    </button>
  ];

  render(): React$Element<any> {
    const {
      displayRangeStart,
      displayRangeEnd,
      appointmentsFetching,
      appointmentsFetchingError = false,
      appointmentsFetchingErrorMessage,
      appointments,
      deleteAppointment,
    } = this.props;

    const {
      sortedInfo = {},
      proSearch,
      clientSearch,
      isCancelAppointmentAsProConfirmationVisible,
      isCancelAppointmentAsClientConfirmationVisible,
      selectedClientId
    } = this.state;

    const defaultPartnerSearch = '';

    return (
      <Card cardClass="route-content">
        <PageHeader
          title="Appointments"
          backIcon={false}
          extra={[
            <Button key="1" type="primary" onClick={this.onCSVExportClick}>Export CSV</Button>
          ]}
          className={style.pageHeader}
        >
          <div className={style.infoBar}>
            <div className={style.filterRow}>
              <RangePicker value={[displayRangeStart, displayRangeEnd]} onChange={this.onRangeDateChange} />
            </div>
            <div className={style.filterRow}>
              <Search
                placeholder="Pro Fields Search"
                onChange={e => this.onSearch(e, 'pro')}
                className={style.searchInput}
              />
              <Search
                placeholder="Client Fields Search"
                onChange={e => this.onSearch(e, 'client')}
                className={style.searchInput}
              />
            </div>
          </div>
        </PageHeader>
        {/* eslint-disable-next-line no-nested-ternary */}
        {appointmentsFetching
          ? (
            <div className="spinContainer">
              <Spin size="large" />
            </div>
          )
          : (
            !appointmentsFetchingError
              ? (
                <>
                  <Table
                    columns={columns(
                      sortedInfo,
                      this.onUserSelect,
                      this.context.formatCurrency,
                      this.context.formatNumericDateTimeWithTimeZone,
                      this.renderButtons
                    )}
                    rowKey={generateRowKey}
                    onChange={this.onChange}
                    pagination={{
                      pageSize: PAGE_SIZE,
                      current: getPage(this.props.location.search),
                      defaultCurrent: 1,
                      onChange: this.pagerChange,
                      hideOnSinglePage: false,
                    }}
                    dataSource={getSearchResults < Appointment > (proSearch, clientSearch, defaultPartnerSearch, appointments, extractProClientFields)}
                    scroll={{ x: 'max-content' }}
                  />

                  <Modal
                    visible={isCancelAppointmentAsProConfirmationVisible}
                    onCancel={this.closeConfirmAppointmentCancellationModal}
                    width="35%"
                    footer={[
                      <Button 
                        key="submit" 
                        type="danger" 
                        onClick={() => {
                          deleteAppointment(selectedClientId, true);
                          this.closeConfirmAppointmentCancellationModal();
                        }}
                      >
                        Yes
                      </Button>,
                      <Button key="back" type="primary" onClick={this.closeConfirmAppointmentCancellationModal}>
                        No
                      </Button>,
                    ]}
                  >
                    Do you want to cancel this appointment as Pro?
                  </Modal>

                  <Modal
                    visible={isCancelAppointmentAsClientConfirmationVisible}
                    onCancel={this.closeConfirmAppointmentCancellationModal}
                    width="35%"
                    footer={[
                      <Button 
                        key="submit" 
                        type="danger" 
                        onClick={() => {
                          deleteAppointment(selectedClientId, false);
                          this.closeConfirmAppointmentCancellationModal();
                        }}
                      >
                        Yes
                      </Button>,
                      <Button key="back" type="primary" onClick={this.closeConfirmAppointmentCancellationModal}>
                        No
                      </Button>,
                    ]}
                  >
                    Do you want to cancel this appointment as Client?
                  </Modal>
                </>
                
              )
              : (
                <div className="errorContainer">
                  Error!
                  <div className="errorMsg">{appointmentsFetchingErrorMessage}</div>
                </div>
              )
          )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <a style={{ display: 'none' }} href='' ref={this.csvDownloadAnchor} />
      </Card>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAppointments,
  setAppointmentsDisplayRange,
  deleteAppointment: deleteAppointmentAction,
  apiAddNotification
}, dispatch);

const mapStateToProps = state => ({
  displayRangeStart: selectDisplayRangeStart(state),
  displayRangeEnd: selectDisplayRangeEnd(state),
  appointments: selectRetrievedAppointments(state),
  appointmentsCount: selectRetrievedAppointmentsCount(state),
  appointmentsFetching: selectAppointmentsAreFetching(state),
  appointmentsFetchingError: selectAppointmentsFetchingFailed(state),
  appointmentsFetchingErrorMessage: selectAppointmentsFetchingErrorMessage(state),
  userData: state.loggedInUser,
});


export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointments)): AbstractComponent<AppointmentsOwnProps>);
