/** @flow */
/* eslint-disable react/prop-types */

import React, { type AbstractComponent } from 'react';
import { connect } from 'react-redux';

import {
  selectActiveUser,
  selectCredits,
  selectEarnings,
  selectAcceptBookings,
  selectHideProfile,
  selectUseAvailabilitySchedule,
  selectMinimumBookingDuration,
} from '../../../store/reducers/user';
import AccountDetail from '../account-detail';
import FreeCredits from '../free-credits';
import Earnings from '../earnings';
import ProDetails from '../pro-detail';
import ProBookingAndAvailability from '../pro-booking';
import Settings from '../settings';
import UserFeaturedServices from '../user-featured-services';
import ProAvailability from '../pro-availability';
import AdminNotes from '../admin-notes';
import PartnersList from '../partners/partners';
import Card from '../../Layout/card';

import style from './style.module.css';

import type {
  SelectedUserCreditsState,
  SelectedUserEarningsState,
  AdminPanelStore,
  UserWithStatus,
} from '../../../types';

type AccDetailsTabOwnProps = $ReadOnly<{|
  isPending: boolean,
  userAuth: string,
|}>

type AccDetailsTabProps = $ReadOnly<{|
  ...AccDetailsTabOwnProps,
  activeUser: ?UserWithStatus,
  credits: ?SelectedUserCreditsState,
  earnings: ?SelectedUserEarningsState,
  useAvailabilitySchedule: ?boolean,
  acceptBookings: ?boolean,
  hideProfile: ?boolean,
  minimumBookingDuration: ?number,
|}>;

const AccDetailsTab = (props: AccDetailsTabProps) => {
  const {
    isPending,
    activeUser: user,
    userAuth,
    credits,
    earnings,
    acceptBookings,
    hideProfile,
    useAvailabilitySchedule,
    minimumBookingDuration
  } = props;
  const {
    bookingTerminate,
    cardAdded,
    emailMarketingNotification,
    emailNotification,
    pushMarketingNotification,
    showFullName,
  } = user.state;

  const renderPartners = (
    <PartnersList
      user={user}
      userAuth={userAuth}
    />
  );

  const renderSettings = (
    <Settings 
      bookingTerminate={bookingTerminate}
      cardAdded={cardAdded}
      emailMarketingNotification={emailMarketingNotification}
      emailNotification={emailNotification}
      pushMarketingNotification={pushMarketingNotification}
      showFullName={showFullName}
    />
  )

  const renderEarningsAndFreeCreditsCards = userId => (
    <div className={style.moneyCardsContainer}>
      <div><FreeCredits credits={credits} userId={userId} userAuth={userAuth} /></div>
      <div><Earnings earnings={earnings} userId={userId} userAuth={userAuth} /></div>
    </div>
  );

  const renderFreeCreditsCardAlone = userId => (
    <FreeCredits credits={credits} userId={userId} userAuth={userAuth} />
  );
  
  return (
    <>
      <AccountDetail isPending={isPending} user={user} userAuth={userAuth} />

      {user && (user.pro && !isPending) ? (
        <Card class={style.moneyCard}>{renderEarningsAndFreeCreditsCards(user.id)}</Card>
      ) : user && !isPending && (<Card class={style.moneyCard}>{renderFreeCreditsCardAlone(user.id)}</Card>)}

      {user && (user.pro || isPending) ? (
        <>
          <ProDetails
            user={user}
            userAuth={userAuth}
            isPending={isPending}
          />

          { user && user.pro && (
            <ProBookingAndAvailability 
              acceptBookings={acceptBookings}
              hideProfile={hideProfile}
              useAvailabilitySchedule={useAvailabilitySchedule}
              minimumBookingDuration={minimumBookingDuration}
            />
          )}

          {renderPartners}

          {renderSettings}

          <UserFeaturedServices
            isPending={isPending}
            user={user}
            authData={userAuth}
          />

          { user.availability && <ProAvailability user={{ availability: user.availability }} /> }
        </>
      ) : (
        <>
          {renderPartners}
          {renderSettings}
        </>       
      )}

      <AdminNotes
        authData={userAuth}
        user={user}
      />

    </>
  );
};
const mapStateToProps = (state: AdminPanelStore) => ({
  activeUser: selectActiveUser(state),
  credits: selectCredits(state),
  earnings: selectEarnings(state),
  acceptBookings: selectAcceptBookings(state),
  useAvailabilitySchedule: selectUseAvailabilitySchedule(state),
  hideProfile: selectHideProfile(state),
  minimumBookingDuration: selectMinimumBookingDuration(state)
});

export default (connect(mapStateToProps)(AccDetailsTab): AbstractComponent<AccDetailsTabOwnProps>);
