/** @flow */

import type { ActionType, TransactionsSearchActionPayload } from '../../types';
import { actionTypes } from './index';

export function transactionsSetDays(days: string): ActionType<TransactionsSearchActionPayload> {
  return {
    type: actionTypes.TRANSACTIONS_SET_DAYS,
    payload: { days },
  };
}

export function transactionsSetRange(
  range: any[]
): ActionType<TransactionsSearchActionPayload> {
  return {
    type: actionTypes.TRANSACTIONS_SET_RANGE,
    payload: { range },
  };
}
