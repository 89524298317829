/** @flow */

import { Dispatch } from 'redux';
import type { ActionType, CountryType } from '../../types';
import { actionTypes } from './index';
import * as api from '../api/countries';

const fetchCountriesSuccess = (countries: CountryType[]): ActionType<{ countries: CountryType[] }> => ({
  type: actionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: { countries },
});

const fetchCountriesFailure = (error: string) => ({
  error,
  type: actionTypes.FETCH_COUNTRIES_FAILURE,
});

const countriesDeleteFailure = (error: string) => ({
  type: actionTypes.DELETE_COUNTRIES_FAILURE,
  error: error || '',
});

const countriesErrorCleanup = () => ({
  type: actionTypes.CLEANUP_COUNTRIES_FAILURE,
})

export const fetchCountries = (userAuth: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: actionTypes.FETCH_COUNTRIES_REQUEST,
    });

    const countries: CountryType[] = await api.getCountries(userAuth);

    dispatch(fetchCountriesSuccess(countries));
  } catch (error) {
    dispatch(fetchCountriesFailure(error));
  }
};

export const addCountry = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();

    const newCountry = { ...data };
    const countries: CountryType[] = await api.addCountry(userAuth || '', newCountry);

    dispatch(fetchCountriesSuccess(countries));
  } catch (error) {
    dispatch(fetchCountriesFailure(error));
  }
};

export const editCountry = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();

    const modifiedCountry = { ...data };
    const countries: CountryType[] = await api.editCountry(userAuth || '', modifiedCountry);

    dispatch(fetchCountriesSuccess(countries));
    return true;
  } catch (error) {
    dispatch(fetchCountriesFailure(error));
  }
};

export const deleteCountry = data => async (dispatch: Dispatch, getState: () => Store) => {
  try {
    const { loggedInUser: { userAuth } } = getState();
    const countries: CountryType[] = await api.deleteCountry(userAuth || '', data);

    if (countries.message) {
      dispatch(countriesDeleteFailure(countries.message));
    } else {
      dispatch(fetchCountriesSuccess(countries));
    }
  } catch (error) {
    dispatch(fetchCountriesFailure(error));
  }
};

export const clearCountriesError = () => (dispatch: Dispatch) => dispatch(countriesErrorCleanup());
