/** @flow */

import React, { Component } from 'react';
import type { AbstractComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import UsersRouteWrapper from '../../components/users-route-wrapper';

import { exportActiveUsers } from '../../store/api/csvExport';
import { apiAddNotification } from '../../store/actions/api';

import {
  getActiveUsers,
  clearActiveUsers,
} from '../../store/actions/user';

import { PAGE_SIZE } from '../../constants';
import { tableColumns as columns } from '../../helpers/table-data';
import {
  activeUsersSetFilters,
  activeUsersSetPage,
  activeUsersSetSearch,
  activeUsersSetSorted,
  activeUsersSetStatusFilters,
} from '../../store/actions/activeUsers';
import {
  selectActiveUsersFilters,
  selectActiveUsersPage,
  selectActiveUsersSearch,
  selectActiveUsersSearchPhrases,
  selectActiveUsersSorted,
  selectActiveUsersSorter,
  selectActiveUsersStatusFilters,
  selectPreparedStatusFilters,
} from '../../store/reducers/activeUsers';
import type {
  ActionType,
  SorterType,
  StatusOptionType,
  PartnerType,
  RelatedPartnersFilters,
  NotificationType,
  UserStatus,
  ConfigData,
  DateRangeSearchCriteria
} from '../../types';
import { selectPartnersEntities } from '../../store/reducers/partners';
import { selectConfigData, selectIsConfigLoadingError } from '../../store/reducers/configData';
import performCSVExport from '../../interactors/csvExport'

type ActiveUsersOwnProps = $ReadOnly<{| |}>;

type ActiveUsersProps = $ReadOnly<{|
  ...ActiveUsersOwnProps,
  page: number,
  search: string,
  sorted: string,
  clearActiveUsers: () => ActionType<>,
  getActiveUsers: (
    userAuth: string,
    page?: number,
    size?: number,
    statusFilters?: Array<UserStatus>,
    search?: string,
    sort?: string,
    filters?: RelatedPartnersFilters,
    dateRangeCriteria: DateRangeSearchCriteria,
  ) => ActionType<>,
  preparedStatusFilters: StatusOptionType[],
  setPage: (page: number) => ActionType<>,
  setSearch: (search: string) => ActionType<>,
  setSorted: (sorted: string) => ActionType<>,
  setStatusFilters: (statusFilters: Array<UserStatus>) => ActionType<>,
  setFilters: (filters: { [key: string]: string[] }) => void,
  sorter: SorterType,
  statusFilters: Array<UserStatus>,
  filters: RelatedPartnersFilters,
  partners: PartnerType[],
  uArrays: { total: number, activeUsers ?: any[] },
  userData: {
    userAuth ?: string,
  },
  notifyCSVExportFailure: (notification: NotificationType) => void,
  configData: null | ConfigData
|}>;

class ActiveUsers extends Component<ActiveUsersProps, ActiveUsersState> {
  componentDidMount() {
    const { page, search, sorted, statusFilters, filters, getActiveUsers, userData } = this.props;

    if (userData.userAuth) {
      getActiveUsers(
        userData.userAuth, page, PAGE_SIZE, statusFilters, search, sorted, filters, {},
      );
    };
  }

  componentWillUnmount() {
    this.props.clearActiveUsers();
  }

  onPaginationChange = (page = 0, dateRangeCriteria = {}) => {
    const { search, sorted, setPage, statusFilters, filters, userData } = this.props;

    setPage(page);
    this.props.getActiveUsers(
      userData.userAuth,
      page,
      PAGE_SIZE,
      statusFilters,
      search,
      sorted,
      filters,
      dateRangeCriteria
    );    
  };

  onFilterChange = (filter = [], dateRangeCriteria = {}) => {
    const { search, sorted, setPage, setStatusFilters, filters, userData } = this.props;

    setStatusFilters(filter);
    setPage(0);
    this.props.getActiveUsers(
      userData.userAuth,
      0,
      PAGE_SIZE,
      filter,
      search,
      sorted,
      filters,
      dateRangeCriteria
    );    
  };

  onSearchChange = (search = '', dateRangeCriteria = {}) => {
    const { setPage, sorted, setSearch, statusFilters, filters, userData } = this.props;

    setPage(0);
    setSearch(search);

    this.props.getActiveUsers(
      userData.userAuth,
      0,
      PAGE_SIZE,
      statusFilters,
      search,
      sorted,
      filters,
      dateRangeCriteria
    );
  };

  onSortChange = (sort = '', filters, dateRangeCriteria = {}) => {
    const { search, setPage, setSorted, statusFilters, setFilters, userData } = this.props;

    setSorted(sort);
    setFilters(filters);
    setPage(0);
    this.props.getActiveUsers(
      userData.userAuth,
      0,
      PAGE_SIZE,
      statusFilters,
      search,
      sort,
      filters,
      dateRangeCriteria
    );
  };

  onCSVExport: (downloadLink: HTMLAnchorElement, dateRangeCriteria: DateRangeSearchCriteria) => Promise<void> = async (downloadLink, dateRangeCriteria = {}) => {
    const { page, filters, statusFilters, search, sorted, userData } = this.props;
    const apiDelegate = (userAuth: string) => exportActiveUsers(
      userAuth,
      page,
      PAGE_SIZE,
      statusFilters,
      search,
      sorted,
      filters,
      dateRangeCriteria
    );

    await performCSVExport(userData.userAuth, apiDelegate, this.props.notifyCSVExportFailure, downloadLink);
  }

  render() {
    const { 
      page, 
      search, 
      preparedStatusFilters, 
      sorter, 
      filters, 
      userData,
      partners
    } = this.props;

    if(!partners.length) {
      return (
        <div className="spinContainer" style={{ width: '100%' }}>
          <Spin size="large" />
        </div>
      );
    }

    const partnersFilter = partners.map(filter => ({
      text: filter.tag.toUpperCase(),
      value: filter.tag
    }));
    const { hiddenPartnerName } = this.props.configData;
    const { activeUsers = [] } = this.props.uArrays;

    return (
      <UsersRouteWrapper
        withFilter
        usersArr={activeUsers}
        uArrays={this.props.uArrays}
        isAU
        userAuth={userData.userAuth}
        columns={columns}
        scroll={{ x: 2490 }}
        sorter={sorter}
        onPaginationChange={this.onPaginationChange}
        onFilterChange={this.onFilterChange}
        onSearchChange={this.onSearchChange}
        onSortChange={this.onSortChange}
        currentPage={page}
        search={search}
        selectedFilters={filters}
        statusFilters={preparedStatusFilters}
        partnersFilter={{ relatedPartners: partnersFilter }}
        hiddenPatnerName={hiddenPartnerName}
        totalItems={this.props.uArrays.total}
        onCSVExport={this.onCSVExport}
      />
    );
  }
}

const mapStateToProps = state => ({
  page: selectActiveUsersPage(state),
  search: selectActiveUsersSearch(state),
  searchPhrases: selectActiveUsersSearchPhrases(state),
  sorted: selectActiveUsersSorted(state),
  sorter: selectActiveUsersSorter(state),
  preparedStatusFilters: selectPreparedStatusFilters(state),
  statusFilters: selectActiveUsersStatusFilters(state),
  filters: selectActiveUsersFilters(state),
  partners: selectPartnersEntities(state),
  userData: state.loggedInUser,
  uArrays: state.usersArrays,
  configData: selectConfigData(state),
  isConfigLoadingError: selectIsConfigLoadingError(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActiveUsers,
  setPage: activeUsersSetPage,
  setSearch: activeUsersSetSearch,
  setSorted: activeUsersSetSorted,
  setStatusFilters: activeUsersSetStatusFilters,
  setFilters: activeUsersSetFilters,
  clearActiveUsers,
  notifyCSVExportFailure: apiAddNotification,
}, dispatch);

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveUsers): AbstractComponent<ActiveUsersOwnProps>);
