import React from "react";
import styles from "./style.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      Version: {process.env.REACT_APP_VERSION}
    </footer>
  );
}

export default Footer;
