/** @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Switch } from 'antd';
import { RouteChildrenProps } from 'react-router';
import 'antd/dist/antd.css';

import { getAutoLinkingEmails } from '../../../store/api/partners';

import type { PartnerDataType } from '../index';
import style from '../style.module.css';

const { TextArea } = Input;

type PartnersFormProps = {
  defaultPartnerData: PartnerDataType,
  data: PartnerDataType,
  errors: { [key: string]: string },
  editFS?: boolean,
  userAuth?: string,
  onChangeField: () => void,
  onSubmit?: () => void,
  onFieldValueChanged: (name: string, value: boolean) => void,
};

type PartnersFormState = {
  defaultPartnerData: PartnerDataType,
};

class PartnersForm extends Component<RouteChildrenProps & PartnersFormProps, PartnersFormState> {
  constructor(props) {
    super(props);

    const { defaultPartnerData } = props;

    this.state = {
      defaultPartnerData,
      areAutoLinkingEmailsLoading: true
    }
  }

  async componentDidMount() {
    if(!this.props.data.tag) {
      this.setState({ areAutoLinkingEmailsLoading: false });
      return;
    }

    const autoLinkingEmails = await getAutoLinkingEmails(this.props.userAuth, this.props.data.tag);

    const packAutoLinkingEmailsIntoDataField = {
      target: {
        value: autoLinkingEmails.join(',\n'),
        name: 'autoLinkingEmails'
      }
    };
    this.props.onChangeField(packAutoLinkingEmailsIntoDataField);
    this.setState({ areAutoLinkingEmailsLoading: false });
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit();
  }

  handleFieldValueChanged = name => value => {
    const { onFieldValueChanged } = this.props;
    onFieldValueChanged && onFieldValueChanged(name, value);
  }

  render() {
    const { defaultPartnerData, areAutoLinkingEmailsLoading } = this.state;
    const { data, errors = {}, editFS, onChangeField } = this.props;

    return(
      <div className={style.formCreate}>
        
        <div className={style.formElem}>
          <div className={style.switchWrapper}>
            <span className={style.switchLabel}>B2B</span>
            <Switch onChange={this.handleFieldValueChanged('b2B')} checked={data.b2B} />
          </div>
        </div>

        <div className={style.inputsWrapper}>
          <div className={style.inputsColumn}>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Name"
                allowClear
                defaultValue={defaultPartnerData.name}
                value={data.name}
                onChange={onChangeField}
                name="name"
              />
              {errors.name && <span className={style.errorMsg}>{errors.name}</span>}
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Short Name"
                allowClear
                defaultValue={defaultPartnerData.shortName}
                value={data.shortName}
                onChange={onChangeField}
                name="shortName"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Tag"
                allowClear
                defaultValue={defaultPartnerData.tag}
                value={data.tag}
                onChange={onChangeField}
                name="tag"
              />
              {errors.tag && <span className={style.errorMsg}>{errors.tag}</span>}
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Email Domains"
                allowClear
                defaultValue={defaultPartnerData.emailDomains}
                value={data.emailDomains}
                onChange={onChangeField}
                name="emailDomains"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Image Url"
                allowClear
                defaultValue={defaultPartnerData.imgUrl}
                value={data.imgUrl}
                onChange={onChangeField}
                name="imgUrl"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Bonus"
                allowClear
                defaultValue={defaultPartnerData.regBonus}
                value={data.regBonus}
                onChange={onChangeField}
                name="regBonus"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Call Fee Percent"
                allowClear
                defaultValue={defaultPartnerData.callFeePercent}
                value={data.callFeePercent}
                onChange={onChangeField}
                name="callFeePercent"
              />

            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Welcome Message"
                allowClear
                defaultValue={defaultPartnerData.welcomeMessage}
                value={data.welcomeMessage}
                onChange={onChangeField}
                name="welcomeMessage"
              />
            </div>
            <div className={style.formElem}>
              <TextArea
                placeholder="Description"
                autoSize={{ minRows: 2, maxRows: 3 }}
                onChange={onChangeField}
                value={data.description}
                name="description"
              />
            </div>
          </div>

          <div className={style.inputsColumn}>
            <TextArea
              placeholder={areAutoLinkingEmailsLoading ? 'Fetching emails for auto linking...' : 'Emails for auto linking to this network'}
              disabled={areAutoLinkingEmailsLoading}
              style={{ minHeight: '532px' }}
              onChange={onChangeField}
              value={data.autoLinkingEmails}
              name="autoLinkingEmails"
              onBlur={e => {
                const formattedValue = e.target.value.replace(/\s*,\s*/gi, ',\n');
                e.target.value = formattedValue;
              }}
            />
          </div>
        </div>        
        
        {!editFS && (
          <Button key="submit" type="primary" onClick={this.handleSubmit}>
            Submit
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userAuth: state.loggedInUser.userAuth,
});

export default connect(
  mapStateToProps
)(PartnersForm);
