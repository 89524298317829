import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import style from "./style.module.css";
import Card from "../../Layout/card";
import NoteItem from "./note-item";
import { Spin } from "antd";

import {
  addNote, removeNote, editNote, getAllNotes,
} from "../../../store/actions/notes";

class AdminNotes extends Component {
  state = {
    note_message: "",
    notes: this.props.notes,
    action: 'add',
  };

  componentDidMount () {
    const { getAllNotes, authData, user: { id } } = this.props;
    getAllNotes(id, authData);
  }

  onChange = e => this.setState({ note_message: e.target.value });

  addNoteHandler = () => {
    const { addNote, authData, user: { id }, getAllNotes } = this.props;
    if (this.state.note_message) {
      const noteData = {
        message: this.state.note_message,
      };
      addNote(noteData, id, authData);
    }
    this.setState({
      note_message: '',
    });
    setTimeout(()=>(getAllNotes(id, authData)), 300);
  };

  removeNoteHandler = (noteId) => {
    const { removeNote, authData, user: { id }, getAllNotes } = this.props;
    const noteData = { id: noteId };
    removeNote(noteData, id, authData);
    this.setState({
      note_message: '',
    });
    setTimeout(()=>(getAllNotes(id, authData)), 300);
  };

  editNoteText = (noteId, noteMeassage) => {
    this.setState({
      note_message: noteMeassage,
      action: 'edit',
      noteId,
    })
  };

  editNoteHandler = (noteId) => {
    const { editNote, authData, user: { id }, getAllNotes } = this.props;
    if (this.state.note_message) {
      const noteData = { id: noteId, message: this.state.note_message };
      editNote(noteData, id, authData);
    }
    this.setState({
      note_message: '',
      action: 'add',
    });
    setTimeout(()=>(getAllNotes(id, authData)), 300);
  }


  saveNote = () => {
    const { action, noteId } = this.state;

    if (action === 'add') {
      this.addNoteHandler();
    } else if (action === 'edit') {
      this.editNoteHandler(noteId);
    }
  }

  render() {
    const { notes, loading } = this.props;

    let notesArray = [];
    if (!loading && notes) {
      notesArray = notes.map(note => <NoteItem key={note.id} data={note} removeNote={this.removeNoteHandler} editNote={this.editNoteText} userData={this.props.userData}/>);
    }
    return (
      <Card cardClass={style.adminNotesCard}>
        <div style={{ display: "flex" }}>
          <div className={style.taLabel}>Admin Notes</div>
          <textarea
            rows={3}
            className={style.ta}
            value={this.state.note_message}
            onChange={this.onChange}
          />
        </div>
        <button
          type="button"
          className={style.saveNoteBtn}
          onClick={this.saveNote}
        >
          Save
        </button>
        { loading ? <Spin size="large" /> : notesArray}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userData: state.loggedInUser,
  loading: state.note.loading,
  notes: state.note.notes,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNote,
      removeNote,
      editNote,
      getAllNotes,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNotes);
