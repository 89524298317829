/** @flow */

import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { InternationalizationContext } from '../../../i18n/internationalization';
import Card from '../../Layout/card';

import style from './style.module.css';

import type { UserAvailabilityEntry } from '../../../types';


type Props = {
  user: {
    availability: Array<UserAvailabilityEntry>
  }
}

const ProAvailability = ({ user }: Props): React$Node => {
  const { formatAmPm, format24HoursTime, getCurrentTimezone } = useContext(InternationalizationContext);
  const { availability } = user;
  
  const dateTimeProperties = Intl.DateTimeFormat().resolvedOptions();
  /* eslint-disable-next-line no-prototype-builtins */
  const shouldUseAmPmFormat = dateTimeProperties.hasOwnProperty('hour12') && dateTimeProperties.hour12;

  const renderAvailabilities = availability.map(({ daysList, timeFrom, timeTo }) => {
    const { hour: fromHour, minute: fromMinute } = timeFrom;
    const { hour: toHour, minute: toMinute } = timeTo;

    const startTime = shouldUseAmPmFormat ? formatAmPm(fromHour, fromMinute) : format24HoursTime(fromHour, fromMinute);
    const endTime = shouldUseAmPmFormat ? formatAmPm(toHour, toMinute) : format24HoursTime(toHour, toMinute);
        
    return (
      <div className={style.availabilityItem} key={v4()}>
        <div className={style.time}>
          <div>{`${startTime} - ${endTime}`}</div>
          <div>{getCurrentTimezone()}</div>          
        </div>
        <div className={style.days}>
          <span className={style.every}>Every</span> {daysList.join(', ').toLowerCase()}
        </div>
        <div className={style.hr} />
      </div>
    );
  });

  return availability.length ? (
    <div>
      <div className="headline">Pro Availabilty</div>
      <Card cardClass={style.proAvailabilityCard} class={style.cardContent}>
        <div className={style.cardWrapper}>
          {renderAvailabilities}
        </div>
      </Card>
    </div>
  ) : null;
};

export default ProAvailability;
