import React from 'react';
import { Checkbox, Tag, Popover } from 'antd';
import { intervalToDuration, parseISO, compareAsc } from 'date-fns';
import moment from 'moment';
import { convertDate, convertDateSafari, secToMS, secToDH, secToHMS, convertDuration } from './index';
import { INFO_TYPES } from '../constants';
// import endCallIcon from '../assets/hang-up.png';
import CallDetailsPopover from '../components/calls-table-controls/call-details-popover'
import UserDevicesPopover from '../components/user-table-controls/user-devices-popover'

export const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
export const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

export const pendingTableColumns = (sInfo = {}) => [
  {
    title: 'Appl ID',
    dataIndex: 'updateProData.id',
    sorter: (a, b) => a.updateProData.id - b.updateProData.id,
    sortOrder: sInfo.columnKey === 'updateProData.id' && sInfo.order
  },
  {
    title: 'User ID',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
    sortOrder: sInfo.columnKey === 'id' && sInfo.order
  },
  {
    title: 'First name',
    dataIndex: 'name',
    sorter: (a, b) => String(a.name).localeCompare(b.name),
    sortOrder: sInfo.columnKey === 'name' && sInfo.order
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    sorter: (a, b) => String(a.lastName).localeCompare(b.lastName),
    sortOrder: sInfo.columnKey === 'lastName' && sInfo.order
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: (a, b) => String(a.email).localeCompare(b.email),
    sortOrder: sInfo.columnKey === 'email' && sInfo.order
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => String(a.status).localeCompare(b.status),
    sortOrder: sInfo.columnKey === 'status' && sInfo.order
  },
  {
    title: 'Application submitted',
    dataIndex: 'updateProData.timestamp',
    render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
    // compare date
    sorter: (a, b) =>
      new Date(a.updateProData.timestamp).getTime() -
      new Date(b.updateProData.timestamp).getTime(),
    sortOrder: sInfo.columnKey === 'updateProData.timestamp' && sInfo.order
  },
  {
    title: 'Days pending',
    dataIndex: 'updateProData.timestamp',
    render: text => {
      const submissionDate = moment(text);
      const currentDate = moment();
      return  currentDate.diff(submissionDate, 'days');
    }
  },
  {
    title: 'Last active',
    dataIndex: 'lastActive'
  },
  {
    title: 'Registration date',
    dataIndex: 'registrationDate',
    render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
    // compare date
    sorter: (a, b) =>
      new Date(a.registrationDate).getTime() -
      new Date(b.registrationDate).getTime(),
    sortOrder: sInfo.columnKey === 'registrationDate' && sInfo.order
  }
];

export const withdrawalsTableColumns = (sInfo = {}, btnsRender) => [
  {
    title: 'ID',
    dataIndex: 'id',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.id - b.id,
    sortOrder: sInfo.columnKey === 'id' && sInfo.order
  },
  {
    title: 'First name',
    dataIndex: 'user.name'
  },
  {
    title: 'Last name',
    dataIndex: 'user.lastName'
  },
  {
    title: 'Email',
    dataIndex: 'user.email'
  },
  {
    title: 'Withdrawal Amount',
    dataIndex: 'amount',
    render: text => <div style={{ textAlign: 'center' }}>{text}</div>,
    sorter: (a, b) => a.amount - b.amount,
    sortOrder: sInfo.columnKey === 'amount' && sInfo.order
  },
  {
    title: 'Action',
    dataIndex: '',
    render: btnsRender
  }
];

export const tableColumns = (sInfo = {}, filters = {}, selectedFilters = {}) => [
  {
    title: 'Id',
    dataIndex: 'id',
    width: 70,
    fixed: 'left',
    sorter: true,
    sortOrder: sInfo.columnKey === 'id' && sInfo.order
  },
  {
    title: 'First name',
    dataIndex: 'name',
    width: 150,
    fixed: 'left',
    sorter: true,
    sortOrder: sInfo.columnKey === 'name' && sInfo.order
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    width: 150,
    fixed: 'left',
    sorter: true,
    sortOrder: sInfo.columnKey === 'lastName' && sInfo.order
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 220,
    sorter: true,
    sortOrder: sInfo.columnKey === 'email' && sInfo.order
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 140,
    render: text => <div style={{ fontSize: 12 }}>{text}</div>
  },
  {
    title: 'Partners',
    dataIndex: 'relatedPartners',
    width: 140,
    filters: filters.relatedPartners || [],
    defaultFilteredValue: selectedFilters.relatedPartners || [],
    filteredValue: sInfo.relatedPartners,
    onFilter: (value, record) => record.relatedPartners.includes(value),
    render: relatedPartners => {
      const partners = relatedPartners || [];
      const partnerStyle = {
        margin: '0 2px 2px 0'
      };
      if (partners.length) {
        return partners.map(partner => (
          <Tag color="#bbb" key={partner} style={partnerStyle}>
            {partner.toUpperCase()}
          </Tag>
        ));
      }
      return <Tag color="#bbb" style={partnerStyle}>-</Tag>
    },
  },
  {
    title: 'Payout System',
    dataIndex: 'proPaymentSystem',
    width: 100,
    render: text => (
      <Tag
        color="#bbb"
        style={{
          margin: '0 2px 2px 0',
          textAlign: 'center',
        }}
      >
        {text || '-'}
      </Tag>
    ),
  },
  {
    title: 'Last Device',
    dataIndex: 'lastDevice',
    width: 100,
    render: (text, item) => (
      <UserDevicesPopover latestDevice={text} userDevices={item.devices} />
    )
  },
  {
    title: 'Application Version',
    dataIndex: 'lastDevice.appVersion',
    width: 100,
    render: text => text || '-',
  },
  {
    title: 'Locale',
    dataIndex: 'lastDevice.locale',
    width: 80,
    render: text => text || '-',
  },
  {
    title: 'Last active',
    dataIndex: 'dateLastActive',
    width: 170,
    render: (text, record) => record.activeNow ? 'active now' : convertDate(text),
    sorter: true,
    sortOrder: sInfo.columnKey === 'dateLastActive' && sInfo.order
  },
  {
    title: 'Registration date',
    dataIndex: 'registrationDate',
    width: 170,
    render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
    sorter: true,
    sortOrder: sInfo.columnKey === 'registrationDate' && sInfo.order
  },
  {
    title: 'Last outgoing transaction',
    dataIndex: 'lastOutgoingTransaction',
    width: 170,
    render: text => text ? (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text) : '-',
  },
  {
    title: 'Last incoming Earning',
    dataIndex: 'lastIncomingEarning',
    width: 170,
    render: text => text ? (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text) : '-',
  },
  {
    title: 'Telmie Credit',
    dataIndex: 'telmieCredit',
    width: 100,
    sorter: true,
    sortOrder: sInfo.columnKey === 'telmieCredit' && sInfo.order
  },
  {
    title: 'Payment From Card',
    dataIndex: 'paymentFromCard',
    width: 100,
    sorter: true,
    sortOrder: sInfo.columnKey === 'paymentFromCard' && sInfo.order
  },
  {
    title: 'Total',
    children: [
      {
        title: 'Active',
        dataIndex: 'activeFor',
        width: 100,
        render: text => secToDH(text),
      },
      {
        title: 'Spend',
        dataIndex: 'totalSpend',
        width: 100,
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalSpend' && sInfo.order
      },
      {
        title: 'Earn',
        dataIndex: 'totalEarn',
        width: 100,
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalEarn' && sInfo.order
      },
      {
        title: 'Text Outgoing',
        dataIndex: 'totalTextOutgoing',
        width: 100,
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalTextOutgoing' && sInfo.order
      },
      {
        title: 'Text Incoming',
        dataIndex: 'totalTextIncoming',
        width: 100,
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalTextIncoming' && sInfo.order
      },
      {
        title: 'Minutes Outgoing',
        dataIndex: 'totalMinsOutgoing',
        width: 100,
        render: text => secToHMS(text),
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalMinsOutgoing' && sInfo.order
      },
      {
        title: 'Minutes Incoming',
        dataIndex: 'totalMinsIncoming',
        width: 100,
        render: text => secToHMS(text),
        sorter: true,
        sortOrder: sInfo.columnKey === 'totalMinsIncoming' && sInfo.order
      }
    ]
  },
  {
    title: 'Registration Info',
    dataIndex: 'regInfo',
    width: 100,
    render: text => text || '-',
  }
];

export const invitesColumns = (sInfo = {}) => [
  {
    title: 'Date',
    dataIndex: 'date',
    defaultSortOrder: 'descend',
    render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    sortOrder: sInfo.columnKey === 'date' && sInfo.order
  },
  {
    title: 'Invite type',
    dataIndex: 'type'
  },
  {
    title: 'Receiver Email',
    dataIndex: 'receiverEmail',
    sorter: (a, b) => String(a.receiverEmail).localeCompare(b.receiverEmail),
    sortOrder: sInfo.columnKey === 'receiverEmail' && sInfo.order
  },
  {
    title: 'Receiver Name',
    dataIndex: 'receiverName',
    sorter: (a, b) => String(a.receiverName).localeCompare(b.receiverName),
    sortOrder: sInfo.columnKey === 'receiverName' && sInfo.order
  },
  {
    title: 'Receiver Role',
    dataIndex: 'receiverRole'
  },
  {
    title: 'Sender Email',
    dataIndex: 'senderEmail',
    sorter: (a, b) => String(a.senderEmail).localeCompare(b.senderEmail),
    sortOrder: sInfo.columnKey === 'senderEmail' && sInfo.order
  },
  {
    title: 'Sender Full Name',
    dataIndex: 'senderFullName',
    sorter: (a, b) => String(a.senderFullName).localeCompare(b.senderFullName),
    sortOrder: sInfo.columnKey === 'senderFullName' && sInfo.order
  },
  {
    title: 'Sender Role',
    dataIndex: 'senderRole'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];

export const incomplTableColumns = (sInfo = {}) => [
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: (a, b) => String(a.email).localeCompare(b.email),
    sortOrder: sInfo.columnKey === 'email' && sInfo.order
  },
  {
    title: 'Date',
    dataIndex: 'date',
    render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    sortOrder: sInfo.columnKey === 'date' && sInfo.order
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile'
  }
];

export const infoColumns = infoType => {
  switch (infoType) {
    case INFO_TYPES.ACTIVITIES:
      return [
        {
          title: 'Activity',
          dataIndex: 'activity',
          render: ({ activityGroup, name }) => (
            `${activityGroup} (${name})`
          ),
          sorter: (a, b) => String(a.activity.activityGroup).localeCompare(b.activity.name)
        },
        {
          title: 'Activity Date',
          dataIndex: 'activity.activityDate',
          render: text => (isSafari || mobileDevice) ? convertDateSafari(text) : convertDate(text),
          // compare date
          sorter: (a, b) =>
            compareAsc(new Date(a.activity.activityDate), new Date(b.activity.activityDate))
        },
        {
          title: 'First name',
          dataIndex: 'name',
          sorter: (a, b) => String(a.name).localeCompare(b.name)
        },
        {
          title: 'Last name',
          dataIndex: 'lastName',
          sorter: (a, b) => String(a.lastName).localeCompare(b.lastName)
        },
      ];
    case INFO_TYPES.CLIENTS:
    case INFO_TYPES.LIST_OF_PROS:
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id
        },
        {
          title: 'First name',
          dataIndex: 'name',
          sorter: (a, b) => String(a.name).localeCompare(b.name)
        },
        {
          title: 'Last name',
          dataIndex: 'lastName',
          sorter: (a, b) => String(a.lastName).localeCompare(b.lastName)
        },
        {
          title: 'Email',
          dataIndex: 'email',
          sorter: (a, b) => String(a.email).localeCompare(b.email)
        }
      ];
    default:
  }
};

export const appointmentsColumns = (sInfo = {}, onClick, formatCurrency, formatDateTime, renderButtons) => [
  {
    title: 'Id',
    dataIndex: 'id',
  },
  {
    title: 'Pro Id',
    dataIndex: ['pro', 'id'],
  },
  {
    title: 'Pro Name',
    dataIndex: ['pro', 'fullName'],
    render: (text, item) => (
      <span className="table-selectable-name" onClick={onClick(item.pro.id)}>
        {text}
      </span>
    )
  },
  {
    title: 'Pro Email',
    dataIndex: ['pro', 'email']
  },
  {
    title: 'Client Id',
    dataIndex: ['client', 'id']
  },
  {
    title: 'Client Name',
    dataIndex: ['client', 'fullName'],
    render: (text, item) => (
      <span className="table-selectable-name" onClick={onClick(item.client.id)}>
        {text}
      </span>
    )
  },
  {
    title: 'Client Email',
    dataIndex: ['client', 'email'],
  },
  {
    title: 'Payment Amount',
    dataIndex: 'paymentAmount',
    render: text => formatCurrency(text),
    sorter: (a, b) => a.paymentAmount - b.paymentAmount,
    sortOrder: sInfo.columnKey === 'paymentAmount' && sInfo.order
  },
  {
    title: 'Refund Amount',
    dataIndex: 'refundAmount',
    sorter: (a, b) => a.refundAmount - b.refundAmount,
    sortOrder: sInfo.columnKey === 'refundAmount' && sInfo.order
  },
  {
    title: 'Refund Percentage',
    dataIndex: 'refundPercentage',
    sorter: (a, b) => a.refundPercentage - b.refundPercentage,
    sortOrder: sInfo.columnKey === 'refundPercentage' && sInfo.order
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: text => formatDateTime(text),
    sorter: (a, b) =>
      compareAsc(new Date(a.created), new Date(b.created)),
    sortOrder: sInfo.columnKey === 'created' && sInfo.order,
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    render: text => formatDateTime(text),
    sorter: (a, b) => compareAsc(new Date(a.startTime), new Date(b.startTime)),
    sortOrder: sInfo.columnKey === 'startTime' && sInfo.order,
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    render: text => formatDateTime(text),
    sorter: (a, b) =>
      compareAsc(new Date(a.endTime), new Date(b.endTime)),
    sortOrder: sInfo.columnKey === 'endTime' && sInfo.order,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    render: (_text, item) => convertDuration(intervalToDuration({
      start: parseISO(item.startTime),
      end: parseISO(item.endTime),
    })),
    sorter: (a, b) =>
      (parseISO(a.endTime).getTime() - parseISO(a.startTime).getTime()) -
      (parseISO(b.endTime).getTime() - parseISO(b.startTime).getTime()),
    sortOrder: sInfo.columnKey === 'duration' && sInfo.order,
  },
  {
    title: 'Aggregate Call Duration',
    dataIndex: 'aggregateCallDuration',
    render: text => text ?? '-',
    sorter: (a, b) => a.aggregateCallDuration - b.aggregateCallDuration,
    sortOrder: sInfo.columnKey === 'aggregateCallDuration' && sInfo.order
  },
  {
    title: 'Percentage Call Duration',
    dataIndex: 'percentageCallDuration',
    sorter: (a, b) => a.percentageCallDuration - b.percentageCallDuration,
    sortOrder: sInfo.columnKey === 'percentageCallDuration' && sInfo.order
  },
  {
    title: 'Count of Calls',
    dataIndex: 'countOfCalls',
    sorter: (a, b) => a.countOfCalls - b.countOfCalls,
    sortOrder: sInfo.columnKey === 'countOfCalls' && sInfo.order
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Cancellation Time',
    dataIndex: 'cancellationTime',
    render: text => text ? formatDateTime(text) : '-',
    sorter: (a, b) => compareAsc(new Date(a.cancellationTime), new Date(b.cancellationTime)),
    sortOrder: sInfo.columnKey === 'cancellationTime' && sInfo.order,
  },
  {
    title: 'Actions',
    render: text => {
      const a = moment();
      const b = moment(text.startTime);
      const hasButtons = b.diff(a) > 0 && !['CANCELLED_BY_PRO', 'CANCELLED_BY_CLIENT'].includes(text.status);

      return hasButtons ? renderButtons(text) : null;
    }
  },
];

export const callsColumns = (sInfo = {}, onClick, callsStatuses, partners, modalEndCall, formatDateTime) => [
  {
    title: 'Id',
    dataIndex: 'id',
    render: (text, item) => (
      <CallDetailsPopover callId={text} description={item.description} />
    )
  },
  {
    title: 'Pro Id',
    dataIndex: 'proId'
  },
  {
    title: 'Pro Name',
    dataIndex: 'proFullName',
    render: (text, item) => (
      <span className="table-selectable-name" onClick={onClick(item.proId)}>
        {text}
      </span>
    )
  },
  {
    title: 'Pro Email',
    dataIndex: 'proEmail'
  },
  {
    title: 'Client Id',
    dataIndex: 'clientId'
  },
  {
    title: 'Client Name',
    dataIndex: 'clientFullName',
    render: (text, item) => (
      <span className="table-selectable-name" onClick={onClick(item.clientId)}>
        {text}
      </span>
    )
  },
  {
    title: 'Client Email',
    dataIndex: 'clientEmail'
  },
  {
    title: 'Partner',
    dataIndex: 'relatedPartner',
    filters: partners || [],
    filteredValue: sInfo.relatedPartner,
    onFilter: (value, record) => record.relatedPartner.includes(value),
    render: relatedPartner => (
      <Tag color="#bbb" key={relatedPartner} style={{ margin: '0 2px 2px 0' }}>
        {relatedPartner.toUpperCase() ?? '-'}
      </Tag>
    )
  },
  {
    title: 'Date',
    dataIndex: 'startDate',
    render: text => formatDateTime(text),
    sorter: (a, b) =>
      new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    sortOrder: sInfo.columnKey === 'startDate' && sInfo.order
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filters: callsStatuses,
    filteredValue: sInfo.status,
    onFilter: (value, record) => record.status.includes(value),
    render: status => (
      <Tag color="#bbb" key={status} style={{ margin: '0 2px 2px 0' }}>
        {status.toUpperCase().replace('_',' ') ?? '-'}
      </Tag>
    )
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    render: text => secToMS(text),
    sorter: (a, b) => a.duration - b.duration,
    sortOrder: sInfo.columnKey === 'duration' && sInfo.order
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    sortOrder: sInfo.columnKey === 'amount' && sInfo.order
  },
  {
    title: 'Call Type',
    dataIndex: 'type'
  }
  /*
  {
    title: 'End a call',
    dataIndex: 'endCall',
    render: (text, item) => (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        onClick={modalEndCall(item)}
        className="endCall"
        type="button"
        style={{ backgroundImage: `url(${endCallIcon})` }}
      />
    ),
  }
  */
];

export const transactionsColumns = (sInfo = {}, onChageUser, trStatuses, formatCurrency, formatDateTime) => [
  {
    title: 'User Id',
    dataIndex: 'userID'
  },
  {
    title: 'User Name',
    dataIndex: 'userFullName',
    render: (text, item) => (
      // <span className="table-selectable-name" onClick={onClick(item.userId)}>
      <span className="table-selectable-name" onClick={onChageUser(item.userID)}>
        {text}
      </span>
    )
  },
  {
    title: 'User Email',
    dataIndex: 'userEmail'
  },
  {
    title: 'Date',
    dataIndex: 'dateTime',
    render: text => formatDateTime(text),
  },
  {
    title: 'Type',
    dataIndex: 'transactionType',
    filters: trStatuses,
    filteredValue: sInfo.transactionType,
    onFilter: (value, record) => record.transactionType.includes(value),
    render: (text, item) => (
      <Popover
        content={item.description}
        title="Transaction description"
      >
        {text}
      </Popover>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    sortOrder: sInfo.columnKey === 'amount' && sInfo.order,
    render: text => formatCurrency(text),
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    sorter: (a, b) => a.fee - b.fee,
    sortOrder: sInfo.columnKey === 'fee' && sInfo.order,
    render: text => formatCurrency(text),
  },
];

export const featuredServicesTableColumns = (openEditModal, deleteFeaturedService) => [
  {
    title: 'Service Id',
    dataIndex: 'id'
  },
  {
    title: 'Service title',
    dataIndex: 'title'
  },
  {
    title: 'Service sub-title',
    dataIndex: 'subTitle'
  },
  {
    title: 'Service description',
    dataIndex: 'description',
  },
  {
    title: 'Sector',
    dataIndex: 'sector',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Service tag',
    dataIndex: 'service',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Image url',
    dataIndex: 'imgUrl',
    render: (text, item) => (
      <img src={item.imgUrl} alt="imgUrl" style={{ width: '100px' }} />
    )
  },
  {
    title: 'Fix price',
    dataIndex: 'fixPrice',
    render: (text, item) => (
      <Checkbox checked={item.fixPrice} />
    )
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text, item) => (
      <div>
        <button className="editService" type="button" onClick={openEditModal(item)}>
          Edit
        </button>
        <button className="deleteService" type="button" onClick={deleteFeaturedService({ id: item.id })}>
          Delete
        </button>
      </div>
    ),
  },
];

export const partnersTableColumns = (openEditModal, deletePartner) => [
  {
    title: 'Network Id',
    dataIndex: 'id',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Short Name',
    dataIndex: 'shortName'
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
  },
  {
    title: 'B2B',
    dataIndex: 'b2B',
    render: text => text ? 'yes' : 'no',
  },
  {
    title: 'Email Domains',
    dataIndex: 'emailDomains'
  },
  {
    title: 'Bonus',
    dataIndex: 'regBonus',
  },
  {
    title: 'Call Fee Percent',
    dataIndex: 'callFeePercent',
  },
  {
    title: 'Welcome Message',
    dataIndex: 'welcomeMessage',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (text, item) => (
      <>
        {item.totalPros < 2 && item.totalClients < 1 && <Tag color="#bbb" key={text} style={{ margin: '0 2px 2px 0' }}>Empty network</Tag>}
        <div>{text}</div>
      </>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text, item) => (
      <div>
        <button className="editPartner" type="button" onClick={openEditModal(item)}>
          Edit
        </button>
        <button className="deletePartner" type="button" onClick={deletePartner({ id: item.id })}>
          Delete
        </button>
      </div>
    ),
  },
];

export const countriesTableColumns = (openEditModal, deleteCountry) => [
  {
    title: 'Country code',
    dataIndex: 'code',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.code - b.code,
  },
  {
    title: 'Name',
    dataIndex: 'displayName'
  },
  {
    title: 'Local Name',
    dataIndex: 'localName'
  },
  {
    title: 'Search Terms for Google Maps',
    dataIndex: 'searchTerms',
  },
  {
    title: 'Total pros',
    dataIndex: 'totalPros',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text, item) => (
      <div>
        <button className="editCountry" type="button" onClick={openEditModal(item)}>
          Edit
        </button>
        <button className="deleteCountry" type="button" onClick={deleteCountry({ code: item.code })}>
          Delete
        </button>
      </div>
    ),
  },
];
