import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loader: {
    padding: '50px 0',
    width: '100%',
  }
});

function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <LinearProgress />
    </div>
  );
}

export default React.memo(Loader);
