import { apiUrls } from "./index";

const featuredServicesManipulation = (url, method, authData, data) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json ");
  headers.append("Authorization", `Bearer ${authData}`);
  const body = JSON.stringify(data);
  return fetch(url, { method, headers, body }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      if (method === "DELETE") {
        return {
          removeFeaturedService: 'success',
        }
      } else if(method === 'PUT') {
        return {
          editFeaturedService: 'success',
        }
      } else {
        return response.json().then(json => json);
      }
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const addFeaturedService = (data, authData) =>
  featuredServicesManipulation(apiUrls.FEATURED_SERVICERS, "POST", authData, data);

export const editFeaturedService = (data, authData) =>
  featuredServicesManipulation(apiUrls.FEATURED_SERVICERS, "PUT", authData, data);

export const removeFeaturedService = (data, authData) =>
  featuredServicesManipulation(apiUrls.FEATURED_SERVICERS, "DELETE", authData, data);

export const getAllFeaturedService = (authData) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);
  return fetch(apiUrls.FEATURED_SERVICERS, { method: "GET", headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }  
  );
}