import { apiUrls } from "./index";

const sendNotification = (url, method, data, authData) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);
  headers.append("Content-Type", "application/json ");

  return fetch(url, { method, headers, body: JSON.stringify(data) }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return { 
          error: true,
        };
      } else {
        return { 
          error: false,
        };
      }
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const getPushList = authData => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(apiUrls.SEND_PUSH, { method: "GET", headers }).then(response =>
    response
      .json()
      .then(json => json)
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        return { error: true };
      })
  );
};

export const updatePush = (item, authData) =>
  sendNotification(apiUrls.SEND_PUSH, "PUT", item, authData);

export const sendPush = (users, txt, authData) => {
  const data = {
    users,
    title: "",
    body: txt
  };

  return sendNotification(apiUrls.SEND_PUSH, "POST", data, authData);
};

export const sendEmail = (users, tag, authData) => {
  const data = {
    users,
    tag
  };

  return sendNotification(apiUrls.SEND_EMAIL, "POST", data, authData);
};
