/* eslint-disable react/no-unused-prop-types */
/** @flow */

import React from 'react';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Table, Spin, Checkbox, DatePicker, Pagination, Statistic, PageHeader, Row, Button, Input } from 'antd';
import Card from '../Layout/card';
import SearchArea from '../user-table-controls/search-area';
import MessagesArea from './message-area';

import {
  PAGE_SIZE,
  ROUTES,
} from '../../constants';
import type { SorterType, StatusOptionType, RelatedPartnersFilters, DateRangeSearchCriteria } from '../../types';

import style from './style.module.css';

const { RangePicker } = DatePicker;

const searchItemsArr = [
  {
    label: 'General:',
    name: 'general'
  }
];

type UserRouteWrapperDefaultProps = {|
  withFilter?: boolean,
  onCSVExport ?: (downloadLink: ?HTMLAnchorElement, searchCriteria: DateRangeSearchCriteria) => void,
|}

type UserRouteWrapperOwnProps = {|
  ...UserRouteWrapperDefaultProps,
  currentPage: number,
  search: string,
  totalItems: number,
  onFilterChange: (statusFilters: string[], searchCriteria: DateRangeSearchCriteria) => void,
  onSearchChange: (query: string, searchCriteria: DateRangeSearchCriteria) => void,
  onSortChange: (sort: string, filters: {| [key: string]: Array<string> |}, searchCriteria: DateRangeSearchCriteria) => void,
  onPaginationChange: (page: number, searchCriteria: DateRangeSearchCriteria) => void,
  sorter: SorterType,
  statusFilters: StatusOptionType[],
  isAU: boolean,
  userAuth: ?string,
  statusFilters: Array<{| status: string, checked: boolean |}>,
  isInvites: boolean,
  uArrays: {|
    total?: number,
    activeUsers?: any[],
    load?: boolean,
    error?: boolean,
    message?: string,
  |},
  scroll: {|
    x ?: string | number | boolean;
    y ?: string | number | boolean;
    scrollToFirstRowOnChange ?: boolean;
  |},
  usersArr: Array<any>,
  partnersFilter: RelatedPartnersFilters,
  selectedFilters: RelatedPartnersFilters,
  columns: (
    sInfo: SorterType,
    filters: RelatedPartnersFilters,
    selectedFilters: RelatedPartnersFilters
  ) => Array<any>,
  hiddenPatnerName: string
|};

type UserRouteWrapperProps = UserRouteWrapperOwnProps & ContextRouter;

type UserRouteWrapperState = {
  selectedRowKeys: Array<any>,
  registrationPeriodStart: ?Moment | null,
  registrationPeriodEnd: ?Moment | null,
  activePeriodStart: ?Moment | null,
  activePeriodEnd: ?Moment | null,
  registrationSearchDays: null | number,
  activePeriodSearchDays: null | number
};

class UsersRouteWrapper extends React.Component<UserRouteWrapperProps, UserRouteWrapperState> {
  static defaultProps: UserRouteWrapperDefaultProps = {
    withFilter: false,
    onCSVExport: undefined,
  };

  csvDownloadAnchor: { current: null | HTMLAnchorElement }

  constructor(props: ContextRouter & UserRouteWrapperOwnProps) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      registrationPeriodStart: null,
      registrationPeriodEnd: null,
      registrationSearchDays: null,
      activePeriodStart: null,
      activePeriodEnd: null,
      activePeriodSearchDays: null,
    };

    this.csvDownloadAnchor = React.createRef < HTMLAnchorElement > ();
  }

  componentDidMount() {
    const { hiddenPartnerName, partnersFilter } = this.props;
    const filters = partnersFilter.relatedPartners.filter(filter => filter.value !== hiddenPartnerName).map(filter => filter.value);

    this.props.onSortChange('', { relatedPartners: filters }, this.getDateRangeSearchCriteria());
  }

  getDateRangeSearchCriteria() {
    const {
      registrationPeriodStart,
      registrationPeriodEnd,
      registrationSearchDays,
      activePeriodStart,
      activePeriodEnd,
      activePeriodSearchDays
    } = this.state;

    const dateRangeSearchCriteria: DateRangeSearchCriteria = {};

    if (registrationPeriodStart && registrationPeriodEnd) {
      const reqParams = {
        registrationPeriod: {
          start: registrationPeriodStart.toISOString(),
          end: registrationPeriodEnd.toISOString()
        }
      }
      Object.assign(dateRangeSearchCriteria, reqParams);
    };

    if (registrationSearchDays) {
      const reqParams = {
        registrationPeriod: {
          period: registrationSearchDays
        }
      }
      Object.assign(dateRangeSearchCriteria, reqParams);
    }

    if (activePeriodStart && activePeriodEnd) {
      const reqParams = {
        lastActivePeriod: {
          start: activePeriodStart.toISOString(),
          end: activePeriodEnd.toISOString()
        }
      }
      Object.assign(dateRangeSearchCriteria, reqParams);
    };

    if (activePeriodSearchDays) {
      const reqParams = {
        lastActivePeriod: {
          period: activePeriodSearchDays
        }
      }
      Object.assign(dateRangeSearchCriteria, reqParams);
    }

    return dateRangeSearchCriteria;
  }

  onSearch: (searchFields: { [key: string]: string }) => void = searchFields => {
    const { onSearchChange } = this.props;    

    const query = Object.keys(searchFields)
      .map(item => `${searchFields[item]}`)
      .join(',');

    onSearchChange(query, this.getDateRangeSearchCriteria());
  };

  onStatusCheck: (checked: boolean, item: StatusOptionType) => void = (checked, item) => {
    const { statusFilters } = this.props;

    const statusFilter = [...statusFilters];

    const newStatusFilter = statusFilter.map(obj => {
      if (item.status === obj.status) {
        return {
          ...obj,
          checked
        };
      }

      return obj;
    });

    this.onStatusFilterChange(
      newStatusFilter
        .filter(obj => obj.checked)
        .map(obj => obj.status)
    );
  };

  onStatusFilterChange: (statusFilter: string[]) => void = (statusFilter: string[]) => {
    const { onFilterChange } = this.props;

    onFilterChange(statusFilter, this.getDateRangeSearchCriteria());
  };

  onRow: (record: { id: string }) => {| onClick: () => void |} = record => ({
    onClick: () => {
      const { history } = (this.props: ContextRouter);
      let pathname = '';

      if (history.location.pathname === ROUTES.ACTIVE_USERS) {
        pathname = `${ROUTES.USER}/${record.id}`;
      }

      history.push({ pathname });
    }
  });

onSelectChange: (selectedRowKeys: Array<string>) => void = selectedRowKeys => {
  this.setState({ selectedRowKeys });
};

onChange: (pagination: any, filters: {| [key: string]: Array<string> |}, sorter: SorterType) => void = (pagination, filters, sorter) => {
  if (sorter.order === 'ascend') {
    this.props.onSortChange(`${sorter.columnKey},asc`, filters, this.getDateRangeSearchCriteria());
  } else if (sorter.order === 'descend') {
    this.props.onSortChange(`${sorter.columnKey},desc`, filters, this.getDateRangeSearchCriteria());
  } else {
    this.props.onSortChange('', filters, this.getDateRangeSearchCriteria());
  }
};

onCSVExportClick: (_event: SyntheticEvent<HTMLButtonElement>) => void = _event => {
  if (this.csvDownloadAnchor.current && typeof this.props.onCSVExport === 'function') {
    this.props.onCSVExport(this.csvDownloadAnchor.current, this.getDateRangeSearchCriteria());
  }
};

onRegistrationPeriodRangeChange: (dates: Array<Moment>) => void = (dates, _dateStrings) => {
  const [dateFrom, dateTo] = dates;
  this.setState({
    registrationPeriodStart: dateFrom,
    registrationPeriodEnd: dateTo,
    registrationSearchDays: null
  })
};

onRegistrationDaysChange = event => {
  const { currentTarget: { value: days } } = event;

  this.setState({
    registrationPeriodStart: null,
    registrationPeriodEnd: null,
    registrationSearchDays: days
  });
};

onActivePeriodRangeChange: (dates: Array<Moment>) => void = (dates, _dateStrings) => {
  const [dateFrom, dateTo] = dates;
  this.setState({
    activePeriodStart: dateFrom,
    activePeriodEnd: dateTo,
    activePeriodSearchDays: null
  })
};

onActivePeriodDaysChange = event => {
  const { currentTarget: { value: days } } = event;

  this.setState({
    activePeriodStart: null,
    activePeriodEnd: null,
    activePeriodSearchDays: days
  });
};

render(): React$Element<any> {
  const {
    selectedRowKeys,
    registrationPeriodStart,
    registrationPeriodEnd,    
    registrationSearchDays,
    activePeriodStart,
    activePeriodEnd,
    activePeriodSearchDays
  } = this.state;

  const {
    columns,
    scroll = {},
    isAU,
    search,
    sorter,
    statusFilters,
    selectedFilters,
    partnersFilter
  } = (this.props: UserRouteWrapperOwnProps);

  const {
    load: isLoaded = false,
    error: isError = false,
    message: errorMsg = ''
  } = this.props.uArrays;

  const { usersArr =[], withFilter = false } = this.props;

  const rowSelection = isAU
    ? {
      selectedRowKeys,
      onChange: this.onSelectChange
    }
    : null;

  const initialSearchPhrases = {
    general: search,
  };

  return (
    <Card cardClass="route-content">
      {/* eslint-disable-next-line no-nested-ternary */ }
      { isLoaded ? (
        !isError ? (
          <>
            <PageHeader
              title="Active Users"
              backIcon={false}
              extra={[
                <Button key="1" type="primary" onClick={this.onCSVExportClick}>Export CSV</Button>
              ]}
              className={style.pageHeader}
            >
              <Row>
                <Statistic
                  style={{
                    display: 'inline-block',
                    marginRight: '20px'
                  }}
                  title="Total number of users"
                  value={this.props.totalItems}
                />
                {isAU && (
                  <Statistic
                    style={{ display: 'inline-block' }}
                    title="Selected users"
                    value={selectedRowKeys.length}
                  />
                )}
              </Row>
            </PageHeader>
            {withFilter && (
              <div>
                {this.props.isInvites
                  ? statusFilters.map(({ status, checked }) => (
                    <Checkbox
                      key={status}
                      checked={checked}
                      onChange={e =>
                        this.onStatusCheck(e.target.checked, {
                          status,
                          checked
                        })}
                    >
                      {status}
                    </Checkbox>
                  ))
                  : statusFilters.map(({ status, checked }) => (
                    <Checkbox
                      key={status}
                      checked={checked}
                      onChange={e =>
                        this.onStatusCheck(e.target.checked, {
                          status,
                          checked
                        })}
                    >
                      {status}
                    </Checkbox>
                  ))}
              </div>
            )}
            <div className={style.filterRow}>
              <span className={style.rangeLabel}>Registration period:</span>
              <RangePicker value={[registrationPeriodStart, registrationPeriodEnd]} onChange={this.onRegistrationPeriodRangeChange} />
              <Input
                placeholder="Enter days"
                suffix="Days"
                className={style.daysInput}
                value={registrationSearchDays}
                onChange={this.onRegistrationDaysChange}
              />
            </div>
            <div className={style.filterRow}>
              <span className={style.rangeLabel}>Last active period:</span>
              <RangePicker value={[activePeriodStart, activePeriodEnd]} onChange={this.onActivePeriodRangeChange} />
              <Input
                placeholder="Enter days"
                suffix="Days"
                className={style.daysInput}
                value={activePeriodSearchDays}
                onChange={this.onActivePeriodDaysChange}
              />
            </div>
            <SearchArea
              initialSearchPhrases={initialSearchPhrases}
              onSearch={this.onSearch}
              searchItemsArr={searchItemsArr}
            />
            <Table
              columns={columns(sorter, partnersFilter, selectedFilters)}
              rowKey={record => record.id}
              rowSelection={rowSelection}
              onChange={this.onChange}
              onRow={this.onRow}
              dataSource={usersArr}
              scroll={scroll}
              pagination={false}
            />
            <div style={{ margin: 20 }}>
              <Pagination
                total={this.props.totalItems}
                pageSize={PAGE_SIZE}
                current={this.props.currentPage + 1}
                onChange={page => {
                  this.props.onPaginationChange(page - 1, this.getDateRangeSearchCriteria());
                }}
              />
            </div>
            <MessagesArea
              selectedUsers={selectedRowKeys}
              userAuth={this.props.userAuth}
            />
          </>
        ) : (
          <div className="errorContainer">
            Error!
            <div className="errorMsg">{errorMsg}</div>
          </div>
        )
      ) : (
        <div className="spinContainer">
          <Spin size="large" />
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */ }
      <a style={{ display: 'none' }} href='' ref={this.csvDownloadAnchor} />
    </Card>
    );
  }
}

const hoc: React$ComponentType<$Diff<React$ElementConfig<typeof UsersRouteWrapper>, ContextRouter>> = withRouter(UsersRouteWrapper);

export default hoc;