/** @flow */

import type { Duration } from 'date-fns';
import format from 'date-fns/format';
import { apiUrls } from '../store/api';

export const convertDate = (date: any): string => {
  const d =
    typeof date === 'string' || date instanceof String
      ? new Date(Date.parse(date))
      : new Date(date);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(d.getTime()) || !date
    ? '-'
    : `${d
      .getDate()
      .toString()
      .padStart(2, '0')}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${d.getFullYear()} ${d
      .getHours()
      .toString()
      .padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
};

export const convertTime = (date: string | number | Date | String): string => {
  const d =
    typeof date === 'string' || date instanceof String
      ? new Date(date instanceof String ? Date.parse(date.valueOf()) : date)
      : new Date(date);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(d.getTime()) || !date
    ? '-'
    : `${d
      .getHours()
      .toString()
      .padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
};

export const convertDuration = (duration: Duration): string => {
  const { hours, minutes } = duration;
  if (typeof minutes === 'number') {
    return hours
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      : `00:${minutes.toString().padStart(2, '0')}`;
  }

  return '-';
}

export const convertMsTime = (time: any, timeFormat: string): string => format(time, timeFormat);

export const convertDateSafari = (date: any): string => {
  const fallbackValue = '-';

  if (!date) {
    return fallbackValue;
  }

  const d = new Date(date.toString());

  return Number.isNaN(d.getTime())
    ? fallbackValue
    : `${d
      .getDate()
      .toString()
      .padStart(2, '0')}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${d.getFullYear()} ${d
      .getHours()
      .toString()
      .padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
}

export const convertTimeSafari = (date: any): string => {
  const fallbackValue = '-';

  if (!date) {
    return fallbackValue;
  }

  const d = new Date(date.toString());

  return Number.isNaN(d.getTime())
    ? fallbackValue
    : `${d
      .getHours()
      .toString()
      .padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
}

const convertVersionToNum = version =>
  version.split('.').reduce((acc, el, i, arr) =>
      // eslint-disable-next-line no-restricted-properties
    acc + +el * Math.pow(100, (arr.length - i - 1) * 2)
    , 0);
export const sortVersions = (a: string, b: string): number => {
  // eslint-disable-next-line no-underscore-dangle
  const _a = a ? convertVersionToNum(a) : 0;
  // eslint-disable-next-line no-underscore-dangle
  const _b = b ? convertVersionToNum(b) : 0;
  return _a - _b;
};

export const secToDH = (sec: number = 0): string => {
  let h = Math.floor(sec / 3600);
  let d;

  // eslint-disable-next-line no-unused-expressions
  h > 24 && ((d = Math.floor(h / 24)), (h = Math.floor(h % 24)));

  return `${d ? `${d}d ` : ''}${h ? `${h}h ` : ''}`;
};

export const secToMS = (sec: number = 0): string => {
  const s = sec % 60;
  const m = (sec - s) / 60;

  return `${m ? `${m}m ` : ''}${s}s`;
};

export const secToHMS = (sec: number = 0): string => {
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);
  return `${h ? `${h}h ` : ''}${m ? `${m}m ` : ''}${s}s`;
};

export const countItemsByStatus = (arr: any[], fieldKey: string): { [key: string]: number } =>
  arr.reduce((acc, el) => {
    const stat = el[fieldKey];
    acc[stat] = (acc[stat] || 0) + 1;
    return acc;
  }, {});

/**
 *
 * @desc Returns avatar link by id or current user's avatar if `id` isn't specified
 *
 * @param {string} id
 *
 * @return {string}
 */
export const getAvatarLink = (id: string = ''): string => apiUrls.IMAGE_GET(id);


/**
 * @desc Returns users sorter mapped for API
 */
export function getSorterForApi(sorter: string): string {
  const [field, sortOrder] = sorter.split(',');

  if (!field) {
    return '';
  }

  let sortColumn;

  switch (field) {
    case 'status':
      sortColumn = 'status';
      break;
    case 'registrationDate':
      sortColumn = 'regdate';
      break;
    case 'regLocale':
      sortColumn = 'locale';
      break;
    case 'telmieCredit':
      sortColumn = 'telmiecredit';
      break;
    case 'topUpCredit':
      sortColumn = 'topupcredit';
      break;
    case 'paymentFromCard':
      sortColumn = 'cardpayment';
      break;
    case 'totalSpend':
      sortColumn = 'spend';
      break;
    case 'totalEarn':
      sortColumn = 'earn';
      break;
    case 'totalTextIncoming':
      sortColumn = 'textin';
      break;
    case 'totalTextOutgoing':
      sortColumn = 'textout';
      break;
    case 'totalMinsIncoming':
      sortColumn = 'minin';
      break;
    case 'totalMinsOutgoing':
      sortColumn = 'minout';
      break;
    case 'dateLastActive':
      sortColumn = 'lastactive';
      break;

    default:
      sortColumn = field.toLowerCase();
  }

  return `${sortColumn},${sortOrder.toUpperCase()}`;
}
