/** @flow */

// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import Snackbar from '@material-ui/core/Snackbar';
import React, { useEffect, type AbstractComponent } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';

import { detect } from 'detect-browser';
import { LOCAL_STORAGE } from '../constants';

import { routesList } from '../routes/routes-list';
import CustomRoute from '../routes/custom-route';

import Header from './Layout/Header';
import SideNav from './Layout/sideNav';

import {
  getLoggedInUserData,
  clearLoading,
  setUserAuth
} from '../store/actions/user';
import { getPreferences } from '../store/actions/preferences';
import { getConfig } from '../store/actions/configData';
import Footer from './Layout/footer';

import style from './style.module.css';
import { selectPreparedNotifications } from '../store/reducers/api';
import type { NotificationType } from '../types';
import {
  apiCloseNotification, NOTIFICATION_HIDE_DURATION,
} from '../store/actions/api';
import { fetchPartners } from '../store/actions/partners';

type AppOwnProps = $ReadOnly<{|
|}>

  type AppProps = {
    ...AppOwnProps,
    loggedInUser: { userAuth: string },
  }

const resizeObserver = new ResizeObserver(entries => {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const entry of entries) {
    if(entry.contentBoxSize) {
      const body = document.getElementsByTagName('body')[0];
      const minContentWidth = 1200;
      const deviceWidth = window.innerWidth;

      if(window.innerWidth < minContentWidth) {
        const scale = deviceWidth / minContentWidth * 100;
        body.style.zoom = `${scale}%`;
      } else {
        body.style.zoom = `100%`;
      }
    }
  }
});

const App = (props: AppProps) => {
  const browser = detect();
  const { loggedInUser } = props;
  const { userAuth } = loggedInUser || {};

  const getUserToken = (): string | null => {
    const token = localStorage.getItem(LOCAL_STORAGE.USER_AUTH);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);

    return { token, refreshToken };
  };

  const dispatch = useDispatch();

  const storageChangeHandler = e => {
    if(e.key === LOCAL_STORAGE.USER_AUTH) {
      dispatch(setUserAuth(e.newValue));
    }
  }

  useEffect(() => {
    if(['iOS', 'Mac OS'].includes(browser.os)) {
      return;
    }

    const body = document.getElementsByTagName('body')[0];
    resizeObserver.observe(body);

    return () => {
      resizeObserver.disconnect();
    }
  }, [])

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', storageChangeHandler);

    return () => {
      window.removeEventListener('storage', storageChangeHandler);
    }
  }, [])

  useEffect(() => {
    const { token, refreshToken } = getUserToken();

    if (token) {
      dispatch(getLoggedInUserData(token, refreshToken));
    } else {
      dispatch(clearLoading());
    }
  }, []);

  useEffect(() => {
    userAuth && dispatch(fetchPartners(userAuth));
    userAuth && dispatch(getPreferences(userAuth));
  }, [userAuth]);

  const handleNotificationClose = React.useCallback((notificationId: string) => () => {
    dispatch(apiCloseNotification(notificationId));
  }, [dispatch])

  const handleNotificationClick = React.useCallback((withReload: boolean = false) => () => {
    if (withReload) {
      window.location.reload();
    }
  }, []);

  const preparedNotifications: NotificationType[] = useSelector(selectPreparedNotifications);

  return (
    <div id="app">
      <div className={style.mainLayout}>
        {preparedNotifications.map(({ opened, withReload, id, message, type }: NotificationType) => (
          <Snackbar
            key={`NOTIFICATION_${id}`}
            open={opened}
            autoHideDuration={withReload ? null : NOTIFICATION_HIDE_DURATION}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClick={handleNotificationClick(withReload)}
            onClose={handleNotificationClose(id)}
          >
            <Alert
              elevation={6}
              severity={type}
              variant="filled"
              onClose={withReload ? null : handleNotificationClose(id)}
            >
              {message}
            </Alert>
          </Snackbar>
        ))}
        <Header />
        <div className={style.layoutWrapper}>
          <div className={style.layout}>
            {loggedInUser.userAuth && <SideNav />}
            <Switch>
              {Object.keys(routesList).map((path, i) => (
                <CustomRoute exact key={i} path={path} {...routesList[path]} />
              ))}
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

export default (withRouter(connect(mapStateToProps)(App)): AbstractComponent<AppOwnProps>);
