export const LOCAL_STORAGE = {
  USER_AUTH: 'USER_AUTH',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
};

export const USER_ACTIVITIES_REQUEST_SIZE = 200;
export const AVAILABILITY_TIME_FORMAT = 'HH:mm';
export const TELMIE_PAY = 'TELMIE_PAY';

export const KEYCODE_ENTER = 13;

export const CONFIG_NAME = '/config.json';

export const PAGE_SIZE = 20;

export const INFO_TYPES = {
  ACC_DETAILS: 'ACC_DETAILS',
  ACTIVITIES: 'ACTIVITIES',
  MONEY: 'MONEY',
  PEOPLE: 'PEOPLE',
  CLIENTS: 'CLIENTS',
  LIST_OF_PROS: 'LIST_OF_PROS'
};

export const ARCHIVED = 'ARCHIVED';

export const statusArrs = {
  users: [
    'REGISTERED',
    'SUSPENDED',
    ARCHIVED,
    'DECLINE_STARTED_PRO_APP',
    'STARTED_PRO_APP',
    'DECLINE_APPROVAL',
    'PENDING_APPROVAL',
    'APPROVED_AS_PRO',
    'PRO_HIDE_PROFILE',
    'SUSPENDED_AS_PRO'
  ],
  invites: [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Registered as User',
      value: 'REGISTERED_AS_USER'
    },
    {
      name: 'Accepted',
      value: 'ACCEPTED'
    },
    {
      name: 'Get bonus',
      value: 'GET_BONUS'
    }
  ],
  transactions: [
    {
      name: 'SYS_CHARGE',
      value: 'SYS_CHARGE'
    },
    {
      name: 'CALL_PAY_USER',
      value: 'CALL_PAY_USER'
    },
    {
      name: 'CALL_PAY_PRO',
      value: 'CALL_PAY_PRO'
    },
    {
      name: 'PAYOUT',
      value: 'PAYOUT'
    },
    {
      name: 'ADD_CARD',
      value: 'ADD_CARD'
    },
    {
      name: 'ADD_FRIEND',
      value: 'ADD_FRIEND'
    },
    {
      name: 'ADD_PRO',
      value: 'ADD_PRO'
    },
    {
      name: 'SYSTEM_BONUS',
      value: 'SYSTEM_BONUS'
    },
    {
      name: 'REGISTRATION',
      value: 'REGISTRATION'
    },
    {
      name: 'TOP_UP_CARD',
      value: 'TOP_UP_CARD'
    },
    {
      name: 'TOP_UP_APPLE_PAY',
      value: 'TOP_UP_APPLE_PAY'
    },
    {
      name: 'TOP_UP_ADD_CARD',
      value: 'TOP_UP_ADD_CARD'
    }
  ],
  calls: [
    {
      name: 'ACTIVE',
      value: 'ACTIVE'
    },
    {
      name: 'FAILED',
      value: 'FAILED'
    },
    {
      name: 'SUCCEED',
      value: 'SUCCEED'
    },
    {
      name: 'BREAK',
      value: 'BREAK'
    },
    {
      name: 'DISCONNECTED',
      value: 'DISCONNECTED'
    },
    {
      name: 'BROKEN',
      value: 'BROKEN'
    },
    {
      name: 'NO_ANSWER',
      value: 'NO_ANSWER'
    },
    {
      name: 'CLIENT_DECLINE',
      value: 'CLIENT_DECLINE'
    },
    {
      name: 'PRO_DECLINE',
      value: 'PRO_DECLINE'
    },
    {
      name: 'PRO_BUSY',
      value: 'PRO_BUSY'
    }
  ]
};

export const COLUMN_KEY_TO_FIELD_MAP = {
  'state.datelastactive': 'lastactive',
  'paymentfromcard': `cardpayment`,
  'totalspend': 'spend',
  'totalearn': 'earn',
  'totaltextoutgoing': 'textout',
  'totaltextincoming': 'textin',
  'totalminsincoming': 'minin',
  'totalminsoutgoing': 'minout',
}
