import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Descriptions, Input, Switch, Button } from 'antd';

import style from './style.module.css';

import Card from '../../components/Layout/card';
import {
  getPreferences,
  editPreferences
} from '../../store/actions/preferences';

const { Paragraph } = Typography;
const { Search } = Input;

const Settings = ({
                       userData,
                       getAllPreferences,
                       editAllPreferences,
                       preferencesData: { data = [] },
                       preferencesData
                     }) => {
  const [allPreferences, setAllPreferences] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [searchedPreferences, setSearchedPreferences] = useState([]);
  const { userAuth } = userData;
  const preferencesFlag = allPreferences
    ? isSearched
      ? searchedPreferences
      : allPreferences
    : false;

  useEffect(() => {
    userAuth && getAllPreferences(userAuth);
  }, []);
  useEffect(() => {
    setAllPreferences(data.sort((a, b) => a.id - b.id));
  }, [preferencesData]);

  const ucFirst = str => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  const formatTitle = title => {
    const tempTitle = title.split('.');
    return tempTitle.map(item => ucFirst(item)).join(' ');
  };

  const onPermissionChange = (str, item) => {
    const newAllPermissions = allPreferences.map(obj => {
      if (obj.id === item.id) {
        return {
          ...obj,
          value: str
        };
      }
      return obj;
    });

    setAllPreferences(newAllPermissions.sort((a, b) => a.id - b.id));

    setSearchedPreferences(newAllPermissions.filter(newPermission => (
      searchedPreferences.some(({ id }) => id === newPermission.id)
    )).sort((a, b) => a.id - b.id));
  };

  const onSearch = value => {
    if (value) {
      const searchedPreferences = allPreferences.filter(item => item.key.toLowerCase().indexOf(value.toLowerCase()) !== -1);

      setSearchedPreferences(searchedPreferences);
      setIsSearched(true);
    } else {
      setIsSearched(false);
    }
  };

  return (
    <Card cardClass="route-content">
      <h2>Settings</h2>
      <Search
        placeholder="Search Settings"
        className={style.permissionSearch}
        onChange={e => onSearch(e.target.value)}
      />
      <Descriptions
        bordered
        size="small"
        layout="vertical"
        style={{ marginBottom: 10 }}
        column={{
          xxl: 2,
          xl: 2,
          lg: 1,
          md: 1,
          sm: 1,
          xs: 1
        }}
      >
        {preferencesFlag &&
        preferencesFlag.map(item => {
          const isEdited = data.find(i => i.id === item.id).value !== item.value;
          const editedItem = isEdited ? { color: 'red' } : { color: 'black' };
          switch (item.value) {
            case 'false':
              return (
                <Descriptions.Item
                  key={item.id}
                  label={(
                    <span className={style.permissionTitle} style={editedItem}>
                      {formatTitle(item.key)}
                    </span>
                  )}
                >
                  <Switch
                    className={style.switcher}
                    onChange={checked =>
                      onPermissionChange(checked.toString(), item)}
                    checked={JSON.parse(item.value)}
                    checkedChildren="1"
                    unCheckedChildren="0"
                  />
                </Descriptions.Item>
              );
            case 'true':
              return (
                <Descriptions.Item
                  key={item.id}
                  label={(
                    <span className={style.permissionTitle} style={editedItem}>
                      {formatTitle(item.key)}
                    </span>
                  )}
                >
                  <Switch
                    className={style.switcher}
                    onChange={checked =>
                      onPermissionChange(checked.toString(), item)}
                    checked={JSON.parse(item.value)}
                    checkedChildren="1"
                    unCheckedChildren="0"
                  />
                </Descriptions.Item>
              );
            default:
              return (
                <Descriptions.Item
                  key={item.id}
                  label={(
                    <span className={style.permissionTitle} style={editedItem}>
                      {formatTitle(item.key)}
                    </span>
                  )}
                >
                  <Paragraph
                    editable={{
                      onChange: e => onPermissionChange(e, item)
                    }}
                  >
                    {item.value}
                  </Paragraph>
                </Descriptions.Item>
              );
          }
        })}
      </Descriptions>
      <Button
        type="primary"
        onClick={() => userAuth && editAllPreferences(allPreferences, userAuth)}
      >
        Save
      </Button>
    </Card>
  );
};

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  preferencesData: state.preferencesData
});

const mapDispatchToProps = dispatch => ({
  getAllPreferences: authData => dispatch(getPreferences(authData)),
  editAllPreferences: (data, authData) =>
    dispatch(editPreferences(data, authData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
