import { apiUrls } from "./index";
import { http } from "./fetch-wrapper";

export const getPendings = authData => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return http(apiUrls.GET_PENDINGS, { method: "GET", headers });
};

const manipulatePending = (url, method, authData) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return http(url, { method, headers });
};
export const activateUser = (id, authData) =>
  manipulatePending(apiUrls.PENDING_ID(id), "POST", authData);

export const declineUser = (id, authData) =>
  manipulatePending(apiUrls.PENDING_ID(id), "PUT", authData);

export const deleteUserProData = (id, authData) =>
  manipulatePending(apiUrls.PENDING_ID(id), "DELETE", authData);

export const getWithdrawals = authData => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(apiUrls.GET_WITHDRAWS, { method: "GET", headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const getWithdrawalDetails = (id, authData) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(apiUrls.WITHDRAW_ID(id), { method: "GET", headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
};

const withdrawalManipulation = (url, method, authData) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(url, { method, headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return { error: true, message: response.message };
      }
      return { error: false };
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const approveWithdrawal = (id, authData) =>
  withdrawalManipulation(apiUrls.WITHDRAW_ID(id), "POST", authData);

export const declineWithdrawal = (id, authData) =>
  withdrawalManipulation(apiUrls.WITHDRAW_ID(id), "DELETE", authData);
