import * as api from "../api/notes";
import { actionTypes } from "./index";

export const addNote = (noteData, userId, authData) => async dispatch => {
  dispatch({ type: actionTypes.ADD_NOTE_START });
  const response = await api.addNote(noteData, userId, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.ADD_NOTE_FAILURE,
      message: response.message
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
  } else {
    dispatch({ type: actionTypes.ADD_NOTE_SUCCESS });
  }
};

export const removeNote = (noteData, userId, authData) => async dispatch => {
  dispatch({ type: actionTypes.REMOVE_NOTE_START });
  const response = await api.removeNote(noteData, userId, authData);

  if (response.error) {
    dispatch({
      type: actionTypes.REMOVE_NOTE_FAILURE,
      message: response.message,
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
  } else {
    dispatch({ type: actionTypes.REMOVE_NOTE_SUCCESS });
  }
};

export const editNote = (noteData, userId, authData) => async dispatch => {
  dispatch({ type: actionTypes.EDIT_NOTE_START });
  const response = await api.editNote(noteData, userId, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.EDIT_NOTE_FAILURE,
      message: response.message,
    });
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
  } else {
    dispatch({ type: actionTypes.EDIT_NOTE_SUCCESS });
  }
};

export const getAllNotes = (userId, authData) => async dispatch => {
  dispatch({ type: actionTypes.GET_NOTES_START });
  const response = await api.getAllNotes(userId, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.GET_NOTES_FAILURE,
      message: response.message,
    });
  } else {
    dispatch({ type: actionTypes.GET_NOTES_SUCCESS, notes: response });
  }
};