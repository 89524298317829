/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import { Switch } from 'antd';
import Input from '../../input';
import Select from '../../select';
import Textarea from '../../textarea';
import Card from '../../Layout/card';

import ApproveArea from './approve-area';
import { convertDateSafari } from '../../../helpers/index';
import style from './style.module.css';
import { getCategories } from '../../../store/actions/data';
import { editUser } from '../../../store/actions/user';

export const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
export const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

const OTHER = 'other';
const LINE1 = 'line1';
const CITY = 'city';
const POSTCODE = 'postCode';
const COUNTRY = 'country';
const DATE_OF_BIRTH = 'dateOfBirth';
const SERVICE_DESCRIPTION = 'serviceDescription';
const COST_PER_MINUTE = 'costPerMinute';
const MOBILE = 'mobile';
const VIDEO = 'video';
const VIDEO_URL = 'videoURL';
const VIDEO_PREVIEW_URL = 'videoPreviewURL';
const SERVICE = 'service';
const SECTOR = 'sector';
const CATEGORY = 'category';
const SERVICE_OTHER = 'serviceOther';
const PAYMENT_SYSTEM = 'proPaymentSystem';
const PAYOUT_METHOD = 'defaultPayoutMethod';
const AVAILABLE_PAYOUT_METHODS = 'availablePayoutMethods';
const USE_MANUAL_PAYOUT_METHOD = 'useManualPayoutMethod';

const DEFAULT_PAYMENT_METHOD = 'DEFAULT';
const DEFAULT_PAYOUT_METHOD = 'MANUAL';

const BusinessProDetail = ({ isPending, user, userAuth, serverData, fetchCategories, editUser }) => {
  const { services, sectors, categories } = serverData;
  const textAlignCenter = { textAlign: 'center' };
  const [serviceOther, setServiceOther] = useState('');
  const [isEdited, setEdited] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const formatDateOfBirth = dateOfBirth => {
    const tmp = new Date(dateOfBirth);
    return `${tmp.getDate().toString().padStart(2, '0')}.${(tmp.getMonth() + 1).toString().padStart(2, '0')}.${tmp.getFullYear()}`;
  };

  const fillUserInfo = () => {
    const { pro, updateProData } = user || {};
    const userInfo = {};
    if (pro) {
      const { location = '{}', dateOfBirth, pro, mobile } = user || {};
      const {
        serviceDescription,
        costPerMinute = 0,
        video,
        videoURL = null,
        videoPreviewURL = null,
        service,
        sector,
        category,
        proPaymentSystem,
        availablePayoutMethods,
        useManualPayoutMethod
      } = pro || {};
      const { country, city, line1, postCode } = JSON.parse(location) || {};

      userInfo[LINE1] = line1;
      userInfo[CITY] = city;
      userInfo[POSTCODE] = postCode;
      userInfo[COUNTRY] = country;
      userInfo[DATE_OF_BIRTH] = dateOfBirth;
      userInfo[SERVICE_DESCRIPTION] = serviceDescription;
      userInfo[COST_PER_MINUTE] = costPerMinute;
      userInfo[MOBILE] = mobile;
      userInfo[VIDEO] = video;
      userInfo[VIDEO_URL] = videoURL;
      userInfo[VIDEO_PREVIEW_URL] = videoPreviewURL;
      userInfo[SERVICE] = service;
      userInfo[SECTOR] = sector;
      userInfo[CATEGORY] = category;
      userInfo[PAYMENT_SYSTEM] = proPaymentSystem;
      userInfo[AVAILABLE_PAYOUT_METHODS] = availablePayoutMethods;
      userInfo[USE_MANUAL_PAYOUT_METHOD] = useManualPayoutMethod;
    } else if (updateProData) {
      const {
        videoURL = '',
        videoPreviewURL = '',
        location = '{}',
        dateOfBirth,
        serviceDescription,
        costPerMinute,
        mobile,
        video,
        service,
        sector,
        category,
        proPaymentSystem,
        availablePayoutMethods,
        useManualPayoutMethod
      } = updateProData || {};
      const { country, city, line1, postCode } = JSON.parse(location) || {};

      userInfo[LINE1] = line1;
      userInfo[CITY] = city;
      userInfo[POSTCODE] = postCode;
      userInfo[COUNTRY] = country;
      userInfo[DATE_OF_BIRTH] = dateOfBirth;
      userInfo[SERVICE_DESCRIPTION] = serviceDescription;
      userInfo[COST_PER_MINUTE] = costPerMinute;
      userInfo[MOBILE] = mobile;
      userInfo[VIDEO] = video;
      userInfo[VIDEO_URL] = videoURL;
      userInfo[VIDEO_PREVIEW_URL] = videoPreviewURL;
      userInfo[SERVICE] = service;
      userInfo[SECTOR] = sector;
      userInfo[CATEGORY] = category;
      userInfo[PAYMENT_SYSTEM] = proPaymentSystem;
      userInfo[AVAILABLE_PAYOUT_METHODS] = availablePayoutMethods;
      userInfo[USE_MANUAL_PAYOUT_METHOD] = useManualPayoutMethod;
    }

    return userInfo;
  };

  const fillChangedFields = () => {
    const changedFields = {};
    const { updateProData, dateOfBirth, pro } = user || {};
    const {
      location = '{}',
      dateOfBirth: updateDateOfBirth,
      serviceDescription,
      costPerMinute,
      mobile,
      video,
      videoURL,
      videoPreviewURL,
      service,
      sector,
      category,
    } = updateProData || {};
    const { country, city, line1, postCode } = JSON.parse(location) || {};

    if (line1 !== userInfo[LINE1] || !pro) {
      changedFields[LINE1] = !pro ? '' : line1;
    }
    if (postCode !== userInfo[POSTCODE] || !pro) {
      changedFields[POSTCODE] = !pro ? '' : postCode;
    }
    if (city !== userInfo[CITY] || !pro) {
      changedFields[CITY] = !pro ? '' : city;
    }
    if (country !== userInfo[COUNTRY] || !pro) {
      changedFields[COUNTRY] = !pro ? '' : country;
    }
    if (formatDateOfBirth(updateDateOfBirth) !== formatDateOfBirth(dateOfBirth) || !pro) {
      changedFields[DATE_OF_BIRTH] = !pro ? '' : formatDateOfBirth(updateDateOfBirth);
    }
    if (serviceDescription !== userInfo[SERVICE_DESCRIPTION] || !pro) {
      changedFields[SERVICE_DESCRIPTION] = !pro ? '' : serviceDescription;
    }
    if (costPerMinute !== userInfo[COST_PER_MINUTE] || !pro) {
      changedFields[COST_PER_MINUTE] = !pro ? '' : costPerMinute;
    }
    if (mobile !== userInfo[MOBILE] || !pro) {
      changedFields[MOBILE] = !pro ? '' : mobile;
    }
    if (video !== userInfo[VIDEO] || !pro) {
      changedFields[VIDEO] = !pro ? '' : video;
    }
    if (videoURL !== userInfo[VIDEO_URL] || !pro) {
      changedFields[VIDEO_URL] = !pro ? '' : videoURL;
    }
    if (videoPreviewURL !== userInfo[VIDEO_PREVIEW_URL] || !pro) {
      changedFields[VIDEO_PREVIEW_URL] = !pro ? '' : videoPreviewURL;
    }
    if (service !== userInfo[SERVICE] || !pro) {
      if (services[service]) {
        changedFields[SERVICE] = !pro ? '' : services[service].name;
        changedFields[SERVICE_OTHER] = '';
      } else {
        changedFields[SERVICE_OTHER] = !pro ? '' : service;
      }
    }
    if (sector !== userInfo[SECTOR] || !pro) {
      changedFields[SECTOR] = !pro ? '' : sectors[sector] || '';
    }
    if (category !== userInfo[CATEGORY] || !pro) {
      changedFields[CATEGORY] = !pro ? '' : categories[category] ? categories[category].name : '';
    }

    return changedFields;
  };

  useEffect(() => {
    const userInfo = fillUserInfo();

    fetchCategories(userAuth);
    setUserInfo(userInfo);

    setServiceOther((user.pro || user.updateProData || {}).service || '');
  }, []);

  const getServiceTag = () => {
    const userServiceName = userInfo[SERVICE];
    return services[userServiceName] ? userServiceName : OTHER;
  };

  const getServices = () => {
    const fullServices = Object.keys(services).map(tag => ({
      value: tag,
      name: services[tag].name
    }));
    fullServices.push({
      value: OTHER,
      name: `${OTHER.charAt(0).toUpperCase()}${OTHER.substring(1)}`
    });
    return fullServices;
  };

  const getSectors = () => Object.keys(sectors).map(tag => ({
    value: tag,
    name: sectors[tag]
  }));

  const getCategories = (sector = userInfo[SECTOR]) => {
    const fullCategories = [];

    Object.keys(categories).forEach(tag => {
      categories[tag].sector === sector && fullCategories.push({
        value: tag,
        name: categories[tag].name
      });
    });

    return fullCategories;
  };

  const servicesData = getServices();
  const sectorsData = getSectors();
  const categoriesData = getCategories();

  const handleOnSelectService = e => {
    const { value } = e.target;

    setEdited(true);

    const servicesArr = Object.values(services);
    const defaultOtherSector = servicesArr[0] && servicesArr[0].sector || '';
    const sector = services[value] && services[value].sector || defaultOtherSector;
    const category = value === userInfo[SERVICE] ? userInfo[CATEGORY] : getCategories(sector)[0].value;

    setUserInfo({ ...userInfo, [CATEGORY]: category, [SECTOR]: sector, [SERVICE]: value });
  };

  const handleOnSelectSector = e => {
    const { value } = e.target;
    setEdited(true);
    setUserInfo({ ...userInfo, [SECTOR]: value });
  };

  const handleOnSelectCategory = e => {
    const { value } = e.target;
    setEdited(true);
    setUserInfo({ ...userInfo, [CATEGORY]: value });
  };

  const handleOnChange = e => {
    const { name, value } = e.target;

    setEdited(true);

    if (name === SERVICE_OTHER) {
      setServiceOther(value);
    } else {
      setUserInfo({ ...userInfo, [name]: value });
    }
  };

  const handleOnCancel = () => {
    const userInfo = fillUserInfo();
    setUserInfo(userInfo);
    setServiceOther(user.pro && user.pro.service || '');
    setEdited(false);
  };

  const handleOnSave = () => {
    const service = userInfo[SERVICE];
    const serviceTag = getServiceTag();

    const data = {
      id: user.id,
      mobile: userInfo[MOBILE],
      pro: {
        service: serviceTag === OTHER ? serviceOther : service,
        sector: userInfo[SECTOR],
        category: userInfo[CATEGORY],
        serviceDescription: userInfo[SERVICE_DESCRIPTION],
        costPerMinute: userInfo[COST_PER_MINUTE],
        video: userInfo[VIDEO],
        [PAYMENT_SYSTEM]: userInfo[PAYMENT_SYSTEM],
        // eslint-disable-next-line no-use-before-define
        defaultPayoutMethod: (availablePayoutMethods.map(method => ({
          token: method.id,
          type: method.type
        })).find(obj => obj.token === userInfo[PAYOUT_METHOD])),
        [USE_MANUAL_PAYOUT_METHOD]: userInfo[USE_MANUAL_PAYOUT_METHOD]
      }
    };

    editUser(data, userAuth, false);
  };

  function payoutMethodToString(method) {
    return method.type === DEFAULT_PAYOUT_METHOD
      ? DEFAULT_PAYOUT_METHOD : [method.bank.name, `***${method.bank.last4}`, method.bank.currency].join(', ');
  }

  function getDefaultPayoutMethodId(payoutMethods) {
    const defaultMethod = payoutMethods.find(method => method.default)
    if (defaultMethod == null ?? true){
      return '';
    }
    return defaultMethod.id ?? '';
  }

  const serviceTag = getServiceTag();
  const changedFields = isPending ? fillChangedFields() : {};

  const availablePaymentSystem = (user && user.pro && user.pro.availablePaymentSystem) || [DEFAULT_PAYMENT_METHOD];

  const availablePayoutMethods = (user && user.pro && user.pro.availablePayoutMethods) || []

  const videoPlayerData = {
    [VIDEO_URL]: changedFields[VIDEO_URL] ? changedFields[VIDEO_URL] : userInfo[VIDEO_URL] ? userInfo[VIDEO_URL] : '',
    [VIDEO_PREVIEW_URL]: changedFields[VIDEO_PREVIEW_URL] ? changedFields[VIDEO_PREVIEW_URL] : userInfo[VIDEO_PREVIEW_URL] ? userInfo[VIDEO_PREVIEW_URL] : '',
  }

  return (
    <div>
      <div className="headline">Individual Pro details</div>
      <Card cardClass={style.proDetailsCard} class={style.cardContent}>
        <div className={style.detailsArea}>
          <Input
            label="Personal address :"
            name={LINE1}
            value={userInfo[LINE1] || ''}
            disabled
            changedFields={changedFields}
          />
          <div className="doubleInput">
            <Input
              label="City:"
              name={CITY}
              value={userInfo[CITY] || ''}
              disabled
              changedFields={changedFields}
            />
            <Input
              label="Post Code:"
              name={POSTCODE}
              value={userInfo[POSTCODE] || ''}
              disabled
              changedFields={changedFields}
            />
          </div>
          <Input
            label="Country:"
            name={COUNTRY}
            value={userInfo[COUNTRY] || ''}
            disabled
            changedFields={changedFields}
          />
          <Input
            type="date"
            label="Date of birth:"
            name={DATE_OF_BIRTH}
            value={(isSafari || mobileDevice) ? convertDateSafari(userInfo[DATE_OF_BIRTH]) : userInfo[DATE_OF_BIRTH]}
            disabled
            changedFields={changedFields}
          />

          <Select
            label="Service name:"
            name={SERVICE}
            value={serviceTag}
            data={servicesData}
            onChange={handleOnSelectService}
            disabled={isPending}
            changedFields={changedFields}
          />
          {serviceTag === OTHER && (
            <Input
              label="Other service:"
              name={SERVICE_OTHER}
              value={serviceOther || ''}
              disabled={isPending}
              onChange={handleOnChange}
              changedFields={changedFields}
            />
          )}

          <Select
            label="Service sector:"
            name={SECTOR}
            value={userInfo[SECTOR]}
            data={sectorsData}
            onChange={handleOnSelectSector}
            disabled={isPending || (serviceTag !== OTHER)}
            changedFields={changedFields}
          />

          <Select
            label="Service category:"
            name={CATEGORY}
            value={userInfo[CATEGORY]}
            data={categoriesData}
            onChange={handleOnSelectCategory}
            disabled={isPending || (serviceTag !== OTHER)}
            changedFields={changedFields}
          />

          <Textarea
            label="Service description:"
            name={SERVICE_DESCRIPTION}
            value={userInfo[SERVICE_DESCRIPTION] || ''}
            disabled={isPending}
            onChange={handleOnChange}
            changedFields={changedFields}
          />
          <div className={style.currencyWrap}>
            <Input
              value="£"
              disabled
              inputStyle={textAlignCenter}
              changedFields={changedFields}
            />
          </div>
          <div className={style.costPerMinute}>
            <Input
              name={COST_PER_MINUTE}
              value={userInfo[COST_PER_MINUTE] || ''}
              disabled={isPending}
              onChange={handleOnChange}
              inputStyle={textAlignCenter}
              changedFields={changedFields}
            />
          </div>
          <div className={style.minuteWrap}>
            <Input
              value="min"
              disabled
              inputStyle={textAlignCenter}
              changedFields={changedFields}
            />
          </div>
          <Input
            label="Mobile:"
            name={MOBILE}
            value={userInfo[MOBILE] || ''}
            disabled={isPending}
            changedFields={changedFields}
            onChange={handleOnChange}
          />
          <Input
            label="YouTube ID:"
            name={VIDEO}
            value={userInfo[VIDEO] || ''}
            disabled={isPending}
            changedFields={changedFields}
            onChange={handleOnChange}
          />
          {videoPlayerData[VIDEO_URL] && (
            <div className={style.userInfoVideoWrap}>
              <video className={style.userInfoVideo} controls poster={videoPlayerData[VIDEO_PREVIEW_URL]}>
                <source src={videoPlayerData[VIDEO_URL]} type="video/mp4" />
                <track kind="captions" />
              </video>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <FormControl style={{ width: '300px', marginRight: '24px' }} key='payment-system'>
              <InputLabel id="payment-system">Pro default Payment System</InputLabel>
              <MaterialSelect
                disabled={!userInfo[PAYMENT_SYSTEM]}
                labelId="payment-system"
                name={PAYMENT_SYSTEM}
                value={userInfo[PAYMENT_SYSTEM] ||
                  (availablePaymentSystem.includes(DEFAULT_PAYMENT_METHOD) ? DEFAULT_PAYMENT_METHOD : '')}
                onChange={handleOnChange}
              >
                {availablePaymentSystem.map(system => (
                  <MenuItem key={system} value={system}>{system}</MenuItem>
                ))}
              </MaterialSelect>
            </FormControl>
            <FormControl style={{ width: '300px' }} key='payout-method'>
              <InputLabel id="payout-method">Pro default Payout Method</InputLabel>
              <MaterialSelect
                disabled={availablePayoutMethods.length === 0}
                labelId="payout-method"
                name={PAYOUT_METHOD}
                value={userInfo[PAYOUT_METHOD] ?? getDefaultPayoutMethodId(availablePayoutMethods)}
                onChange={handleOnChange}
              >
                {availablePayoutMethods.map(method => (
                  <MenuItem key={method.type} value={method.id}>{payoutMethodToString(method)}</MenuItem>
                ))}
              </MaterialSelect>
            </FormControl>
            <FormControl style={{ width: '300px', marginLeft: '20px' }} key='use-manual_payout-method'>
              <div style={{ fontSize:13 }}>Use Manual payout:</div>
              <Switch
                style={{ width: 50, position: 'absolute', bottom: 0  }}
                checked={userInfo[USE_MANUAL_PAYOUT_METHOD]}
                disabled={userInfo[USE_MANUAL_PAYOUT_METHOD] && getDefaultPayoutMethodId(availablePayoutMethods) === DEFAULT_PAYOUT_METHOD}
                name={USE_MANUAL_PAYOUT_METHOD}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={() => handleOnChange({ target: { name: USE_MANUAL_PAYOUT_METHOD, value: !userInfo[USE_MANUAL_PAYOUT_METHOD] } })}
              />
            </FormControl>
          </div>
        </div>

        {isPending && <ApproveArea />}

        <div className={style.controls}>
          {isEdited && [
            <button
              type="button"
              className="cancelBtn"
              onClick={handleOnCancel}
            >
              Cancel
            </button>,
            <button
              type="button"
              className="saveBtn"
              onClick={handleOnSave}
            >
              Save
            </button>
          ]}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  serverData: state.serverData,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCategories: getCategories,
  editUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProDetail);
