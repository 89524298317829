/** @flow */

import { Store } from 'redux';
import { createSelector } from 'reselect';
import type { ActionType } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'calls';

// Constants (up)

// Reducer (down)

type CallsState = {
  days: string,
  range: any[],
};

const initialState: CallsState = {
  days: '',
  range: [],
};

export default function reducer(
  state: CallsState = initialState, action: ActionType<>,
): CallsState {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CALLS_SET_DAYS: {
      const { days }: { days: string } = payload;

      return {
        ...state,
        days,
      };
    }

    case actionTypes.CALLS_SET_RANGE: {
      const { range }: { range: any[] } = payload;

      return {
        ...state,
        range,
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector = (state: Store) => state[moduleName];

export const selectCallsDays = createSelector(
  stateSelector, (calls: CallsState): string => calls.days,
);

export const selectCallsRange = createSelector(
  stateSelector, (calls: CallsState): any[] => calls.range,
);

// Selectors (up)
