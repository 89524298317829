/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import { actionTypes } from '../actions';
import type { LoggedInUserState, LoggedInUserAction, AdminPanelStore } from '../../types';

// Constants (down)

export const moduleName = 'loggedInUser';

const loggedInUserInitialState: LoggedInUserState = {
  loading: true,
  logInFailure: false
};

// Constants (up)

// Reducers (down)

export default (state: LoggedInUserState = loggedInUserInitialState, action: LoggedInUserAction): LoggedInUserState => {
  const { type } = action;

  switch (type) {
    case actionTypes.LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.LOG_IN_SUCCESS: {
      const { userData, userAuth } = action;

      return {
        ...state,
        ...userData,
        loading: false,
        logInFailure: false,
        userAuth,
      };
    }
    
    case actionTypes.SET_USER_AUTH: {
      const { userAuth } = action;

      return {
        ...state,
        userAuth,
      };
    }
    case actionTypes.LOG_IN_FAILURE:
      return {
        loading: false,
        logInFailure: true
      };
    case actionTypes.LOGGED_OFF:
      return {
        ...loggedInUserInitialState,
        loading: false
      };

    default:
      return state;
  }
};

// Reducers (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, LoggedInUserState> = (state: AdminPanelStore, _props: any) => (state[moduleName]: LoggedInUserState);

const userAuthCombiner: (s: LoggedInUserState) => ?string  = (s: LoggedInUserState) => s.userAuth;

export const selectUserAuth: OutputSelector<AdminPanelStore, any, ?string> = 
  createSelector(stateSelector, userAuthCombiner);

// Selectors (up)
