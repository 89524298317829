/** @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'antd';
import { RouteChildrenProps } from 'react-router';
import 'antd/dist/antd.css';
import type { CountryDataType } from '../index';
import style from '../style.module.css';


type CountriesFormProps = {
  defaultCountryData: CountryDataType,
  data: CountryDataType,
  errors: { [key: string]: string },
  editFS?: boolean,
  userAuth?: string,
  onChangeField: () => void,
  onSubmit?: () => void,
  onFieldValueChanged: (name: string, value: boolean) => void,
};

type CountriesFormState = {
  defaultCountryData: CountryDataType,
};

class CountriesForm extends Component<RouteChildrenProps & CountriesFormProps, CountriesFormState> {
  constructor(props) {
    super(props);

    const { defaultCountryData } = props;

    this.state = {
      defaultCountryData
    }
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit();
  }

  render() {
    const { defaultCountryData } = this.state;
    const { data, editFS, onChangeField } = this.props;

    return(
      <div className={style.formCreate}>
        <div className={style.inputsWrapper}>
          <div className={style.inputsColumn}>
            <div className={style.formElem}>
              <div className={style.formElemName}>Country code:</div>
              <Input
                disabled={editFS}
                size="large"
                allowClear
                defaultValue={defaultCountryData.code}
                value={data?.code}
                onChange={onChangeField}
                name="code"
              />
            </div>
            <div className={style.formElem}>
              <div className={style.formElemName}>Name:</div>
              <Input
                size="large"
                allowClear
                defaultValue={defaultCountryData.displayName}
                value={data?.displayName}
                onChange={onChangeField}
                name="displayName"
              />
            </div>
            <div className={style.formElem}>
              <div className={style.formElemName}>Local Name:</div>
              <Input
                size="large"
                allowClear
                defaultValue={defaultCountryData.localName}
                value={data?.localName}
                onChange={onChangeField}
                name="localName"
              />
            </div>
            <div className={style.formElem}>
              <div className={style.formElemName}>Search Terms for Google Maps:</div>
              <Input
                size="large"
                allowClear
                defaultValue={defaultCountryData.searchTerms}
                value={data?.searchTerms}
                onChange={onChangeField}
                name="searchTerms"
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!editFS && (
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Add
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userAuth: state.loggedInUser.userAuth,
});

export default connect(
  mapStateToProps
)(CountriesForm);
