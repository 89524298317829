import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';

import style from './style.module.css';

import { ARCHIVED, INFO_TYPES, ROUTES } from '../../constants';
import { chooseSelectedUser, clearSelectedUser } from '../../store/actions/user';
import Delimeter from './delimeter';
import AccountControlsArea from './acc-controls-area';
import MoneyTab from './money-tab';
import AccDetailsTab from './acc-details-tab';
import People from '../people';

/* eslint-disable react/prop-types */
const UserInfo = ({ userId, isPending, isForDelete, loggedInUser, selectedUser, chooseSelectedUser, clearSelectedUser }) => {
  const history = useHistory();
  const { userAuth } = loggedInUser;
  const { selectedUser: user, error, message, infoList, isModifying } = selectedUser || {};

  if (user && ((isForDelete && user.status !== ARCHIVED) || (isPending && !user.updateProData))) {
    history.push(`${ROUTES.USER}/${user.id}`);
  } else if (!isForDelete && user && user.status === ARCHIVED) {
    history.push(`${ROUTES.ARCHIVED_USER}/${user.id}`);
  }

  const {
    name,
    lastName,
    status
  } = user || {};

  const [activeTab, setActiveTab] = useState(INFO_TYPES.ACC_DETAILS);

  useEffect(() => {
    chooseSelectedUser(userId, userAuth);
    return () => {
      clearSelectedUser();
    };
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case INFO_TYPES.PEOPLE:
        return <People isPro={!!user.pro} userId={user.id.toString()} />;
      case INFO_TYPES.MONEY:
        return <MoneyTab userAuth={userAuth} data={infoList} userId={user.id} />;
      case INFO_TYPES.ACC_DETAILS:
      default: {
        return (
          <AccDetailsTab
            selectedUser={selectedUser}
            userAuth={userAuth}
            isPending={isPending}
          />
        );
      }
    }
  };

  return (
    <div>
      {
        error ? (
          <div className="errorContainer">Error! {message}</div>
        ) : !isModifying && user ? (
          <>
            <div className={style.profileHeader}>
              <h1 style={{ margin: 0 }}>{name} {lastName}</h1>
            </div>
            <Delimeter statusText={status} />

            {!isPending && <AccountControlsArea isForDelete={isForDelete} activeTab={activeTab} changeTab={setActiveTab} />}

            {renderContent()}
          </>
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  selectedUser: state.selectedUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  chooseSelectedUser,
  clearSelectedUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
