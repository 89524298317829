import React from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTES, PRIVATE, PUBLIC, PRIVATE_AND_PUBLIC } from '../../constants/routes-constants';

import Home from '../../pages/home';
import LogIn from '../../pages/log-in';
import Invites from '../../pages/invites';
import Calls from '../../pages/calls';
import Settings from '../../pages/settings';
import ProRequests from '../../pages/pro-requests';
import ProRequest from '../../pages/pro-request';
import Withdrawals from '../../pages/withdrawals';
import Transactions from '../../pages/transactions';
import ActiveUsers from '../../pages/users-active';
import IncompleteRegUsers from '../../pages/users-incomplete-reg';
import FeaturedServices from '../../pages/featured-services';
import User from '../../pages/user';
import UserArchived from '../../pages/user-archived';
import Sectors from '../../pages/sectors';
import Partners from '../../pages/partners';
import Appointments from '../../pages/appointments'
import Finances from '../../pages/finances';
import Countries from '../../pages/countries';

export const routesList = {
  [ROUTES.HOME]: {
    component() {
      return <Home />;
    },
    type: PRIVATE
  },
  [ROUTES.INVITES]: {
    component() {
      return <Invites />;
    },
    type: PRIVATE
  },
  [ROUTES.CALLS]: {
    component() {
      return <Calls />;
    },
    type: PRIVATE
  },
  [ROUTES.APPOINTMENTS]: {
    component() {
      return <Appointments />;
    },
    type: PRIVATE
  },
  [ROUTES.SETTINGS]: {
    component() {
      return <Settings />;
    },
    type: PRIVATE
  },
  [ROUTES.FINANCES]: {
    component() {
      return <Finances />;
    },
    type: PRIVATE
  },
  [ROUTES.COUNTRIES]: {
    component() {
      return <Countries />;
    },
    type: PRIVATE
  },
  [ROUTES.PRO_REQUESTS]: {
    component() {
      return <ProRequests />;
    },
    type: PRIVATE
  },
  [ROUTES.PRO_REQUEST_ID]: {
    component() {
      return <ProRequest />;
    },
    type: PRIVATE
  },
  [ROUTES.SECTORS]: {
    component() {
      return <Sectors />;
    },
    type: PRIVATE
  },
  [ROUTES.WITHDRAWALS]: {
    component() {
      return <Withdrawals />;
    },
    type: PRIVATE
  },
  [ROUTES.TRANSACTIONS]: {
    component() {
      return <Transactions />;
    },
    type: PRIVATE
  },
  [ROUTES.ACTIVE_USERS]: {
    component() {
      return <ActiveUsers />;
    },
    type: PRIVATE
  },
  [ROUTES.INCOMPLETE_REGISTRATIONS]: {
    component() {
      return <IncompleteRegUsers />;
    },
    type: PRIVATE
  },
  [ROUTES.FEATURED_SERVICES]: {
    component() {
      return <FeaturedServices />;
    },
    type: PRIVATE
  },
  [ROUTES.ARCHIVED_USER_ID]: {
    component() {
      return <UserArchived />;
    },
    type: PRIVATE
  },
  [ROUTES.USER_ID]: {
    component() {
      return <User />;
    },
    type: PRIVATE
  },
  [ROUTES.PARTNERS]: {
    component() {
      return <Partners />;
    },
    type: PRIVATE
  },
  [ROUTES.LOGIN]: {
    component() {
      return <LogIn />;
    },
    type: PUBLIC
  },
  [ROUTES.ALL]: {
    component() {
      return <Redirect to={{ pathname: ROUTES.HOME }} />;
    },
    type: PRIVATE_AND_PUBLIC
  }
};
