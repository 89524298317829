/** @flow */

import { stringify } from 'query-string';
import { apiUrls } from './index';
import { http } from './fetch-wrapper';

export const getAppointments = (authData: string, startDate: Date, endDate: Date): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  const params = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  return http(`${apiUrls.BOOKINGS}?${stringify(params)}`, { method: 'GET', headers });
};

export const deleteAppointment = (authData: string, id: number, isPro: boolean) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  return http(`${apiUrls.DELETE_APPOINTMENT(id, isPro)}`, { method: 'DELETE', headers });  
}