/** @flow */

// Types (down)

import { Store } from 'redux';
import { createSelector } from 'reselect';
import type { ActionType } from '../../types';
import { actionTypes } from '../actions';

export type SectorType = {
  id: number,
  name: string,
  tag: string,
  childs: SectorType[],
};

// Types (up)

// Constants (down)

export const moduleName = 'sectors';

// Constants (up)

// Reducer (down)

type SectorsState = {
  fetching: boolean,
  error: string,
  entities: SectorType[],
};

const initialState: SectorsState = {
  fetching: false,
  error: '',
  entities: [],
};

export default function reducer(state: SectorsState = initialState, action: ActionType<>): SectorsState {
  const { error, type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_SECTORS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: initialState.error,
        entities: initialState.entities,
      };

    case actionTypes.FETCH_SECTORS_SUCCESS: {
      const { sectors }: { sectors: SectorType[] } = payload;

      return {
        ...state,
        fetching: false,
        error: initialState.error,
        entities: sectors,
      };
    }

    case actionTypes.FETCH_SECTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: error || 'Cannot fetch sectors list! Try again later',
        entities: initialState.entities,
      };

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector = (state: Store): SectorsState => state[moduleName];

export const selectSectorsIsFetching = createSelector(
  stateSelector,
  (sectors: SectorsState): boolean => sectors.fetching,
);

export const selectSectorsEntities = createSelector(
  stateSelector,
  (sectors: SectorsState): SectorType[] => sectors.entities,
);

// Selectors (up)
