import cn from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import style from './style.module.css';
import Input from '../../input';
import nouserimage from '../../../assets/nouserimage.jpg';
import { editUser } from '../../../store/actions/user';
import { getAvatarLink } from '../../../helpers';

class AccountDetail extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    const { name, lastName, email, id } = user;

    this.state = {
      userInfo: {
        id,
        name,
        lastName,
        email
      },
      isEdited: false
    };
  }

  onChange = e => {
    const { value, name } = e.target;
    this.setState(prev => ({
      userInfo: {
        ...prev.userInfo,
        [name]: value
      },
      isEdited: true
    }));
  };

  onCancel = () => {
    const { user = {} } = this.props;
    this.setState({
      userInfo: { ...user },
      isEdited: false
    });
  };

  onSave = () => {
    const { userAuth } = this.props;
    const fields = this.state.userInfo || {};
    const data = { ...fields };
    this.props.editUser(data, userAuth, false);
  };

  fillChangedFields = () => {
    const changedFields = {};
    const { user }  = this.props;
    const { userInfo = {} } = this.state;
    const { updateProData } = user || {};
    const { name: updatedName, lastName: updateLastName } = updateProData || {};
    const { name: userName, lastName: userLastName } = userInfo || {};

    if (updatedName && userName !== updatedName) {
      changedFields.name = updatedName;
    }

    if (updateLastName && userLastName !== updateLastName) {
      changedFields.lastName = updateLastName;
    }

    return changedFields;
  };

  render() {
    const { isPending, user = {}, } = this.props;
    const { isEdited, userInfo = {} } = this.state;
    const { name, lastName, email } = userInfo;

    let avatar;

    if (user.avatarUrl !== null) {
      avatar = (
        <img
          src={`${user.avatarUrl}`}
          alt={`${user.name} ${user.lastName}`}
        />
      );
    } else {
      avatar = (<img src={nouserimage} alt="" />);
    }

    const nextAvatarId = (user && user.updateProData && user.updateProData.avatarID) || null;

    const changedFields = isPending ? this.fillChangedFields() : {};

    return (
      <div className={style.accountDetail}>
        <div style={{ width: '100%' }}>
          <div className="headline">Account details</div>
          <div className="doubleInput">
            <Input
              label="First Name:"
              name="name"
              value={name}
              onChange={this.onChange}
              disabled={isPending}
              changedFields={changedFields}
            />
            <Input
              label="Last Name:"
              name="lastName"
              value={lastName}
              onChange={this.onChange}
              disabled={isPending}
              changedFields={changedFields}
            />
          </div>
          <Input
            label="Email:"
            name="email"
            value={email}
            disabled
          />
          <Input
            label="Multiplatform link to profile:"
            name="branchLink"
            value={user.branchLink}
            disabled
          />
          <div className={style.controls}>
            {isEdited && [
              <button
                type="button"
                className="cancelBtn"
                onClick={this.onCancel}
              >
                Cancel
              </button>,
              <button type="button" className="saveBtn" onClick={this.onSave}>
                Save
              </button>
            ]}
          </div>
        </div>
        <div className={style.avatars}>
          {nextAvatarId && (
            <div className={cn(style.imageContainer, style.nextAvatar)}>
              <div className={style.image}>
                <img alt="" src={getAvatarLink(nextAvatarId)} />
              </div>
            </div>
          )}

          <div className={style.imageContainer}>
            <div className={style.image}>
              {avatar}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountDetail.propTypes = {
  userAuth: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  editUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    updateProData: PropTypes.shape({
      avatarID: PropTypes.number,
    })
  }),
};

AccountDetail.defaultProps = {
  user: {},
};

const mapDispatchToProps = dispatch => bindActionCreators({
  editUser
}, dispatch);

export default connect(null, mapDispatchToProps)(AccountDetail);
