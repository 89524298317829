import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Spin, Collapse, Modal, Button } from "antd";
import "antd/dist/antd.css";

import Card from "../../components/Layout/card";
import FeaturedServiceForm from './featuredServiceForm';
import { featuredServicesTableColumns as columns } from "../../helpers/table-data";
import {
  addFeaturedService,
  editFeaturedService,
  removeFeaturedService,
  getAllFeaturedService,
  clearFeaturedServices,
} from '../../store/actions/featuredServices';

const { Panel } = Collapse;

class FeaturedServies extends Component {
  constructor(props){
    super(props);

    this.state = {
      sortedInfo: { order: "descend", field: "id", columnKey: "id" },
      visibleModalEdit: false,
      visibleModalDelete: false,
      confirmLoading: false,
      serviceData: {
        title: '',
        subTitle: '',
        description: '',
        sector: '',
        category: '',
        service: '',
        amount: '',
        fixPrice: true,
        imgUrl: '',
      },
    }
  }

  componentDidMount() {
    const { getAllFeaturedService } = this.props;
    this.userAuth = this.props.userData.userAuth;
    this.userAuth && getAllFeaturedService(this.userAuth);
    this.defaultServiceData = {
      title: '',
      subTitle: '',
      description: '',
      sector: '',
      category: '',
      service: '',
      amount: '',
      fixPrice: true,
      imgUrl: '',
    };
  }

  componentWillUnmount() {
    const { clearFeaturedServices } = this.props;
    clearFeaturedServices();
  }

  handleChangeField = e => {
    const { value, name } = e.target;
    this.setState(prev => ({
      serviceData: {
        ...prev.serviceData,
        [name]: value,
      }
    }));
  };

  showModalEdit = () => {
    this.setState({
      visibleModalEdit: true,
    });
  };

  showModalDelete = () => {
    this.setState({
      visibleModalDelete: true,
    });
  };

  setDefaultServiceDataState = () => (
    this.setState({
      serviceData: this.defaultServiceData,
    })
  );

  handleCancel = () => {
    this.setDefaultServiceDataState();
    this.setState({
      visibleModalEdit: false,
      visibleModalDelete: false,
    });
  };

  createNewFeaturedService = () => {
    this.setDefaultServiceDataState();
  };

  openEditModal = data => () => {
    this.showModalEdit();
    this.setState({
      serviceData: data,
    })
  };

  openDeleteModal = data => () => {
    this.showModalDelete();
    this.setState({
      serviceData: data,
    })
  };

  createNewFeaturedServiceHandle = () => {
    const { addFeaturedService } = this.props;
    const { serviceData } = this.state;
    addFeaturedService(serviceData, this.userAuth);
  };

  editFeaturedServiceHendle = () => {
    const { editFeaturedService } = this.props;
    const { serviceData } = this.state;
    this.setState({
      visibleModalEdit: false,
      confirmLoading: false,
    });
    editFeaturedService(serviceData, this.userAuth);
  };

  deleteFeaturedService = () => {
    const { removeFeaturedService } = this.props;
    const { serviceData } = this.state;
    this.setState({
      visibleModalDelete: false,
      confirmLoading: false,
    });
    removeFeaturedService(serviceData, this.userAuth);
  };

  render() {
    const { loadingFeaturedServices, featuredServices } = this.props;
    const { sortedInfo, visibleModalEdit, visibleModalDelete, confirmLoading, serviceData } = this.state;
    return(
      <Card cardClass="route-content" headerText="Featured services">
        {!loadingFeaturedServices ? (
          <div className="featuredService">
            <Collapse defaultActiveKey={['1']} onChange={this.createNewFeaturedService}>
              <Panel showArrow={false} header="Create new featured service" key="2">
                <FeaturedServiceForm
                  onChangeField={this.handleChangeField}
                  data={serviceData}
                  defaultServiceDataState={this.setDefaultServiceDataState}
                  defaultServiceData={this.defaultServiceData}
                  createNewFeaturedServiceHandle={this.createNewFeaturedServiceHandle}
                />
              </Panel>
            </Collapse>
            <Table
              columns={columns(
                  this.openEditModal,
                  this.openDeleteModal,
                )}
              rowKey={record => record.id}
              onChange={this.onChange}
              onRow={this.onRow}
              size="small"
              dataSource={featuredServices || []}
              locale={{emptyText: 'No featured services'}}
              scroll={{ x: true }}
            />
            <Modal
              title={`Edit ${serviceData ? serviceData.title : 'featured service'}`}
              visible={visibleModalEdit}
              confirmLoading={confirmLoading}
              width="60%"
              footer={[
                <Button key="submit" type="primary" loading={confirmLoading} onClick={this.editFeaturedServiceHendle}>
                  Submit
                </Button>,
                <Button key="back" type="danger" onClick={this.handleCancel}>
                  Cancel changes
                </Button>,
                ]}
            >
              <FeaturedServiceForm
                data={serviceData}
                onChangeField={this.handleChangeField}
                editFS
                defaultServiceDataState={this.setDefaultServiceDataState}
                defaultServiceData={this.defaultServiceData}
              />
            </Modal>

            <Modal
              title='Delete featured service'
              visible={visibleModalDelete}
              width="35%"
              footer={[
                <Button key="submit" type="danger" onClick={this.deleteFeaturedService}>
                  Delete
                </Button>,
                <Button key="back" type="primary" onClick={this.handleCancel}>
                  Cancel changes
                </Button>,
                ]}
            >
              Are you sure, that you want to delete this service?
            </Modal>
          </div>

        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>

        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  featuredServices: state.featuredServices.featuredServices,
  loadingFeaturedServices: state.featuredServices.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addFeaturedService,
  editFeaturedService,
  removeFeaturedService,
  getAllFeaturedService,
  clearFeaturedServices,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedServies);
