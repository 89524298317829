import React, { Component } from "react";
import EmojiField from "emoji-picker-textfield";
import { confirmAlert } from "react-confirm-alert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Spin } from "antd";
import Card from "../Layout/card";
import Input from "../input";
import {
  sendPush,
  sendEmail,
  getPushList,
  updatePush
} from "../../store/api/notifications";
import PushItem from "./push-item";

import "react-tabs/style/react-tabs.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import style from "./style.module.css";

class MessagesArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      pushList: [],
      selectedPush: null
    };
  }

  onPushMesChange = (e, value) => {
    // eslint-disable-next-line no-underscore-dangle
    const unified = this._field.getImages(value);
    // eslint-disable-next-line no-underscore-dangle
    this._field.state.value = this._field.getUnicode(value);
    this.setState({ unified });
  };

  clearPushMes = () => {
    this.setState({ unified: "" });
    // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
    this._field && (this._field.state.value = "");
  };

  onPushConfirm = (isUpdate = false) => () =>
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="confirmModal">
          <p className="confirmTitle">
            Do you want to {`${isUpdate ? "update" : "send"}`} push
            notifications?
          </p>
          <button
            type="button"
            className="confirmOkBtn"
            onClick={
              isUpdate
                ? this.onUpdatePush(onClose)
                : this.pushConfirmSending(onClose)
            }
          >
            Yes
          </button>
          <button type="button" className="confirmCancelBtn" onClick={onClose}>
            No
          </button>
        </div>
      )
    });

  pushConfirmSending = onClose => async () => {
    this.setState({ pushError: false, pushErrorMsg: "" });
    sendPush(
      this.props.selectedUsers,
      // eslint-disable-next-line no-underscore-dangle
      this._field.state.value,
      this.props.userAuth
    )
      .then(data => {
        // eslint-disable-next-line no-unused-expressions
        data.error
          ? this.setState({ pushError: true, pushErrorMsg: data.message })
          : this.clearPushMes();
        onClose();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        onClose();
      });
  };

  onChangeEmail = e => {
    this.setState({ emailTag: e.target.value });
  };

  onEmailConfirm = () =>
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="confirmModal">
          <p className="confirmTitle">
            Do you want to send email notifications?
          </p>
          <button
            type="button"
            className="confirmOkBtn"
            onClick={this.emailConfirmSending(onClose)}
          >
            Yes
          </button>
          <button type="button" className="confirmCancelBtn" onClick={onClose}>
            No
          </button>
        </div>
      )
    });

  emailConfirmSending = onClose => async () => {
    this.setState({ emailError: false, emailErrorMsg: "" });
    sendEmail(
      this.props.selectedUsers,
      this.state.emailTag,
      this.props.userAuth
    )
      .then(data => {
        // eslint-disable-next-line no-unused-expressions
        data.error
          ? this.setState({ emailError: true, emailErrorMsg: data.message })
          : this.setState({ emailTag: "" });
        onClose();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        onClose();
      });
  };

  onTabSelect = (index, prevIndex) => {
    // 0 - Send email; 1 - Send custom push; 2 - Edit push
    // eslint-disable-next-line no-unused-expressions
    prevIndex === 1 && this.clearPushMes();
    // eslint-disable-next-line no-unused-expressions
    prevIndex === 2 && this.clearSelectedPush();
    // eslint-disable-next-line no-unused-expressions
    index === 2 && !this.state.pushList.length && this.getPushList();
    this.setState({ tabIndex: index });
  };

  getPushList = () => {
    this.setState({ loadingPushList: true, pushList: [], pushListErr: false });
    getPushList(this.props.userAuth)
      .then(data => {
        // eslint-disable-next-line no-unused-expressions
        data.error
          ? this.setState({ loadingPushList: false, pushListErr: true })
          : this.setState({ loadingPushList: false, pushList: [...data] });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.setState({ loadingPushList: false });
      });
  };

  setPushItem = item => {
    this.setState({ selectedPush: { ...item } });
    // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
    this._pushTitleField && (this._pushTitleField.state.value = item.title);
    // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
    this._pushBodyField && (this._pushBodyField.state.value = item.body);
  };

  clearSelectedPush = (onlyFields = false) => {
    // eslint-disable-next-line no-unused-expressions
    onlyFields
      ? this.setState({ selectedPush: null })
      : this.setState({ selectedPush: null, pushList: [] });
    // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
    this._pushTitleField && (this._pushTitleField.state.value = "");
    // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
    this._pushBodyField && (this._pushBodyField.state.value = "");
  };

  onPushEdit = name => (e, value) => {
    // eslint-disable-next-line no-unused-expressions
    name === "pushTitle" &&
      // eslint-disable-next-line no-underscore-dangle
      (this._pushTitleField.state.value = this._pushTitleField.getUnicode(
        value
      ));
    // eslint-disable-next-line no-unused-expressions
    name === "pushBody" &&
      // eslint-disable-next-line no-underscore-dangle
      (this._pushBodyField.state.value = this._pushBodyField.getUnicode(value));
  };

  onUpdatePush = onClose => async () => {
    this.setState({ pushUpdateErr: false });
    const data = {
      ...this.state.selectedPush,
      // eslint-disable-next-line no-underscore-dangle
      title: this._pushTitleField.state.value,
      // eslint-disable-next-line no-underscore-dangle
      body: this._pushBodyField.state.value
    };

    const replaceItem = () => {
      const { pushList = [], selectedPush } = this.state;
      const index = pushList.findIndex(elem => elem.id === selectedPush.id);
      const newArr = [...pushList];
      newArr.splice(index, 1, data);
      return newArr;
    };

    updatePush(data, this.props.userAuth)
      // eslint-disable-next-line no-shadow
      .then(data => {
        // eslint-disable-next-line no-unused-expressions
        data.error
          ? this.setState({ pushUpdateErr: true })
          : (this.setState(prev => ({ pushList: replaceItem(prev) })),
            this.clearSelectedPush(true));
        onClose();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.setState({ pushUpdateErr: true });
        onClose();
      });
  };

  render() {
    const { selectedUsers } = this.props;

    return (
      <Card>
        {selectedUsers.length ? (
          <div>
            <p>Selected users: {selectedUsers.length}</p>

            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={this.onTabSelect}
            >
              <TabList>
                <Tab>Send email</Tab>
                <Tab>Send custom push</Tab>
                <Tab>Edit push</Tab>
              </TabList>

              <TabPanel className={style.pushCreator}>
                <div className={style.creatorTitle}>E-mail notification</div>
                <Input
                  label="Email tag (from Prismic):"
                  value={this.state.emailTag}
                  onChange={this.onChangeEmail}
                />
                <button
                  type="button"
                  className="saveBtn"
                  onClick={this.onEmailConfirm}
                >
                  Send
                </button>
                {this.state.emailError && (
                  <div className="errorSamllMsg">
                    Error in sending email! {this.state.emailErrorMsg}
                  </div>
                )}
              </TabPanel>
              <TabPanel className={style.pushCreator}>
                <div className={style.creatorTitle}>Push notification</div>
                <div style={{ color: "#3d5273", marginLeft: "50%" }}>
                  Preview:
                </div>
                <div className={style.textArea}>
                  <EmojiField
                    placeholder="Type here"
                    onChange={this.onPushMesChange}
                    ref={_field => (this._field = _field)}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.unified }}
                  />
                </div>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="saveBtn" onClick={this.onPushConfirm()}>
                  Send
                </button>
                {this.state.pushError && (
                  <div className="errorSamllMsg">
                    Error in sending push! {this.state.pushErrorMsg}
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {this.state.loadingPushList ? (
                  <div style={{ textAlign: "center" }}>
                    <Spin size="large" />
                  </div>
                ) : (
                  <div className={style.editPushTab}>
                    <div className={style.pushEditArea}>
                      <EmojiField
                        placeholder="Title"
                        onChange={this.onPushEdit("pushTitle")}
                        fieldType="input"
                        disabled={!this.state.selectedPush}
                        ref={_field => (this._pushTitleField = _field)}
                      />
                      <EmojiField
                        placeholder="Body"
                        onChange={this.onPushEdit("pushBody")}
                        disabled={!this.state.selectedPush}
                        ref={_field => (this._pushBodyField = _field)}
                      />
                      <button
                        type="button"
                        className="saveBtn"
                        disabled={!this.state.selectedPush}
                        onClick={this.onPushConfirm(true)}
                      >
                        Send
                      </button>
                      {this.state.pushListErr && (
                        <div className="errorSamllMsg">
                          Error in getting push list.
                        </div>
                      )}
                      {this.state.pushUpdateErr && (
                        <div className="errorSamllMsg">
                          Error in updating push notification.
                        </div>
                      )}
                    </div>

                    <div className={style.pushList}>
                      {this.state.pushList.map(el => (
                        <PushItem
                          item={el}
                          setItem={this.setPushItem}
                          key={el.key}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </TabPanel>
            </Tabs>
          </div>
        ) : (
          <div>
            There are no selected users for sending e-mail or push
            notifications.
          </div>
        )}
      </Card>
    );
  }
}

export default MessagesArea;
