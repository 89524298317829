/** @flow */

import sanitize from 'sanitize-filename';

const contentDispositionParser = /^form-data;\sname="attachment";\sfilename="(?<filename>.+)"$/;

type ResponseLike = {
  status: number;
  message: string;
  ...
}

export class FetchFileError extends Error {
  status: number;

  name: string;

  constructor(message: string, status: number) {
    super(message);

    this.status = status;
    this.name = this.constructor.name;
  }
}

export const checkIfResponseError = (response: Response | ResponseLike): boolean => typeof response.status === 'number' && (response.status < 200 || response.status >= 300);

const handleResponse = (response: Response) => {
  const responseError = checkIfResponseError(response);

  return responseError ? response.text() : response.json();
};

const handleResponseData = (data: any): any => {
  let response: any = null;
  const errorMessage = 'Something went wrong';

  if (data) {
    if (typeof data === 'object') {
      response = data;
    } else if (typeof data === 'string') {
      try {
        response = JSON.parse(data);
      } catch(e) {
        response = data;
      }
    } else {
      response = String(data);
    }

    if (typeof response === 'object' && Object.hasOwnProperty.call(response, 'status')) {
      const responseError = checkIfResponseError((response: ResponseLike));

      if(responseError) {
        throw (response.message || response.error || errorMessage);
      }
    }
  } else {
    throw errorMessage;
  }

  return response;
};

const handleError = errorMessage => ({
  error: true,
  message: errorMessage
});

export const http = (URL: string, options: RequestOptions = {}): Promise<any> => fetch(URL, options)
  .then(handleResponse)
  .then(handleResponseData)
  .catch(handleError);

export const fetchFileBlob = async (url: URL, options: RequestOptions = {}): Promise<[Blob, ?string]> => {
  const response: Response = await fetch(url, options);

  if (checkIfResponseError(response)) {
    const errorResponse = await response.text();
    let errorMessage = 'Failed to fetch the requested file';

    try {
      errorMessage = `${errorMessage}: ${handleResponseData(errorResponse)}`;
    } catch (e) {
      errorMessage = `${errorMessage}: ${e}`;
    }

    throw new FetchFileError(errorMessage, response.status);
  }

  const fileName = response.headers.get('Content-Disposition')?.match(contentDispositionParser)?.groups?.filename;
  const blob = await response.blob();

  return [blob, fileName ? sanitize(fileName) : undefined];
}
