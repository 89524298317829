import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import sectorsReducer, { moduleName as sectorsModule } from './sectors';
import partnersReducer, { moduleName as partnersModule } from './partners';
import countryReducer, { moduleName as countryModule } from './countries';
import activeUsersReducer, { moduleName as activeUsersModule } from './activeUsers';
import apiReducer, { moduleName as apiModule } from './api';
import archivedUsersReducer, { moduleName as archivedUsersModule } from './archivedUsers';
import callsReducer, { moduleName as callsModule } from './calls';
import transactionsReducer, { moduleName as transactionsModule } from './transactions';
import userActivityReducer, { moduleName as userActivityModule } from './userActivity';
import appointmentsReducer, { moduleName as appointmentsModule } from './appointments';
import loggedInUserReducer, { moduleName as loggedInUserModule } from './logged-in-user';
import configDataReducer, { moduleName as configDataModule } from './configData';
import * as user from './user';
import * as data from './data';
import * as pending from './pending';
import preferencesDataReducer, { moduleName as preferencesDataModule } from './preferences';
import * as notes from './notes';
import * as featuredServices from './featuredServices';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  [activeUsersModule]: activeUsersReducer,
  [apiModule]: apiReducer,
  [archivedUsersModule]: archivedUsersReducer,
  [callsModule]: callsReducer,
  [sectorsModule]: sectorsReducer,
  [partnersModule]: partnersReducer,
  [countryModule]: countryReducer,
  [transactionsModule]: transactionsReducer,
  [userActivityModule]: userActivityReducer,
  [appointmentsModule]: appointmentsReducer,
  router: connectRouter(history),
  [loggedInUserModule]: loggedInUserReducer,
  serverData: data.serverData,
  usersArrays: user.usersArrays,
  pending: pending.pendingReducer,
  activateUser: pending.activateUser,
  selectedUser: user.selectedUserReducer,
  [preferencesDataModule]: preferencesDataReducer,
  note: notes.notes,
  featuredServices: featuredServices.featuredServices,
  [configDataModule]: configDataReducer
});

export default rootReducer;
