import * as api from "../api/preferences";
import { actionTypes } from "./index";

const setPreferences = response => ({
  type: actionTypes.SET_PREFERENCES,
  preferences: response
});

export const getPreferences = authData => async dispatch => {
  const response = await api.getPreferences(authData);

  if (!response.error) {
    dispatch(setPreferences(response));
  }
};

export const editPreferences = (data, authData) => async dispatch => {
  const response = await api.editPreferences("PUT", authData, data);
  if (response.message === 'You don’t have enough rights') {
    alert(response.message);
  }
  if (!response.error) {
    dispatch(setPreferences(response));
  }
};
