import { apiUrls } from "./index";

export const getPreferences = authData => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(apiUrls.GET_PREFERENCES, { method: "GET", headers }).then(
    response =>
      response
        .json()
        .then(json => json)
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          return { error: true };
        })
  );
};

export const editPreferences = (method, authData, data) => {
  const headers = new Headers();

  if (method === "PUT") {
    headers.append("Content-Type", "application/json ");
  }

  headers.append("Authorization", `Bearer ${authData}`);

  return fetch(apiUrls.EDIT_PREFERENCES, { method, headers, body: JSON.stringify(data) }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
};
