/** @flow */

import React, { Component, type AbstractComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Spin, type PaginationConfig } from 'antd';
import Card from '../../components/Layout/card';
// $FlowIgnore[cannot-resolve-module] This is a stylesheet import
import 'antd/dist/antd.css';
import {
  getIncompleteUsers,
  clearIncompleteUsers
} from '../../store/actions/user';
import { PAGE_SIZE } from '../../constants';
import { incomplTableColumns as columns } from '../../helpers/table-data';
import type { SorterType } from '../../types';

type IncompleteRegUsersOwnProps = $ReadOnly<{|
|}>

  type IncompleteRegUsersProps = $ReadOnly<{|
  ...IncompleteRegUsersOwnProps,
  clearIncompleteUsers: () => void,
  getIncompleteUsers: (userAuth: string) => void,
  uArrays: {
    error ?: boolean,
    load ?: boolean,
    message ?: string,
    incomplUsers ?: {}[]
  },
  userData: {
    userAuth ?: string,
  },
|}>;

type IncompleteRegUsersState = {
  sortedInfo: SorterType | {},
  currentPage: number,
};

class IncompleteRegUsers extends Component<IncompleteRegUsersProps, IncompleteRegUsersState> {
  userAuth: string | null;

  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: {},
      currentPage: 1,
    };

    this.userAuth = null;
  }

  componentDidMount() {
    const { getIncompleteUsers, userData: { userAuth } } = this.props;

    this.userAuth = userAuth || null;

    if (this.userAuth) {
      getIncompleteUsers(this.userAuth)
    }
  }

  componentWillUnmount() {
    const { clearIncompleteUsers } = this.props;

    clearIncompleteUsers()
  }

  onChange: (pagination: PaginationConfig, _filters: any, sorter: SorterType) => void = (pagination, _filters, sorter) => {
    this.setState(prevState => ({
      sortedInfo: sorter,
      currentPage: prevState.currentPage !== pagination.current ? pagination.current : 1
    }));
  };

  render() {
    const { sortedInfo, currentPage } = this.state;
    const { uArrays } = this.props;

    const {
      load: isLoaded = false,
      error: isError = false,
      message: errorMsg = '',
      incomplUsers = []
    } = uArrays;

    return (
      <Card cardClass="route-content">
        <p>Total number of incomplete users: {incomplUsers.length}</p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoaded ? (
          !isError ? (
            <Table
              columns={columns(sortedInfo)}
              rowKey={record => record.email}
              onChange={this.onChange}
              pagination={{ pageSize: PAGE_SIZE, current: currentPage }}
              dataSource={incomplUsers}
            />
          ) : (
            <div className="errorContainer">
              Error!
              <div className="errorMsg">{errorMsg}</div>
            </div>
          )
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  uArrays: state.usersArrays
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getIncompleteUsers,
      clearIncompleteUsers
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(IncompleteRegUsers): AbstractComponent<IncompleteRegUsersOwnProps>);
