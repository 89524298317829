/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';

import Card from '../../components/Layout/card';
import style from './style.module.css';

import {
  clearPendings,
  getWithdrawals,
  getWithdrawalDetails,
  approveWithdrawal,
  declineWithdrawal
} from '../../store/actions/pending';

import { PAGE_SIZE } from '../../constants';
import { withdrawalsTableColumns as columns } from '../../helpers/table-data';

class Withdrawals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: {},
      currentPage: 1
    };
  }

  componentDidMount() {
    this.userAuth = this.props.userData.userAuth;
    // eslint-disable-next-line no-unused-expressions
    this.userAuth && this.props.getWithdrawals(this.userAuth);
  }

  componentWillUnmount() {
    this.props.clearPendings();
  }

  onChange = (pagination, _filters, sortedInfo) => {
    this.setState(prevState => ({
      sortedInfo,
      currentPage: prevState.currentPage !== pagination.current ? pagination.current : 1
    }));
  };

  wDetails = id => () => this.props.getWithdrawalDetails(id, this.userAuth);

  wApprove = id => () => this.props.approveWithdrawal(id, this.userAuth);

  wDecline = id => () => this.props.declineWithdrawal(id, this.userAuth);

  renderBtns = (text, record) => [
    <button
      className={`${style.rowBtn} ${style.approveBtn}`}
      onClick={this.wApprove(record.id)}
      type="button"
    >
      Approve
    </button>,
    <button
      className={`${style.rowBtn} ${style.declineBtn}`}
      onClick={this.wDecline(record.id)}
      type="button"
    >
      Decline
    </button>,
    <button
      className={`${style.rowBtn}`}
      onClick={this.wDetails(record.id)}
      type="button"
    >
      Ask for more info
    </button>
  ];

  render() {
    const { sortedInfo, currentPage } = this.state;
    const {
      message = '',
      error: isError,
      load: isLoaded,
      withdrawals = []
    } = this.props.pending;

    // eslint-disable-next-line no-console
    console.log('isError', isError, 'message', message);

    return (
      <Card cardClass="route-content" headerText="Withdrawals">
        {isLoaded ? (
          [
            (isError || message) && (
              <div className="errorContainer">
                <div className={isError ? 'errorMsg' : 'notifMsg'}>
                  {isError && 'Error! '} {message}
                </div>
              </div>
            ),
            <Table
              columns={columns(sortedInfo, this.renderBtns)}
              rowKey={record => record.id}
              onChange={this.onChange}
              pagination={{ pageSize: PAGE_SIZE, current: currentPage }}
              dataSource={withdrawals}
            />
          ]
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  pending: state.pending
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearPendings,
      getWithdrawals,
      getWithdrawalDetails,
      approveWithdrawal,
      declineWithdrawal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Withdrawals);
