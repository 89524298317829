/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type { AdminPanelStore, ActionType, TransactionsState, TransactionsSearchActionPayload } from '../../types';
import { actionTypes } from '../actions';


// Constants (down)

export const moduleName = 'transactions';

// Constants (up)

// Reducer (down)


const initialState: TransactionsState = {
  days: '',
  range: [],
};

export default function reducer(
  state: TransactionsState = initialState, action: ActionType<TransactionsSearchActionPayload>,
): TransactionsState {
  const { type, payload } = action;

  if (!payload) {
    return { ... state };
  }

  switch (type) {
    case actionTypes.TRANSACTIONS_SET_DAYS: {
      const { days } = payload;

      return {
        ...state,
        days: days || '',
      };
    }

    case actionTypes.TRANSACTIONS_SET_RANGE: {
      const { range } = payload;

      return {
        ...state,
        range: range || [],
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, TransactionsState> = (state: AdminPanelStore, _props: any) => (state[moduleName]: TransactionsState);
const daysCombiner: (s: TransactionsState) => string = (s: TransactionsState): string => s.days;
const rangeCombiner: (s: TransactionsState) => any[] = (s: TransactionsState): any[] => s.range;

export const selectTransactionsDays: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, daysCombiner);
export const selectTransactionsRange: OutputSelector<AdminPanelStore, any, any[]> = createSelector(stateSelector, rangeCombiner);

// Selectors (up)
