import React, { Component } from "react";
import style from "./style.module.css";

const Input = props => {
  let value = "";
  let placeholder = "";
  let tmp;
  // eslint-disable-next-line no-unused-expressions
  props.isToPlaceholder
    ? (value = "",(placeholder = props.value))
    : ({ value, placeholder } = props);

  /* date coditions */
  const { type = "text" } = props;
  // eslint-disable-next-line no-unused-expressions
  type === "date" &&
  (
    tmp = new Date(value),
      value = `${tmp.getFullYear()}-${(tmp.getMonth() + 1).toString().padStart(2, "0")}-${tmp.getDate().toString().padStart(2, "0")}`
  );
  const onChangeDate = e => {
    props.onChange({
      ...e,
      target: {
        ...e.target,
        value: e.target.value
      }
    });
  };

  const isChanged = props.changedFields
    // eslint-disable-next-line no-prototype-builtins
    ? props.changedFields.hasOwnProperty(props.name)
    : false;

  return (
    <div style={{ marginBottom: 15 }}>
      <div className={`${style.formInput} ${isChanged && style.formInputChanged}`}>
        {props.label && <label>{props.label}</label>}
        <div className={style.inputContainer}>
          {isChanged ? (
            [
              <div
                className={style.prevValue}
                style={props.inputStyle ? props.inputStyle : {}}
              >
                {props.changedFields[props.name]}
              </div>,
              <input
                style={props.inputStyle ? props.inputStyle : {}}
                type={type}
                disabled={props.disabled}
                placeholder={placeholder}
                name={props.name}
                onChange={type === "date" ? onChangeDate : props.onChange}
                value={value}
                maxLength={props.maxlength}
              />
            ]
          ) : (
            <input
              style={props.inputStyle ? props.inputStyle : {}}
              type={type}
              disabled={props.disabled}
              placeholder={placeholder}
              name={props.name}
              onChange={type === "date" ? onChangeDate : props.onChange}
              value={value}
              maxLength={props.maxlength}
            />
          )}
        </div>
      </div>
      {props.info && <div className={style.info}>{props.info}</div>}
    </div>
  );
};

export default Input;
