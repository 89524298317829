/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type { ActionType, AppointmentsState, AdminPanelStore, Appointment } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'appointments';

// Constants (up)

// Reducer (down)

const initialState: AppointmentsState = {
  fetching: false,
  error: false,
  errorMessage: '',
  appointments: {
    results: [],
    total: 0,
  },
  displayRangeStart: null,
  displayRangeEnd: null,
};

export default function reducer(state: AppointmentsState = initialState, action: ActionType<>): AppointmentsState {
  const { error, type, payload } = action;

  switch (type) {
    case actionTypes.GET_APPOINTMENTS_START:
      return {
        ...state,
        fetching: true,
        error: initialState.error,
        errorMessage: initialState.errorMessage,
        appointments: initialState.appointments,
      };

    case actionTypes.GET_APPOINTMENTS_SUCCESS: {
      if (!payload) {
        return { ...state };
      }

      const { appointments } = payload;

      return {
        ...state,
        fetching: false,
        error: initialState.error,
        errorMessage: initialState.errorMessage,
        appointments,
      };
    }

    case actionTypes.GET_APPOINTMENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: error || 'Cannot fetch appointments! Try again later',
        appointments: initialState.appointments,
      };

    case actionTypes.SET_APPOINTMENTS_DISPLAY_RANGE:
      if (!payload) {
        return { ...state };
      }

      return {
        ...state,
        displayRangeStart: payload.startDate,
        displayRangeEnd: payload.endDate,
      }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, AppointmentsState> = (state: AdminPanelStore): AppointmentsState => state[moduleName];

const appointmentsAreFetchingCombiner = (appointmentsState: AppointmentsState): boolean => appointmentsState.fetching;
const retrievedAppointmentsCombiner = (appointmentsState: AppointmentsState): Appointment[] => appointmentsState.appointments.results;
const retrievedAppointmentsCountCombiner = (appointmentsState: AppointmentsState): number => appointmentsState.appointments.total;
const displayRangeStartCombiner = (appointmentsState: AppointmentsState): ?Date => appointmentsState.displayRangeStart;
const displayRangeEndCombiner = (appointmentsState: AppointmentsState): ?Date => appointmentsState.displayRangeEnd;
const fetchingErrorMessageCombiner = (appointmentsState: AppointmentsState): string => appointmentsState.errorMessage;
const fetchingFailedCombiner = (appointmentsState: AppointmentsState): boolean => appointmentsState.error;

export const selectAppointmentsAreFetching: OutputSelector<AdminPanelStore, any, boolean> = createSelector(stateSelector, appointmentsAreFetchingCombiner);
export const selectRetrievedAppointments: OutputSelector<AdminPanelStore, any, Appointment[]> = createSelector(stateSelector, retrievedAppointmentsCombiner);
export const selectRetrievedAppointmentsCount: OutputSelector<AdminPanelStore, any, number> = createSelector(stateSelector, retrievedAppointmentsCountCombiner);
export const selectDisplayRangeStart: OutputSelector<AdminPanelStore, any, ?Date> = createSelector(stateSelector, displayRangeStartCombiner);
export const selectDisplayRangeEnd: OutputSelector<AdminPanelStore, any, ?Date> = createSelector(stateSelector, displayRangeEndCombiner);
export const selectAppointmentsFetchingErrorMessage: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, fetchingErrorMessageCombiner);
export const selectAppointmentsFetchingFailed: OutputSelector<AdminPanelStore, any, boolean> = createSelector(stateSelector, fetchingFailedCombiner);

// Selectors (up)

