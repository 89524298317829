/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import { actionTypes } from '../actions';
import type { AdminPanelStore, PreferencesState, SetPreferencesAction } from '../../types';

// Constants (down)

export const moduleName = 'preferencesData';
const INITIAL_STATE: PreferencesState = { data: undefined };
const BOOKING_SLOT_DURATION_SETTING_KEY = 'booking.slot.duration.min';

// Constants (up)

// Reducers (down)

const preferencesDataReducer = (state: PreferencesState = INITIAL_STATE, action: SetPreferencesAction): PreferencesState => {
  switch (action.type) {
    case actionTypes.SET_PREFERENCES:
      return {
        ...state,
        data: Array.isArray(action.preferences) ? [...action.preferences] : []
      };
    default:
      return state;
  }
};

// Reducers (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, PreferencesState> =
  (state: AdminPanelStore) => (state[moduleName]: PreferencesState)

const bookingSlotDurationCombiner: (s: PreferencesState) => ?number = s => {
  const settingValue = s.data?.find(setting => setting.key.toLowerCase() === BOOKING_SLOT_DURATION_SETTING_KEY)?.value;
  return settingValue ? Number.parseInt(settingValue, 10) : null;
}

export const selectBookingSlotDuration: OutputSelector<AdminPanelStore, any, ?number> = createSelector(
  stateSelector,
  bookingSlotDurationCombiner
);

// Selectors (up)

export default preferencesDataReducer;