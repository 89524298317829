import { apiUrls } from "./index";

const notesManipulation = (url, method, authData, data) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json ");
  headers.append("Authorization", `Bearer ${authData}`);
  const body = JSON.stringify(data);
  return fetch(url, { method, headers, body }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: "You don’t have enough rights"
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      if (method === "DELETE") {
        return {
          removeNote: 'success',
        }
      } else if(method === 'PUT') {
        return {
          editNote: 'success',
        }
      } else {
        return response.json().then(json => json);
      }
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const addNote = (data, id, authData) =>
  notesManipulation(apiUrls.ADMIN_NOTES(id), "POST", authData, data);

export const editNote = (data, id, authData) =>
  notesManipulation(apiUrls.ADMIN_NOTES(id), "PUT", authData, data);

export const removeNote = (data, id, authData) =>
  notesManipulation(apiUrls.ADMIN_NOTES(id), "DELETE", authData, data);

export const getAllNotes = (id, authData) => 
  notesManipulation(apiUrls.ADMIN_NOTES(id), "GET", authData);