/** @flow */

import { apiUrls } from './index';
import { http } from './fetch-wrapper';
import type { UserActivityGroup, UserActivitySort } from '../actions/userActivity';
import { USER_ACTIVITIES_REQUEST_SIZE } from '../../constants';

export type UserActivityParams = {
  group: UserActivityGroup,
  page?: number,
  size?: number,
  sort: UserActivitySort,
};

export function activity(
  authData: string,
  userId: string,
  params: UserActivityParams,
) {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);

  headers.append('Content-Type', 'application/json');

  const requestOptions = {
    headers,
    method: 'GET',
  };

  const searchParams = new URLSearchParams({
    ...params,
    sort: params.sort.toLowerCase(),
    page: `${params.page || ''}`,
    size: `${params.size || USER_ACTIVITIES_REQUEST_SIZE}`,
    type: 'ACTIVE',
  });

  return http(`${apiUrls.USERS_ACTIVITY(userId)}?${searchParams.toString()}`, requestOptions);
}
