/** @flow */

import { http } from './fetch-wrapper';
import { apiUrls } from './index';

export const getCategoryServices = (authData: string) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);

  const requestOptions = { method: 'GET', headers };

  return http(apiUrls.CATEGORY_SERVICES, requestOptions);
};

export const manageCategoryServices = (authData: string, method, data) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method, headers, body: JSON.stringify(data) };

  return http(apiUrls.CATEGORY_SERVICES, requestOptions);
};
