/** @noflow */

import { v4 } from 'uuid';

import { addWeeks } from 'date-fns';
import { actionTypes } from './index';
import type { Dispatch, ActionType } from '../../types';
import type { AppointmentsDtoType } from '../reducers/appointments';
import { getAppointments, deleteAppointment } from '../api/appointments';
import { apiAddNotification, NOTIFICATION_TYPE } from './api';

const DEFAULT_WEEKS_AHEAD = 2;

function appointmentsFetchSuccess(appointments: AppointmentsDtoType): ActionType<{ appointments: AppointmentsDtoType }> {
  return {
    type: actionTypes.GET_APPOINTMENTS_SUCCESS,
    payload: { appointments },
  };
}

function appointmentsFetchFailure(error: any) {
  return {
    error: error.message,
    type: actionTypes.GET_APPOINTMENTS_FAILURE,
  };
}


export function fetchAppointments(startDate: Date | null, endDate: Date | null): (dispatch: Dispatch, getState: () => any) => Promise<void> {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      dispatch({
        type: actionTypes.GET_APPOINTMENTS_START,
      });

      const { loggedInUser: { userAuth } } = getState();
      const rangeBegin = startDate || new Date();
      const rangeEnd = endDate || addWeeks(rangeBegin, DEFAULT_WEEKS_AHEAD);

      const result: AppointmentsDtoType = await getAppointments(userAuth || '', rangeBegin, rangeEnd);

      dispatch(appointmentsFetchSuccess(result));
    } catch (error) {
      dispatch(appointmentsFetchFailure(error));
    }
  };
}

export function deleteAppointmentAction(id: string, isPro: boolean): (dispatch: Dispatch, getState: () => any) => Promise<void> {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const { loggedInUser: { userAuth }, appointments: { displayRangeStart, displayRangeEnd } } = getState();
      await deleteAppointment(userAuth || '', id, isPro);

      dispatch(apiAddNotification({
        id: v4(),
        message: `The appointment has been successfully cancelled on behalf of the ${isPro ? 'Pro' : 'Client'}`,
        type: NOTIFICATION_TYPE.SUCCESS,
      }));
      
      dispatch(fetchAppointments(displayRangeStart, displayRangeEnd));
    } catch (error) {
      dispatch(apiAddNotification({
        id: v4(),
        message: `The appointment is not cancelled. ${error.message}`,
        type: NOTIFICATION_TYPE.SUCCESS,
      }));
    }
  };
}

export function setAppointmentsDisplayRange(startDate: Date, endDate: Date): ActionType<{ startDate: Date, endDate: Date }> {
  return {
    type: actionTypes.SET_APPOINTMENTS_DISPLAY_RANGE,
    payload: { startDate, endDate },
  };
}
