import React from "react";
import style from "./style.module.css";
import { convertDate, convertDateSafari } from "../../../../helpers/index";

export const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
export const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

class NoteItem extends React.Component {
  render() {
    const { data, removeNote, editNote, userData } = this.props;
    let { createdAt, editedAt } = data;
    const messageNew = new Date(createdAt).getTime() === new Date(editedAt).getTime();
    let date = messageNew ? new Date(createdAt) : new Date(editedAt);
    const idAdmin = userData.id;
    return (
      <div className={style.noteContainer}>
        <div className={style.dateDiv}>
          <p>{isSafari && mobileDevice ? convertDateSafari(date) : convertDate(date)}</p>
          <p>{data.sender.name} {data.sender.lastName}</p>
        </div>
        <div className={style.message}>
          <div>{data.message}</div>
          { !messageNew && <span>last edited on {isSafari && mobileDevice ? convertDateSafari(date) : convertDate(date)}</span>}
        </div>
        <div className={style.buttonSections}>
          {idAdmin === data.sender.id &&
            <div>
              <button className={style.delete} onClick={()=>(removeNote(data.id))}>DELETE NOTE</button>
              <button className={style.edit} onClick={()=>(editNote(data.id, data.message))}>EDIT NOTE</button>
            </div>
          }
        </div>
        <div className={style.hr}></div>
      </div>
    );
  }
}

export default NoteItem;
