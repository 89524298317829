/** @flow */

import moment from 'moment';
import type { RelatedPartnersFilters, UserStatus, DateRangeSearchCriteria } from '../../types';
import { fetchFileBlob } from './fetch-wrapper';
import { apiUrls } from './index';

const invokeCSVExportEndpoint = async (authData: ?string, url: URL, body: ?any, defaultFileName: string): Promise<[Blob, string]> => {
  if (!authData) {
    throw new Error('User authentication is required to call this API');
  }

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  let serializedRequestBody;

  if (typeof body === 'object' && body !== null) {
    headers.append('Content-Type', 'application/json ');
    serializedRequestBody = JSON.stringify(body);
  } else if (typeof body !== 'undefined') {
    serializedRequestBody = String(body);
  }

  const [csvFileBlob, csvFileName] = await fetchFileBlob(url, {
    method: serializedRequestBody ? 'POST' : 'GET',
    body: serializedRequestBody,
    headers, 
  });

  return [csvFileBlob, csvFileName || defaultFileName];
} 


export const exportTransactions = async (authData: string, days: ?number, startDate: ?moment$Moment, endDate: ?moment$Moment): Promise<[Blob, string]> => {
  const url = new URL(apiUrls.GET_TRANSACTIONS_CSV);

  if (typeof days === 'number' && (!Number.isNaN(days))) url.searchParams.append('days', `${days}`);
  if (startDate && moment.isMoment(startDate)) url.searchParams.append('startDate', startDate.toISOString());
  if (endDate && moment.isMoment(endDate)) url.searchParams.append('endDate', endDate.toISOString());

  return invokeCSVExportEndpoint(authData, url, undefined, 'transactions.csv');
}

export const exportCalls = async (authData: string, days: ?number, startDate: ?moment$Moment, endDate: ?moment$Moment): Promise<[Blob, string]> => {
  const url = new URL((apiUrls.GET_CALLS_CSV: string));

  if (typeof days === 'number' && (!Number.isNaN(days))) url.searchParams.append('days', `${days}`);
  if (startDate && moment.isMoment(startDate)) url.searchParams.append('startDate', startDate.toISOString());
  if (endDate && moment.isMoment(endDate)) url.searchParams.append('endDate', endDate.toISOString());

  return invokeCSVExportEndpoint(authData, url, undefined, 'calls.csv');
}

export const exportActiveUsers = async (
  authData: string,
  page: number = 0,
  size: number = 20,
  filter: Array<UserStatus> = [
    'REGISTERED',
    'SUSPENDED',
    'STARTED_PRO_APP',
    'PENDING_APPROVAL',
    'APPROVED_AS_PRO',
    'SUSPENDED_AS_PRO'
  ],
  search: string = '',
  sort: string = '',
  filters: RelatedPartnersFilters = {},
  dateRangeSearchCriteria: DateRangeSearchCriteria
): Promise<[Blob, string]> => {
  const [sortField, sortDirection] = sort.split(',');
  const { relatedPartners: partners = [] } = filters;

  const url = new URL((apiUrls.GET_ACTIVE_USERS_CSV: string));
  const requestBody = {
    search,
    sortDirection: sortDirection ? sortDirection.toUpperCase() : sortDirection,
    pageNumber: page,
    pageSize: size,
    sort: sortField,
    statusList: (filter && filter.length > 0) ? filter : undefined,
    partners: (partners && partners.length > 0) ? partners : undefined,
    ...dateRangeSearchCriteria
  };

  return invokeCSVExportEndpoint(authData, url, requestBody, 'activeUsers.csv');
}

export const exportSectorsCsv = (authData: string) => {
  const url = new URL(apiUrls.GET_CATEGORIES_CSV);
  return invokeCSVExportEndpoint(authData, url, undefined, 'sectors.csv');
}

export const exportNetworks = async (authData: string): Promise<[Blob, string]> => {
  const url = new URL((apiUrls.GET_NETWORKS_CSV: string));

  return invokeCSVExportEndpoint(authData, url, undefined, 'networks.csv');
}
