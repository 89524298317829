/* eslint-disable no-alert */
/** @noflow */

import { v4 } from 'uuid';
import * as api from '../api/user';
import { actionTypes } from './index';
import { INFO_TYPES, LOCAL_STORAGE } from '../../constants';
import { getAllNotes } from './notes';
import { apiAddNotification, NOTIFICATION_TYPE } from './api';
// import { refreshAuthToken } from '../api/user';
import { getSorterForApi } from '../../helpers';

import type { Dispatch, Action, ManageEarningsPayload } from '../../types';

const logInSuccess = response => ({
  type: actionTypes.LOG_IN_SUCCESS,
  userData: response,
  userAuth: response.token.accessToken
});
export const setUserAuth = userAuth => ({
  type: actionTypes.SET_USER_AUTH,
  userAuth
});
const logInFailure = () => ({
  type: actionTypes.LOG_IN_FAILURE
});
const loggedOff = () => ({
  type: actionTypes.LOGGED_OFF
});
const getUsersFailure = message => ({
  type: actionTypes.ERROR_GETTING_USERS,
  message
});
const userLoading = () => ({
  type: actionTypes.LOADING
});
const clearAction = type => ({ type });
const setArrAction = (type, arr, total) => {
  if (total) {
    return { type, arr, total };
  }
  return { type, arr };
};
export const selectUser = user => ({
  type: actionTypes.SELECT_USER,
  user
});

const setCallStatusStart = () => ({
  type: actionTypes.SET_STATUS_CALL_START,
});

const clearUser = () => ({
  type: actionTypes.CLEAR_USER
});
const editUserSuccess = user => ({
  type: actionTypes.EDIT_USER_SUCCESS,
  user
});
const manipulateUserFailure = (message, manipType) => ({
  type: actionTypes.MANIPULATE_USER_FAILURE,
  message,
  manipType
});
const changeAUStatusSuccess = user => ({
  type: actionTypes.CHANGE_A_U_STATUS_SUCCESS,
  user
});
const modifyUserFailure = message => ({
  type: actionTypes.MODIFY_USER_FAILURE,
  message
});
const modifyU = () => ({
  type: actionTypes.START_MODIFY_USER
});
const setUserInfoList = (infoList, infoType) => ({
  type: actionTypes.SET_USER_INFO_LIST,
  infoList,
  infoType
});

const startForceLogOut = () => ({
  type: actionTypes.START_FORCE_LOGOUT
});

const forceLogoutFailure = message => ({
  type: actionTypes.FORCE_LOGOUT_FAILURE,
  message
});

const forceLogoutSucces = () => ({
  type: actionTypes.FORCE_LOGOUT_SUCCESS
});

export const logIn = authData => async dispatch => {
  dispatch(userLoading());
  const response = await api.logIn(authData);
  if (response.error) {
    dispatch(logInFailure());
  } else {
    dispatch(logInSuccess(response));

    localStorage.setItem(LOCAL_STORAGE.USER_AUTH, response.token.accessToken);
    localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, response.token.refreshToken);
  }
};

export const logOff = () => dispatch => {
  dispatch(loggedOff());

  localStorage.removeItem(LOCAL_STORAGE.USER_AUTH);
  localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
};

export const clearLoading = () => dispatch => {
  dispatch(loggedOff());
};

export const getLoggedInUserData = (userAuth, refreshToken) => async dispatch => {
  try{
    dispatch(userLoading());
    const response = await api.refreshAuthToken(userAuth, refreshToken);

    localStorage.setItem(LOCAL_STORAGE.USER_AUTH, response.token.accessToken);
    localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, response.token.refreshToken);   
    dispatch(logInSuccess(response));
  } catch(e) {
    dispatch(apiAddNotification({
      id: v4(),
      type: NOTIFICATION_TYPE.ERROR,
      message: 'Cannot refresh token. Login again'
    }));

    dispatch(logOff());
  }
};

export const clearActiveUsers = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_ACTIVE_USERS));
};

export const getActiveUsers = (
  authData,
  page,
  size,
  filter,
  search,
  sort = '',
  filters = {},
  dateRangeCriteria
) => async dispatch => {
  try {
    dispatch(clearActiveUsers()); // when updating starts (for spinner)
    const response = await api.getActiveUsers(authData, page, size, filter, search, getSorterForApi(sort), filters, dateRangeCriteria);
    dispatch(
      setArrAction(actionTypes.SET_ACTIVE_USERS, response.results, response.total)
    );
  } catch(e) {
    dispatch(getUsersFailure(e.message));
  }
};

export const clearArchivedUsers = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_ARCHIVED_USERS));
};

export const clearIncompleteUsers = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_INCOMPLETE_USERS));
};

export const getIncompleteUsers = authData => async dispatch => {
  dispatch(clearIncompleteUsers());
  const response = await api.getIncompleteUsers(authData);
  if (response.error) {
    dispatch(getUsersFailure(response.message));
  } else {
    dispatch(setArrAction(actionTypes.SET_INCOMPLETE_USERS, response));
  }
};

export const clearInvites = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_INVITES));
};

export const getInvites = authData => async dispatch => {
  dispatch(clearInvites());
  const response = await api.getInvites(authData);
  if (response.error) {
    dispatch(getUsersFailure(response.message));
  } else {
    dispatch(setArrAction(actionTypes.SET_INVITES, response));
  }
};

export const clearCalls = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_CALLS));
};

export const getCalls = (
  authData,
  start = '',
  end = '',
  days = ''
) => async dispatch => {
  const queryArr = [];

  if (start) {
    queryArr.push({
      name: 'startDate',
      value: start.toISOString(),
    });
  }
  if (end) {
    queryArr.push({
      name: 'endDate',
      value: end.toISOString(),
    });
  }

  if (days) {
    queryArr.push({
      name: 'days',
      value: days
    });
  }

  dispatch(clearCalls());

  const response = await api.getCalls(authData, queryArr);

  if (response.error) {
    dispatch(getUsersFailure(response.message));
  } else {
    dispatch(setArrAction(actionTypes.SET_CALLS, response.results));
  }
};

export const clearTransactions = () => dispatch => {
  dispatch(clearAction(actionTypes.CLEAR_TRANSACTIONS));
};

export const getTransactions = (
  authData,
  start = '',
  end = '',
  days = ''
) => async dispatch => {
  const queryArr = [];

  if (start) {
    queryArr.push({
      name: 'startDate',
      value: start.toISOString(),
    });
  }
  if (end) {
    queryArr.push({
      name: 'endDate',
      value: end.toISOString(),
    });
  }
  if (days) {
    queryArr.push({
      name: 'days',
      value: days
    });
  }

  dispatch(clearTransactions());
  const response = await api.getTransactions(authData, queryArr);

  if (response.error) {
    dispatch(getUsersFailure(response.message));
  } else {
    dispatch(setArrAction(actionTypes.SET_TRANSACTIONS, response.results));
  }
};

export const clearSelectedUser = () => dispatch => {
  dispatch(clearUser());
};

export const chooseSelectedUser = (userId, authData) => async dispatch => {
  try {
    dispatch(clearUser());
    const { getUserInfo, getUserAvailability } = api;

    const userInfo = await getUserInfo(userId, authData);
    if(!userInfo.pro) {
      dispatch(selectUser(userInfo));
      return;
    }

    const availability = await getUserAvailability(userId, authData);
    const proUserInfo = { ...userInfo, availability };
    dispatch(selectUser(proUserInfo));
  } catch(e) {
    dispatch(manipulateUserFailure(e.message, 'get'));
  }
};

export const setCallStatus = (authData, data) => async dispatch => {
  dispatch(setCallStatusStart());
  const response = await api.setCallStatus(authData, data, 'PUT');
  if (response.error) {
    dispatch(apiAddNotification({
      id: v4(),
      message: response.message,
      type: NOTIFICATION_TYPE.ERROR,
    }));
  } else {
    dispatch(apiAddNotification({
      id: v4(),
      message: `Call with id: ${response.id} was ended successful`,
      type: NOTIFICATION_TYPE.SUCCESS,
    }));
  }
};

export const restoreUser = (id, authData) => async dispatch => {
  const response = await api.deleteUser(id, authData, false);

  if (response.error) {
    dispatch(manipulateUserFailure(response.message, 'delete'));
  } else {
    dispatch(clearUser());
  }
  return response;
};

export const deleteUser = (id, authData) => async dispatch => {
  const response = await api.deleteUser(id, authData, true);

  if (response.error) {
    dispatch(manipulateUserFailure(response.message, 'delete'));
  } else {
    dispatch(clearUser());
  }
  return response;
};

export const editUser = (
  data,
  authData,
  updateAU = true
) => async dispatch => {
  const { id } = data;
  if (data.serviceName && data.serviceName === 'Other') {
    data.pro.service = 'other';
  }
  if (data.pro && data.pro.service === 'other') {
    data.pro.service = data.pro.serviceOther;
  }
  dispatch(modifyU());
  const response = await api.editUser(data, id, authData);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(manipulateUserFailure(response.message, 'edit'));
  } else {
    dispatch(editUserSuccess(response));
    if (updateAU) {
      dispatch(getActiveUsers(authData));
    }
  }
};

export const changeActiveUserStatus = (
  id,
  value,
  authData,
  updateAU = true
) => async dispatch => {
  dispatch(modifyU());
  const response = await api.changeActiveUserStatus(id, authData, value);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(
      modifyUserFailure(`${response.message} (Error in changing user status)`)
    );
  } else {
    dispatch(changeAUStatusSuccess(response));
    if (updateAU) {
      dispatch(getActiveUsers(authData));
    }
  }
};

export const changeProfileHidden = (
  id,
  value,
  authData,
  updateAU = true
) => async dispatch => {
  dispatch(modifyU());
  const response = await api.changeProfileHidden(id, authData, value);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(
      modifyUserFailure(`${response.message} (Error in changing user status)`)
    );
  } else {
    dispatch(changeAUStatusSuccess(response));
    if (updateAU) {
      dispatch(getActiveUsers(authData));
    }
  }
};

export const changeProStatus = (
  id,
  value,
  authData,
  updateAU = true
) => async dispatch => {
  dispatch(modifyU());
  const response = await api.changeProStatus(id, authData, value);
  if (response.error) {
    dispatch(
      modifyUserFailure(`${response.message} (Error in changing PRO status)`)
    );
  } else {
    dispatch(changeAUStatusSuccess(response));
    if (updateAU) {
      dispatch(getActiveUsers(authData));
    }
  }
};

export const changeUserStatus = (
  id,
  value,
  authData,
  updateAU = true
) => async dispatch => {
  dispatch(modifyU());
  const response = await api.chUserStatus(id, authData, value);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(
      modifyUserFailure(`${response.message} (Error in changing PRO status)`)
    );
  } else {
    dispatch(changeAUStatusSuccess(response));
    if (updateAU) {
      dispatch(getActiveUsers(authData));
    }
  }
};

export const getUsActivities = (id, authData) => async dispatch => {
  dispatch(modifyU());
  const response = await api.getUsActivities(id, authData);
  if (response.error) {
    dispatch(
      modifyUserFailure(
        `${response.message
        } (Error in getting ${INFO_TYPES.ACTIVITIES.toLowerCase()})`
      )
    );
  } else {
    dispatch(setUserInfoList(response.results, INFO_TYPES.ACTIVITIES));
  }
};
export const getUsClients = (user, authData) => async dispatch => {
  dispatch(modifyU());
  const response = await api.getUsClient(user.id, authData);
  if (response.error) {
    dispatch(
      modifyUserFailure(
        `${response.message
        } (Error in getting ${INFO_TYPES.CLIENTS.toLowerCase()})`
      )
    );
  } else {
    dispatch(setUserInfoList(response.results, INFO_TYPES.CLIENTS));
  }
};
export const getUsProsList = (id, authData) => async dispatch => {
  dispatch(modifyU());
  const response = await api.getUsProsList(id, authData);
  if (response.error) {
    dispatch(
      modifyUserFailure(`${response.message} (Error in getting list of pros)`)
    );
  } else {
    dispatch(setUserInfoList(response.results || [], INFO_TYPES.LIST_OF_PROS));
  }
};
export const getUsMoney = (id, page, size, authData) => async dispatch => {
  dispatch(modifyU());
  const response = await api.getUsMoney(id, page, size, authData);

  if (response.error) {
    dispatch(
      modifyUserFailure(`${response.message} (Error in getting list of money)`)
    );
  } else {
    dispatch(setUserInfoList(response, INFO_TYPES.MONEY));
  }
};

export const addFreeCredits = (method, authData, data) => async dispatch => {
  dispatch({ type: actionTypes.ADD_CREDITS_START });
  const response = await api.manageFreeCredits(method, authData, data);

  if (response.error) {
    dispatch({
      type: actionTypes.ADD_CREDITS_FAILURE,
      message: response.message
    });
  } else {
    dispatch({
      type: actionTypes.ADD_CREDITS_SUCCESS,
      message: response.message || 'Ok'
    });
  }
};
export const deleteFreeCredits = (method, authData, data) => async dispatch => {
  dispatch({ type: actionTypes.DELETE_CREDITS_START });
  const response = await api.manageFreeCredits(method, authData, data);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch({
      type: actionTypes.DELETE_CREDITS_FAILURE,
      message: response.message
    });
  } else {
    dispatch({
      type: actionTypes.DELETE_CREDITS_SUCCESS,
      message: response.message || 'Ok'
    });
  }
};

export const addProEarnings = (method: string, authData: string, data: ManageEarningsPayload): Dispatch => async dispatch => {
  dispatch(({ type: actionTypes.ADD_EARNINGS_START }: Action));
  const response = await api.manageProEarnings(method, authData, data);

  if (response.error) {
    dispatch(({
      type: actionTypes.ADD_EARNINGS_FAILURE,
      message: response.message
    }: Action));
  } else {
    dispatch(({
      type: actionTypes.ADD_EARNINGS_SUCCESS,
      message: response.message || 'Ok'
    }: Action));
  }
};

export const deleteProEarnings = (method: string, authData: string, data: ManageEarningsPayload): Dispatch => async dispatch => {
  dispatch(({ type: actionTypes.DELETE_EARNINGS_START }: Action));
  const response = await api.manageProEarnings(method, authData, data);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(({
      type: actionTypes.DELETE_EARNINGS_FAILURE,
      message: response.message
    }: Action));
  } else {
    dispatch(({
      type: actionTypes.DELETE_EARNINGS_SUCCESS,
      message: response.message || 'Ok'
    }: Action));
  }
};

export const getAllFeaturedServicesByUser = (data, authData) => async dispatch => {
  dispatch({ type: actionTypes.GET_USER_FEATURED_SERVICES_START });
  const response = await api.getAllFeaturedServicesByUser(data, authData);
  if (response.error) {
    dispatch({
      type: actionTypes.GET_USER_FEATURED_SERVICES_FAILURE,
      message: response.message,
    });
  } else {
    dispatch({ type: actionTypes.GET_USER_FEATURED_SERVICES_SUCCESS, featuredServices: response });
    dispatch(getAllNotes(data.userId, authData));
  }
};

export const setFeaturedServiceByUser = (data, authData) => async dispatch => {
  dispatch({ type: actionTypes.SET_USER_FEATURED_SERVICES_START });
  const response = await api.setFeaturedServiceByUser(data, authData);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch({
      type: actionTypes.SET_USER_FEATURED_SERVICES_FAILURE,
      message: response.message,
    });
  } else {
    dispatch({ type: actionTypes.SET_USER_FEATURED_SERVICES_SUCCESS });
    dispatch(getAllFeaturedServicesByUser(data, authData));
  }
};

export const forceLogOut = (id: number, authData: string) => async dispatch => {
  dispatch(startForceLogOut());
  const response = await api.logoutFromAllDevices(id, authData);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(
      forceLogoutFailure(`${response.message} (Error in logging out the user from all of their devices)`)
    );
  } else {
    dispatch(forceLogoutSucces());
  }
};

export const updateUserStateSuccess = (status: UserState) => ({
  type: actionTypes.UPDATE_USER_STATE, 
  payload: status
});

export const updateUserState = (data: UpdateUserStateData, id: UserId, authData: string) => async dispatch => {  
  const response = await api.updateUserState(data, id, authData);

  if (response.error) {
    alert(response.message);
    return;
  }

  dispatch(updateUserStateSuccess(response));
};
