/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import { actionTypes } from '../actions';
import type {
  AdminPanelStore,
  SelectedUserState,
  UserArraysState,
  UserArraysAction,
  SelectedUserAction,
  SelectedUserCreditsState,
  SelectedUserEarningsState,
  UserWithStatus
 } from '../../types';
 import { selectBookingSlotDuration } from './preferences';

// Constants (down)

const INITIAL_STATE: UserArraysState = { error: false, load: false }; 

// Constants (up)

// Reducer (down)

export const usersArrays = (state: UserArraysState = INITIAL_STATE, action: UserArraysAction): UserArraysState => {
  const setFields = {
    error: false,
    load: true
  };

  const clearFields = {
    error: false,
    load: false
  };
  const totalMesSent = action.arr ? action.arr.results ? action.arr.results.reduce((prevVal, el) => prevVal + el.totalTextOutgoing, 0) : action.arr.reduce((prevVal, el) => prevVal + el.totalTextOutgoing, 0) : null;
  switch (action.type) {
    case actionTypes.SET_ACTIVE_USERS: {
      const activeUsers = typeof action.arr !== 'undefined'
      ? action.arr.results ? action.arr.results : action.arr
      : [];

      return {
        ...state,
        ...setFields,
        totalMesSent,
        total: action.total,
        activeUsers: activeUsers.map(activeUser => ({
          ...activeUser,
          lastDevice: activeUser.devices ? activeUser.devices[0] : {},
        })),
      };
    }
    case actionTypes.CLEAR_ACTIVE_USERS:
      return {
        ...state,
        ...clearFields,
        activeUsers: []
      };
    case actionTypes.SET_ARCHIVED_USERS: {
      const archivedUsers = typeof action.arr !== 'undefined'
       ? action.arr.results ? action.arr.results : action.arr
       : [];

      return {
        ...state,
        ...setFields,
        total: action.total,
        archivedUsers: archivedUsers.map(archivedUser => ({
          ...archivedUser,
          lastDevice: archivedUser.devices ? archivedUser.devices[0] : {},
        }))
      };
    }
    case actionTypes.CLEAR_ARCHIVED_USERS:
      return {
        ...state,
        ...clearFields,
        archivedUsers: []
      };
    case actionTypes.SET_INCOMPLETE_USERS:
      return {
        ...state,
        ...setFields,
        incomplUsers: Array.isArray(action.arr) ? action.arr : []
      };
    case actionTypes.CLEAR_INCOMPLETE_USERS:
      return {
        ...state,
        ...clearFields,
        incomplUsers: []
      };
    case actionTypes.SET_INVITES:
      return {
        ...state,
        ...setFields,
        invites: Array.isArray(action.arr) ? action.arr : []
      };
    case actionTypes.CLEAR_INVITES:
      return {
        ...state,
        ...clearFields,
        invites: []
      };
    case actionTypes.SET_CALLS:
      return {
        ...state,
        ...setFields,
        calls: Array.isArray(action.arr) ? action.arr : []
      };
    case actionTypes.CLEAR_CALLS:
      return {
        ...state,
        ...clearFields,
        calls: []
      };
    case actionTypes.SET_STATUS_CALL_SUCCESS:
      return {
        statusCall: action.status
      }
    case actionTypes.SET_TRANSACTIONS:
      return {
        ...state,
        ...setFields,
        transactions: Array.isArray(action.arr) ? action.arr : []
      };
    case actionTypes.CLEAR_TRANSACTIONS:
      return {
        ...state,
        ...clearFields,
        transactions: []
      };
    case actionTypes.ERROR_GETTING_USERS:
      return {
        ...state,
        error: true,
        load: true,
        message: action.message
      };
    default:
      return state;
  }
};

export const selectedUserReducer = (state: SelectedUserState = { error: false }, action: SelectedUserAction): SelectedUserState => {
  switch (action.type) {
    case actionTypes.SELECT_USER:
      return {
        ...state,
        selectedUser: action.user,
        error: false,
        message: ''
      };
    case actionTypes.CLEAR_USER:
      return {
        ...state,
        error: false,
        modifyErr: false,
        selectedUser: null,
        message: '',
        modifyMsg: '',
        infoList: [],
        infoType: '',
        credits: {},
        userFeateuredServices: null
      };
    case actionTypes.START_MODIFY_USER:
      return {
        ...state,
        modifyErr: false,
        modifyMsg: ``,
        isModifying: true,
        infoList: [],
        infoType: ''
      };
    case actionTypes.UPDATE_USER_STATE:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          state: action.payload
        },
        error: false,
        message: ''
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.user,
        error: false,
        message: '',
        isModifying: false
      };
    case actionTypes.MANIPULATE_USER_FAILURE:
      return {
        ...state,
        error: true,
        message: `${action.message ? action.message : ''}
					${action.manipType ? `(${action.manipType} user failure)` : ''}`
      };
    case actionTypes.CHANGE_A_U_STATUS_SUCCESS:
      return {
        ...state,
        selectedUser: action.user,
        isModifying: false,
        modifyMsg: `User status is changed`
      };
    case actionTypes.MODIFY_USER_FAILURE:
      return {
        ...state,
        modifyErr: true,
        modifyMsg: `${action.message ? action.message : ''}`,
        isModifying: false
      };
    case actionTypes.RESTORE_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.user,
        isModifying: false,
        modifyMsg: `User is restored`
      };
    case actionTypes.SET_USER_INFO_LIST:
      return {
        ...state,
        isModifying: false,
        infoList: action.infoList,
        infoType: action.infoType
      };
    case actionTypes.ADD_CREDITS_START:
      return {
        ...state,
        credits: { isProcessing: true }
      };
    case actionTypes.ADD_CREDITS_SUCCESS:
      return {
        ...state,
        credits: {
          isProcessing: false,
          message: action.message || '',
        }
      };
    case actionTypes.ADD_CREDITS_FAILURE:
      return {
        ...state,
        credits: { isProcessing: false, errorMsg: `Error! ${action.message || 'Unknown error while adding credits'}` }
      };
    case actionTypes.DELETE_CREDITS_START:
      return {
        ...state,
        credits: { isProcessing: true }
      };
    case actionTypes.DELETE_CREDITS_SUCCESS:
      return {
        ...state,
        credits: {
          isProcessing: false,
          message: action.message || '',
        }
      };
    case actionTypes.DELETE_CREDITS_FAILURE:
      return {
        ...state,
        credits: { isProcessing: false, errorMsg: `Error! ${action.message || 'Unknown error while deleting credits'}` }
      };
    case actionTypes.ADD_EARNINGS_START:
    case actionTypes.DELETE_EARNINGS_START:
      return {
        ...state,
        earnings: { isProcessing: true }
      };
    case actionTypes.ADD_EARNINGS_SUCCESS:
    case actionTypes.DELETE_EARNINGS_SUCCESS:
      return {
        ...state,
        earnings: {
          isProcessing: false,
          message: action.message || '',
        }
      };
    case actionTypes.ADD_EARNINGS_FAILURE:
    case actionTypes.DELETE_EARNINGS_FAILURE:
      return {
        ...state,
        earnings: { 
          isProcessing: false,
          errorMsg: `Error! ${action.message || 'Unknown error while deleting earnings'}` 
        }
      };
    case actionTypes.GET_USER_FEATURED_SERVICES_START:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.GET_USER_FEATURED_SERVICES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        userFeateuredServices: action.featuredServices,
      };
    case actionTypes.GET_USER_FEATURED_SERVICES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        message: action.message,
      };
    case actionTypes.SET_USER_FEATURED_SERVICES_START:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.SET_USER_FEATURED_SERVICES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case actionTypes.SET_USER_FEATURED_SERVICES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        message: action.message,
      };
    case actionTypes.START_FORCE_LOGOUT:
      return {
        ...state,
        modifyErr: false,
        modifyMsg: ``,
        isModifying: true,
      };
    case actionTypes.FORCE_LOGOUT_SUCCESS:
      return {
        ...state,
        isModifying: false,
        modifyMsg: `The user has been logged out from all of their devices`
      };
    case actionTypes.FORCE_LOGOUT_FAILURE:
      return {
        ...state,
        modifyErr: true,
        modifyMsg: `${action.message ? action.message : ''}`,
        isModifying: false
      };
    default:
      return state;
  }
};

// Reducer (up)

// Selectors (down)

const selectedUserStateSelector: InputSelector<AdminPanelStore, void, SelectedUserState> =
  (state: AdminPanelStore) => state.selectedUser;

const activeUserCombiner: (s: SelectedUserState) => ?UserWithStatus = s =>
  s.selectedUser;
const useAvailabilityScheduleCombiner: (u: ?UserWithStatus) => ?boolean = u =>
  u?.pro?.proState?.proUseAvailability;
const acceptBookingsCombiner: (u: ?UserWithStatus) => ?boolean = u =>
  u?.pro?.proState?.proBookingAccept;
const hideProfileCombiner: (u: ?UserWithStatus) => ?boolean = u =>
  u?.pro?.proState?.proHideProfile;
const minBookingSlotsCombiner: (u: ?UserWithStatus) => ?number = u =>
  u?.pro?.minBookingSlots;
const creditsCombiner: (s: SelectedUserState) => ?SelectedUserCreditsState = s =>
  s.credits;
const earningsCombiner: (s: SelectedUserState) => ?SelectedUserEarningsState = s =>
  s.earnings;

export const selectActiveUser: OutputSelector<AdminPanelStore, void, ?UserWithStatus> = 
  createSelector(selectedUserStateSelector, activeUserCombiner);

export const selectUseAvailabilitySchedule: OutputSelector<AdminPanelStore, void, ?boolean> = 
  createSelector(selectActiveUser, useAvailabilityScheduleCombiner);
export const selectAcceptBookings: OutputSelector<AdminPanelStore, void, ?boolean> = 
  createSelector(selectActiveUser, acceptBookingsCombiner);
export const selectHideProfile: OutputSelector<AdminPanelStore, void, ?boolean> = 
  createSelector(selectActiveUser, hideProfileCombiner);
export const selectCredits: OutputSelector<AdminPanelStore, void, ?SelectedUserCreditsState> = 
  createSelector(selectedUserStateSelector, creditsCombiner);
export const selectEarnings: OutputSelector<AdminPanelStore, void, ?SelectedUserEarningsState> = 
  createSelector(selectedUserStateSelector, earningsCombiner);

const seleсtMinBookingSlots: OutputSelector<AdminPanelStore, void, ?number> = 
  createSelector(selectActiveUser, minBookingSlotsCombiner);

const minimumBookingDurationCombiner: (
  minBookingSlots: ?number,
  bookingSlotDuration: ?number
) => ?number = (minBookingSlots, bookingSlotDuration) => 
    typeof minBookingSlots === 'number' &&
    typeof bookingSlotDuration === 'number' &&
    Number.isFinite(minBookingSlots) &&
    Number.isFinite(bookingSlotDuration)
      ? minBookingSlots * bookingSlotDuration
      : null


export const selectMinimumBookingDuration: OutputSelector<AdminPanelStore, void, ?number> = 
  createSelector(seleсtMinBookingSlots, selectBookingSlotDuration, minimumBookingDurationCombiner)


// Selectors (up)