/** @flow */

import memoizeOne from 'memoize-one';

export type TableSearchType = "pro" | "client";

export type RowWithClientProFieldsType = {
  proEmail: string,
  proFullName: string,
  proId: string,
  clientEmail: string,
  clientFullName: string,
  clientId: string
};

export type ClientProFieldsFilterFuncType = <T>(rows: $ReadOnlyArray<T>, value: string) => Array<T>;

export type ClientProFieldsExtractorFuncType<T> = (item: T) => RowWithClientProFieldsType;

const equalityFn = (newArgs: any[], prevArgs: any[]) => {
  const [newSearchString1, newSearchString2, newSearchString3, newSearchString4, newFilteringArray] = newArgs;
  const [prevSearchString1, prevSearchString2, prevSearchString3, prevSearchString4, prevFilteringArray] = prevArgs;
  return (
    newSearchString1 === prevSearchString1 &&
    newSearchString2 === prevSearchString2 &&
    newSearchString3 === prevSearchString3 &&
    newSearchString4 === prevSearchString4 &&
    newFilteringArray === prevFilteringArray
  );
};

function createFilterByFieldType<T>(
  type: TableSearchType,
  extractor: ?ClientProFieldsExtractorFuncType<T>
): (rows: $ReadOnlyArray<T>, value: string) => Array<T> {
  return (rows: $ReadOnlyArray<T>, value: string) =>
    rows.filter(item => {
      const { proEmail = '', proFullName = '', proId = '', clientEmail = '', clientFullName = '', clientId = '', relatedPartner = '' } =
        extractor && typeof extractor === 'function' ? extractor(item) : ((item: any): RowWithClientProFieldsType); // If the caller does not pass an extractor func, we assume that the item itself contains the necessary fields.
      
      switch (type) {
        case 'pro':
          return (
            proEmail.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            proFullName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            String(proId)
              .toLowerCase()
              .indexOf(value.toLowerCase()) !== -1
          );
        case 'client':
          return (
            clientEmail.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            clientFullName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            String(clientId)
              .toLowerCase()
              .indexOf(value.toLowerCase()) !== -1
          );
        case 'partner':
          return (
            relatedPartner.toLowerCase().indexOf(value.toLowerCase()) !== -1
          );
        default:
          return item;
      }
    });
}

function getSearchResults<T>(
  proSearch: string,
  clientSearch: string,
  relatedPartnerSearch: string,
  searchedItems: Array<T> = [],
  extractor: ?ClientProFieldsExtractorFuncType<T>
): Array<T> {
  const proFieldsSearchResults = createFilterByFieldType<T>('pro', extractor)(searchedItems, proSearch);
  const clientFieldsSearchResults = createFilterByFieldType<T>('client', extractor)(proFieldsSearchResults, clientSearch);
  const partnerFieldSearchResult = createFilterByFieldType('partner', extractor)(clientFieldsSearchResults, relatedPartnerSearch);  

  return partnerFieldSearchResult
}

export default (memoizeOne(getSearchResults, equalityFn): <T>(proSearch: string, clientSearch: string, items: Array<T>, extractor: ?ClientProFieldsExtractorFuncType<T>) => Array<T>);
