import PropTypes from 'prop-types';
import React from 'react';
import style from './style.module.css';

const BalanceItem = ({ balance, text }) => {
  const balanceArr = balance.toString().split('.');

  return (
    <div className={style.balanceItem}>
      <div className={style.balanceAmount}>
        <span style={{ fontSize: '36px' }}>£{balanceArr[0]}</span>.
        {balanceArr[1] ? balanceArr[1].padEnd(2, '0') : '0'.padEnd(2, '0')}
      </div>
      <div className={style.balanceText}>{text}</div>
    </div>
  );
};

BalanceItem.propTypes = {
  balance: PropTypes.string,
  text: PropTypes.string.isRequired,
};

BalanceItem.defaultProps = {
  balance: '',
};

export default BalanceItem;
