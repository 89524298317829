/** @flow */

import { Store } from 'redux';
import { createSelector } from 'reselect';
import type { ActionType, ConfigData } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'configData';

// Constants (up)

// Reducer (down)

type ConfigDataState = {
  configData: null | ConfigData,
  isConfigLoadingError: boolean,
};

const initialState: ConfigDataState = {
  configData: null,
  isConfigLoadingError: false
};

export default function reducer(
  state: CallsState = initialState, action: ActionType<>,
): CallsState {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_CONFIG_DATA: {
      return {
        ...state,
        configData: payload,
      };
    }

    case actionTypes.SET_CONFIG_ERROR: {
      return {
        ...state,
        isConfigLoadingError: true,
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector = (state: Store) => state[moduleName];

export const selectConfigData = createSelector(
  stateSelector, (config: CallsState): string => config.configData,
);

export const selectIsConfigLoadingError = createSelector(
  stateSelector, (config: CallsState): string => config.isConfigLoadingError,
);

// Selectors (up)
