export const ROUTES = {
  ALL: '*',
  HOME: '/',
  PRO_REQUESTS: '/pro-requests',
  PRO_REQUEST: '/pro-request',
  PRO_REQUEST_ID: '/pro-request/:id',
  WITHDRAWALS: '/withdrawals',
  ACTIVE_USERS: '/users-active',
  USER: '/user',
  USER_ID: '/user/:id',
  ARCHIVED_USER: '/user-archived',
  ARCHIVED_USER_ID: '/user-archived/:id',
  INCOMPLETE_REGISTRATIONS: '/users-incomplete',
  TRANSACTIONS: '/transactions',
  INVITES: '/invites',
  CALLS: '/calls',
  SETTINGS: '/settings',
  FINANCES: '/finances',
  COUNTRIES: '/countries',
  LOGIN: '/log-in',
  FEATURED_SERVICES: '/featured_services',
  SECTORS: '/sectors',
  PARTNERS: '/partners',
  APPOINTMENTS: '/appointments',
};

export const PRIVATE = 'private';
export const PUBLIC = 'public';
export const PRIVATE_AND_PUBLIC = 'private_public';
