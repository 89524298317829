/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type { ActionType, PartnerType, PartnersState, AdminPanelStore } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'partners';

// Constants (up)

// Reducer (down)

const initialState: PartnersState = {
  fetching: false,
  error: '',
  entities: [],
  failedEmails: null,
};

export default function reducer(state: PartnersState = initialState, action: ActionType<>): PartnersState {
  const { error, type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_PARTNERS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: initialState.error,
        entities: initialState.entities,
      };

    case actionTypes.FETCH_PARTNERS_SUCCESS: {
      if (!payload) {
        return { ...state };
      }

      const { partners }: { partners: PartnerType[] } = payload;

      return {
        ...state,
        fetching: false,
        entities: partners,
      };
    }

    case actionTypes.FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: error || 'Cannot fetch partners list! Try again later',
        entities: initialState.entities,
      };

    case actionTypes.DELETE_PARTNERS_FAILURE:
      return {
        ...state,
        error: error || 'Cannot delete partner(s)! Try again later',
      };

    case actionTypes.SET_FAILED_AUTOLINKING_EMAILS:
      return {
        ...state,
        failedEmails: payload.failedEmails,
        error
      };
    
    case actionTypes.CLEANUP_PARTNERS_FAILURE:
      return {
        ...state,
        error: '',
      };

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, PartnersState> = (s: AdminPanelStore, _props: any): PartnersState => s[moduleName];

const isFetchingCombiner: (s: PartnersState) => boolean = (s: PartnersState): boolean => s.fetching;
const entitiesCombiner: (s: PartnersState) => PartnerType[] = (s: PartnersState): PartnerType[] => s.entities;
const errorCombiner: (s: PartnersState) => string = (s: PartnersState): string => s.error;

export const selectPartnersIsFetching: OutputSelector<AdminPanelStore, any, boolean>  = createSelector(stateSelector, isFetchingCombiner);
export const selectPartnersEntities: OutputSelector<AdminPanelStore, any, PartnerType[]> = createSelector(stateSelector, entitiesCombiner);
export const selectPartnersError: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, errorCombiner);

// Selectors (up)
