import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from './store/index';

import App from './components/app';
import { history } from './store/reducers';
import theme from './style/theme';
import Internationalization from './i18n/internationalization';

const Application = () => (
  <Provider store={configureStore({})}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <Internationalization>
          <CssBaseline />
          <App />
        </Internationalization>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

export default Application;
