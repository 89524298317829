import React, {useState} from "react";
import { Input, Button } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';

import style from "./style.module.css";

const { Password } = Input;

const LogInForm = ({logIn, loggedInUser}) => {
  const EMAIL = "email";
  const PASSWORD = "password";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogIn = () => {
    logIn(window.btoa(`${email}:${password}`));
  };

  const handleKeyPress = e => e.key === "Enter" && handleLogIn();

  const handleOnChange = e => {
    const { name, value } = e.target;
    if (name === EMAIL) {
      setEmail(value);
    } else if (name === PASSWORD) {
      setPassword(value);
    }
  };

  return (
    <div className={style.logInForm}>
      {loggedInUser && loggedInUser.logInFailure && (
        <div className={style.failure}>
          Sorry, your login details are not correct
        </div>
      )}

      <div className={style.formRow}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={EMAIL}>
          <span className={style.label}>Email:</span>
          <Input
            id={EMAIL}
            name={EMAIL}
            size="large"
            value={email}
            className={style.customInput}
            onChange={handleOnChange}
            placeholder="Enter your username"
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </label>
      </div>
      <div className={style.formRow}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={PASSWORD}>
          <span className={style.label}>Password</span>
          <Password
            id={PASSWORD}
            name={PASSWORD}
            size="large"
            className={style.customInput}
            prefix={<KeyOutlined className="site-form-item-icon" />}
            onKeyPress={handleKeyPress}
            value={password}
            onChange={handleOnChange}
          />
        </label>
      </div>
      <Button className={style.customButton} type="primary" size="large" onClick={handleLogIn}>Log in</Button>
    </div>
  );
};

export default LogInForm;
