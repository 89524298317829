/** @flow */

import React from 'react';
import { Popover } from 'antd';

type UserDevice = {
  activeNow: boolean,
  agent: string,
  agentVersion: string,
  appVersion: string,
  device: string,
  id: number,
  lastActive: string,
  locale: string,
  msLastActive: number,
  msUpdateTime: number,
  type: string,
  updateTime: string
}

type UserDevicesPopoverProps = {
  latestDevice: UserDevice,
  userDevices: Array<UserDevice>
}

const popoverContentStyle = {
  'whiteSpace': 'pre'
};

const copyAllDevicesToClipboard = (allDevices: string): void => {
  navigator.clipboard.writeText(allDevices);
}

const makeOneLineDescriptionOfTheLatestDevice = (latestDevice: UserDevice): string => 
  (latestDevice && `${latestDevice.agent || ''}${latestDevice.agentVersion ? ` | ${latestDevice.agentVersion}` : ''}`) || '-';

const isDeviceNotNull = device => device !== null && device !== undefined

const makeDescriptionOfAllUserDevices = (devices: Array<UserDevice>): string => 
  devices.filter(isDeviceNotNull).map(makeOneLineDescriptionOfTheLatestDevice).join('\n');

const latestDeviceOnClickHandler = devicesSummary => e => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  copyAllDevicesToClipboard(devicesSummary);
}

function UserDevicesPopover(props: UserDevicesPopoverProps) {
  const { latestDevice, userDevices } = props;

  const allDevices = userDevices ? makeDescriptionOfAllUserDevices(userDevices) : '';
  const latestDeviceDescription = latestDevice ? makeOneLineDescriptionOfTheLatestDevice(latestDevice) : '';

  return (
    <Popover
      content={<div style={popoverContentStyle}>{allDevices}</div>}
      title="User Devices"
    >
      <span onClick={latestDeviceOnClickHandler(allDevices)}>{latestDeviceDescription}</span>
    </Popover>
  );
}

export default UserDevicesPopover;