import { actionTypes } from '../actions';

export const notes = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTE_START:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.ADD_NOTE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: 'success adding note',
      };
    case actionTypes.ADD_NOTE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };

    case actionTypes.REMOVE_NOTE_START:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.REMOVE_NOTE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: 'success remove note',
      };
    case actionTypes.REMOVE_NOTE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };
    
    case actionTypes.EDIT_NOTE_START:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
        message: '',
      };
    case actionTypes.EDIT_NOTE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: 'success editing note',
      };
    case actionTypes.EDIT_NOTE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        message: action.message,
      };
    case actionTypes.GET_NOTES_START:
      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    case actionTypes.GET_NOTES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        notes: action.notes,
      };
    case actionTypes.GET_NOTES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};