/** @flow */

import type { UserId } from '../../types';

export const apiRoot: string = process.env.REACT_APP_API_ROOT || '';
// for main server
// export const apiRoot = 'https://telmie.com/api/';

export const apiAdminRoot = `${apiRoot}admin/`;

export const apiUrls: { [key: string]: any } = Object.freeze({
  CATEGORY_SERVICES: `${apiAdminRoot}category-services`,
  FEATURED_SERVICERS: `${apiAdminRoot}featured-call`,
  GET_USER_FEATURED_SERVICES: (userId: UserId): string => `${apiAdminRoot}users/${userId}/featured`,
  SET_USER_FEATURED_SERVICES: (userId: UserId, agreement: boolean, enable: boolean, fcid: number): string =>
    `${apiAdminRoot}users/${userId}/featured?agreement=${String(agreement)}&enable=${String(enable)}&fcid=${fcid}`,
  SET_STATUS_CALL: `${apiAdminRoot}calls`,
  LOG_IN: `${apiRoot}auth`,
  GET_LOGGED_IN_USER: `${apiRoot}users`,
  GET_CATEGORIES: `${apiAdminRoot}category-services`,
  GET_CATEGORIES_CSV: `${apiAdminRoot}category-services/csv`,
  GET_USERS: `${apiAdminRoot}users`,
  GET_ACTIVE_USERS: `${apiAdminRoot}users/active`,
  GET_ACTIVE_USERS_CSV: `${apiAdminRoot}users/active/csv`,
  GET_APPOINTMENTS_CSV: `${apiRoot}v3/admin/appointments/csv`,
  GET_FINANCES_CSV: `${apiAdminRoot}invoices/csv`,
  COUNTRIES: `${apiAdminRoot}countries`,
  DELETE_APPOINTMENT: (id: number, isPro: boolean) => `${apiAdminRoot}appointment/${id}?isPro=${isPro}`,
  GET_ARCHIVED_USERS: `${apiAdminRoot}users`,
  GET_INCOMPLETE_USERS: `${apiAdminRoot}users/incomplete`,
  GET_INVITES: `${apiAdminRoot}invites`,
  GET_CALLS: `${apiAdminRoot}calls`,
  GET_CALLS_CSV: `${apiAdminRoot}calls/csv`,
  GET_TRANSACTIONS: `${apiAdminRoot}transactions`,
  GET_TRANSACTIONS_CSV: `${apiAdminRoot}transactions/csv`,
  GET_PENDINGS: `${apiAdminRoot}pending`,
  PENDING_ID: (id: UserId): string => `${apiAdminRoot}pending/${id}`,
  USER_ID: (id: UserId): string => `${apiAdminRoot}users/${id}`,
  USER_AVAILABILITY: (id: UserId, timezone: string): string => `${apiRoot}v4/admin/users/${id}/availability?timezone=${timezone}`,
  CHANGE_ACTIVE_USER_STATUS: (id: UserId): string => `${apiAdminRoot}users/${id}/status`,
  GET_WITHDRAWS: `${apiAdminRoot}withdraw`,
  WITHDRAW_ID: (id: number | string): string => `${apiAdminRoot}withdraw/${id}`,

  GET_USER_ACTIVITY: (id: UserId): string => `${apiAdminRoot}users/${id}/activity`,
  GET_USER_LIST: (id: UserId): string => `${apiAdminRoot}users/${id}/shortlist`,
  GET_USER_MONEY: (id: UserId, page: number, size: number): string =>
    `${apiAdminRoot}users/${id}/money?page=${page}&size=${size}`,

  USERS_CREDIT: `${apiAdminRoot}users/credit`,

  USERS_EARNINGS: `${apiAdminRoot}users/earning`,

  IMAGE_GET: (id: string = ''): string => `${apiRoot}v3/image${id ? `/${id}`: ''}`,

  SEND_PUSH: `${apiAdminRoot}users/push`,
  SEND_EMAIL: `${apiAdminRoot}users/mail`,

  GET_TOTAL_STATISTICS: `${apiAdminRoot}total-statistics`,
  GET_STATISTICS: `${apiAdminRoot}statistics`,

  GET_PREFERENCES: `${apiRoot}preferences`,
  EDIT_PREFERENCES: `${apiRoot}preferences/all`,
  ADMIN_NOTES: (userId: UserId): string => `${apiAdminRoot}users/${userId}/note`,

  REFRESH_TOKEN: `${apiRoot}v3/refresh-token`,

  USERS_ACTIVITY: (userId: UserId): string => `${apiAdminRoot}users/${userId}/v3activity`,

  PARTNERS: `${apiAdminRoot}partners`,
  BOOKINGS: `${apiAdminRoot}bookings`,
  LOGOUT: (id: UserId): string => `${apiAdminRoot}users/${id}/logout`,

  GET_NETWORKS_CSV: `${apiAdminRoot}partners/csv`,

  UPDATE_USER_STATE: (userId: UserId) => `${apiRoot}v3/admin/user/${userId}/state`,

  UPDATE_PARTNER_AUTOLINKING_EMAILS: `${apiRoot}v4/admin/partner/emails`,
  GET_PARTNER_AUTOLINKING_EMAILS: (tag: string) => `${apiRoot}v4/admin/partner/emails?tag=${tag}`,
});
