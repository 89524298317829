import { ROUTES } from './routes-constants';

export const MENU_ITEMS = [
  {
    id: '0',
    title: 'Pending Requests',
    submenu: [
      {
        id: '00',
        route: ROUTES.PRO_REQUESTS,
        title: 'Pro applications'
      },
      {
        id: '01',
        route: ROUTES.WITHDRAWALS,
        title: 'Withdrawals'
      }
    ]
  },
  {
    id: '1',
    title: 'User Management',
    submenu: [
      {
        id: '10',
        route: ROUTES.ACTIVE_USERS,
        title: 'Active users'
      },
      {
        id: '11',
        route: ROUTES.INCOMPLETE_REGISTRATIONS,
        title: 'Incomplete registration'
      },
      {
        id: '12',
        route: ROUTES.INVITES,
        title: 'Invites'
      }
    ]
  },
  {
    id: '2',
    title: 'Transactions',
    route: ROUTES.TRANSACTIONS
  },
  {
    id: '3',
    title: 'Calls',
    route: ROUTES.CALLS
  },
  {
    id: '8',
    title: 'Appointments',
    route: ROUTES.APPOINTMENTS
  },
  {
    id: '4',
    title: 'Sectors',
    route: ROUTES.SECTORS
  },
  {
    id: '5',
    title: 'Additional',
    submenu: [
      {
        id: '50',
        route: ROUTES.HOME,
        title: 'Statistics'
      },
      {
        id: '51',
        route: ROUTES.SETTINGS,
        title: 'Settings'
      },
      {
        id: '52',
        route: ROUTES.FINANCES,
        title: 'Finances'
      },
      {
        id: '53',
        route: ROUTES.COUNTRIES,
        title: 'Pro Countries'
      }
    ]
  },
  {
    id: '6',
    title: 'Featured services',
    route: ROUTES.FEATURED_SERVICES
  },
  {
    id: '7',
    title: 'Networks',
    route: ROUTES.PARTNERS
  }
];
