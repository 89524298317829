/** @flow */

import React, { Component } from 'react';

import { KEYCODE_ENTER } from '../../../constants';
import SearchItem from './search-item';
import style from './style.module.css';

type SearchAreaRequiredProps = {
  onSearch: ({ [key: string]: string }) => void;
}

type SearchAreaProps = {
  ...SearchAreaRequiredProps,
  isShown?: boolean;
  initialSearchPhrases?: ?{ [key: string]: string };
  searchItemsArr?: Array<{ name: string }>;
};

type SearchAreaState = {
  [key: string]: string,
};

class SearchArea extends Component<SearchAreaProps, SearchAreaState> {
  static defaultProps: $Diff<SearchAreaProps, SearchAreaRequiredProps> = {
    isShown: false,
    initialSearchPhrases: null,
    searchItemsArr: [],
  };

  constructor(props: SearchAreaProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    const { initialSearchPhrases } = this.props;

    if (initialSearchPhrases) {
      this.setState(prevState => ({
        ...prevState,
        ...initialSearchPhrases,
      }));
    }
  }

  handleChange: (e: SyntheticInputEvent<HTMLInputElement>) => void = e => {
    const { name, value } = e.currentTarget;

    this.setState({ [name]: value });
  };

  onSearch: () => void = () => {
    const { onSearch } = this.props;

    const notEmptyFields = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in this.state) {
      // eslint-disable-next-line no-unused-expressions
      this.state[key] && (notEmptyFields[key] = this.state[key].toLowerCase());
    }

    onSearch(notEmptyFields);
  };

  onKeyPress: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = e => {
    if (e.keyCode === KEYCODE_ENTER) {
      this.onSearch();
    }
  }

  render(): React$Node {
    const { isShown, searchItemsArr = [] } = this.props;

    return isShown ? null : (
      <div className={style.searchGroup} tabIndex="-1">
        <div className={style.searchGroupLabel}> Search by fields: </div>

        <div className={style.searchItems}>
          {searchItemsArr.map((el: { name: string }) => (
            <SearchItem
              {...el}
              key={el.name}
              value={this.state[el.name] || ''}
              onChange={this.handleChange}
              onPressEnter={this.onSearch}
            />
          ))}
        </div>

        <button onClick={this.onSearch} className={`${style.searchBtn} saveBtn`} type="button">
          Search
        </button>
      </div>
    );
  }
}

export default SearchArea;
