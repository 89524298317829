import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu } from "antd";

import style from "./style.module.css";
import Card from "../card";
import { MENU_ITEMS } from "../../../constants/menu-items";

const { SubMenu } = Menu;

const SideNav = ({ currentPath }) => {
  const OPEN_KEYS = "OpenKeys";
  const SELECTED_KEYS = "SelectedKeys";

  const getMenuOptions = option => {
    const defaultOpenKeys = [];
    const selectedKeys = [];
    MENU_ITEMS.forEach(item => {
      if (item.submenu) {
        const submenu = item.submenu.filter(submenuItem => submenuItem.route === currentPath)[0];

        defaultOpenKeys.push(item.id);
        if (submenu) {
          selectedKeys.push(submenu.id);
        }
      } else if(item.route === currentPath) {
        selectedKeys.push(item.id);
      }
    });

    return option === OPEN_KEYS ? defaultOpenKeys : selectedKeys;
  };

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [defaultOpenKeys] = useState(getMenuOptions(OPEN_KEYS));

  useEffect(() => {
    const menuOptions = getMenuOptions(SELECTED_KEYS);
    setSelectedKeys(menuOptions);
  }, [currentPath]);

  return (
    <div className={style.cardDiv}>
      <Card>
        <Menu
          style={{ width: 256 }}
          mode="inline"
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
        >
          {MENU_ITEMS.map(obj => {
            if (obj.submenu) {
              return (
                <SubMenu
                  key={obj.id}
                  title={<span className={style.menuTitle}>{obj.title}</span>}
                >
                  {obj.submenu.map(subObj => (
                    <Menu.Item key={subObj.id}>
                      <Link to={subObj.route}>
                        <span className={style.submenuTitle}>
                          {subObj.title}
                        </span>
                      </Link>
                    </Menu.Item>
                    ))}
                </SubMenu>
              );
            }
              return (
                <Menu.Item key={obj.id}>
                  <Link to={obj.route}>
                    <span className={style.submenuTitle}>{obj.title}</span>
                  </Link>
                </Menu.Item>
              );

          })}
        </Menu>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currentPath: state.router.location.pathname
});

export default connect(mapStateToProps)(SideNav);
