/** @flow */

import { Dispatch, Store } from 'redux';
import type { ActionType } from '../../types';
import type { SectorType } from '../reducers/sectors';
import { actionTypes } from './index';
import { getCategoryServices, manageCategoryServices } from '../api/category';

export function sectorsFetch() {
  return async (dispatch: Dispatch, getState: () => Store) => {
    try {
      dispatch({
        type: actionTypes.FETCH_SECTORS_REQUEST,
      });

      const { loggedInUser: { userAuth } } = getState();

      const sectors: SectorType[] = await getCategoryServices(userAuth || '');

      dispatch(sectorsFetchSuccess(sectors));
    } catch (error) {
      dispatch(sectorsFetchFailure(error));
    }
  };
}

export function sectorsUpdate(method, data) {
  return async (dispatch: Dispatch, getState: () => Store) => {
    try {
      const { loggedInUser: { userAuth } } = getState();

      const sectors: SectorType[] = await manageCategoryServices(userAuth || '', method, data);
      if (!sectors.error) {
        dispatch(sectorsFetchSuccess(sectors));
      }
    } catch (error) {
      dispatch(sectorsFetchFailure(error));
    }
  };
}

function sectorsFetchSuccess(sectors: SectorType[]): ActionType<{ sectors: SectorType[] }> {
  return {
    type: actionTypes.FETCH_SECTORS_SUCCESS,
    payload: { sectors },
  };
}

function sectorsFetchFailure(error: string) {
  return {
    error,
    type: actionTypes.FETCH_SECTORS_FAILURE,
  };
}
