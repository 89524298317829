import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LogInForm from "../../components/log-in-form";
import { logIn } from "../../store/actions/user";

import style from "./style.module.css";

class LogIn extends PureComponent {
  render() {
    return (
      <div className={style.logInWrapper}>
        <h1>Log in</h1>

        <LogInForm
          loggedInUser={this.props.loggedInUser}
          logIn={this.props.logIn}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logIn
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogIn);
