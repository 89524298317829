/** @flow */

import React from 'react'
import { Icon } from 'antd';
import style from './style.module.css';

import Card from '../../Layout/card';

type ProBookingAndAvailabilityProps = $ReadOnly<{|
  minimumBookingDuration: ?number,
  acceptBookings: ?boolean,
  hideProfile: ?boolean,
  useAvailabilitySchedule: ?boolean
|}>

const iconSize = '24px';
const okIconStyle = { fontSize: iconSize, color: 'green' };
// const warningIconStyle = { fontSize: iconSize, color: 'orange' };
const disabledIconStyle = { fontSize: iconSize, color: '#e3002b' };
const infoIconStyle = { fontSize: iconSize, color: '#556cd6' };

const renderOKFailStatusIcon = (status: boolean) =>
  status
    ? <Icon type="check-circle" theme="filled" style={okIconStyle} />
    : <Icon type="close-circle" theme="filled" style={disabledIconStyle} />  

const ProBookingAndAvailability = (
  { minimumBookingDuration, acceptBookings, hideProfile, useAvailabilitySchedule }: ProBookingAndAvailabilityProps
): React$Node => (
  <div>
    <div className="headline">Booking and Availability</div>
    <Card cardClass={style.proSettingsCard} class={style.cardContent}>
      <div className={style.settingsArea}>
        <div>Pro accepts bookings:</div>
        <div>{renderOKFailStatusIcon(!!acceptBookings)}</div>
        <div>{acceptBookings ? 'Yes' : 'No'}</div>
        <div>Minimum booking duration:</div>
        {
          typeof minimumBookingDuration === 'number' && Number.isFinite(minimumBookingDuration)
            ? (
              <>
                <div><Icon type="clock-circle" style={infoIconStyle} /></div>
                <div>{`${minimumBookingDuration} minutes`}</div>
              </>
            )
            : (
              <>
                <div><Icon type="question-circle" style={disabledIconStyle} /></div>
                <div>N/A</div>
              </>
            )
        }
        <div>Availability schedule activated:</div>
        <div>{renderOKFailStatusIcon(!!useAvailabilitySchedule)}</div>
        <div>{useAvailabilitySchedule ? 'Yes' : 'No'}</div>
        <div>Pro profile visible:</div>
        <div>{renderOKFailStatusIcon(!hideProfile)}</div>
        <div>{hideProfile ? 'No' : 'Yes'}</div>
      </div>
    </Card>
  </div>
  );

export default ProBookingAndAvailability;