/** @flow */

import React from 'react';

import { Popover } from 'antd';

type CallDetailsPopoverProps = {
  callId: string,
  description: string
}

const popoverContentStyle = {
  'whiteSpace': 'pre'
};

const copyCallDetailsToClipboard = (callDetails: string) => {
  navigator.clipboard.writeText(callDetails);
}

function CallDetailsPopover(props: CallDetailsPopoverProps) {
  const { callId, description } = props;

  const callIdOnClickHandler = e => {
    e.preventDefault();
    copyCallDetailsToClipboard(description)
  }

  return (
    <Popover
      content={<div style={popoverContentStyle}>{description}</div>}
      title="Call Details"
    >
      <span onClick={callIdOnClickHandler}>{callId}</span>
    </Popover>
  );

}

export default CallDetailsPopover;