/** @flow */

import { apiUrls } from './index';
import type { HttpRequestMethod, RelatedPartnersFilters, UserId, UserStatus, DateRangeSearchCriteria } from '../../types';
import { http } from './fetch-wrapper';

export const logIn = (authData: string): Promise<any> => {
  const headers = new Headers();

  headers.append('Authorization', `Basic ${authData}`);

  return http(apiUrls.LOG_IN, { method: 'GET', headers });
};

export const refreshAuthToken = (accessToken: string, refreshToken: string): Promise<any> => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const body = JSON.stringify({
    accessToken,
    refreshToken,
    tokenType: 'Bearer',
  });

  return http(apiUrls.REFRESH_TOKEN, { method: 'POST', headers, body });
}

export const getLoggedInUser = (authData: string): Promise<any> => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);

  return http(apiUrls.GET_LOGGED_IN_USER, { method: 'GET', headers });
};

const getUsers = (url, authData, query = '') => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);

  return http(url + query, { method: 'GET', headers });
};

export const getActiveUsers = (
  authData: string,
  page: number = 0,
  size: number = 20,
  filter: Array<UserStatus> = [
    'REGISTERED',
    'SUSPENDED',
    'STARTED_PRO_APP',
    'PENDING_APPROVAL',
    'APPROVED_AS_PRO',
    'SUSPENDED_AS_PRO'
  ],
  search: string = '',
  sort: string = '',
  filters: RelatedPartnersFilters = {},
  dateRange: DateRangeSearchCriteria
): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');
  const [sortField, sortDirection] = sort.split(',');
  const { relatedPartners: partners = [] } = filters;

  return fetch(apiUrls.GET_USERS, {
    method: 'POST',
    body: JSON.stringify({
      search,
      sortDirection: sortDirection ? sortDirection.toUpperCase() : sortDirection,
      pageNumber: page,
      pageSize: size,
      sort: sortField,
      statusList: filter,
      partners,
      paymentSystems: [],
      ...dateRange
    }),
    headers
  }).then(
    response => {
     if (response.status === 403) {
       return {
         error: true,
         message: 'You don’t have enough rights'
       };
     }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const getIncompleteUsers = (authData: string): Promise<any> =>
  getUsers(apiUrls.GET_INCOMPLETE_USERS, authData);

export const getInvites = (authData: string): Promise<any> => getUsers(apiUrls.GET_INVITES, authData);

export const getCalls = (authData: string, queryArr: Array<{| name: string, value: any |}> = []): Promise<any> => {
  let query = '';
  queryArr.forEach(({ name, value }, i) => {
    if (i === 0) {
      query = `?${name}=${value}`;
    } else {
      query += `&${name}=${value}`;
    }
  });
  return getUsers(apiUrls.GET_CALLS, authData, query);
};

export const setCallStatus = (authData: string, data: any, method: HttpRequestMethod): Promise<any> => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json ');
  headers.append('Authorization', `Bearer ${authData}`);
  const body = JSON.stringify(data);

  return fetch(apiUrls.SET_STATUS_CALL, { method, headers, body }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: 'You don’t have enough rights'
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
};

export const getTransactions = (authData: string, queryArr: Array<{| name: string, value: any |}> = []): Promise<any> => {
  let query = '';
  queryArr.forEach(({ name, value }, i) => {
    if (i === 0) {
      query = `?${name}=${value}`;
    } else {
      query += `&${name}=${value}`;
    }
  });
  return getUsers(apiUrls.GET_TRANSACTIONS, authData, query);
};

const userManipulation = (url: string, method: HttpRequestMethod, authData: string, data: any): Promise<any> => {
  const headers = new Headers();
  method === 'PUT' && headers.append('Content-Type', 'application/json ');
  headers.append('Authorization', `Bearer ${authData}`);

  return http(url, { method, headers, body: JSON.stringify(data) });
};

export const getUserInfo = (id: UserId, authData: string): Promise<any> =>
  userManipulation(apiUrls.USER_ID(id), 'GET', authData);

export const getUserAvailability = (id: UserId, authData: string): Promise<any> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userManipulation(apiUrls.USER_AVAILABILITY(id, timezone), 'GET', authData);
}  

export const editUser = (data: any, id: UserId, authData: string): Promise<any> =>
  userManipulation(apiUrls.USER_ID(id), 'PUT', authData, data);

export const updateUserState = (data: any, id: UserId, authData: string): Promise<any> =>
  userManipulation(apiUrls.UPDATE_USER_STATE(id), 'PUT', authData, data);

const changeUserStatus = (url: string, authData: string, data: any): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  return http(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  });
};

export const deleteUser = (id: UserId, authData: string, value: boolean): Promise<any> => {
  const data = {
    userId: id,
    status: 'ARCHIVED',
    value
  };
  return changeUserStatus(
    apiUrls.CHANGE_ACTIVE_USER_STATUS(id),
    authData,
    data
  );
};

export const changeActiveUserStatus = (id: UserId, authData: string, value: boolean): Promise<any> => {
  const data = {
    userId: id,
    status: 'SUSPENDED',
    value
  };
  return changeUserStatus(
    apiUrls.CHANGE_ACTIVE_USER_STATUS(id),
    authData,
    data
  );
};

export const changeProStatus = (id: UserId, authData: string, value: boolean): Promise<any> => {
  const data = {
    userId: id,
    status: 'SUSPENDED_AS_PRO',
    value
  };
  return changeUserStatus(
    apiUrls.CHANGE_ACTIVE_USER_STATUS(id),
    authData,
    data
  );
};

export const chUserStatus = (id: UserId, authData: string, value: boolean): Promise<any> => {
  const data = {
    userId: id,
    status: 'SUSPENDED',
    value
  };
  return changeUserStatus(
    apiUrls.CHANGE_ACTIVE_USER_STATUS(id),
    authData,
    data
  );
};

export const changeProfileHidden = (id: UserId, authData: string, value: boolean): Promise<any> => {
  const data = {
    userId: id,
    status: 'PRO_HIDE_PROFILE',
    value
  };
  return changeUserStatus(
    apiUrls.CHANGE_ACTIVE_USER_STATUS(id),
    authData,
    data
  );
};

const getUserActivity = (url: string, method: HttpRequestMethod, authData: string): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  return http(url, { method, headers });
};

export const getUsActivities = (id: UserId, authData: string): Promise<any> =>
  getUserActivity(apiUrls.GET_USER_ACTIVITY(id), 'GET', authData);

export const getUsClient = (id: UserId, authData: string): Promise<any> =>
  getUserActivity(
    `${apiUrls.GET_USER_ACTIVITY(id)}?isPro=true`,
    'GET',
    authData
  );

export const getUsProsList = (id: UserId, authData: string): Promise<any> =>
  getUserActivity(apiUrls.GET_USER_ACTIVITY(id), 'GET', authData);

export const getUsMoney = (id: UserId, page: number, size: number, authData: string): Promise<any> =>
  getUserActivity(apiUrls.GET_USER_MONEY(id, page, size), 'GET', authData);

export const manageFreeCredits = (method: HttpRequestMethod, authData: string, data: any): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method, headers, body: JSON.stringify(data) };

  return http(apiUrls.USERS_CREDIT, requestOptions);
};

export const manageProEarnings = (method: HttpRequestMethod, authData: string, data: any): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method, headers, body: JSON.stringify(data) };

  return http(apiUrls.USERS_EARNINGS, requestOptions);
};


export const getAllFeaturedServicesByUser = (data: {| userId: UserId |}, authData: string): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  const { userId } = data;
  return fetch(apiUrls.GET_USER_FEATURED_SERVICES(userId), { method: 'GET', headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: 'You don’t have enough rights'
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return response.json().then(json => json);
    },
    error => {
      throw new Error(error.message);
    }
  );
}

export const logoutFromAllDevices: (userId: UserId, authData: string) => Promise<{
  error: boolean,
  message?: string,
}> = async (userId, authData) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);

  try {
    const response = await fetch(apiUrls.LOGOUT(userId), { method: 'DELETE', headers });

    if (response.status === 403) {
      return {
        error: true,
        message: 'You don’t have enough rights'
      };
    }
    if (response.status !== 200 && response.status !== 204) {
      return response
        .json()
        .then(json => ({ ...json, error: true }))
        .catch(err => ({ error: true, message: err.message }));
    }
    return {
      error: false,
      message: 'success',
    };
  } catch (e) {
    throw new Error(e.message);
  }
}

export const setFeaturedServiceByUser = (
  data: {|
    userId: UserId,
    agreement: boolean,
    enable: boolean,
    fcid: number,
  |},
  authData: string,
): Promise<any> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');
  const { userId, agreement, enable, fcid } = data;
  return fetch(apiUrls.SET_USER_FEATURED_SERVICES(userId, agreement, enable, fcid), { method: 'POST', headers }).then(
    response => {
      if (response.status === 403) {
        return {
          error: true,
          message: 'You don’t have enough rights'
        };
      }
      if (response.status !== 200) {
        return response
          .json()
          .then(json => ({ ...json, error: true }))
          .catch(err => ({ error: true, message: err.message }));
      }
      return {
        error: false,
        message: 'success',
      };
    },
    error => {
      throw new Error(error.message);
    }
  );
};
