/** @flow */

import React, { Component } from 'react';
import type { AbstractComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Spin,
  Collapse,
  Descriptions,
  Tooltip,
  Statistic,
  DatePicker,
  Input,
  Modal,
  Button,
  PageHeader,
  Row,
  type PaginationConfig
} from 'antd';

import type { ContextRouter } from 'react-router';
import style from './style.module.css';

import Card from '../../components/Layout/card';

import {
  getCalls,
  clearCalls,
  setCallStatus,
} from '../../store/actions/user';

import { callsColumns as columns } from '../../helpers/table-data';
import getSearchResults from '../../helpers/table-search';
import { getPage, generateRowKey } from '../../helpers/table-rows-paging';
import {
  PAGE_SIZE,
  ROUTES,
} from '../../constants';
import { countItemsByStatus, secToMS } from '../../helpers';
import { callsSetDays, callsSetRange } from '../../store/actions/calls';
import { selectCallsDays, selectCallsRange } from '../../store/reducers/calls';
import { apiAddNotification } from '../../store/actions/api';
import type { NotificationType, SorterType } from '../../types';
import { exportCalls } from '../../store/api/csvExport';
import performCSVExport from '../../interactors/csvExport'
import { InternationalizationContext } from '../../i18n/internationalization';
import type { IInternationalizationContext } from '../../i18n/internationalization';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Search } = Input;

type CallsOwnProps = $ReadOnly<{| |}>
  type CallsProps = $ReadOnly<{|
  ...CallsOwnProps,
  days: string,
  getCalls: (userAuth: ?string, from?: any, to?: any, days?: string) => void,
  clearCalls: () => void,
  setCallStatus: (authData: ?string, data: any) => void,
  range: any[],
  setDays: (days: string) => void,
  setRange: (range: any[]) => void,
  uArrays: {
  error?: boolean,
    load?: boolean,
    message?: string,
    calls: any[],
  },
  userData: {
    userAuth?: string,
  },
  addNotification: (notification: NotificationType) => void,
|}>

type CallsState = {
  visibleModalEndCall: boolean,
  totalAmount: number | string,
  totalDuration: number | string,
  callData: { [key: string]: any } | null,
  callsByStatus: { [key: string]: string },
  partners: { [key: string]: string }[],
  sortedInfo: SorterType,
  proSearch: string,
  clientSearch: string,
  partnerSearch: stirng,
};

class Calls extends Component<ContextRouter & CallsProps, CallsState> {
  static contextType = InternationalizationContext;

  context: IInternationalizationContext;

  userAuth: string | null;

  csvDownloadAnchor: { current: null | HTMLAnchorElement }

  constructor(props) {
    super(props);

    this.state = {
      visibleModalEndCall: false,
      callData: null,
      sortedInfo: {},
      callsByStatus: {},
      totalAmount: 0,
      totalDuration: 0,
      proSearch: '',
      clientSearch: '',
      partnerSearch: '',
    };

    this.csvDownloadAnchor = React.createRef < HTMLAnchorElement > ();
  }

  componentDidMount() {
    const { days, range: [from, to], userData: { userAuth } } = this.props;

    this.userAuth = userAuth || null;

    if (this.userAuth) {
      this.props.getCalls(this.userAuth, from, to, days);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { calls = [] } = nextProps.uArrays;
    const { calls: prevCalls = [] } = this.props.uArrays;

    if (prevCalls !== calls) {
      if (calls.length !== 0) {
        const partnersSet = new Set(calls.map(call => call.relatedPartner));
        const partnersFilter = Array.from(partnersSet).map(partner => ({ text: partner.toUpperCase(), value: partner }));
        this.setState({
          callsByStatus: countItemsByStatus(calls, 'status'),
          partners: partnersFilter
        });
      }

      this.pagerChange(1);
    }
  }

  componentDidUpdate(prevProps) {
    const { calls = [] } = this.props.uArrays;
    const { proSearch, clientSearch, partnerSearch } = this.state;
    if (prevProps.uArrays.calls !== calls) {
      let totalAmount = 0;
      let totalDuration = 0;
      const dataSource = getSearchResults(proSearch, clientSearch, partnerSearch, calls);
      dataSource.forEach(item => {
        totalAmount += item.amount;
        totalDuration += item.duration;
      });

      // TODO Rewrite it with properly logic
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        totalAmount: totalAmount.toFixed(2),
        totalDuration: secToMS(totalDuration)
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCalls();
  }

  onChange = (pagination: PaginationConfig, filters, sorter) => {
    this.setState({ sortedInfo: sorter });

    const currentPage = getPage(this.props.location.search);
    if (currentPage === pagination.current) {
      // This was a sort or filter change - reset the current page to 1
      this.pagerChange(1);
    }
  };

  onUserSelect = id => () => {
    const { history } = this.props;

    history.push({
      pathname: `${ROUTES.USER}/${id}`
    });
  };

  onSetCallStatus = () => {
    const { callData } = this.state;

    if (callData) {
      const data = {
        callId: callData.id,
        status: 'FAILED',
        duration: 0,
        proId: callData.proId,
        clientId: callData.clientId,
        fcid: callData.fcid,
      };

      this.props.setCallStatus(this.userAuth, data);

      setTimeout(() => {
        this.props.getCalls(this.userAuth);
        this.setState({
          visibleModalEndCall: false,
        });
      }, 500);
    }
  };

  onOpenModalEndCall = data => () => {
    this.showModalEndCall();

    this.setState({
      callData: data,
    });
  };

  showModalEndCall = () => {
    this.setState({
      visibleModalEndCall: true,
    });
  };

  setDefaultCallDataState = () => (
    this.setState({
      callData: {},
    })
  );

  handleCancel = () => {
    this.setDefaultCallDataState();
    this.setState({
      visibleModalEndCall: false,
    });
  };

  onRangeDateChange = date => {
    const { setDays, setRange } = this.props;
    const [dateFrom, dateTo] = date;

    setRange(date);

    setDays('');

    const startDate = dateFrom?.startOf('day');
    const endDate = dateTo?.endOf('day');

    if (this.userAuth) {
      this.props.getCalls(
        this.userAuth,
        startDate,
        endDate,
        ''
      );
    }
  };

  onDaysChange = event => {
    const { setDays, setRange } = this.props;

    const { currentTarget: { value: days } } = event;

    setDays(days);

    setRange([]);

    if (this.userAuth) {
      this.props.getCalls(this.userAuth, '', '', days);
    }
  };

  onSearch = (e, type) => {
    const {
      target: { value }
    } = e;
    const searchKey = `${type}Search`;
    this.setState(
      {
        [searchKey]: value,
      }
    );

    this.pagerChange(1);
  };

  pagerChange = current => {
    const { history, match } = this.props;
    if (match && current) {
      history.push({
        pathname: match.url,
        search: `?page=${current}`
      });
    }
  };

  performCSVExport = async () => {
    if (!this.csvDownloadAnchor.current) {
      return;
    }

    const { range: [from, to], days, userData: { userAuth } } = (this.props: CallsProps);
    const apiDelegate = (userAuth: string) => exportCalls(userAuth, Number.parseInt(days, 10), from, to);

    await performCSVExport(userAuth, apiDelegate, this.props.addNotification, this.csvDownloadAnchor.current);
  }

  onCSVExportClick = (_event: SyntheticEvent<HTMLButtonElement>) => {
    this.performCSVExport();
  }

  render() {
    const { days, range } = this.props;

    const {
      sortedInfo = {},
      callsByStatus,
      partners,
      totalAmount,
      totalDuration,
      visibleModalEndCall,
      proSearch,
      clientSearch,
      partnerSearch,
    } = this.state;

    const {
      load: isLoaded = false,
      error: isError = false,
      message: errorMsg = '',
      calls = []
    } = this.props.uArrays;

    const callsStatuses = Object.keys(callsByStatus).map(item => ({
      text: item,
      value: item
    }));

    const {
      ACTIVE = '0',
      FAILED = '0',
      SUCCEED = '0',
      BREAK = '0',
      DISCONNECTED = '0',
      BROKEN = '0',
      NO_ANSWER = '0',
      CLIENT_DECLINE = '0',
      PRO_DECLINE = '0',
      PRO_BUSY = '0'
    } = callsByStatus;
    return (
      <Card cardClass="route-content">
        <PageHeader
          title="Calls"
          backIcon={false}
          extra={[
            <Button key="1" type="primary" onClick={this.onCSVExportClick}>Export CSV</Button>
          ]}
        >
          <Row>
            <div className={style.totalCount}>
              <Statistic
                title="Total number of calls"
                value={calls.length}
                className={style.statisticsTag}
              />
              <Statistic
                title="Total amount"
                value={totalAmount}
                className={style.statisticsTag}
              />
              <Statistic
                title="Total duration"
                value={totalDuration}
                className={style.statisticsTag}
              />
            </div>
          </Row>
        </PageHeader>
        <div className={style.infoBar}>
          <Collapse className={style.collapseTag}>
            <Panel header="Calls Types" key="1">
              <div className={style.statisticsBar}>
                <Descriptions
                  bordered
                  border
                  size="small"
                  column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, which is active now">
                        <span className={style.statisticsTitle}>ACTIVE</span>
                      </Tooltip>
                    )}
                  >
                    {ACTIVE}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, which not started (no answer or drop)">
                        <span className={style.statisticsTitle}>FAILED</span>
                      </Tooltip>
                    )}
                  >
                    {FAILED}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, which successfully ended">
                        <span className={style.statisticsTitle}>SUCCEED</span>
                      </Tooltip>
                    )}
                  >
                    {SUCCEED}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, which was broken because customer doesn't have enough money">
                        <span className={style.statisticsTitle}>BREAK</span>
                      </Tooltip>
                    )}
                  >
                    {BREAK}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call drop because one of the participants was disconnected">
                        <span className={style.statisticsTitle}>
                          DISCONNECTED
                        </span>
                      </Tooltip>
                    )}
                  >
                    {DISCONNECTED}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Hung call (if call has active status duration and amount = 0 but start date was 8 hours ago)">
                        <span className={style.statisticsTitle}>BROKEN</span>
                      </Tooltip>
                    )}
                  >
                    {BROKEN}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, which was ended in 40 (30) seconds waiting">
                        <span className={style.statisticsTitle}>
                          NO_ANSWER
                        </span>
                      </Tooltip>
                    )}
                  >
                    {NO_ANSWER}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, when Client declines outgoing call">
                        <span className={style.statisticsTitle}>
                          CLIENT_DECLINE
                        </span>
                      </Tooltip>
                    )}
                  >
                    {CLIENT_DECLINE}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, when Pro declines incoming call">
                        <span className={style.statisticsTitle}>
                          PRO_DECLINE
                        </span>
                      </Tooltip>
                    )}
                  >
                    {PRO_DECLINE}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Call, when Client initiates a call to Pro who is on active call">
                        <span className={style.statisticsTitle}>
                          PRO_BUSY
                        </span>
                      </Tooltip>
                    )}
                  >
                    {PRO_BUSY}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </Panel>
          </Collapse>
          <div className={style.filterRow}>
            <RangePicker value={range} onChange={this.onRangeDateChange} />
            <Input
              placeholder="Enter days"
              suffix="Days"
              className={style.daysInput}
              value={days}
              onChange={this.onDaysChange}
            />
          </div>
          <div className={style.filterRow}>
            <Search
              placeholder="Pro Fields Search"
              onChange={e => this.onSearch(e, 'pro')}
              className={style.searchInput}
            />
            <Search
              placeholder="Client Fields Search"
              onChange={e => this.onSearch(e, 'client')}
              className={style.searchInput}
            />
            <Search
              placeholder="Partner Fields Search"
              onChange={e => this.onSearch(e, 'partner')}
              className={style.searchInput}
            />
          </div>
        </div>

        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoaded ? (
          !isError ? (
            <>
              <Table
                columns={columns(
                  sortedInfo,
                  this.onUserSelect,
                  callsStatuses,
                  partners,
                  this.onOpenModalEndCall,
                  this.context.formatNumericDateTimeWithTimeZone
                )}
                rowKey={generateRowKey}
                onChange={this.onChange}
                pagination={{ pageSize: PAGE_SIZE, current: getPage(this.props.location.search), defaultCurrent: 1, onChange: this.pagerChange }}
                dataSource={getSearchResults(proSearch, clientSearch, partnerSearch, calls)}
                scroll={{ x: true }}
              />
              <Modal
                title='End this call'
                visible={visibleModalEndCall}
                width="35%"
                footer={[
                  <Button key="submit" type="danger" onClick={this.onSetCallStatus}>
                    End call
                  </Button>,
                  <Button key="back" type="primary" onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                ]}
              >
                Terminate permanently active call?
              </Modal>
            </>
          ) : (
            <div className="errorContainer">
              Error!
              <div className="errorMsg">{errorMsg}</div>
            </div>
          )
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <a style={{ display: 'none' }} href='' ref={this.csvDownloadAnchor} />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  days: selectCallsDays(state),
  range: selectCallsRange(state),
  userData: state.loggedInUser,
  uArrays: state.usersArrays
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getCalls,
  clearCalls,
  setCallStatus,
  setDays: callsSetDays,
  setRange: callsSetRange,
  addNotification: apiAddNotification,
}, dispatch);

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(Calls)): AbstractComponent<CallsOwnProps>);
