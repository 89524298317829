/** @flow */

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import shallowequal from 'shallowequal'

import { Spin } from 'antd';

import Input from '../../input';

import style from './style.module.css';

import { addProEarnings, deleteProEarnings } from '../../../store/actions/user';
import { TELMIE_PAY } from '../../../constants';


// eslint-disable-next-line no-unused-vars
import type { Dispatch, Action, ManageEarningsPayload } from '../../../types';

type EarningsProps = $ReadOnly<{
  isProcessing: boolean,
  message?: string,
  errorMsg?: string
}>

type OwnProps = $ReadOnly<{
  earnings?: EarningsProps,
  userId: number,
  userAuth: string
}>

type Props = $ReadOnly<{
  ...OwnProps,
  addProEarnings: (method: string, authData: string, data: ManageEarningsPayload) => Dispatch,
    deleteProEarnings: (method: string, authData: string, data: ManageEarningsPayload) => Dispatch
}>

  type State = {
    addValue: string,
    deleteValue: string,
    descValue: string,
    success: boolean,
    isValid?: boolean,
    isProcessing: boolean
  }

const ADD_EARNINGS_DESC = 'Added by Telmie';
const DELETE_EARNINGS_DESC = 'Removed by Telmie';

class Earnings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addValue: '',
      deleteValue: '',
      descValue: '',
      success: false,
      isProcessing: false // This is needed for getDerivedStateFromProps to reflect the current value of 'isProcessing' from the props
    };
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    const { earnings = {} } = props;

    if (state.isProcessing && !earnings.isProcessing && !earnings.errorMsg) {
      // Reset the state upon successful completion of the transaction
      return { addValue: '', deleteValue: '', descValue: '', success: true, isProcessing: false }
    }

    if (state.isProcessing !== earnings.isProcessing) {
      // Update the cached value of 'isProcessing' if changed in the passed props.
      // Such a change is expected to be triggered by the corresponding reducers
      // after dispatching the add/remove earnings actions
      return {
        ...state,
        isProcessing: earnings.isProcessing
      }
    }

    // Do nothing otherwise
    return null;
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // Any changes in the props should trigger a render
    if (!shallowequal(this.props, nextProps)) {
      return true;
    }

    // However, for the state, we deliberately ignore changes in the cached 'isProcessing' value
    return this.state.addValue !== nextState.addValue ||
      this.state.deleteValue !== nextState.deleteValue ||
      this.state.descValue !== nextState.descValue ||
      this.state.success !== nextState.success
  }

  onChangeAdd = e => {
    const { value } = e.target;
    const { descValue } = this.state;
    const addEarningsDesc = (descValue && descValue !== DELETE_EARNINGS_DESC) ? descValue : ADD_EARNINGS_DESC;

    this.setState({
      addValue: value,
      descValue: addEarningsDesc,
      isValid: !!value.match(/^\d+(\.\d+){0,1}$/),
      success: false
    });
  };

  onChangeDelete = e => {
    const { value } = e.target;
    const { descValue } = this.state;
    const deleteEarningsDesc = (descValue && descValue !== ADD_EARNINGS_DESC) ? descValue : DELETE_EARNINGS_DESC

    this.setState({
      deleteValue: value,
      descValue: deleteEarningsDesc,
      isValid: !!value.match(/^\d+(\.\d+){0,1}$/),
      success: false
    });
  };

  onAdd = () => {
    const { addProEarnings, userId, userAuth } = this.props;
    const { isValid, addValue, descValue } = this.state;

    const data = {
      userId,
      amount: parseFloat(addValue) || 0,
      paymentSystem: TELMIE_PAY,
      description: descValue,
    };

    isValid && addProEarnings('POST', userAuth, data);
  };

  onDelete = () => {
    const { deleteProEarnings, userId, userAuth } = this.props;
    const { isValid, deleteValue, descValue } = this.state;

    const data = {
      userId,
      amount: parseFloat(deleteValue) || 0,
      paymentSystem: TELMIE_PAY,
      description: descValue,
    };

    isValid && deleteProEarnings('DELETE', userAuth, data);
  };

  onChangeDesc = e => {
    const { value } = e.target;

    this.setState({
      descValue: value,
      isValid: value.length <= 25,
      success: false
    });
  };

  render() {
    const { earnings = {} } = this.props;

    return (
      <>
        <div className={cn(style.earningsContainer, {
          [style.earningsContainerDisabled]: earnings.isProcessing || this.state.deleteValue
        })}
        >
          <div className={style.earningsLabel}>Add earnings</div>
          <Input
            placeholder="0.00"
            onChange={this.onChangeAdd}
            value={this.state.addValue}
            disabled={earnings.isProcessing || this.state.deleteValue}
          />
        </div>
        <div className={cn(style.earningsContainer, {
          [style.earningsContainerDisabled]: earnings.isProcessing || this.state.addValue
        })}
        >
          <div className={style.earningsLabel}>Delete earnings</div>
          <Input
            placeholder="0.00"
            onChange={this.onChangeDelete}
            value={this.state.deleteValue}
            disabled={earnings.isProcessing || this.state.addValue}
          />
        </div>
        <div className={cn(style.earningsContainer)}>
          <div className={style.earningsLabel}>Description</div>
          <Input
            placeholder=""
            onChange={this.onChangeDesc}
            value={this.state.descValue}
            disabled={earnings.isProcessing}
            maxlength={25}
          />
        </div>

        {earnings.isProcessing ? (
          <div style={{ marginLeft: 20 }}>
            <Spin size="large" />
          </div>
        ) : (
          this.state.addValue && this.state.descValue &&
          (this.state.isValid ? (
            <button className="saveBtn" onClick={this.onAdd} type="button">
              Add earnings
            </button>
          ) : (
            <div style={{ color: 'red', fontStyle: 'italic' }}>
              Please type a correct amount
            </div>
          ))
        )}
        {earnings.isProcessing ? (
          <div style={{ marginLeft: 20 }}>
            <Spin size="large" />
          </div>
        ) : (
          this.state.deleteValue && this.state.descValue &&
          (this.state.isValid ? (
            <button className="saveBtn" onClick={this.onDelete} type="button">
              Delete earnings
            </button>
          ) : (
            <div style={{ color: 'red', fontStyle: 'italic' }}>
              Please type a correct amount
            </div>
          ))
        )}
        {this.state.success && earnings.message && (
          <span style={{ marginRight: 10, color: 'green' }}>{earnings.message}</span>
        )}
        {earnings.errorMsg && (
          <div style={{ color: 'red', fontStyle: 'italic' }}>
            {earnings.errorMsg}
          </div>
        )}
      </>
    );
  }
}

Earnings.propTypes = {
  userId: PropTypes.number.isRequired,
  userAuth: PropTypes.string.isRequired,
  earnings: PropTypes.shape({
    isProcessing: PropTypes.bool,
    message: PropTypes.string,
    errorMsg: PropTypes.string
  })
}

Earnings.defaultProps = {
  earnings: {}
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addProEarnings,
  deleteProEarnings,
}, dispatch);

export default connect < _, Props, OwnProps > (null, mapDispatchToProps)(Earnings);
