/** @flow */

// ACTION TYPES
export const actionTypes: { [key: string]: string } = {
  ACTIVE_USERS_SET_PAGE: 'ACTIVE_USERS_SET_PAGE',
  ACTIVE_USERS_SET_SEARCH: 'ACTIVE_USERS_SET_SEARCH',
  ACTIVE_USERS_SET_SORTED: 'ACTIVE_USERS_SET_SORTED',
  ACTIVE_USERS_SET_STATUS_FILTERS: 'ACTIVE_USERS_SET_STATUS_FILTERS',
  ACTIVE_USERS_SET_SEARCH_PHRASES: 'ACTIVE_USERS_SET_SEARCH_PHRASES',
  ACTIVE_USERS_SET_FILTERS: 'ACTIVE_USERS_SET_FILTERS',

  ARCHIVED_USERS_SET_PAGE: 'ARCHIVED_USERS_SET_PAGE',
  ARCHIVED_USERS_SET_SEARCH: 'ARCHIVED_USERS_SET_SEARCH',
  ARCHIVED_USERS_SET_SORTED: 'ARCHIVED_USERS_SET_SORTED',

  API_ADD_NOTIFICATION: 'API_ADD_NOTIFICATION',
  API_CLOSE_NOTIFICATION: 'API_CLOSE_NOTIFICATION',
  API_REMOVE_NOTIFICATION: 'API_REMOVE_NOTIFICATION',
  API_RESET: 'API_RESET',

  CALLS_SET_DAYS: 'CALLS_SET_DAYS',
  CALLS_SET_RANGE: 'CALLS_SET_RANGE',

  TRANSACTIONS_SET_DAYS: 'TRANSACTIONS_SET_DAYS',
  TRANSACTIONS_SET_RANGE: 'TRANSACTIONS_SET_RANGE',
  TRANSACTIONS_CSV_EXPORT_FAILURE: 'TRANSACTIONS_CSV_EXPORT_FAILURE',

  LOADING: 'LOADING',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',
  LOGGED_OFF: 'LOGGED_OFF',
  SET_CATEGORIES: 'SET_CATEGORIES',

  ERROR_GETTING_USERS: 'ERROR_GETTING_USERS',
  SET_ACTIVE_USERS: 'SET_ACTIVE_USERS',
  CLEAR_ACTIVE_USERS: 'CLEAR_ACTIVE_USERS',
  SET_ARCHIVED_USERS: 'SET_ARCHIVED_USERS',
  CLEAR_ARCHIVED_USERS: 'CLEAR_ARCHIVED_USERS',
  SET_INCOMPLETE_USERS: 'SET_INCOMPLETE_USERS',
  CLEAR_INCOMPLETE_USERS: 'CLEAR_INCOMPLETE_USERS',
  SET_INVITES: 'SET_INVITES',
  CLEAR_INVITES: 'CLEAR_INVITES',
  SET_CALLS: 'SET_CALLS',
  CLEAR_CALLS: 'CLEAR_CALLS',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',

  START_PROCESSING_PENDING: 'START_PROCESSING_PENDING',
  PROCESSING_PENDING_FAILURE: 'PROCESSING_PENDING_FAILURE',
  PROCESSING_PENDING_SUCCESS: 'PROCESSING_PENDING_SUCCESS',
  CLEAR_ACTIVATE_USER_STATUS: 'CLEAR_ACTIVATE_USER_STATUS',
  CHANGE_ACTIVE_USER_STATUS: 'CHANGE_ACTIVE_USER_STATUS',
  CHANGE_A_U_STATUS_SUCCESS: 'CHANGE_A_U_STATUS_SUCCESS',
  START_MODIFY_USER: 'START_MODIFY_USER',
  MODIFY_USER_FAILURE: 'MODIFY_USER_FAILURE',
  RESTORE_USER_SUCCESS: 'RESTORE_USER_SUCCESS',
  SET_USER_INFO_LIST: 'SET_USER_INFO_LIST',

  PENDINGS_GETTING_FAILURE: 'PENDINGS_GETTING_FAILURE',
  SET_PENDINGS: 'SET_PENDINGS',
  CLEAR_PENDINGS: 'CLEAR_PENDINGS',
  SET_WITHDRAWALS: 'SET_WITHDRAWALS',
  GET_WITHDRAWALS_FAILURE: 'GET_WITHDRAWALS_FAILURE',
  WITHDRAWAL_MANIPULATE_FAILURE: 'WITHDRAWAL_MANIPULATE_FAILURE',
  WITHDRAWAL_MANIPULATE_SUCCESS: 'WITHDRAWAL_MANIPULATE_SUCCESS',
  WITHDRAWAL_MANIPULATE_START: 'WITHDRAWAL_MANIPULATE_START',

  CLEAR_USER: 'CLEAR_USER',
  SELECT_USER: 'SELECT_USER',

  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  MANIPULATE_USER_FAILURE: 'MANIPULATE_USER_FAILURE',

  ADD_CREDITS_START: 'ADD_CREDITS_START',
  ADD_CREDITS_SUCCESS: 'ADD_CREDITS_SUCCESS',
  ADD_CREDITS_FAILURE: 'ADD_CREDITS_FAILURE',

  DELETE_CREDITS_START: 'DELETE_CREDITS_START',
  DELETE_CREDITS_SUCCESS: 'DELETE_CREDITS_SUCCESS',
  DELETE_CREDITS_FAILURE: 'DELETE_CREDITS_FAILURE',

  ADD_EARNINGS_START: 'ADD_EARNINGS_START',
  ADD_EARNINGS_SUCCESS: 'ADD_EARNINGS_SUCCESS',
  ADD_EARNINGS_FAILURE: 'ADD_EARNINGS_FAILURE',

  DELETE_EARNINGS_START: 'DELETE_EARNINGS_START',
  DELETE_EARNINGS_SUCCESS: 'DELETE_EARNINGS_SUCCESS',
  DELETE_EARNINGS_FAILURE: 'DELETE_EARNINGS_FAILURE',

  GET_TOTAL_STATISTICS: 'GET_TOTAL_STATISTICS',
  CLEAR_STATISTICS: 'CLEAR_STATISTICS',

  SET_PREFERENCES: 'SET_PREFERENCES',

  ADD_NOTE_START: 'ADD_NOTE_START',
  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILURE: 'ADD_NOTE_FAILURE',

  REMOVE_NOTE_START: 'REMOVE_NOTE_START',
  REMOVE_NOTE_SUCCESS: 'REMOVE_NOTE_SUCCESS',
  REMOVE_NOTE_FAILURE: 'REMOVE_NOTE_FAILURE',

  EDIT_NOTE_START: 'EDIT_NOTE_START',
  EDIT_NOTE_SUCCESS: 'EDIT_NOTE_SUCCESS',
  EDIT_NOTE_FAILURE: 'EDIT_NOTE_FAILURE',

  GET_NOTES_START: 'GET_NOTES_START',
  GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
  GET_NOTES_FAILURE: 'GET_NOTES_FAILURE',

  ADD_FEATURED_SERVICE_START: 'ADD_FEATURED_SERVICE_START',
  ADD_FEATURED_SERVICE_SUCCESS: 'ADD_FEATURED_SERVICE_SUCCESS',
  ADD_FEATURED_SERVICE_FAILURE: 'ADD_FEATURED_SERVICE_FAILURE',

  REMOVE_FEATURED_SERVICE_START: 'REMOVE_FEATURED_SERVICE_START',
  REMOVE_FEATURED_SERVICE_SUCCESS: 'REMOVE_FEATURED_SERVICE_SUCCESS',
  REMOVE_FEATURED_SERVICE_FAILURE: 'REMOVE_FEATURED_SERVICE_FAILURE',

  EDIT_FEATURED_SERVICE_START: 'EDIT_FEATURED_SERVICE_START',
  EDIT_FEATURED_SERVICE_SUCCESS: 'EDIT_FEATURED_SERVICE_SUCCESS',
  EDIT_FEATURED_SERVICE_FAILURE: 'EDIT_FEATURED_SERVICE_FAILURE',

  GET_ALL_FEATURED_SERVICES_START: 'GET_ALL_FEATURED_SERVICES_START',
  GET_ALL_FEATURED_SERVICES_SUCCESS: 'GET_ALL_FEATURED_SERVICES_SUCCESS',
  GET_ALL_FEATURED_SERVICES_FAILURE: 'GET_ALL_FEATURED_SERVICES_FAILURE',

  GET_USER_FEATURED_SERVICES_START: 'GET_USER_FEATURED_SERVICES_START',
  GET_USER_FEATURED_SERVICES_SUCCESS: 'GET_USER_FEATURED_SERVICES_SUCCESS',
  GET_USER_FEATURED_SERVICES_FAILURE: 'GET_USER_FEATURED_SERVICES_FAILURE',

  SET_USER_FEATURED_SERVICES_START: 'SET_USER_FEATURED_SERVICES_START',
  SET_USER_FEATURED_SERVICES_SUCCESS: 'SET_USER_FEATURED_SERVICES_SUCCESS',
  SET_USER_FEATURED_SERVICES_FAILURE: 'SET_USER_FEATURED_SERVICES_FAILURE',

  CLEAR_FEATURED_SERVICES: 'CLEAR_FEATURED_SERVICES',

  SET_STATUS_CALL_START: 'SET_STATUS_CALL_START',
  SET_STATUS_CALL_SUCCESS: 'SET_STATUS_CALL_SUCCESS',
  SET_STATUS_CALL_FAILURE: 'SET_STATUS_CALL_FAILURE',

  USER_ACTIVITY_FETCH_REQUEST: 'USER_ACTIVITY_FETCH_REQUEST',
  USER_ACTIVITY_FETCH_SUCCESS: 'USER_ACTIVITY_FETCH_SUCCESS',
  USER_ACTIVITY_FETCH_FAILURE: 'USER_ACTIVITY_FETCH_FAILURE',
  USER_ACTIVITY_SET_GROUP: 'USER_ACTIVITY_SET_GROUP',
  USER_ACTIVITY_RESET: 'USER_ACTIVITY_RESET',

  FETCH_SECTORS_REQUEST: 'FETCH_SECTORS_REQUEST',
  FETCH_SECTORS_SUCCESS: 'FETCH_SECTORS_SUCCESS',
  FETCH_SECTORS_FAILURE: 'FETCH_SECTORS_FAILURE',

  FETCH_PARTNERS_REQUEST: 'FETCH_PARTNERS_REQUEST',
  FETCH_PARTNERS_SUCCESS: 'FETCH_PARTNERS_SUCCESS',
  FETCH_PARTNERS_FAILURE: 'FETCH_PARTNERS_FAILURE',
  DELETE_PARTNERS_FAILURE: 'DELETE_PARTNERS_FAILURE',
  CLEANUP_PARTNERS_FAILURE: 'CLEANUP_PARTNERS_FAILURE',

  FETCH_COUNTRIES_REQUEST: 'FETCH_COUNTRIES_REQUEST',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE: 'FETCH_COUNTRIES_FAILURE',
  DELETE_COUNTRIES_FAILURE: 'DELETE_COUNTRIES_FAILURE',
  CLEANUP_COUNTRIES_FAILURE: 'CLEANUP_COUNTRIES_FAILURE',

  GET_APPOINTMENTS_START: 'GET_APPOINTMENTS_START',
  GET_APPOINTMENTS_SUCCESS: 'GET_APPOINTMENTS_SUCCESS',
  GET_APPOINTMENTS_FAILURE: 'GET_APPOINTMENTS_FAILURE',

  SET_APPOINTMENTS_DISPLAY_RANGE: 'SET_APPOINTMENTS_DISPLAY_RANGE',

  START_FORCE_LOGOUT: 'START_FORCE_LOGOUT',
  FORCE_LOGOUT_SUCCESS: 'FORCE_LOGOUT_SUCCESS',
  FORCE_LOGOUT_FAILURE: 'FORCE_LOGOUT_FAILURE',

  SET_CONFIG_DATA: 'SET_CONFIG_DATA',
  SET_CONFIG_ERROR: 'SET_CONFIG_ERROR',

  SET_USER_AUTH: 'SET_USER_AUTH',

  UPDATE_USER_STATE: 'UPDATE_USER_STATE',

  SET_FAILED_AUTOLINKING_EMAILS: 'SET_FAILED_AUTOLINKING_EMAILS'
};
