/** @flow */

import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import SectorInformationTitle from '../sector-infromation-title';
import SectorRemovingDialog from '../sector-removing-dialog';
import { sectorsUpdate } from '../../../store/actions/sectors';
import type { SectorType } from '../../../store/reducers/sectors';

export const SECTOR_TYPE = 'sector';
export const CATEGORY_TYPE = 'category';
export const SERVICE_TYPE = 'service';

const useStyles = makeStyles({
  sectorInformation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  content: {},
  controls: {}
});

export type SectorInformationType = {
  id: number,
  name: string,
  tag: string,
  childs: SectorType[],
};

export type PropsInformationType = {
  sector: SectorInformationType,
  parents: string[],
  handleEditSector: () => void,
};

type SectorInformationProps = PropsInformationType & {};

function SectorInformation(props: SectorInformationProps) {
  const dispatch = useDispatch();
  const { sector, parents, handleEditSector } = props;
  const { id, name, tag, childs } = sector;

  const [removingMode, setRemovingMode] = React.useState<boolean>(false);

  const handleRemoveClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setRemovingMode(true);
  };

  const handleRemoveCancel = () => {
    setRemovingMode(false);
  };

  const handleRemoveConfirm = () => {
    const data = { id };
    dispatch(sectorsUpdate('DELETE', data));
    setRemovingMode(false);
  };

  const getTypeOfEditItem = (item = sector) => {
    const childs = [];

    if (item.childs && item.childs.length) {
      childs.push(item.tag);
      for (let i = 0; i < item.childs.length; i += 1) {
        const child = item.childs[i];
        if (child.childs && child.childs.length) {
          childs.push(child.tag);
          break;
        }
      }
    }

    return childs.length ? childs.length > 1 ? SECTOR_TYPE : CATEGORY_TYPE : SERVICE_TYPE;
  };

  const handleEditClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const editItemType = getTypeOfEditItem();

    event.stopPropagation();
    handleEditSector({
      editItemType,
      parents,
      ...sector,
    });
  };

  const classes = useStyles();

  return (
    <div className={classes.sectorInformation}>
      <div className={classes.content}>
        <SectorInformationTitle name={name} tag={tag} />
      </div>
      <div className={classes.controls}>
        <>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={!!childs.length} onClick={handleRemoveClick}>
            <DeleteIcon />
          </IconButton>
        </>
      </div>
      <SectorRemovingDialog
        open={removingMode}
        data={{ name, tag }}
        onCancel={handleRemoveCancel}
        onRemove={handleRemoveConfirm}
      />
    </div>
  );
}

export default SectorInformation;
