/** @flow */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import SectorPanelItem from '../../components/sectors/sector-panel-item';
import Loader from '../../components/ui/loader/loader';
import SectorForm from '../../components/sectors/sector-form';
import { sectorsFetch, sectorsUpdate } from '../../store/actions/sectors';
import { selectSectorsEntities, selectSectorsIsFetching } from '../../store/reducers/sectors';
import { selectPartnersEntities, selectPartnersIsFetching } from '../../store/reducers/partners';
import { selectUserAuth } from '../../store/reducers/logged-in-user';
import type { SectorType } from '../../store/reducers/sectors';
import type { PartnerType } from '../../store/reducers/partners';
import { apiAddNotification } from '../../store/actions/api';

import { exportSectorsCsv } from '../../store/api/csvExport';
import performCSVExport from '../../interactors/csvExport'

import style from './sectors.module.css';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sectors: {
    padding: '32px',
    width: '100%',
    border: '1px solid #000',
    boxShadow: 'none'
  },
  sectorPanels: {
    padding: '16px 0'
  },
  button: {
    backgroundColor: '#068f06',
    marginRight: '12px',
    '&:hover': {
      backgroundColor: '#19a919'
    }
  },
  buttonCsv: {
    backgroundColor: '#1890ff',
    '&:hover': {
      backgroundColor: '#40a9ff'
    }
  }
});

function Sectors() {
  const dispatch = useDispatch();

  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(false);
  const [editSector, setEditSector] = React.useState<number>(null);

  React.useEffect(() => {
    dispatch(sectorsFetch());
  }, [dispatch]);

  const isFetchingSectors: boolean = useSelector(selectSectorsIsFetching);
  const isFetchingPartners: boolean = useSelector(selectPartnersIsFetching);

  const userAuth = useSelector(selectUserAuth);

  const sectors: SectorType[] = useSelector(selectSectorsEntities) || [];
  const partners: PartnerType[] = useSelector(selectPartnersEntities) || [];

  const csvDownloadAnchor = React.createRef();

  const classes = useStyles();

  const handleOpenSectorForm = () => {
    setIsFormOpen(true);
  };

  const handleEditSector = sector => {
    setEditSector(sector);
    handleOpenSectorForm();
  };

  const handleCancel = () => {
    setIsFormOpen(false);
    setEditSector(null);
  };

  const handleSubmit = (method, data) => {
    dispatch(sectorsUpdate(method, data));
    handleCancel();
  };

  const onCSVExportClick = async () => {
    if (!csvDownloadAnchor.current) {
      return;
    }
    const downloadAnchor = csvDownloadAnchor.current;

    const apiDelegate = (userAuth: string) => exportSectorsCsv(userAuth);
    await performCSVExport(userAuth, apiDelegate, () => dispatch(apiAddNotification), downloadAnchor);
  }

  return (
    <div className={style.sectorsWrap}>
      <Paper className={classes.sectors}>
        <div className={classes.header}>
          <Typography variant="h2">Sectors</Typography>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={handleOpenSectorForm}
            >
              Add New
            </Button>
            <Button 
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonCsv}
              onClick={onCSVExportClick}
            >
              Export CSV
            </Button>
          </div>          
        </div>
        <div className={classes.sectorPanels}>
          {isFetchingSectors || isFetchingPartners ? (
            <Loader />
          ) : (
            sectors.map((sector: SectorType) => <SectorPanelItem key={sector.id} sector={sector} handleEditSector={handleEditSector} />)
          )}
        </div>
      </Paper>
      {!isFetchingSectors && !isFetchingPartners && (
        <SectorForm
          editSector={editSector}
          sectors={sectors}
          partners={partners}
          open={isFormOpen}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a style={{ display: 'none' }} href='' ref={csvDownloadAnchor} />
    </div>
  );
}

export default Sectors;
