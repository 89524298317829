import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";

import rootReducer, { history } from "./reducers";

const loggerMiddleware = createLogger();
const middlewares = [
  thunk,
  routerMiddleware(history)
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}

const configureStore = (initialState = {}) => {
  const middlewareEnhancer = applyMiddleware(
    ...middlewares
  );

  const composedEnhancer = composeWithDevTools(middlewareEnhancer);

  return createStore(rootReducer, initialState, composedEnhancer);
};

export default configureStore;
