/** @noflow */

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import React from 'react';

type SectorInformationTitleProps = {
  name: string,
  tag: string,
};

function SectorInformationTitle(props: SectorInformationTitleProps) {
  const { name, tag } = props;

  return (
    <div>
      <Typography variant="caption">
        {name}
      </Typography>
      {' '}
      <Chip label={tag} />
    </div>
  );
}

export default SectorInformationTitle;
