import React from "react";
import style from "./style.module.css";

const Textarea = props => {
  const isChanged = props.changedFields
    // eslint-disable-next-line no-prototype-builtins
    ? props.changedFields.hasOwnProperty(props.name)
    : false;

  return (
    <div className={`${style.formTextarea} ${isChanged && style.formTaChanged}`}>
      {props.label && <label>{props.label}</label>}
      <div className={style.taConteiner}>
        {isChanged ? (
          [
            <div className={style.prevValue}>
              {props.changedFields[props.name]}
            </div>,
            <textarea
              rows="5"
              disabled={props.disabled}
              placeholder={props.placeholder}
              name={props.name}
              onChange={props.onChange}
              value={props.value}
            />
          ]
        ) : (
          <textarea
            rows="5"
            disabled={props.disabled}
            placeholder={props.placeholder}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
          />
        )}
      </div>
    </div>
  );
};

export default Textarea;
