/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type { ActionType, UserActivityItem, UserActivityState, UserActivityGroup, AdminPanelStore } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'userActivity';

// Constants (up)

// Reducer (down)

const initialState: UserActivityState = {
  fetched: false,
  fetching: false,
  activityGroup: 'CLIENTS',
  error: '',
  entities: [],
};

export default function reducer(
  state: UserActivityState = initialState, action: ActionType<>,
): UserActivityState {
  const { error, type, payload } = action;

  switch (type) {
    case actionTypes.USER_ACTIVITY_FETCH_REQUEST:
      return {
        ...state,
        fetched: false,
        fetching: true,
        error: initialState.error,
        entities: initialState.entities,
      };

    case actionTypes.USER_ACTIVITY_FETCH_SUCCESS: {
      if (!payload) {
        return { ...state };
      }

      const { activities }: { activities: UserActivityItem[] } = payload;

      return {
        ...state,
        fetched: true,
        fetching: false,
        error: initialState.error,
        entities: [...activities],
      };
    }

    case actionTypes.USER_ACTIVITY_FETCH_FAILURE:
      return {
        ...state,
        fetched: false,
        fetching: false,
        error: error || 'Cannot fetch activities for current user!',
        entities: initialState.entities,
      };

    case actionTypes.USER_ACTIVITY_SET_GROUP: {
      if (!payload) {
        return { ...state };
      }

      const { activityGroup }: { activityGroup: UserActivityGroup } = payload;

      return {
        ...state,
        activityGroup,
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, void, UserActivityState> = (store: AdminPanelStore) => store[moduleName];

export const selectUserActivityIsFetching: OutputSelector<AdminPanelStore, void, boolean> = createSelector(
  stateSelector,
  (userActivity: UserActivityState) => userActivity.fetching,
);

export const selectUserActivityGroup: OutputSelector<AdminPanelStore, void, UserActivityGroup> = createSelector(
  stateSelector,
  (userActivity: UserActivityState): UserActivityGroup => userActivity.activityGroup,
);

export const selectUserActivityEntities: OutputSelector<AdminPanelStore, void, UserActivityItem[]> = createSelector(
  stateSelector,
  (userActivity: UserActivityState): UserActivityItem[] => userActivity.entities,
);

// Selectors (up)
