import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import { Spin } from 'antd';

import Input from '../../input';

import style from './style.module.css';
import { addFreeCredits, deleteFreeCredits } from '../../../store/actions/user';
import { TELMIE_PAY } from '../../../constants';

const ADD_FREE_CREDITS_DESC = 'Bonus from Telmie';
const DELETE_FREE_CREDITS_DESC = 'Telmie credit Expired';

interface FreeCreditsProps {
  credits: any;
  addFreeCredits: any;
  userId: string;
  userAuth: string;
  deleteFreeCredits: any;
}

interface FreeCreditsState {
  addValue: string;
  deleteValue: string;
  descValue: string;
  success: boolean;
}

class FreeCredits extends Component<FreeCreditsProps, FreeCreditsState> {
  constructor(props) {
    super(props);

    this.state = {
      addValue: '',
      deleteValue: '',
      descValue: '',
      success: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { credits = {} } = this.props;
    const { credits: nextCredits = {} } = nextProps;

    // eslint-disable-next-line no-unused-expressions
    credits.isProcessing === true &&
      !nextCredits.errorMsg &&
      this.setState({ addValue: '', deleteValue: '', descValue: '', success: true });
  }

  onChangeAdd = e => {
    const { value } = e.target;
    const { descValue } = this.state;
    let addFreeCreditsDesc = descValue;

    if (!addFreeCreditsDesc || addFreeCreditsDesc === DELETE_FREE_CREDITS_DESC) {
      addFreeCreditsDesc = ADD_FREE_CREDITS_DESC;
    }

    this.setState({
      addValue: value,
      descValue: addFreeCreditsDesc,
      isValid: !!value.match(/^\d+(\.\d+){0,1}$/),
      success: false
    });
  };

  onChangeDelete = e => {
    const { value } = e.target;
    const { descValue } = this.state;
    let deleteFreeCreditsDesc = descValue;

    if (!deleteFreeCreditsDesc || deleteFreeCreditsDesc === ADD_FREE_CREDITS_DESC) {
      deleteFreeCreditsDesc = DELETE_FREE_CREDITS_DESC;
    }

    this.setState({
      deleteValue: value,
      descValue: deleteFreeCreditsDesc,
      isValid: !!value.match(/^\d+(\.\d+){0,1}$/),
      success: false
    });
  };

  onChangeDesc = e => {
    const { value } = e.target;

    this.setState({
      descValue: value,
      isValid: value.length <= 25,
      success: false
    });
  };

  onAdd = () => {
    const { addFreeCredits, userId, userAuth } = this.props;
    const { isValid, addValue, descValue } = this.state;

    const data = {
      userId,
      amount: parseFloat(addValue) || 0,
      paymentSystem: TELMIE_PAY,
      description: descValue,
    };

    isValid && addFreeCredits('POST', userAuth, data);
  };

  onDelete = () => {
    const { deleteFreeCredits, userId, userAuth } = this.props;
    const { isValid, deleteValue, descValue } = this.state;

    const data = {
      userId,
      amount: parseFloat(deleteValue) || 0,
      paymentSystem: TELMIE_PAY,
      description: descValue,
    };

    isValid && deleteFreeCredits('DELETE', userAuth, data);
  };

  render() {
    const { credits = {} } = this.props;

    return (
      <>
        <div className={cn(style.creditsContainer, {
          [style.creditsContainerDisabled]: credits.isProcessing || this.state.deleteValue
        })}
        >
          <div className={style.creditsLabel}>Add telmie credit</div>
          <Input
            placeholder="0.00"
            onChange={this.onChangeAdd}
            value={this.state.addValue}
            disabled={credits.isProcessing || this.state.deleteValue}
          />
        </div>
        <div className={cn(style.creditsContainer, {
          [style.creditsContainerDisabled]: credits.isProcessing || this.state.addValue
        })}
        >
          <div className={style.creditsLabel}>Delete telmie credit</div>
          <Input
            placeholder="0.00"
            onChange={this.onChangeDelete}
            value={this.state.deleteValue}
            disabled={credits.isProcessing || this.state.addValue}
          />
        </div>
        <div className={cn(style.creditsContainer)}>
          <div className={style.creditsLabel}>Description</div>
          <Input
            placeholder=""
            onChange={this.onChangeDesc}
            value={this.state.descValue}
            disabled={credits.isProcessing}
            maxlength={25}
          />
        </div>

        {credits.isProcessing ? (
          <div style={{ marginLeft: 20 }}>
            <Spin size="large" />
          </div>
        ) : (
          this.state.addValue && this.state.descValue &&
          (this.state.isValid ? (
            <button className="saveBtn" onClick={this.onAdd} type="button">
              Add credits
            </button>
          ) : (
            <div style={{ color: 'red', fontStyle: 'italic' }}>
              Enter correct number
            </div>
          ))
        )}
        {credits.isProcessing ? (
          <div style={{ marginLeft: 20 }}>
            <Spin size="large" />
          </div>
        ) : (
          this.state.deleteValue && this.state.descValue &&
          (this.state.isValid ? (
            <button className="saveBtn" onClick={this.onDelete} type="button">
              Delete credits
            </button>
          ) : (
            <div style={{ color: 'red', fontStyle: 'italic' }}>
              Enter correct number
            </div>
          ))
        )}
        {this.state.success && credits.message && (
          <span style={{ marginRight: 10, color: 'green' }}>{credits.message}</span>
        )}
        {credits.errorMsg && (
          <div style={{ color: 'red', fontStyle: 'italic' }}>
            {credits.errorMsg}
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addFreeCredits,
  deleteFreeCredits,
}, dispatch);

export default connect(null, mapDispatchToProps)(FreeCredits);
