/** @flow */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
/* $FlowIgnore[cannot-resolve-module] there are no Flow typings for matreial-ui */
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* $FlowIgnore[cannot-resolve-module] there are no Flow typings for matreial-ui */
import Checkbox from '@material-ui/core/Checkbox';
import Card from '../../Layout/card';
import { selectPartnersEntities, selectPartnersIsFetching } from '../../../store/reducers/partners';
import type { PartnerType, UserWithStatus } from '../../../types';
import { editUser } from '../../../store/actions/user';

import style from './partners.module.css';

type PartnersProps = {
  user: UserWithStatus,
  userAuth: string,
};

const PartnersList = (props: PartnersProps): React$Node => {
  const dispatch = useDispatch();
  const { user, userAuth } = props;
  const { id, relatedPartners = [] } = user || {};
  const [selectedPartners, setSelectedPartners] = React.useState([]);
  const [isEdited, setIsEdited] = React.useState(false);
  const isFetchingPartners: boolean = useSelector(selectPartnersIsFetching);
  const partners: PartnerType[] = useSelector(selectPartnersEntities) || [];

  React.useEffect(() => {
    setSelectedPartners(relatedPartners);
  }, [relatedPartners]);

  const checkIsEdited = partners => {
    const sortedRelatedPartners = relatedPartners ? [...relatedPartners].sort() : []
    const sortedSelectedPartners = [...partners].sort();
    const isEdited = JSON.stringify(sortedRelatedPartners) === JSON.stringify(sortedSelectedPartners);

    setIsEdited(!isEdited);
  }

  const handleOnChangePartners = e => {
    const { name, checked } = e.target
    let partners;

    if (checked) {
      partners = selectedPartners ? [...selectedPartners, name] : [name]
    } else {
      partners = selectedPartners ? selectedPartners.filter(selectedPartner => selectedPartner !== name) : []
    }

    checkIsEdited(partners);
    setSelectedPartners(partners);
  }

  const handleOnCancel = () => {
    setIsEdited(false);
    setSelectedPartners(relatedPartners);
  }

  const handleOnSave = () => {
    dispatch(editUser({
      id,
      relatedPartners: selectedPartners,
    }, userAuth, false));
  }

  const renderPartners = partners.map(partner => {
    const checked = selectedPartners ? selectedPartners.some(selectedPartner => selectedPartner === partner.tag) : false;

    return (
      <div className={style.partner} key={partner.id}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={checked}
              onChange={handleOnChangePartners}
              name={partner.tag}
              color="primary"
            />
          )}
          label={partner.name}
        />
      </div>
    );
  });

  return partners.length && !isFetchingPartners ? (
    <div className="featuredServiceCard">
      <div className="headline">Partners</div>
      <Card>
        <div className={style.partnersWrap}>
          {renderPartners}
        </div>

        {isEdited && (
          <div className={style.controls}>
            <button
              type="button"
              className="cancelBtn"
              onClick={handleOnCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="saveBtn"
              onClick={handleOnSave}
            >
              Save
            </button>
          </div>
        )}
      </Card>
    </div>
  ) : null;
};

export default PartnersList;
