/** @flow */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Table, Spin, Modal, Button, PageHeader } from 'antd';
import 'antd/dist/antd.css';
import Card from '../../components/Layout/card';
import CountriesForm from './countries-form';
import { countriesTableColumns as columns } from '../../helpers/table-data';
import { addCountry, editCountry, deleteCountry, clearCountriesError, fetchCountries } from '../../store/actions/countries';
import { selectCountriesEntities, selectCountriesError, selectCountriesFetching } from '../../store/reducers/countries';
import { apiAddNotification } from '../../store/actions/api';
import style from './style.module.css';


const CODE_NAME = 'code';

const DEFAULT_FIELDS_VALUE = {
  code: '',
  displayName: '',
  localName: '',
  searchTerms: ''
};

export type CountryDataType = {
  displayName: string,
  localName: string,
  searchTerms: string
};

type CountryProps = {
  addCountry: () => void,
  editCountry: () => void,
  deleteCountry: () => void,
  clearCountriesError: () => void,
};

type CountryState = {
  visibleModalAdd: boolean,
  visibleModalEdit: boolean,
  visibleModalDelete: boolean,
  errors: { [key: string]: string },
  countryData: CountryDataType,
  activeKeys: Array<string>,
};

class Countries extends Component<RouteChildrenProps & CountryProps, CountryState> {

  constructor(props) {
    super(props);

    this.state = {
      visibleModalAdd: false,
      visibleModalEdit: false,
      visibleModalDelete: false,
      errors: {},
      countryData: DEFAULT_FIELDS_VALUE
    }
  }

  componentDidMount() {
    const { fetchCountries, userData: { userAuth } } = this.props;
    fetchCountries(userAuth);
  }

  handleChangeField = e => {
    const { value, name } = e.target;
    let validValue = value;
    if (name === CODE_NAME) {
      validValue = value.toUpperCase();
    }

    this.setState(prev => ({
      errors: {},
      countryData: {
        ...prev.countryData,
        [name]: validValue,
      }
    }));
  };

  handleFieldValueChanged = (name, value) => {
    this.setState(prev => ({
      errors: {},
      countryData: {
        ...prev.countryData,
        [name]: value,
      }
    }));
  };

  showModalAdd = () => {
    this.setState({
      visibleModalAdd: true
    })
  }

  showModalEdit = () => {
    this.setState({
      visibleModalEdit: true,
    });
  };

  showModalDelete = () => {
    this.setState({
      visibleModalDelete: true
    });
  };

  setDefaultCountryDataState = () => (
    this.setState({
      countryData: DEFAULT_FIELDS_VALUE,
      errors: {},
    })
  );

  handleCancel = () => {
    this.setDefaultCountryDataState();
    this.setState({
      visibleModalAdd: false,
      visibleModalEdit: false,
      visibleModalDelete: false
    });
  };

  handleCancelError = () => {
    const { clearCountriesError } = this.props;

    clearCountriesError();
  };

  openEditModal = data => () => {
    this.showModalEdit();
    this.setState({
      countryData: data
    })
  };

  openDeleteModal = data => () => {
    this.showModalDelete();
    this.setState({
      countryData: data
    })
  };

  handleEditCountry = async () => {
    const { editCountry } = this.props;
    const { countryData } = this.state;

    const isEditingSucceeded = await editCountry(countryData);

    if(isEditingSucceeded) {
      this.setDefaultCountryDataState();
      this.setState({
        visibleModalEdit: false,
      });
    }
  };

  handleDeleteCountry = () => {
    const { deleteCountry } = this.props;
    const { countryData } = this.state;
    this.setState({
      visibleModalDelete: false,
    });
    deleteCountry(countryData);
  };

  handleOnSubmit = data => {
    const { addCountry } = this.props;

    this.setDefaultCountryDataState();
    addCountry(data);
  };


  validateForm = callback => {
    const { countryData } = this.state;
    const errors = {};

    if (!countryData.code) {
      errors.code = 'The Code field is required.';
    }
    if (!countryData.displayName) {
      errors.displayName = 'The Name field is required.';
    }
    if (!countryData.localName) {
      errors.localName = 'The Local Name field is required.';
    }
    if (!countryData.searchTerms) {
      errors.searchTerms = 'The Search Terms for Google Maps field is required.';
    }

    if (Object.keys(errors).length) {
      let errorMessage = '';
      Object.keys(errors).forEach(key => {
        errorMessage += `${errors[key]  }\n`;
      });
      // eslint-disable-next-line no-alert
      alert(errorMessage);
    } else {
      callback({
        ...countryData
      });
    }
  };

  validateAddForm = callback => {
    const { countryData } = this.state;
    const errors = {};

    if (!countryData.code) {
      errors.code = 'The Code field is required.';
    } else if (this.props.countries.find(c => c.code === countryData.code)) {
      errors.code = 'There\'s already a country with that code.';
    }
    if (!countryData.displayName) {
      errors.displayName = 'The Name field is required.';
    }
    if (!countryData.localName) {
      errors.localName = 'The Local Name field is required.';
    }
    if (!countryData.searchTerms) {
      errors.searchTerms = 'The Search Terms for Google Maps field is required.';
    }

    if (Object.keys(errors).length) {
      let errorMessage = '';
      Object.keys(errors).forEach(key => {
        errorMessage += `${errors[key]  }\n`;
      });
      // eslint-disable-next-line no-alert
      alert(errorMessage);
    } else {
      callback({
        ...countryData
      });
    }
  };

  render() {
    const { isFetchingCountries, countriesError, countries } = this.props;
    const { visibleModalAdd, visibleModalEdit, visibleModalDelete, countryData, errors } = this.state;

    return (
      <Card cardClass="route-content">
        {!isFetchingCountries ? (
          <div className="featuredService">
            <PageHeader
              title="Pro Countries"
              backIcon={false}
            >
              <Button onClick={this.showModalAdd}>Add new Country</Button>
            </PageHeader>
            <Table
              columns={columns(
                this.openEditModal,
                this.openDeleteModal,
              )}
              pagination={false}
              rowKey={record => record.id}
              onChange={this.onChange}
              onRow={this.onRow}
              size="small"
              dataSource={countries || []}
              locale={{ emptyText: 'No countries' }}
              scroll={{ x: true }}
            />

            <Modal
              title="Add new Country"
              visible={visibleModalAdd}
              width="60%"
              onCancel={this.handleCancel}
              footer={null}
            >
              <CountriesForm
                data={countryData}
                errors={errors}
                onChangeField={this.handleChangeField}
                onFieldValueChanged={this.handleFieldValueChanged}
                onSubmit={() => this.validateAddForm(this.handleOnSubmit)}
                defaultCountryData={DEFAULT_FIELDS_VALUE}
              />
            </Modal>

            { visibleModalEdit && (
              <Modal
                title={`Edit ${countryData ? countryData.name : 'country'}`}
                visible={visibleModalEdit}
                width="60%"
                onCancel={this.handleCancel}
                footer={[
                  <Button key="submit" type="primary" onClick={() => this.validateForm(this.handleEditCountry)}>
                    Submit
                  </Button>,
                  <Button key="back" type="danger" onClick={this.handleCancel}>
                    Cancel changes
                  </Button>,
                ]}
              >
                <CountriesForm
                  editFS
                  data={countryData}
                  errors={errors}
                  onChangeField={this.handleChangeField}
                  onFieldValueChanged={this.handleFieldValueChanged}
                  defaultCountryData={DEFAULT_FIELDS_VALUE}
                />
              </Modal>
            )}

            <Modal
              title='Delete country'
              visible={visibleModalDelete}
              width="35%"
              onCancel={this.handleCancel}
              footer={[
                <Button key="submit" type="danger" onClick={this.handleDeleteCountry}>
                  Yes
                </Button>,
                <Button key="back" type="primary" onClick={this.handleCancel}>
                  No
                </Button>,
              ]}
            >
              Are you sure you want to delete this Country?
            </Modal>

            {!!countriesError?.length && (
              <Modal
                title='Error'
                visible={!!countriesError?.length}
                width="35%"
                onCancel={this.handleCancelError}
                footer={[
                  <Button key="back" type="primary" onClick={this.handleCancelError}>
                    Ok
                  </Button>,
                ]}
              >
                <div className={style.errorMsg}>{countriesError}</div>
              </Modal>
            )}
          </div>

        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({

  userData: state.loggedInUser,
  isFetchingCountries: selectCountriesFetching(state),
  countriesError: selectCountriesError(state),
  countries: selectCountriesEntities(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCountries,
  addCountry,
  editCountry,
  deleteCountry,
  clearCountriesError,
  addNotification: apiAddNotification,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries);
