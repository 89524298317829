/** @flow */

import React, { useContext } from 'react';
import { Popover } from 'antd';
import { InternationalizationContext } from '../../../i18n/internationalization';
import style from './style.module.css';

import type { MoneyHistoryTransactionType } from '../../../types';

type BalanceItemPaymentTransactionDetails = {|
  title: string,
  amount: string,
|}

type BalanceItemProps = {|
  title: string,
  date: string,
  flowBalance: string,
  type: MoneyHistoryTransactionType,
  descriptions: Array<BalanceItemPaymentTransactionDetails>,
  amount: string,
|}

const BalanceItem = ({ title, date, flowBalance, type, amount, descriptions }: BalanceItemProps): React$Node => {
  const intlContext = useContext(InternationalizationContext);
  
  const amountCell = (descriptions && descriptions.length)
    ? (
      <Popover
        content={(
          <table className={style.balanceSubItems}>
            {
              descriptions?.map(description => (
                <tr>
                  <td>{description.title}</td><td>{intlContext.formatCurrency(description.amount)}</td>
                </tr>
              ))
            }
          </table>
        )}
      >
        {intlContext.formatCurrency(amount)}
      </Popover>
      )
    : intlContext.formatCurrency(amount)

  return (
    <div className={`${style.tableRow} ${style.transactionItem}`}>
      <div>{title}</div>
      <div>{intlContext.formatNumericDateTimeWithTimeZone(date)}</div>
      <div>{type}</div>
      <div className={style.balanceCell}>
        <div>{amountCell}</div>
        <div>{intlContext.formatCurrency(flowBalance)}</div>
      </div>
    </div>
  );
};

export default BalanceItem;
