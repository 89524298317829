/** @flow */

import type { Dispatch } from 'redux';
import type { ActionType, NotificationType, AddNotificationActionPayload, ModifyNotificationActionPayload } from '../../types';
import { actionTypes } from './index';

// Constants (down)

export const NOTIFICATION_HIDE_DURATION = 5000;

const NOTIFICATION_REMOVE_DURATION = 1000;

export const NOTIFICATION_TYPE: $ReadOnly<{ [key: 'ERROR' | 'SUCCESS']: string }> = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
});

// Constants (up)

export function apiAddNotification(
  notification: NotificationType
): ActionType<AddNotificationActionPayload> {
  return {
    type: actionTypes.API_ADD_NOTIFICATION,
    payload: { notification },
  };
}

export function apiCloseNotification(
  notificationId: string,
): (dispatch: Dispatch<ActionType<ModifyNotificationActionPayload>>) => void {
  return (dispatch: Dispatch<ActionType<ModifyNotificationActionPayload>>) => {
    dispatch({
      type: actionTypes.API_CLOSE_NOTIFICATION,
      payload: { notificationId },
    });

    setTimeout(() => {
      dispatch(apiRemoveNotification(notificationId))
    }, NOTIFICATION_REMOVE_DURATION);
  };
}

export function apiRemoveNotification(
  notificationId: string,
): ActionType<ModifyNotificationActionPayload> {
  return {
    type: actionTypes.API_REMOVE_NOTIFICATION,
    payload: { notificationId },
  };
}
