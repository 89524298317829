import React from "react";
import style from "./style.module.css";

const Delimeter = ({ statusText }) => {
  const isRed =
    statusText === "PENDING_APPROVAL" ||
    statusText === "STARTED_PRO_APP" ||
    statusText === "ARCHIVED" || 
    statusText === "DECLINE_APPROVAL";

  return (
    <div className={style.delimeter}>
      <div
        className={`${style.statusText} ${
          isRed ? style.pendingStatus : style.userStatus
        }`}
      >
        {statusText}
      </div>
    </div>
  );
};

export default Delimeter;
