import React from "react";
import style from "./style.module.css";

const Card = props => (
  <div
    className={`${style.cardContainer} ${
      props.cardClass ? props.cardClass : ""
    }`}
  >
    {props.headerText && (
      <div className={style.cardHeader}>
        {props.headerText}
        {props.headerBtnText && (
          <div className={style.headerBtn} onClick={props.onHeaderBtnClick}>
            {props.headerBtnText}
          </div>
        )}
      </div>
    )}

    <div className={`${props.class ? props.class : ""}`}>{props.children}</div>
  </div>
);

export default Card;
