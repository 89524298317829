/** @flow */

import React, { Component } from 'react';
import type { AbstractComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Spin,
  Collapse,
  Descriptions,
  Tooltip,
  Statistic,
  DatePicker,
  Input,
  PageHeader,
  Row,
  Button,
  type PaginationConfig
} from 'antd';
import type { ContextRouter } from 'react-router-dom';
import style from './style.module.css';
import Card from '../../components/Layout/card';

import {
  getTransactions,
  clearTransactions,
} from '../../store/actions/user';
import { transactionsColumns as columns } from '../../helpers/table-data';
import { PAGE_SIZE, ROUTES } from '../../constants';
import { countItemsByStatus } from '../../helpers';
import {
  selectTransactionsDays, selectTransactionsRange,
} from '../../store/reducers/transactions';
import { apiAddNotification } from '../../store/actions/api';
import { transactionsSetDays, transactionsSetRange } from '../../store/actions/transactions';
import type { NotificationType, SorterType } from '../../types';
import { InternationalizationContext } from '../../i18n/internationalization';
import type { IInternationalizationContext } from '../../i18n/internationalization';
// import { exportTransactions } from '../../store/api/csvExport';
import { exportTransactions } from '../../store/api/csvExport';
import performCSVExport from '../../interactors/csvExport'

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Search } = Input;
const getPage = search => (search ? parseInt(search.slice(6), 10) : 1);

type TransactionsOwnProps = $ReadOnly<{| |}>

  type TransactionsProps = $ReadOnly<{|
  ...TransactionsOwnProps,
  days: string,
  clearTransactions: () => void,
  getTransactions: (userAuth: string, from?: any, to?: any, days?: string) => void,
  notifyCSVExportFailure: (notification: NotificationType) => void,
  range: any[],
  setDays: (days: string) => void,
  setRange: (range: any[]) => void,
  uArrays: {
  error ?: boolean,
    load ?: boolean,
    message ?: string,
    transactions: any[],
  },
  userData: {
    userAuth ?: string,
  },
|}>;

type TransactionState = {
  isSearched: boolean,
  totalAmount: number | string,
  totalFee: number | string,
  searchedData: any[],
  sortedInfo: SorterType,
  trByStatus: { [key: string]: number },
  searchPhrase: string,
};

class Transactions extends Component<TransactionsProps & ContextRouter, TransactionState> {
  static contextType = InternationalizationContext;

  context: IInternationalizationContext;

  userAuth: string | null;

  csvDownloadAnchor: { current: null | HTMLAnchorElement }

  constructor(props) {
    super(props);

    this.state = {
      isSearched: false,
      searchPhrase: '',
      searchedData: [],
      sortedInfo: {},
      trByStatus: {},
      totalAmount: 0,
      totalFee: 0,
    };

    this.userAuth = null;

    this.csvDownloadAnchor = React.createRef < HTMLAnchorElement > ();
  }

  componentDidMount() {
    const { days, range: [from, to], userData: { userAuth } } = this.props;

    this.userAuth = userAuth || null;

    if (this.userAuth) {
      if (from || to) {
        this.props.getTransactions(
          this.userAuth,
          from,
          to,
          days
        );
      } else if (!from && !to) {
        this.props.getTransactions(this.userAuth, '', '', days);
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSearched, searchPhrase } = this.state;

    const { transactions = [] } = nextProps.uArrays;
    const { transactions: prevTransactions = [] } = this.props.uArrays;

    if (prevTransactions !== transactions) {
      transactions.length !== 0 && this.setState({
        trByStatus: countItemsByStatus(transactions, 'transactionType')
      });

      isSearched && this.search(searchPhrase, transactions);

      this.pagerChange(1);
    }
  }

  componentDidUpdate(prevProps) {
    const { searchedData, isSearched } = this.state;
    const { transactions = [] } = this.props.uArrays;

    if (prevProps.uArrays.transactions !== transactions) {
      let totalAmount = 0;
      let totalFee = 0;
      const dataSource = isSearched ? searchedData : transactions;

      dataSource.forEach(item => {
        totalAmount += item.amount;
        totalFee += item.fee;
      });

      // TODO Rewrite it with properly logic
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        totalAmount: totalAmount.toFixed(2),
        totalFee: totalFee.toFixed(2)
      });
    }
  }

  componentWillUnmount() {
    const { clearTransactions } = this.props;

    clearTransactions();
  }

  search = (searchPhrase: string = '', transactions = null) => {
    const { transactions: loadedTransactions } = this.props.uArrays;

    this.setState({ searchPhrase });

    if (!searchPhrase) {
      this.setState({
        isSearched: false,
      });
    } else {
      const searchedTransactions = (transactions || loadedTransactions).filter(item => {
        const { userEmail = '', userFullName = '', userId = '' } = item;

        return (
          userEmail.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1 ||
          userFullName.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1 ||
          String(userId)
            .toLowerCase()
            .indexOf(searchPhrase.toLowerCase()) !== -1
        );
      });

      this.setState(
        {
          searchedData: searchedTransactions
        },
        () => {
          this.setState({
            isSearched: true
          });
        }
      );
    }

    this.pagerChange(1);
  }

  onChange = (pagination: PaginationConfig, filters, sorter) => {
    this.setState({ sortedInfo: sorter });

    const currentPage = getPage(this.props.location.search);
    if (currentPage === pagination.current) {
      // This was a sort or filter change - reset the current page to 1
      this.pagerChange(1);
    }
  };

  onUserSelect = id => () => {
    const { history } = this.props;

    history.push({
      pathname: `${ROUTES.USER}/${id}`
    });
  };

  onRangeDateChange = date => {
    const { setDays, setRange } = this.props;
    const [dateFrom, dateTo] = date;

    const { userAuth } = this.props.userData;

    setRange(date);
    setDays('');

    if (userAuth && (dateFrom || dateTo)) {
      const startDate = dateFrom?.startOf('day');
      const endDate = dateTo?.endOf('day');
      this.props.getTransactions(
        userAuth,
        startDate,
        endDate,
        ''
      );
    } else if (userAuth && !dateFrom && !dateTo) {
      this.props.getTransactions(userAuth);
    }
  };

  onDaysChange = event => {
    const { setDays, setRange } = this.props;

    const { currentTarget: { value: days } } = event;

    setRange([]);
    setDays(days);

    if (this.userAuth) {
      this.props.getTransactions(this.userAuth, '', '', days);
    }
  };

  onSearch = event => {
    const {
      target: { value }
    } = event;

    this.search(value);
  };

  pagerChange = current => {
    const { history, match } = this.props;

    if (match && current) {
      history.push({
        pathname: match.url,
        search: `?page=${current}`
      });
    }
  };

  performCSVExport = async () => {
    if (!this.csvDownloadAnchor.current) {
      return;
    }

    const { range: [from, to], days, userData: { userAuth } } = (this.props: TransactionsProps);
    const apiDelegate = (userAuth: string) => exportTransactions(userAuth, Number.parseInt(days, 10), from, to);

    await performCSVExport(userAuth, apiDelegate, this.props.notifyCSVExportFailure, this.csvDownloadAnchor.current);
  }

  onCSVExportClick = (_event: SyntheticEvent<HTMLButtonElement>) => {
    this.performCSVExport();
  }

  render() {
    const {
      isSearched,
      searchedData,
      sortedInfo = {},
      trByStatus,
      totalAmount,
      totalFee,
    } = this.state;

    const { days, range } = this.props;

    const {
      load: isLoaded = false,
      error: isError = false,
      message: errorMsg = '',
      transactions = []
    } = this.props.uArrays;

    // eslint-disable-next-line no-nested-ternary
    const dataSource = isSearched ? searchedData : transactions;

    const trStatuses = Object.keys(trByStatus).map(item => ({
      text: item,
      value: item
    }));

    /* TODO: Clarify which transaction types are relevant for the Admin Panel */
    /* eslint-disable no-unused-vars */
    const {
      ADD_CARD = '0',
      ADD_FRIEND = '0',
      ADD_PRO = '0',
      ADD_PRO_EARNING = '0',
      BOOKING_PAYMENT = '0',
      BOOKING_PAYMENT_PRO = '0',
      BOOKING_REFUND = '0',
      BOOKING_TOP_UP = '0',
      CALL_PAY_PRO = '0',
      CALL_PAY_USER = '0',
      PACK_COMPENSATION_PRO = '0',
      PACK_PAYMENT = '0',
      PACK_PAYMENT_PRO = '0',
      PACK_REFUND = '0',
      PAYOUT = '0',
      REGISTRATION = '0',
      SYSTEM_BONUS = '0',
      SYS_CHARGE = '0',
      SYS_PAYMENT_INTENT = '0',
      TAKE_MONEY_BACK = '0',
      TAKE_PRO_EARNING = '0',
      TAKE_SYSTEM_BONUS = '0',
      TOP_UP_ADD_CARD = '0',
      TOP_UP_APPLE_PAY = '0',
      TOP_UP_AUTO_PAYMENT = '0',
      TOP_UP_CARD = '0',
      TOP_UP_MIN_AUTO_PAYMENT = '0',
    } = trByStatus;
    /* eslint-enable no-unused-vars */

    return (
      <Card cardClass="route-content">
        <PageHeader
          title="Transactions"
          backIcon={false}
          extra={[
            <Button key="1" type="primary" onClick={this.onCSVExportClick}>Export CSV</Button>
          ]}
        >
          <Row>
            <div className={style.totalCount}>
              <Statistic
                title="Total number of transactions"
                value={transactions.length}
                className={style.statisticsTag}
              />
              <Statistic
                title="Total amount"
                value={totalAmount}
                className={style.statisticsTag}
              />
              <Statistic
                title="Total Fee"
                value={totalFee}
                className={style.statisticsTag}
              />
            </div>
          </Row>
        </PageHeader>
        <div className={style.infoBar}>
          <Collapse className={style.collapseTag}>
            <Panel header="Transactions Types" key="1">
              <div className={style.statisticsBar}>
                <Descriptions
                  bordered
                  border
                  size="small"
                  column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Charge from customers credit card (captured money from customer for call (calls))">
                        <span className={style.statisticsTitle}>SYS_CHARGE</span>
                      </Tooltip>
                    )}
                  >
                    {SYS_CHARGE}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment from CUSTOMER for call">
                        <span className={style.statisticsTitle}>CALL_PAY_USER</span>
                      </Tooltip>
                    )}
                  >
                    {CALL_PAY_USER}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment to PRO for call">
                        <span className={style.statisticsTitle}>CALL_PAY_PRO</span>
                      </Tooltip>
                    )}
                  >
                    {CALL_PAY_PRO}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="PRO payout request from Stripe account to bank account">
                        <span className={style.statisticsTitle}>PAYOUT</span>
                      </Tooltip>
                    )}
                  >
                    {PAYOUT}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus for card added">
                        <span className={style.statisticsTitle}>
                          ADD_CARD
                        </span>
                      </Tooltip>
                    )}
                  >
                    {ADD_CARD}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus for invitation friend">
                        <span className={style.statisticsTitle}>ADD_FRIEND</span>
                      </Tooltip>
                    )}
                  >
                    {ADD_FRIEND}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus for invitation pro">
                        <span className={style.statisticsTitle}>
                          ADD_PRO
                        </span>
                      </Tooltip>
                    )}
                  >
                    {ADD_PRO}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus from system by administrator">
                        <span className={style.statisticsTitle}>
                          SYSTEM_BONUS
                        </span>
                      </Tooltip>
                    )}
                  >
                    {SYSTEM_BONUS}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus for registration">
                        <span className={style.statisticsTitle}>
                          REGISTRATION
                        </span>
                      </Tooltip>
                    )}
                  >
                    {REGISTRATION}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Top-up Telmie balance by credit card">
                        <span className={style.statisticsTitle}>
                          TOP_UP_CARD
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_CARD}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Top-up Telmie balance by Apple Pay">
                        <span className={style.statisticsTitle}>
                          TOP_UP_APPLE_PAY
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_APPLE_PAY}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Bonus for first save credit card (on Top up by card)">
                        <span className={style.statisticsTitle}>
                          TOP_UP_ADD_CARD
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_ADD_CARD}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="An amount manually added to PRO earnings via the Admin Panel">
                        <span className={style.statisticsTitle}>
                          ADD_PRO_EARNING
                        </span>
                      </Tooltip>
                    )}
                  >
                    {ADD_PRO_EARNING}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="An amount manually deducted from PRO earnings via the Admin Panel">
                        <span className={style.statisticsTitle}>
                          TAKE_PRO_EARNING
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TAKE_PRO_EARNING}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Take money on Stripe back to main account">
                        <span className={style.statisticsTitle}>
                          TAKE_MONEY_BACK
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TAKE_MONEY_BACK}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Deduction of a bonus from the system by administrator">
                        <span className={style.statisticsTitle}>
                          TAKE_SYSTEM_BONUS
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TAKE_SYSTEM_BONUS}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment from CUSTOMER for booking">
                        <span className={style.statisticsTitle}>
                          BOOKING_PAYMENT
                        </span>
                      </Tooltip>
                    )}
                  >
                    {BOOKING_PAYMENT}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment to PRO for booking">
                        <span className={style.statisticsTitle}>
                          BOOKING_PAYMENT_PRO
                        </span>
                      </Tooltip>
                    )}
                  >
                    {BOOKING_PAYMENT_PRO}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Topping up CUSTOMER Telmie balance when the amount paid is larger than the price of the booked appointment">
                        <span className={style.statisticsTitle}>
                          BOOKING_TOP_UP
                        </span>
                      </Tooltip>
                    )}
                  >
                    {BOOKING_TOP_UP}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Refunding the price of a cancelled appointment to CUSTOMER Telmie balance">
                        <span className={style.statisticsTitle}>
                          BOOKING_REFUND
                        </span>
                      </Tooltip>
                    )}
                  >
                    {BOOKING_REFUND}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment from CUSTOMER for package">
                        <span className={style.statisticsTitle}>PACK_PAYMENT</span>
                      </Tooltip>
                    )}
                  >
                    {PACK_PAYMENT}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Payment to PRO for package">
                        <span className={style.statisticsTitle}>PACK_PAYMENT_PRO</span>
                      </Tooltip>
                    )}
                  >
                    {PACK_PAYMENT_PRO}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Refunding the price of a returned package to CUSTOMER Telmie balance">
                        <span className={style.statisticsTitle}>
                          PACK_REFUND
                        </span>
                      </Tooltip>
                    )}
                  >
                    {PACK_REFUND}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Compensation to a PRO for package">
                        <span className={style.statisticsTitle}>
                          PACK_COMPENSATION_PRO
                        </span>
                      </Tooltip>
                    )}
                  >
                    {PACK_COMPENSATION_PRO}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Topping up CUSTOMER Telmie balance with extra money charged from credit card by auto payment">
                        <span className={style.statisticsTitle}>
                          TOP_UP_AUTO_PAYMENT
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_AUTO_PAYMENT}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Topping up CUSTOMER Telmie balance with extra money charged from credit card by minimal auto payment">
                        <span className={style.statisticsTitle}>
                          TOP_UP_MIN_AUTO_PAYMENT
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_MIN_AUTO_PAYMENT}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={(
                      <Tooltip title="Captured money from CUSTOMER for call">
                        <span className={style.statisticsTitle}>
                          SYS_PAYMENT_INTENT
                        </span>
                      </Tooltip>
                    )}
                  >
                    {TOP_UP_MIN_AUTO_PAYMENT}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </Panel>
          </Collapse>
          <div className={style.filterRow}>
            <RangePicker value={range} onChange={this.onRangeDateChange} />
            <Input
              placeholder="Enter days"
              suffix="Days"
              className={style.daysInput}
              value={days}
              onChange={this.onDaysChange}
            />
          </div>
          <div className={style.filterRow}>
            <Search
              placeholder="User Fields Search"
              onChange={this.onSearch}
              className={style.searchInput}
            />
          </div>
        </div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoaded ? (
          !isError ? (
            <Table
              columns={columns(
                sortedInfo,
                this.onUserSelect,
                trStatuses,
                this.context.formatCurrency,
                this.context.formatNumericDateTimeWithTimeZone
              )}
              rowKey={record => record.id}
              onChange={this.onChange}
              pagination={{
                pageSize: PAGE_SIZE,
                current: getPage(this.props.location.search),
                defaultCurrent: 1,
                onChange: this.pagerChange,
              }}
              dataSource={dataSource}
              scroll={{ x: true }}
            />
          ) : (
            <div className="errorContainer">
              Error!
              <div className="errorMsg">{errorMsg}</div>
            </div>
          )
        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <a style={{ display: 'none' }} href='' ref={this.csvDownloadAnchor} />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  days: selectTransactionsDays(state),
  range: selectTransactionsRange(state),
  userData: state.loggedInUser,
  uArrays: state.usersArrays,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getTransactions,
  clearTransactions,
  setDays: transactionsSetDays,
  setRange: transactionsSetRange,
  notifyCSVExportFailure: apiAddNotification,
}, dispatch);

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions)): AbstractComponent<TransactionsOwnProps>);
