/** @flow */

import { actionTypes } from './index';
import { LOCAL_STORAGE } from '../../constants';
import { activity } from '../api/users';
import type {
  Dispatch,
  ActionType,
  UserActivityGroup,
  UserActivitySort,
  ThunkAction,
} from '../../types';


export function userActivityFetch(
  userId: string,
  activityGroup: UserActivityGroup,
  activitySort: UserActivitySort = 'BY_ACTIVITY',
): ThunkAction {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionTypes.USER_ACTIVITY_FETCH_REQUEST,
        payload: {
          activityGroup,
          activitySort,
        }
      });

      const userAuth = localStorage.getItem(LOCAL_STORAGE.USER_AUTH);

      const { firstCircle: { resultDto: { results } } } = await activity(userAuth || '', userId, {
        group: activityGroup,
        sort: activitySort,
      });

      dispatch(userActivityFetchSuccess(results));
    } catch (error) {
      dispatch(userActivityFetchFailure(error));
    }
  };
}

function userActivityFetchSuccess(activities: any[]): ActionType<{ activities: any[] }> {
  return {
    type: actionTypes.USER_ACTIVITY_FETCH_SUCCESS,
    payload: {
      activities,
    },
  };
}

function userActivityFetchFailure(error?: string) {
  return {
    error,
    type: actionTypes.USER_ACTIVITY_FETCH_FAILURE,
  };
}

export function userActivitySetGroup(activityGroup: UserActivityGroup): ActionType<{ activityGroup: UserActivityGroup }> {
  return {
    type: actionTypes.USER_ACTIVITY_SET_GROUP,
    payload: { activityGroup },
  };
}
