/** @flow */

import { Store } from 'redux';
import { createSelector } from 'reselect';
import type { ActionType, SorterType } from '../../types';
import { actionTypes } from '../actions';

// Constants (down)

export const moduleName = 'archivedUsers';

// Constants (up)

// Reducer (down)

type ArchivedUsersState = {
  page: number,
  search: string,
  sorted: string,
};

const initialState: ArchivedUsersState = {
  page: 0,
  search: '',
  sorted: '',
};

export default function reducer(
  state: ArchivedUsersState = initialState, action: ActionType<>,
): ArchivedUsersState {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ARCHIVED_USERS_SET_PAGE: {
      const { page }: { page: number } = payload;

      return {
        ...state,
        page,
      };
    }

    case actionTypes.ARCHIVED_USERS_SET_SEARCH: {
      const { search }: { search: string } = payload;

      return {
        ...state,
        search,
      };
    }

    case actionTypes.ARCHIVED_USERS_SET_SORTED: {
      const { sorted }: { sorted: string } = payload;

      return {
        ...state,
        sorted,
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector = (state: Store): ArchivedUsersState => state[moduleName];

export const selectArchivedUsersPage = createSelector(
  stateSelector,
  (activeUsers: ArchivedUsersState): number => activeUsers.page,
);

export const selectArchivedUsersSearch = createSelector(
  stateSelector,
  (activeUsers: ArchivedUsersState): string => activeUsers.search,
);

export const selectArchivedUsersSorted = createSelector(
  stateSelector,
  (activeUsers: ArchivedUsersState): string => activeUsers.sorted,
);

export const selectArchivedUsersSorter = createSelector(
  selectArchivedUsersSorted,
  (sorted: string): SorterType => {
    const [field, shortOrder] = sorted.split(',');

    if (!field) {
      return {};
    }

    const order = shortOrder === 'asc' ? 'ascend' : 'descend';

    return {
      field,
      order,
      columnKey: field,
    };
  }
);

// Selectors (up)
