/** @flow */

import { createSelector } from 'reselect';
import type { InputSelector, OutputSelector } from 'reselect';
import type {
  ActiveUsersState,
  AdminPanelStore,
  ActionType,
  SorterType,
  StatusOptionType,
  ActiveUserPayload,
  SetActiveUsersPagePayload,
  SetActiveUsersSearchPayload,
  SetActiveUsersSortedPayload,
  SetActiveUsersSearchPhrasesPayload,
  SetActiveUsersSetStatusFiltersPayload,
  SetActiveUsersFiltersPayload
} from '../../types';
import { actionTypes } from '../actions';
import { statusArrs } from '../../constants';

// Constants (down)

export const moduleName = 'activeUsers';

// Constants (up)

// Reducer (down)


const initialState: ActiveUsersState = {
  page: 0,
  search: '',
  sorted: '',
  searchPhrases: {},
  statusFilters: [],
  filters: {},
};

export default function reducer(
  state: ActiveUsersState = initialState, action: ActionType<ActiveUserPayload>,
): ActiveUsersState {
  const { type, payload } = action;

  if (!payload) {
    return { ...state };
  }

  switch (type) {
    case actionTypes.ACTIVE_USERS_SET_PAGE: {
      const { page }: { page: number } = (payload: SetActiveUsersPagePayload);

      return {
        ...state,
        page,
      };
    }

    case actionTypes.ACTIVE_USERS_SET_SEARCH: {
      const { search }: { search: string } = (payload: SetActiveUsersSearchPayload);

      return {
        ...state,
        search,
      };
    }

    case actionTypes.ACTIVE_USERS_SET_SORTED: {
      const { sorted }: { sorted: string } = (payload: SetActiveUsersSortedPayload);

      return {
        ...state,
        sorted,
      }
    }

    case actionTypes.ACTIVE_USERS_SET_SEARCH_PHRASES: {
      const { searchPhrases }: { searchPhrases: { [key: string]: string } } = (payload: SetActiveUsersSearchPhrasesPayload);

      return {
        ...state,
        searchPhrases,
      };
    }

    case actionTypes.ACTIVE_USERS_SET_STATUS_FILTERS: {
      const { statusFilters }: { statusFilters: string } = (payload: SetActiveUsersSetStatusFiltersPayload);

      return {
        ...state,
        statusFilters: [...statusFilters],
      };
    }

    case actionTypes.ACTIVE_USERS_SET_FILTERS: {
      const { filters }: { filters: { [key: string]: string[] } } = (payload: SetActiveUsersFiltersPayload);

      return {
        ...state,
        filters,
      };
    }

    default:
      return state;
  }
}

// Reducer (up)

// Selectors (down)

const stateSelector: InputSelector<AdminPanelStore, any, ActiveUsersState> = (state: AdminPanelStore, _props: any): ActiveUsersState => state[moduleName];

const pageCombiner: (s: ActiveUsersState) => number = (s: ActiveUsersState): number => s.page;
const searchCombiner: (s: ActiveUsersState) => string = (s: ActiveUsersState): string => s.search;
const searchPhasesCombiner: (s: ActiveUsersState) => { [key: string]: string } = (s: ActiveUsersState): { [key: string]: string } => s.searchPhrases;
const sortedCombiner: (s: ActiveUsersState) => string = (s: ActiveUsersState): string => s.sorted;
const sorterCombiner: (sorted: string) => SorterType = (sorted: string): SorterType => {
  const [field, shortOrder] = sorted.split(',');

  if (!field) {
    return {};
  }

  const order = shortOrder === 'asc' ? 'ascend' : 'descend';

  return {
    field,
    order,
    columnKey: field,
  };
};
const statusFiltersCombiner: (s: ActiveUsersState) => string[] = (s: ActiveUsersState): string[] => s.statusFilters;
const preparedStatusFiltersCombiner: (statusFilters: string[]) => StatusOptionType[] = (statusFilters: string[]): StatusOptionType[] => (
  statusArrs.users.map(item => ({
    status: item,
    checked: statusFilters.includes(item)
  }))
);
const filtersCombiner: (s: ActiveUsersState) => { [key: string]: string[] } = (s: ActiveUsersState): { [key: string]: string[] } => s.filters;


export const selectActiveUsersPage: OutputSelector<AdminPanelStore, any, number> = createSelector(stateSelector, pageCombiner);
export const selectActiveUsersSearch: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, searchCombiner);
export const selectActiveUsersSearchPhrases: OutputSelector<AdminPanelStore, any, { [key: string]: string }> = createSelector(stateSelector, searchPhasesCombiner);
export const selectActiveUsersSorted: OutputSelector<AdminPanelStore, any, string> = createSelector(stateSelector, sortedCombiner);
export const selectActiveUsersSorter: OutputSelector<AdminPanelStore, any, SorterType> = createSelector(selectActiveUsersSorted, sorterCombiner);
export const selectActiveUsersStatusFilters: OutputSelector<AdminPanelStore, any, string[]> = createSelector(stateSelector, statusFiltersCombiner);
export const selectPreparedStatusFilters: OutputSelector<AdminPanelStore, any, StatusOptionType[]> = createSelector(selectActiveUsersStatusFilters, preparedStatusFiltersCombiner);
export const selectActiveUsersFilters: OutputSelector<AdminPanelStore, any, { [key: string]: string[] }> = createSelector(stateSelector, filtersCombiner);

// Selectors (up)
