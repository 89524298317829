import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BalanceItem from './balance-item';
import TransactionItem from './transaction-item';
import Card from '../../Layout/card';
import style from './style.module.css';
import { getUsMoney } from '../../../store/actions/user';

const MoneyTab = ({ userAuth, data = {}, getUsMoney, userId }) => {
  const { results = [] } = data;

  const showAll = () => getUsMoney(userId, 0, data.total, userAuth);

  return (
    <div style={{ paddingTop: '20px' }}>
      <div className={style.balanceLine}>
        <BalanceItem balance={data.balance} text="Telmie credit" />
        <BalanceItem balance={data.proBalance} text="Earnings" />
      </div>
      {results.length !== data.total && (
        <div className={style.showAllBtn} onClick={showAll}>
          Show all
        </div>
      )}
      <Card cardClass={style.tableCard}>
        <div className={`${style.tableRow} ${style.tableHeader}`}>
          <div>Transaction</div>
          <div>Date</div>
          <div>Type</div>
          <div>Balance</div>
        </div>

        {results.map(el => (
          <TransactionItem {...el} key={el.date} />
        ))}
        {!results.length && (
          <div className={style.emptyList}>No recent transactions</div>
        )}
      </Card>
    </div>
  );
};

MoneyTab.propTypes = {
  userAuth: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  data: PropTypes.shape({
    balance: PropTypes.number.isRequired,
    proBalance: PropTypes.number.isRequired,
  }),
  getUsMoney: PropTypes.func.isRequired,
};

MoneyTab.defaultProps = {
  data: {},
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getUsMoney
}, dispatch);

export default connect(null, mapDispatchToProps)(MoneyTab);
