/** @flow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React from 'react';
import { KEYCODE_ENTER } from '../../../constants';
import style from './style.module.css';

type SearchItemProps = {
  label?: string,
  value: any,
  name: string,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onPressEnter: ?() => void,
  isDate?: boolean
}

const SearchItem = ({ label, value, name, onChange, isDate, onPressEnter }: SearchItemProps): React$Node => (
  <div className={style.searchItem}>
    <label>{label || ''}</label>
    <input
      type={isDate ? 'date' : 'text'}
      value={value}
      name={name}
      onChange={onChange}
      onKeyDown={(e: SyntheticKeyboardEvent<HTMLInputElement>) => e.keyCode === KEYCODE_ENTER && typeof onPressEnter === 'function' && onPressEnter()}
    />
  </div>
);

export default SearchItem;
