/** @flow */

import React from 'react'

import { Switch, Icon } from 'antd';
import { connect } from 'react-redux';
import style from './style.module.css';

import { updateUserState } from '../../../store/actions/user';
import type { UserId, ActionType, UpdateUserStateData } from '../../../types';

import Card from '../../Layout/card';

type ProSettingsProps = $ReadOnly<{|
  bookingTerminate: boolean,
  cardAdded: boolean,
  emailMarketingNotification: boolean,
  emailNotification: boolean,
  pushMarketingNotification: boolean,
  showFullName: boolean,
  userAuth: string,
  selectedUserId: UserId,
  updateUserState: (data: UpdateUserStateData, id: UserId, authData: string) => ActionType<>
|}>

const renderOKFailStatusIcon = (status: boolean) =>
  status
    ? <Icon type="check-circle" theme="filled" style={{ fontSize: '24px', color: 'green' }} />
    : <Icon type="close-circle" theme="filled" style={{ fontSize: '24px', color: '#e3002b' }} />

const Settings = ({
  bookingTerminate,
  cardAdded,
  emailMarketingNotification,
  emailNotification,
  pushMarketingNotification,
  showFullName,
  userAuth,
  selectedUserId,
  updateUserState,
}: ProSettingsProps): React$Node => {

  const onChange = React.useCallback((key: string, value: boolean) => {
    const data = {
      key,
      value: !value
    };
    
    updateUserState(data, selectedUserId, userAuth);
  }, [updateUserState, userAuth]);
  
  return (
    <div>
      <div className="headline">Settings</div>
      <Card cardClass={style.proSettingsCard} class={style.cardContent}>
        <div className={style.settingsList}>

          <div className={style.settingsArea}>
            <div>Linked payment method:</div>
            <div className={style.cardStatus}>
              <div className={style.cardStatusIcon}>{renderOKFailStatusIcon(cardAdded)}</div>
              <div>{cardAdded ? 'Yes' : 'No'}</div>
            </div>
          </div>

          <div className={style.settingsArea}>
            <div>Marketing push-notifications:</div>
            <div>
              <Switch
                name="enable"
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={pushMarketingNotification}              
                onChange={() => onChange('pushMarketingNotification', pushMarketingNotification)}
              />
            </div>
          </div>
          
          <div className={style.settingsArea}>
            <div>Show full name in profile:</div>
            <div>
              <Switch
                name="enable"
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={showFullName}              
                onChange={() => onChange('showFullName', showFullName)}
              />
            </div>
          </div>

          <div className={style.settingsArea}>
            <div>Marketing emails:</div>
            <div>
              <Switch
                name="enable"
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={emailMarketingNotification}              
                onChange={() => onChange('emailMarketingNotification', emailMarketingNotification)}
              />
            </div>
          </div>

          <div className={style.settingsArea}>
            <div>Terminate call after appointment:</div>
            <div>
              <Switch
                name="enable"
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={bookingTerminate}              
                onChange={() => onChange('bookingTerminate', bookingTerminate)}
              />
            </div>
          </div>

          <div className={style.settingsArea}>
            <div>Additional email:</div>
            <div>
              <Switch
                name="enable"
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={emailNotification}              
                onChange={() => onChange('emailNotification', emailNotification)}
              />
            </div>
          </div>

        </div>        
      </Card>
    </div>
  )
};

const mapStateToProps = state => ({
  userAuth: state.loggedInUser.userAuth,
  selectedUserId: state.selectedUser.selectedUser.id,
});

const mapDispatchToProps = dispatch => ({
  updateUserState: (
    data, 
    id, 
    authData
  ) => dispatch(updateUserState(data, id, authData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

