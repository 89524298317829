import React, { Component } from "react";
import style from "./style.module.css";

const Select = props => {
  const isChanged = props.changedFields
    // eslint-disable-next-line no-prototype-builtins
    ? props.changedFields.hasOwnProperty(props.name)
    : false;

  return (
    <div
      className={`${style.formSelect} ${isChanged && style.formSelectChanged}`}
    >
      {props.label && <label>{props.label}</label>}
      <div className={style.selectContainer}>
        {isChanged ? (
          [
            <div
              className={style.prevValue}
              style={props.inputStyle ? props.inputStyle : {}}
            >
              {props.changedFields[props.name]}
            </div>,
            <select
              name={props.name}
              disabled={props.disabled}
              onChange={props.onChange}
              value={props.value}
            >
              {props.data &&
                props.data.length !== 0 &&
                props.data.map(el =>
                  props.isArrayData ? (
                    <option value={el} key={el}>
                      {el}
                    </option>
                  ) : (
                    <option value={el.value} key={el.value}>
                      {el.name}
                    </option>
                  )
                )}
            </select>
          ]
        ) : (
          <select
            name={props.name}
            disabled={props.disabled}
            onChange={props.onChange}
            value={props.value}
          >
            {props.data &&
              props.data.length !== 0 &&
              props.data.map(el =>
                props.isArrayData ? (
                  <option value={el} key={el}>
                    {el}
                  </option>
                ) : (
                  <option value={el.value} key={el.value}>
                    {el.name}
                  </option>
                )
              )}
          </select>
        )}
      </div>
    </div>
  );
};

export default Select;
