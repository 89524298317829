import React, { useEffect, useState } from 'react';
import { Statistic, Card, DatePicker, Input } from 'antd';
import { connect } from 'react-redux';

import style from './style.module.css';

import { getStatistics, getTotalStatistics } from '../../store/actions/data';
import { secToHMS } from '../../helpers';

const { RangePicker } = DatePicker;

const Home = ({
  getTStatistics,
  userData,
  serverData = {},
  getFilteredStatistics
}) => {
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState([]);
  const [days, setDays] = useState('');
  useEffect(() => {
    let { userAuth } = userData;
    // eslint-disable-next-line no-unused-expressions
    userAuth && getTStatistics(userAuth);
  }, []);

  useEffect(() => {
    if (
      !serverData.calls ||
      !serverData.transactions ||
      !serverData.chats ||
      !serverData.users
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [serverData]);

  const { calls = {}, transactions = {}, chats = {}, users = {} } = serverData;

  const {
    autoCall = 0,
    autoCallFailed = 0,
    autoCallSuccess = 0,
    directCall = 0,
    directCallFailed = 0,
    directCallSuccess = 0,
    featuredCall = 0,
    featuredCallFailed = 0,
    featuredCallSuccess = 0,
    total = 0,
    totalAmount = 0,
    totalDuration = 0,
    totalFailed = 0,
    totalSuccess = 0
  } = calls;

  const {
    bonusTelmieCredit = 0,
    payStripeToPro = 0,
    payTelmieToPro = 0,
    telmieFee = 0,
    topUpApplePay = 0,
    topUpCard = 0,
    withdrawal = 0
  } = transactions;

  const { archivedUsers = 0, total: totalUsers = 0, totalPros = 0 } = users;

  const { totalMessages = 0 } = chats;

  const onRangeDateChange = date => {
    let { userAuth } = userData;
    const [dateFrom, dateTo] = date;
    setRange(date);
    if (userAuth && (dateFrom || dateTo)) {
      
      const startDate = dateFrom?.startOf('day');
      const endDate = dateTo?.endOf('day');
      getFilteredStatistics(userAuth, startDate, endDate, '');

      setDays('');
    } else if (userAuth && !dateFrom && !dateTo) {
      getTStatistics(userAuth);
    }
  };

  const onDaysChange = e => {
    let { userAuth } = userData;
    setDays(e.target.value);
    if (userAuth && e.target.value) {
      getFilteredStatistics(userAuth, '', '', e.target.value);

      setRange([]);
    } else if (userAuth && !e.target.value) {
      getTStatistics(userAuth);
    }
  };

  return (
    <div className="route-content">
      <div className={style.filterBar}>
        <RangePicker value={range} onChange={onRangeDateChange} />
        <Input
          placeholder="Enter days"
          suffix="Days"
          className={style.daysInput}
          value={days}
          onChange={onDaysChange}
        />
      </div>
      <div className={style.cardRow}>
        <div className={style.cardColumn}>
          <Card title="Calls" className={style.card} loading={loading}>
            <Card type="inner" title="Total" className={style.subCard}>
              <div className={style.subCardInfo}>
                <Statistic
                  className={style.statistics}
                  title="Total"
                  value={total}
                />
                <Statistic
                  className={style.statistics}
                  title="Total Amount"
                  value={totalAmount}
                />
                <Statistic
                  className={style.statistics}
                  title="Total Duration"
                  value={secToHMS(totalDuration)}
                />
                <Statistic
                  className={style.statistics}
                  title="Total Failed"
                  value={totalFailed}
                />
                <Statistic
                  className={style.statistics}
                  title="Total Success"
                  value={totalSuccess}
                />
              </div>
            </Card>
            <Card type="inner" title="Direct Call" className={style.subCard}>
              <div className={style.subCardInfo}>
                <Statistic
                  className={style.statistics}
                  title="Total"
                  value={directCall}
                />
                <Statistic
                  className={style.statistics}
                  title="Failed"
                  value={directCallFailed}
                />
                <Statistic
                  className={style.statistics}
                  title="Success"
                  value={directCallSuccess}
                />
              </div>
            </Card>
            <Card type="inner" title="Featured Call" className={style.subCard}>
              <div className={style.subCardInfo}>
                <Statistic
                  className={style.statistics}
                  title="Total"
                  value={featuredCall}
                />
                <Statistic
                  className={style.statistics}
                  title="Failed"
                  value={featuredCallFailed}
                />
                <Statistic
                  className={style.statistics}
                  title="Success"
                  value={featuredCallSuccess}
                />
              </div>
            </Card>
            <Card type="inner" title="Auto Call" className={style.subCard}>
              <div className={style.subCardInfo}>
                <Statistic
                  className={style.statistics}
                  title="Total"
                  value={autoCall}
                />
                <Statistic
                  className={style.statistics}
                  title="Failed"
                  value={autoCallFailed}
                />
                <Statistic
                  className={style.statistics}
                  title="Success"
                  value={autoCallSuccess}
                />
              </div>
            </Card>
          </Card>
        </div>
        <div className={style.cardColumn}>
          <Card title="Transactions" className={style.card} loading={loading}>
            <div className={style.subInfo}>
              <Card>
                <div className={style.subCardInfo}>
                  <Statistic
                    className={style.statistics}
                    title="Bonus Telmie Credit"
                    value={bonusTelmieCredit}
                  />
                  <Statistic
                    className={style.statistics}
                    title="Pay Stripe To Pro"
                    value={payStripeToPro}
                  />
                  <Statistic
                    className={style.statistics}
                    title="Pay Telmie To Pro"
                    value={payTelmieToPro}
                  />
                  <Statistic
                    className={style.statistics}
                    title="Telmie Fee"
                    value={telmieFee}
                  />
                </div>
              </Card>
              <Card>
                <div className={style.subCardInfo}>
                  <Statistic
                    className={style.statistics}
                    title="Top-up Apple Pay"
                    value={topUpApplePay}
                  />
                  <Statistic
                    className={style.statistics}
                    title="Top-up Card"
                    value={topUpCard}
                  />
                  <Statistic
                    className={style.statistics}
                    title="Withdrawal"
                    value={withdrawal}
                  />
                </div>
              </Card>
            </div>
          </Card>
          <Card title="Users" className={style.card} loading={loading}>
            <div className={style.subInfo}>
              <Card>
                <div className={style.subCardInfo}>
                  <Statistic title="Total Users" value={totalUsers} />
                  <Statistic title="Archived Users" value={archivedUsers} />
                  <Statistic title="Total Pro Users" value={totalPros} />
                </div>
              </Card>
            </div>
          </Card>
          <Card title="Chats" className={style.card} loading={loading}>
            <Card>
              <div className={style.subCardInfo}>
                <Statistic title="Total Messages" value={totalMessages} />
              </div>
            </Card>
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  serverData: state.serverData
});

const mapDispatchToProps = dispatch => ({
  getTStatistics: authData => dispatch(getTotalStatistics(authData)),
  getFilteredStatistics: (authData, start, end, days) =>
    dispatch(getStatistics(authData, start, end, days))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
