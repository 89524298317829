import React from 'react';
import { useParams } from 'react-router-dom';

import 'antd/dist/antd.css';

import Card from '../../components/Layout/card';
import UserInfo from '../../components/user-info';

const User = () => {
  const { id } = useParams();

  return (
    <Card cardClass="route-content" headerText="Active user">
      <UserInfo
        userId={id}
        isPending={false}
        isForDelete={false}
      />
    </Card>
  );
};

export default User;
