import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Spin } from "antd";
import Card from "../../Layout/card";
import FeaturedService from "./featured-service";

import style from "./style.module.css";

import {
  setFeaturedServiceByUser, getAllFeaturedServicesByUser,
} from "../../../store/actions/user";

class UserFeaturedServices extends Component {
  componentDidMount() {
    const { getAllFeaturedServicesByUser, authData, user: { id } } = this.props;
    getAllFeaturedServicesByUser({ userId: id }, authData);
  }

  render() {
    const { selectedUser, user, isPending, setFeaturedServiceByUser, authData } = this.props;
    let featuredServices = [];
    if(selectedUser.userFeateuredServices) {
      const { selectedUser: { userFeateuredServices: { results } } } = this.props;
      if(results && results.length > 0) {
        const { updateProData } = user || {};
        const { enabledFeaturedServices = [] } = updateProData || {};
        featuredServices = results.map(service => {
          const serviceId = service && service.featuredInfo && service.featuredInfo.id || null;
          const isAgreement = enabledFeaturedServices.indexOf(serviceId);
          if (isAgreement >= 0) {
            service.agreement = true;
          }
          return (
            <FeaturedService
              data={service}
              key={service.featuredInfo.id}
              user={user}
              isPending={isPending}
              setFeaturedServiceByUser={setFeaturedServiceByUser}
              authData={authData}
            />
          )
        });
      }
    }
    return (
      <div className="featuredServiceCard">
        <div className="headline">Featured services</div>
        <Card cardClass={style.featuredServices} class={style.cardContent}>
          {featuredServices.length > 0 ? featuredServices : <Spin /> }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  selectedUser: state.selectedUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setFeaturedServiceByUser,
      getAllFeaturedServicesByUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFeaturedServices);
