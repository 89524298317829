/** @flow */

import { http } from './fetch-wrapper';
import { apiUrls } from './index';

export const getPartners = (authData: string) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);

  const requestOptions = { method: 'GET', headers };

  return http(apiUrls.PARTNERS, requestOptions);
};

export const managePartners = (method, authData, data) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method, headers, body: JSON.stringify(data) };

  return http(apiUrls.PARTNERS, requestOptions);
};

export const updatePartnersAutoLinkingEmails = (authData, data) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method: 'POST', headers, body: JSON.stringify(data) };

  return http(apiUrls.UPDATE_PARTNER_AUTOLINKING_EMAILS, requestOptions);
};

export const getAutoLinkingEmails = (authData, tag) => {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authData}`);
  headers.append('Content-Type', 'application/json ');

  const requestOptions = { method: 'GET', headers };

  return http(apiUrls.GET_PARTNER_AUTOLINKING_EMAILS(tag), requestOptions);
};

export const addPartner = (authData, data) => managePartners('POST', authData, data);

export const editPartner = (authData, data) => managePartners('PUT', authData, data);

export const deletePartner = (authData, data) => managePartners('DELETE', authData, data);

