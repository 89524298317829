/** @flow */
/* eslint-disable react/prop-types */ import React, {
  type AbstractComponent,
  useState,
} from 'react';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import Paper from '@material-ui/core/Paper';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import Tab from '@material-ui/core/Tab';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import TabContext from '@material-ui/lab/TabContext';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import TabList from '@material-ui/lab/TabList';
import { connect, useDispatch, useSelector } from 'react-redux';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import LinearProgress from '@material-ui/core/LinearProgress';
// $FlowFixMe[cannot-resolve-module] Lack of typings for MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'antd';
import {
  selectUserActivityEntities,
  selectUserActivityGroup,
  selectUserActivityIsFetching
} from '../../store/reducers/userActivity';
import type { UserActivityGroup, UserActivityItem, AdminPanelStore } from '../../types';
import { userActivityFetch, userActivitySetGroup } from '../../store/actions/userActivity';
import { infoColumns } from '../../helpers/table-data';
import { INFO_TYPES, PAGE_SIZE } from '../../constants';

type PeopleTab = {
  name: string,
  slug: string,
};

const PEOPLE_TAB_SLUGS = {
  CLIENTS: 'CLIENTS',
  PROS: 'PROS',
  FRIENDS: 'FRIENDS',
};

const PEOPLE_TABS: PeopleTab[] = [
  {
    name: 'Clients',
    slug: PEOPLE_TAB_SLUGS.CLIENTS,
  },
  {
    name: 'Pros',
    slug: PEOPLE_TAB_SLUGS.PROS,
  },
  {
    name: 'Friends',
    slug: PEOPLE_TAB_SLUGS.FRIENDS,
  }
];

const useStyles = makeStyles({
  mainContent: {
    padding: '16px',
  },
  people: {
    position: 'relative',
  },
});

type PeopleOwnProps = $ReadOnly<{|
  isPro: boolean;
userId: string;
|}>

  type PeopleProps = {
    ...PeopleOwnProps,
    isUserActivityFetching: boolean;
    activityEntities: UserActivityItem[];
  };

function People(props: PeopleProps) {
  const { isPro, isUserActivityFetching, userId, activityEntities } = props;

  const classes = useStyles();

  const userActivityGroup: UserActivityGroup = useSelector(selectUserActivityGroup);

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    if (!isPro && userActivityGroup === 'CLIENTS') {
      dispatch(userActivitySetGroup('PROS'));
    }
  }, [isPro, dispatch]);

  React.useEffect(() => {
    dispatch(userActivityFetch(userId, userActivityGroup));
  }, [userActivityGroup, dispatch]);

  const handleTabChange = (event, tab: UserActivityGroup) => {
    dispatch(userActivitySetGroup(tab));
  };

  const onChange = (pagination, _filters, _sorter) => {
    setCurrentPage(pagination.current !== currentPage ? pagination.current : 1);
  };

  return (
    <TabContext value={userActivityGroup}>
      <Paper className={classes.people}>
        <TabList
          indicatorColor="primary"
          textColor="primary"
          value={userActivityGroup}
          onChange={handleTabChange}
        >
          {PEOPLE_TABS.map(({ name, slug }) => (
            <Tab
              key={slug}
              disabled={isUserActivityFetching || (slug === PEOPLE_TAB_SLUGS.CLIENTS && !isPro)}
              label={name}
              value={slug}
            />
          ))}
        </TabList>
        <Paper className={classes.mainContent}>
          {isUserActivityFetching ? (
            <LinearProgress />
          ) : (
            <Table
              columns={infoColumns(INFO_TYPES.ACTIVITIES)}
              rowKey={record => record.id}
              pagination={{ pageSize: PAGE_SIZE, current: currentPage }}
              dataSource={activityEntities}
              onChange={onChange}
            />
          )}
        </Paper>
      </Paper>
    </TabContext>
  );
}

const mapStateToProps = (state: AdminPanelStore) => ({
  isUserActivityFetching: selectUserActivityIsFetching(state),
  activityEntities: selectUserActivityEntities(state),
});

export default (connect(mapStateToProps)(People): AbstractComponent<PeopleOwnProps>);
