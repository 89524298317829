import * as pending from '../api/pending';
import { actionTypes } from './index';
import { selectUser } from './user';

const getPendingsFailure = message => ({
  type: actionTypes.PENDINGS_GETTING_FAILURE,
  message
});
const setPendings = pendings => ({
  type: actionTypes.SET_PENDINGS,
  pendings
});
const clearPendingsArr = () => ({
  type: actionTypes.CLEAR_PENDINGS
});
const getWithdrawalsFailure = message => ({
  type: actionTypes.GET_WITHDRAWALS_FAILURE,
  message
});
const setWithdrawals = withdrawals => ({
  type: actionTypes.SET_WITHDRAWALS,
  withdrawals
});
const withdrawalManipulateFailure = (message, manipulation) => ({
  type: actionTypes.WITHDRAWAL_MANIPULATE_FAILURE,
  manipulation,
  message
});
const withdrawalManipulateSuccess = (response, manipulation) => ({
  type: actionTypes.WITHDRAWAL_MANIPULATE_SUCCESS,
  manipulation
});
const startManipulation = () => ({
  type: actionTypes.WITHDRAWAL_MANIPULATE_START
});

const processingPendingStart = () => ({
  type: actionTypes.START_PROCESSING_PENDING
});
const activateUserFailure = message => ({
  type: actionTypes.PROCESSING_PENDING_FAILURE,
  message
});
const processingPendingSuccess = message => ({
  type: actionTypes.PROCESSING_PENDING_SUCCESS,
  message
});
const clearActivateStatus = () => ({
  type: actionTypes.CLEAR_ACTIVATE_USER_STATUS
});

export const getPendings = authData => async dispatch => {
  const response = await pending.getPendings(authData);

  if (response.error) {
    dispatch(getPendingsFailure(response.message));
  } else {
    dispatch(setPendings(response));
  }
};
export const clearPendings = () => dispatch => {
  dispatch(clearPendingsArr());
};

export const activateUser = (id, authData) => async dispatch => {
  dispatch(processingPendingStart());
  const response = await pending.activateUser(id, authData);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      // eslint-disable-next-line no-alert
      alert(response.message);
    }

    dispatch(activateUserFailure(response.message));
  } else {
    dispatch(processingPendingSuccess('Approved'));

    // TODO refactore this code to redirect to user profile with dispatch(push(`/user/${response.id}`)), fix store errors
    window.location.assign(`${window.location.origin}/user/${response.id}`);
  }
};
export const declineUser = (id, authData) => async dispatch => {
  dispatch(processingPendingStart());
  const response = await pending.declineUser(id, authData);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      alert(response.message);
    }
    dispatch(activateUserFailure(response.message));
  } else {
    dispatch(processingPendingSuccess('Declined'));
    dispatch(selectUser(response));
  }
};
export const deleteUserProData = (id, authData) => async dispatch => {
  dispatch(processingPendingStart());
  const response = await pending.deleteUserProData(id, authData);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      // eslint-disable-next-line no-alert
      alert(response.message);
    }
    dispatch(activateUserFailure(response.message));
  } else {
    dispatch(processingPendingSuccess('Deleted'));
    dispatch(selectUser(response));
  }
};

export const clearActivateUserStatus = () => dispatch =>
  dispatch(clearActivateStatus());

export const getWithdrawals = authData => async dispatch => {
  const response = await pending.getWithdrawals(authData);

  if (response.error) {
    dispatch(getWithdrawalsFailure(response.message));
  } else {
    dispatch(setWithdrawals(response));
  }
};

export const getWithdrawalDetails = (id, authData) => async () => {
  const response = await pending.getWithdrawalDetails(id, authData);
  // eslint-disable-next-line no-console
  console.log('[getWithdrawalDetails] response', response);
};
export const approveWithdrawal = (id, authData) => async dispatch => {
  dispatch(startManipulation());
  const response = await pending.approveWithdrawal(id, authData);
  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      // eslint-disable-next-line no-alert
      alert(response.message);
    }
    dispatch(withdrawalManipulateFailure(response.message, 'approved'));
  } else {
    dispatch(withdrawalManipulateSuccess(response, 'approved'));
    dispatch(getWithdrawals(authData));
  }
};
export const declineWithdrawal = (id, authData) => async dispatch => {
  dispatch(startManipulation());
  const response = await pending.declineWithdrawal(id, authData);

  if (response.error) {
    if (response.message === 'You don’t have enough rights') {
      // eslint-disable-next-line no-alert
      alert(response.message);
    }
    dispatch(withdrawalManipulateFailure(response.message, 'declined'));
  } else {
    dispatch(withdrawalManipulateSuccess(response, 'declined'));
    dispatch(getWithdrawals(authData));
  }
};
