/** @flow */

import { v4 } from 'uuid';
import type { NotificationType } from '../types';
import { NOTIFICATION_TYPE } from '../store/actions/api';

export type CSVExportApiDelegate = (userAuth: string) => Promise<[Blob, string]>;
export type APIFailureNotificationDelegate = (notification: NotificationType) => void;

export default async function performCSVExport(
  userAuth: ?string,
  apiDelegate: CSVExportApiDelegate,
  notificationDelegate: APIFailureNotificationDelegate,
  downloadAnchor: HTMLAnchorElement
): Promise<void> {

  if (!userAuth) {
    notificationDelegate({
      id: v4(),
      message: 'No user is logged in',
      type: NOTIFICATION_TYPE.ERROR,
    });
    return;
  }

  try {
    const [blob, fileName] = await apiDelegate(userAuth);
    const objectURL = URL.createObjectURL(blob);

    downloadAnchor.href = objectURL;
    downloadAnchor.download = fileName;
    downloadAnchor.click();
  } catch (e) {
    notificationDelegate({
      id: v4(),
      message: (e: Error).message,
      type: NOTIFICATION_TYPE.ERROR,
    });
  }
}