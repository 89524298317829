/** @flow */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Table, Spin, Collapse, Modal, Button, PageHeader } from 'antd';

import 'antd/dist/antd.css';
import Card from '../../components/Layout/card';
import PartnersForm from './partners-form';
import { partnersTableColumns as columns } from '../../helpers/table-data';
import { addPartner, editPartner, deletePartner, clearPartnersError, fetchPartners } from '../../store/actions/partners';
import { selectPartnersEntities, selectPartnersError, selectPartnersIsFetching } from '../../store/reducers/partners';
import { apiAddNotification } from '../../store/actions/api';
import { exportNetworks } from '../../store/api/csvExport';
import performCSVExport from '../../interactors/csvExport'

import style from './style.module.css';

const { Panel } = Collapse;
const DEFAULT_FIELDS_VALUE = {
  description: '',
  emailDomains: '',
  imgUrl: '',
  name: '',
  regBonus: '',
  shortName: '',
  tag: '',
  welcomeMessage: '',
  b2B: false,
  callFeePercent: '',
  autoLinkingEmails: '',
};

export type PartnerDataType = {
  description: string,
  emailDomains: string,
  imgUrl: string,
  name: string,
  regBonus: string,
  callFeePercent: string,
  shortName: string,
  tag: string,
  welcomeMessage: string,
  b2B: boolean,
  autoLinkingEmails: string,
};

type PartnersProps = {
  addPartner: () => void,
  editPartner: () => void,
  deletePartner: () => void,
  clearPartnersError: () => void,
};

type PartnersState = {
  visibleModalEdit: boolean,
  visibleModalDelete: boolean,
  errors: { [key: string]: string },
  partnerData: PartnerDataType,
  activeKeys: Array<string>,
};

class Partners extends Component<RouteChildrenProps & PartnersProps, PartnersState> {
  csvDownloadAnchor: { current: null | HTMLAnchorElement }

  constructor(props) {
    super(props);

    this.state = {
      visibleModalEdit: false,
      visibleModalDelete: false,
      errors: {},
      partnerData: DEFAULT_FIELDS_VALUE,
      activeKeys: [],
    }
    this.csvDownloadAnchor = React.createRef < HTMLAnchorElement > ();
  }

  componentDidMount() {
    const { fetchPartners, userData: { userAuth } } = this.props;
    fetchPartners(userAuth);
  }

  handleChangeField = e => {
    const { value, name } = e.target;
    let validValue = value;

    if (name === 'regBonus') {
      validValue = value.replace(/[^\d]/g, '');
    }

    if (name === 'callFeePercent') {
      if (!value.match(/^\d*\.?\d*$/)) return;
    }

    this.setState(prev => ({
      errors: {},
      partnerData: {
        ...prev.partnerData,
        [name]: validValue,
      }
    }));
  };

  handleFieldValueChanged = (name, value) => {
    this.setState(prev => ({
      errors: {},
      partnerData: {
        ...prev.partnerData,
        [name]: value,
      }
    }));
  };

  showModalEdit = () => {
    this.setState({
      visibleModalEdit: true,
    });
  };

  showModalDelete = () => {
    this.setState({
      visibleModalDelete: true,
    });
  };

  setDefaultPartnerDataState = () => (
    this.setState({
      partnerData: DEFAULT_FIELDS_VALUE,
      errors: {},
    })
  );

  handleCancel = () => {
    this.setDefaultPartnerDataState();
    this.setState({
      visibleModalEdit: false,
      visibleModalDelete: false,
    });
  };

  handleCancelError = () => {
    const { clearPartnersError } = this.props;

    clearPartnersError();
  };

  openEditModal = data => () => {
    this.showModalEdit();
    this.setState({
      partnerData: data,
      activeKeys: [],
    })
  };

  openDeleteModal = data => () => {
    this.showModalDelete();
    this.setState({
      partnerData: data,
      activeKeys: [],
    })
  };

  handleAddPartner = () => {
    const { addPartner } = this.props;
    const { partnerData } = this.state;

    addPartner(partnerData);
  };

  handleEditPartner = async () => {
    const { editPartner } = this.props;
    const { partnerData } = this.state;
       
    const isEditingSucceeded = await editPartner(partnerData);

    if(isEditingSucceeded) {
      this.setDefaultPartnerDataState(); 
      this.setState({
        visibleModalEdit: false,
      });
    }    
  };

  handleDeletePartner = () => {
    const { deletePartner } = this.props;
    const { partnerData } = this.state;
    this.setState({
      visibleModalDelete: false,
    });
    deletePartner(partnerData);
  };

  handleOnSubmit = data => {
    const { addPartner } = this.props;

    this.setDefaultPartnerDataState();
    this.setState({
      activeKeys: [],
    });
    addPartner(data);
  };

  checkTagExist = tag => {
    const { partners = [] } = this.props;
    const { partnerData } = this.state;

    return partners.some(partner => partner.tag === tag && partner.id !== partnerData.id);
  };

  validateForm = callback => {
    const { partnerData } = this.state;
    const errors = {};

    if (!partnerData.name) {
      errors.name = 'The name field is required.';
    }
    if (!partnerData.tag) {
      errors.tag = 'The tag field is required.';
    } else if (this.checkTagExist(partnerData.tag)) {
      errors.tag = 'This tag already exists.';
    }

    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      callback({
        ...partnerData,
        regBonus: parseInt(partnerData.regBonus, 10) || 0
      });
    }
  };

  performCSVExport = async () => {
    if (!this.csvDownloadAnchor.current) {
      return;
    }

    const apiDelegate = (userAuth: string) => exportNetworks(userAuth);

    await performCSVExport(
      this.props.userData.userAuth, 
      apiDelegate, this.props.addNotification, 
      this.csvDownloadAnchor.current
    );
  }

  onCSVExportClick = (_event: SyntheticEvent<HTMLButtonElement>) => {
    this.performCSVExport();
  }

  render() {
    const { isFetchingPartners, partnersError, partners } = this.props;
    const { visibleModalEdit, visibleModalDelete, partnerData, errors, activeKeys } = this.state;
    
    return (
      <Card cardClass="route-content">        
        {!isFetchingPartners ? (
          <div className="featuredService">
            <PageHeader
              title="Networks"
              backIcon={false}
              extra={[
                <Button key="1" type="primary" onClick={this.onCSVExportClick}>Export CSV</Button>
              ]}
            >
              <Collapse
                activeKey={activeKeys}
                onChange={activeKeys => {
                  this.setState({ activeKeys });
                  this.setDefaultPartnerDataState();
                }}
              >
                <Panel showArrow={false} header="Add new network" key="1">
                  <PartnersForm
                    data={partnerData}
                    errors={errors}
                    onChangeField={this.handleChangeField}
                    onFieldValueChanged={this.handleFieldValueChanged}
                    onSubmit={() => this.validateForm(this.handleOnSubmit)}
                    defaultPartnerData={DEFAULT_FIELDS_VALUE}
                  />
                </Panel>
              </Collapse>
            </PageHeader>
            <Table
              columns={columns(
                this.openEditModal,
                this.openDeleteModal,
              )}
              rowKey={record => record.id}
              onChange={this.onChange}
              onRow={this.onRow}
              size="small"
              dataSource={partners || []}
              locale={{ emptyText: 'No partners' }}
              scroll={{ x: true }}
              rowClassName={record => record.totalPros < 2 && record.totalClients < 1 ? style.noPartnersRow : ''}
            />

            { visibleModalEdit && (
              <Modal
                title={`Edit ${partnerData ? partnerData.name : 'partner'}`}
                visible={visibleModalEdit}
                width="60%"
                onCancel={this.handleCancel}
                footer={[
                  <Button key="submit" type="primary" onClick={() => this.validateForm(this.handleEditPartner)}>
                    Submit
                  </Button>,
                  <Button key="back" type="danger" onClick={this.handleCancel}>
                    Cancel changes
                  </Button>,
                ]}
              >
                <PartnersForm
                  editFS
                  data={partnerData}
                  errors={errors}
                  onChangeField={this.handleChangeField}
                  onFieldValueChanged={this.handleFieldValueChanged}
                  defaultPartnerData={DEFAULT_FIELDS_VALUE}
                />
              </Modal> 
            )}

            <Modal
              title='Delete partner'
              visible={visibleModalDelete}
              width="35%"
              onCancel={this.handleCancel}
              footer={[
                <Button key="submit" type="danger" onClick={this.handleDeletePartner}>
                  Yes
                </Button>,
                <Button key="back" type="primary" onClick={this.handleCancel}>
                  No
                </Button>,
              ]}
            >
              Are you sure you want to delete this Partner network? This may cause irreparable damage to the network connections.
            </Modal>

            {!!partnersError.length && (
              <Modal
                title='Error'
                visible={!!partnersError.length}
                width="35%"
                onCancel={this.handleCancelError}
                footer={[
                  <Button key="back" type="primary" onClick={this.handleCancelError}>
                    Ok
                  </Button>,
                ]}
              >
                <div className={style.errorMsg}>{partnersError}</div>
              </Modal>
            )}
          </div>

        ) : (
          <div className="spinContainer">
            <Spin size="large" />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <a style={{ display: 'none' }} href='' ref={this.csvDownloadAnchor} />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.loggedInUser,
  isFetchingPartners: selectPartnersIsFetching(state),
  partnersError: selectPartnersError(state),
  partners: selectPartnersEntities(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPartners,
  addPartner,
  editPartner,
  deletePartner,
  clearPartnersError,
  addNotification: apiAddNotification,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners);
