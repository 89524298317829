import React, { Component } from 'react';
import { Input, Icon, Upload, message, Checkbox, Modal, Button, Form, } from "antd";
import "antd/dist/antd.css";

import style from '../style.module.css';

const { TextArea } = Input;

class featuredServiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingImage: false,
      confirmLoading: false,
      defaultServiceData: this.props.defaultServiceData,
    }
  }

  componentDidMount() {
    this.forceUpdate();
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  handleSubmit = () => {
    const { defaultServiceDataState, createNewFeaturedServiceHandle } = this.props;
    createNewFeaturedServiceHandle();
    defaultServiceDataState();
  }

  render() {
    const { imageUrl, confirmLoading, defaultServiceData } = this.state;
    const { data, editFS, onChangeField } = this.props;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return(
      <div className={style.formCreate}>
        {data && editFS ?
          <div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service title"
                allowClear
                defaultValue={data.title}
                value={data.title}
                onChange={onChangeField}
                name="title"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service Sub-title"
                allowClear
                defaultValue={data.subTitle}
                value={data.subTitle}
                onChange={onChangeField}
                name="subTitle"
              />
            </div>
            <div className={style.formElem}>
              <TextArea
                placeholder="Service description"
                autoSize={{ minRows: 2, maxRows: 3 }}
                defaultValue={data.description}
                value={data.description}
                onChange={onChangeField}
                name="description"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Sector"
                allowClear
                defaultValue={data.sector}
                value={data.sector}
                onChange={onChangeField}
                name="sector"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Category"
                allowClear
                defaultValue={data.category}
                value={data.category}
                onChange={onChangeField}
                name="category"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service"
                allowClear
                defaultValue={data.service}
                value={data.service}
                onChange={onChangeField}
                name="service"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Amount"
                allowClear
                defaultValue={data.amount}
                value={data.amount}
                onChange={onChangeField}
                name="amount"
              />
            </div>
            <div className={style.formElem}>
              <Checkbox checked={data.fixPrice} name="fixPrise" onChange={onChangeField}>
                  Fix price
              </Checkbox>
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="imgUrl"
                allowClear
                defaultValue={data.imgUrl}
                value={data.imgUrl}
                onChange={onChangeField}
                name="imgUrl"
              />
              { data.imgUrl &&
                <img src={ data.imgUrl} alt="imgUrl" style={{ width: '100%', marginTop: '20px' }} />
              }
              {/* <div className={style.uploadText}>Upload image</div>
              <Upload
                  name="imgUrl"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={this.beforeUpload}
                  onChange={this.handleChange}
              > */}
                {/* {(imageUrl || data.imgUrl) ? <img src={imageUrl ? imageUrl : data.imgUrl} alt="imgUrl" style={{ width: '100%' }} /> : uploadButton} */}
              {/* </Upload> */}
            </div>
          </div>
        : <form ref={(el) => this.myFormRef = el}>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service title"
                allowClear
                defaultValue={defaultServiceData.title}
                value={data.title}
                onChange={onChangeField}
                name="title"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service Sub-title"
                allowClear
                defaultValue={defaultServiceData.subTitle}
                value={data.subTitle}
                onChange={onChangeField}
                name="subTitle"
              />
            </div>
            <div className={style.formElem}>
              <TextArea
                placeholder="Service description"
                autoSize={{ minRows: 2, maxRows: 3 }}
                onChange={onChangeField}
                value={data.description}
                name="description"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Sector"
                allowClear
                onChange={onChangeField}
                value={data.sector}
                name="sector"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Category"
                allowClear
                onChange={onChangeField}
                value={data.category}
                name="category"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Service"
                allowClear
                onChange={onChangeField}
                value={data.service}
                name="service"
              />
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="Amount"
                allowClear
                onChange={onChangeField}
                value={data.amount}
                name="amount"
              />
            </div>
            <div className={style.formElem} name="fixPrice">
              <Checkbox onChange={onChangeField} checked={data.fixPrice}>
                  Fix price
              </Checkbox>
            </div>
            <div className={style.formElem}>
              <Input
                size="large"
                placeholder="imgUrl"
                allowClear
                defaultValue={data.imgUrl}
                onChange={onChangeField}
                name="imgUrl"
              />
              { data.imgUrl &&
                <img src={ data.imgUrl} alt="imgUrl" style={{ width: '100%', marginTop: '20px' }} />
              }
              {/* <div className={style.uploadText}>Upload image</div>
              <Upload
                  name="imgUrl"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={this.beforeUpload}
                  onChange={this.handleChange}
              >
                {(imageUrl || data.imgUrl) ? <img src={imageUrl ? imageUrl : data.imgUrl} alt="imgUrl" style={{ width: '100%' }} /> : uploadButton}
              </Upload> */}
            </div>
            <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleSubmit}>
              Submit
            </Button>,
          </form>
        }
      </div>
    );
  }
}

export default featuredServiceForm;
