/** @flow */
/* eslint-disable react/prop-types */

import React from 'react';
import type { AbstractComponent } from 'react';

import { confirmAlert } from 'react-confirm-alert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// $FlowIgnore[cannot-resolve-module] this is a CSS import for the Webpack loader
import 'react-confirm-alert/src/react-confirm-alert.css';

import type {
  LoggedInUserState,
  SelectedUserState,
} from '../../../types';

import { INFO_TYPES, PAGE_SIZE, ROUTES } from '../../../constants';

import style from './style.module.css';

import {
  changeActiveUserStatus,
  changeProStatus,
  changeUserStatus,
  changeProfileHidden,
  getUsClients,
  getUsProsList,
  getUsMoney,
  deleteUser,
  restoreUser,
  forceLogOut
} from '../../../store/actions/user';

type AccountControlsAreaOwnProps = $ReadOnly<{|
  isForDelete: boolean,
  activeTab: string,
  changeTab: (((state: string) => string) | string) => void,
|}>

  type AccountControlsAreaProps = $ReadOnly<{|
  ...AccountControlsAreaOwnProps,
  loggedInUser: LoggedInUserState,
  selectedUser: SelectedUserState,
  changeActiveUserStatus: (id: number, value: boolean, authData: string, updateAU: boolean) => void,
  changeProStatus: (id: number, value: boolean, authData: string, updateAU: boolean) => void,
  changeProfileHidden: (id: number, value: boolean, authData: string, updateAU: boolean) => void,
  // getUsClients: (user: { id: number }, authData: string) => void,
  // getUsProsList: (id: number, authData: string) => void,
  getUsMoney: (id: number, page: number, size: number, authData: string) => void,
  deleteUser: (id: number, authData: string) => Promise<any>,
  restoreUser: (id: number, authData: string) => Promise<any>,
  forceLogOut: (id: number, authData: string) => Promise<any>
|}>

const AccountControlsArea = (props: AccountControlsAreaProps) => {
  const history = useHistory();

  const {
    loggedInUser,
    selectedUser,
    isForDelete = false,
    activeTab,
    changeActiveUserStatus,
    changeProStatus,
    changeProfileHidden,
    // getUsClients,
    // getUsProsList,
    getUsMoney,
    deleteUser,
    restoreUser,
    forceLogOut,
  } = props;

  const {
    selectedUser: user = null,
    modifyErr = false,
    modifyMsg
  } = selectedUser;

  const { userAuth } = loggedInUser;

  const submit = (actionText, action = () => { }) => () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="confirmModal">
          <p className="confirmTitle">
            Do you want to {actionText.toUpperCase()} this user?
          </p>
          <button
            type="button"
            className="confirmOkBtn"
            onClick={() => {
              action();
              onClose();
            }}
          >
            Yes
          </button>
          <button type="button" className="confirmCancelBtn" onClick={onClose}>
            No
          </button>
        </div>
      )
    });
  };

  const accDetails = () => props.changeTab(INFO_TYPES.ACC_DETAILS);

  const handleChangeStatus = () => user && userAuth && changeActiveUserStatus(user.id, user.enabled, userAuth, false);

  const handleChangeProStatus = () => user && user.pro && userAuth && changeProStatus(user.id, !!user.pro.proState.proWork, userAuth, false);

  const handleChangeProfileHidden = () => user && user.pro && userAuth && changeProfileHidden(user.id, !user.pro.proState.proHideProfile, userAuth, false);

  const handlePeopleClick = () => {
    props.changeTab(INFO_TYPES.PEOPLE);

    // eslint-disable-next-line no-console
    console.log('People clicked');
  };

  /*
  const handleGetClients = () => {
    if (user && userAuth) {
      props.changeTab(INFO_TYPES.CLIENTS);
      getUsClients(user, userAuth);
    }
  };

  const handleGetProsList = () => {
    if (user && userAuth) {
      props.changeTab(INFO_TYPES.LIST_OF_PROS);
      getUsProsList(user.id, userAuth);
    }
  };
  */

  const handleGetMoney = () => {
    if (user && userAuth) {
      props.changeTab(INFO_TYPES.MONEY);
      getUsMoney(user.id, 0, PAGE_SIZE, userAuth);
    }
  };

  const handleRestoreUser = () => {
    if (user && userAuth) {
      restoreUser(user.id, userAuth).then(res => !res.error && history.push(`${ROUTES.USER}/${user.id}`));
    }
  };

  const handleDeleteUser = () => {
    if (user && userAuth) {
      deleteUser(user.id, userAuth).then(res => !res.error && history.push(`${ROUTES.ARCHIVED_USER}/${user.id}`));
    }
  };

  const handleForceLogout = () => {
    if (user && userAuth) {
      forceLogOut(user.id, userAuth);
    }
  }

  return (
    <div>
      {isForDelete && (
        <div className={`${style.topBtnsArea} ${style.wideBtnsArea}`}>
          <button type="button" disabled>
            Permanently delete user
          </button>
          <button type="button" onClick={submit('restore', handleRestoreUser)}>
            Restore user
          </button>
        </div>
      )}
      <div className={style.topBtnsArea}>
        <button
          type="button"
          disabled={!user}
          onClick={accDetails}
          className={activeTab === INFO_TYPES.ACC_DETAILS ? style.selectedBtn : undefined}
        >
          Account details
        </button>
        <button
          type="button"
          disabled={!user}
          onClick={handlePeopleClick}
          className={activeTab === INFO_TYPES.PEOPLE ? style.selectedBtn : undefined}
        >
          People
        </button>
        <button
          type="button"
          disabled={!user}
          onClick={handleGetMoney}
          className={activeTab === INFO_TYPES.MONEY ? style.selectedBtn : undefined}
        >
          Money
        </button>
        {!isForDelete && (
          <button
            type="button"
            disabled={!user}
            onClick={submit(
              user && user.enabled ? 'Disable' : 'Enable',
              handleChangeStatus
            )}
          >
            {user && user.enabled ? 'Disable' : 'Enable'} User
          </button>
        )}

        {!isForDelete && user && user.state && user.pro && (
          <button
            type="button"
            disabled={!user}
            onClick={submit(
              user && user.pro && user.pro.proState.proWork
                ? 'Pro suspend'
                : 'Pro activate',
              handleChangeProStatus
            )}
          >
            {user && user.pro && user.pro.proState.proWork
              ? 'Pro suspend'
              : 'Pro activate'}
          </button>
        )}
        {!isForDelete && user && user.state && user.pro && (
          <button
            type="button"
            disabled={!user || user.status === 'SUSPENDED_AS_PRO'}
            onClick={submit(
              user && user.pro && user.pro.proState.proHideProfile
                ? 'Activate profile'
                : 'Hide profile',
              handleChangeProfileHidden
            )}
          >
            {user && user.pro && user.pro.proState.proHideProfile
              ? 'Activate profile'
              : 'Hide profile'}
          </button>
        )}

        {!isForDelete && (
          <button
            type="button"
            disabled={!user}
            onClick={submit('delete', handleDeleteUser)}
          >
            Delete user
          </button>
        )}

        {!isForDelete && (
          <button
            type="button"
            disabled={!user}
            onClick={submit('forcibly logout from all devices', handleForceLogout)}
          >
            Log out
          </button>
        )}

      </div>
      {modifyErr ? (
        <div style={{ color: 'red', textAlign: 'center' }}>{modifyMsg}</div>
      ) : (
        modifyMsg && (
          <div style={{ color: 'green', textAlign: 'center' }}>{modifyMsg}</div>
        )
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  selectedUser: state.selectedUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeActiveUserStatus,
  changeProStatus,
  changeUserStatus,
  changeProfileHidden,
  getUsClients,
  getUsProsList,
  getUsMoney,
  deleteUser,
  restoreUser,
  forceLogOut
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(AccountControlsArea): AbstractComponent<AccountControlsAreaOwnProps>);
